import { useAuth } from './useAuth';
import { Api, ApiError, CancelablePromise } from '../api';
import { apiConfig } from '../config/api-config';
import toaster from '../utils/toaster';

function handleApi(request: CancelablePromise<any>): Promise<any> {
	return request.catch((error: ApiError) => {
		switch (error.status) {
			case 500:
				toaster.error(
					error.body.messageList?.join('\n') ||
					'Si è verificato un errore durante la gestione della richiesta.'
				);
		}

		throw error;
	});
}

function handleApiResponse(request: CancelablePromise<any>,
	changeLoadingState: ((state) => void) | null = null,
	errorMsg = 'Si è verificato un errore durante la gestione della richiesta.',
): Promise<any> {
	return request
		.catch((error: ApiError) => {
			toaster.error(errorMsg || error.message)
			throw error;
		})
		.finally(() => {
			if (changeLoadingState) {
				changeLoadingState(false)
			}
		})
}

export const useApiClient = () => {
	const { user } = useAuth();

	apiConfig.TOKEN = user.signInUserSession.idToken.jwtToken;
	// Problema content-type è text/plain invece che application/json:
	// https://github.com/github/fetch/issues/318
	// https://stackoverflow.com/questions/54139097/fetch-api-content-type-is-sent-as-text-plain-when-its-set-to-application-json
	// https://stackoverflow.com/questions/39689386/fetch-post-json-data-application-json-change-to-text-plain
	// Workaround aggiungendolo qui:
	// apiConfig.HEADERS = { 'Content-type': 'application/json', accept: '*/*' }; // forse non serve piu
	// apiConfig.HEADERS = { accept: '*/*' };

	return {
		handleApi,
		handleApiResponse,
		apiClient: new Api(apiConfig),
	};
};
