import { useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import CustomStepper from '../CustomStepper/CustomStepper';
import Riepilogo from '../AiQuotation/Riepilogo';
import Services from '../AiQuotation/Services';
import VehicleResults from '../AiQuotation/VehicleResults';
import VehicleSearch from '../AiQuotation/VehicleSearch';
import VehicleSearch1 from '../AiQuotation/VehicleSearch1';
import Optionals from '../AiQuotation/Optionals';
import VehicleSelection from '../AiQuotation/VehicleSelection';
import dataStepOne from './Data/data.json'
import { useAiContext } from '../../context/AiContext';

import './AiQuotation.scss';


interface AiQuotationProps {
  data?: any;
  showModal: (type, params) => void;
  setDownlodPdfValues: (value) => void;
}


const getSectionComponent = (activeStep, accordionInfoFields, optionals, showModal, setDownlodPdfValues) => {
  switch (activeStep) {
    case 0:
      return (
        <>
          <VehicleSearch1 fields={dataStepOne.carBrandsAndModels} />
          <VehicleSearch />
        </>
      );
    case 1: return (
      <>
        <Riepilogo accordionInfoFields={accordionInfoFields} />
        <Optionals optionals={optionals} />
      </>
    );
    case 2: return (
      <>
        <Riepilogo accordionInfoFields={accordionInfoFields} />
        <Services />
      </>
    );
    case 3: return (
      <>
        <Riepilogo accordionInfoFields={accordionInfoFields} />
        <Services />
        <VehicleSelection />
        <VehicleResults showModal={showModal} setDownlodPdfValues={setDownlodPdfValues}/>
      </>
    );
    default: return null;
  }
}

const getCarInfo = (data) => {
  const { dati_veicolo: { marca, modello, alimentazione, allestimento } } = data;
  return [
    { title: i18next.t('brand'), value: marca },
    { title: i18next.t('model'), value: modello },
    { title: i18next.t('car_setup'), value: allestimento },
    { title: i18next.t('fuel_type'), value: alimentazione },
  ];
}

const AiQuotation: React.FC<AiQuotationProps> = ({ data = {} , showModal, setDownlodPdfValues}) => {
  const { activeStep, setActiveStep, quotReqState: { vehicleState: { optionals = [] } = {} } = {} } = useAiContext();
  const { t } = useTranslation();

  const customSteps = [
    { title: t('choose_vehicle').toUpperCase(), onClick: () => setActiveStep(0) },
    { title: t('optional').toUpperCase(), onClick: () => setActiveStep(1) },
    { title: t('services').toUpperCase(), onClick: () => setActiveStep(2) },
    { title:t('duration_km_downpayment').toUpperCase(), onClick: () => setActiveStep(3) },
  ];

  const accordionInfoFields = useMemo(() => getCarInfo(data), [data]);
  const optionalsData = useMemo(() => optionals, [optionals]);

  return (
    <Container fluid id="container">
      <Row className='mt-5'>
        <Col md={6} className="display-6  ">
         {t('quotation_with')} QUICKO
        </Col>
        <Col md={12}>
          <div>
            {t('estimated_rental_cost_disclaimer')}
          {/* La simulazione di un preventivo sviluppata con QUICKO fornisce una stima del costo previsto per il noleggio di un veicolo. */}
          </div>
          <div>
            {t('non_binding_estimation_disclaimer')}
            {/* La stima fornita non rappresenta un impegno contrattuale. */}
          </div>
        </Col>
      </Row>
      <Row>
        <CustomStepper
          steps={customSteps}
          activeStep={activeStep}
        />
      </Row>
      {getSectionComponent(activeStep, accordionInfoFields, optionalsData, showModal, setDownlodPdfValues)}
    </Container>
  )
}

export default AiQuotation

