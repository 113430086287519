import Form from 'react-bootstrap/Form';
import i18next from 'i18next';

// input type potrebbe essere dinamico e passato come prop.
// al momento è fisso a text.
// Gestire anche number e date in customFilterFn?
const ColumnFilterInput = ({
	props,
	column: { filterValue, setFilter, preFilteredRows, id },
}) => {
	const { filterState, setFilterState, type } = props;

	const handleChange = (e) => {

		setFilterState((prevState) => {
			return {
				...prevState,
				[id]: e.target.value,
			};
		});
	};

	// invece di onchange potrei usare onBlur
	// che corrspnde a onfocusout di html
	// https://stackoverflow.com/questions/37609049/how-to-correctly-catch-change-focusout-event-on-text-input-in-react-js
	return (
		<Form.Control
			size="lg"
			type={type}
			placeholder={`${i18next.t('insert')}...`}
			value={filterState[id]}
			onChange={handleChange}
		/>
	);
};

export default ColumnFilterInput;
