import { NavLink } from 'react-router-dom';
import i18next from 'i18next';
import dayjs from 'dayjs';

import { FORMAT_DATE_TIME } from '../../utils/constants';
import ColumnFilterMultiSelect from './filters/ColumnFilterMultiSelect';
import ColumnFilterDoubleInput from '../VehicleTable/components/filters/ColumnFilterDoubleInput';
import filterFn from './customFilterFn';
import Icon from '../Icon/Icon';

export const userActionsColumnsData = [
	{
		accessor: 'id',
		id: 'id',
		Header: '',
		disableSortBy: true,
	},
	{
		accessor: 'type',
		id: 'type',
		Header: i18next.t('action_type'),
		PrimaryFilter: ColumnFilterMultiSelect,
		filter: filterFn,
		minWidth: 260,
		Cell: (data) => {
			const value = data.value.replace("_", " ").trim()
			return (
				<div>{value}</div>
			)
		}
	},
	{
		accessor: 'timestamp',
		id: 'date',
		Header: i18next.t('date'),
		minWidth: 190,
		inputType: 'date',
		filter: filterFn,
		PrimaryFilter: ColumnFilterDoubleInput,
		Cell: (data) => {
			const dateObject = dayjs(data.value);
			const formattedDate = dateObject.format(FORMAT_DATE_TIME);
			return (
				<small>{formattedDate}</small>
			)
		}
	},
	{
		accessor: 'cliente',
		id: 'cliente',
		Header: i18next.t('customer'),
		minWidth: 160
	},
	{
		accessor: 'marca',
		id: 'marca',
		Header: i18next.t('brand'),
		minWidth: 160
	},
	{
		accessor: 'modello',
		id: 'modello',
		Header: i18next.t('model'),
		minWidth: 160
	},
	{
		accessor: 'allestimento',
		id: 'allestimento',
		Header: i18next.t('car_setup'),
		minWidth: 290
	},
	{
		accessor: 'nr_ordine',
		id: 'nr_ordine',
		Header: i18next.t('n_order'),
		// minWidth: 130,
		Cell: (data: any) => {
			return (
				<NavLink to={`/details/${data.row.values.id}`}>
					{data.value}
					<Icon
						style={{ width: '1.2rem', height: '1.2rem' }}
						name="arrow-right"
					/>
				</NavLink>
			)
		},
	},
	{
		accessor: 'codice_costruttore',
		id: 'codice_costruttore',
		Header: i18next.t('builder_code'),
		minWidth: 190
	},
	{
		accessor: 'telaio',
		id: 'telaio',
		Header: i18next.t('vehicle_frame'),
		minWidth: 190
	},
	{
		accessor: 'targa',
		id: 'targa',
		Header: i18next.t('vehicle_plate'),
		minWidth: 190
	},
	{
		accessor: 'dealer',
		id: 'dealer',
		Header: i18next.t('dealer'),
		minWidth: 190
	},
];
