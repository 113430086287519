import { Form } from 'react-bootstrap';
import Tippy from '@tippyjs/react';
import i18next from 'i18next';
import 'tippy.js/dist/tippy.css';

export const columnsData = [
	{
		accessor: 'id',
		id: 'id',
		Header: '',
		disableSortBy: true,
	},
	{
		accessor: 'checked',
		id: 'checked',
		Header: '',
		disableSortBy: true,
		width: 60,
		Cell: (data: any) => (
			<Form.Group className="ms-3">
				<Form.Check
					onChange={() => data.toggleRow(data.row.original.id)}
					defaultChecked={data.row.original.checked}
					type="checkbox"
				/>
			</Form.Group>
		),
	},
	{
		accessor: 'stato_corrente.type',
		id: 'stato_veicolo',
		Header: i18next.t('state'),
		Cell: ({ value }) => (
			<small className={'vehicle-state ' + value}>{value}</small>
		),
		width: 110,
	},
	{
		width: 130,
		accessor: 'marca',
		id: 'marca',
		Header: i18next.t('brand'),
	},
	{
		accessor: 'modello',
		id: 'modello',
		Header: i18next.t('model'),
	},
	{
		width: 210,
		accessor: 'allestimento',
		id: 'allestimento',
		Header: i18next.t('car_setup'),
	},
	{
		width: 210,
		accessor: 'optionals',
		id: 'optionals',
		Header: i18next.t('optional'),
		Cell: (data) => (
			<Tippy
				placement="top-start"
				content={data.value?.map((v: any) => v.codice_costruttore)?.join(', ')}
				interactive
				disabled={data.value.length < 4}
				maxWidth={200}
			>
				<span>
					{data.value?.map((v: any) => v.codice_costruttore)?.join(', ')}
				</span>
			</Tippy>
		),
	},
	{
		accessor: 'nr_ordine',
		id: 'nr_ordine',
		Header: i18next.t('commerial_order'),

		width: 190,
	},
	{
		width: 180,
		accessor: 'codice_costruttore',
		id: 'codice_costruttore',
		Header: i18next.t('builder_code'),
	},
	{
		width: 130,
		accessor: 'telaio',
		id: 'telaio',
		Header: i18next.t('vehicle_frame'),
	},
	{
		width: 110,
		accessor: 'targa',
		id: 'targa',
		Header: i18next.t('vehicle_plate'),
	},
	{
		accessor: 'dealer',
		id: 'dealer',
		Header: i18next.t('dealer'),
		width: 160,
	},
	{
		accessor: 'stato_disp',
		id: 'stato_disp',
		Header: i18next.t('availability'),
		width: 150,
		Cell: (data) => {
			return data.row.original.stato_disp?.replace('_',' ')
		}
	},
	{
		accessor: 'data_disp',
		id: 'data_disp',
		Header: i18next.t('estimated_delivery'),
		width: 180,
	},
	{
		accessor: 'offerta_minima',
		id: 'offerta_minima',
		Header: i18next.t('min_rental_fee'),
		width: 180,
		Cell: (data) => {
			return data.row.original.offerta_minima.valore
				? `€${data.row.original.offerta_minima.valore} (${data.row.original.offerta_minima.noleggiatore})`
				: '';
		},
	},
	{
		width: 180,
		accessor: 'data_valid',
		id: 'data_valid',
		Header: i18next.t('validity_date'),
	},
	{
		width: 190,
		accessor: 'colore_esterno',
		id: 'colore_esterno',
		Header: i18next.t('external_color'),
	},
	{
		width: 190,
		accessor: 'colore_interno',
		id: 'colore_interno',
		Header: i18next.t('internal_color'),
	},
	{
		width: 120,
		accessor: 'city',
		id: 'city',
		Header: i18next.t('city'),
	},
	{
		accessor: 'data_immatricolazione',
		id: 'data_immatricolazione',
		Header: i18next.t('vehicle_registration_date'),
		width: 190,
	},
	{
		width: 170,
		accessor: 'alimentazione',
		id: 'alimentazione',
		Header: i18next.t('fuel_type'),
	},
	{
		width: 140,
		accessor: 'cambio',
		id: 'cambio',
		Header: i18next.t('vehicle_gear'),
	},
	{
		width: 130,
		accessor: 'trazione',
		id: 'trazione',
		Header: i18next.t('vehicle_drive_system'),
	},
	{
		width: 140,
		accessor: 'carrozzeria',
		id: 'carrozzeria',
		Header: i18next.t('vehicle_body'),
	},
	{
		accessor: 'promo',
		id: 'promo',
		Header: i18next.t('promo'),
		width: 150,
	},
	{
		accessor: 'promo_note',
		id: 'promo_note',
		Header: i18next.t('promo_note'),
		disableSortBy: true,
		width: 190,
	},
];
