import { Modal } from 'react-bootstrap';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import Btn from '../Btn/Btn';
import toaster from '../../utils/toaster';
import { GTMsendEvent } from '../../utils/trackingGA4'
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const DealerGroupModal = ({ isVisible, closeFn, addGroupFn }) => {
	const initialValues = { groupName: '' };
	const { t } = useTranslation()

	let validateFn = (values) => {
		const errors: any = {};
		if (!values.groupName) errors.groupName = i18next.t('mandatory');
		return errors;
	};

	const onSubmitFn = (values, { setSubmitting }) => {
		addGroupFn(values.groupName);
		setSubmitting(false);
		toaster.success(i18next.t('new_dealer_group_added'));

		GTMsendEvent({
			event: 'new_membership_group',
			groupName: values.groupName
		})
		closeFn();
	};

	return (
		<Modal size="lg" show={isVisible} onHide={closeFn}>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmitFn}
				validate={validateFn}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
				}) => {
					return (
						<Form onSubmit={handleSubmit}>
							<Modal.Header closeButton>
								<Modal.Title>{t('create_new_group')}</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<Row>
									<Col md={6}>
										<Form.Group className="mb-3">
											<Form.Label>{t('group_name')}</Form.Label>
											<Form.Control
												onChange={handleChange}
												onBlur={handleBlur}
												name="groupName"
												value={values.groupName}
												type="text"
												className={`${errors.groupName && touched.groupName
													? 'error-border'
													: ''
													}`}
											/>
											{errors.groupName && touched.groupName && (
												<small className="text-danger">
													{errors.groupName as string}
												</small>
											)}
										</Form.Group>
									</Col>
								</Row>
							</Modal.Body>
							<Modal.Footer>
								<Button
									variant="link"
									onClick={closeFn}
									disabled={isSubmitting}
								>
									{t('close')}
								</Button>
								<Btn type="submit" icon="arrow-right">
								{t('create')}
								</Btn>
							</Modal.Footer>
						</Form>
					);
				}}
			</Formik>
		</Modal>
	);
};

export default DealerGroupModal;
