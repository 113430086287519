/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OffertaBuilderDTO } from '../models/OffertaBuilderDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LeasysControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) { }

    /**
     * @returns OffertaBuilderDTO OK
     * @throws ApiError
     */
    public getLeasys(): CancelablePromise<Array<OffertaBuilderDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leasys',
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public postLeasys(
        requestBody?: OffertaBuilderDTO,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/leasys',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
     * @param id
     * @returns OffertaBuilderDTO OK
     * @throws ApiError
     */
    public getLeasys1(
        id: string,
    ): CancelablePromise<OffertaBuilderDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/leasys/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public putLeasys(
        id: string,
        requestBody?: OffertaBuilderDTO,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/leasys/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public deleteLeasys(
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/leasys/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

}
