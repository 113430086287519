import i18next from 'i18next';

export const columnsData = [
	{
		// this column is hidden
		accessor: 'id',
		id: 'id',
		Header: '',
		disableSortBy: true,
	},
	{
		accessor: 'dealer',
		id: 'dealer',
		Header: i18next.t('dealer'),
		maxWidth: 160,
	},
	{
		accessor: 'marca',
		id: 'marca',
		Header: i18next.t('brand'),
		maxWidth: 160,
	},
	{
		accessor: 'modello',
		id: 'modello',
		Header: i18next.t('model'),
		maxWidth: 190,
	},
	{
		accessor: 'nr_ordine',
		id: 'nr_ordine',
		Header: i18next.t('ord_comm'),
		maxWidth: 160,
	},
	{
		accessor: 'codice_costruttore',
		id: 'codice_costruttore',
		Header: i18next.t('cod_costr'),
		maxWidth: 160,
	},
];
