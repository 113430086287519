import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './theme/theme.scss';
import './i18n'
import '@aws-amplify/ui-react/styles.css';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import SvgIcons from './assets/svgIcons';
import { AuthProvider } from './context/AuthContext';
import { CookiesProvider } from 'react-cookie';
import { AiProvider } from './context/AiContext';

const root = ReactDOM.createRoot(document.getElementById('root')!);




root.render(
	<>
		<SvgIcons />
		{/* <React.StrictMode> */}
		<Router>
			<AuthProvider>
				{process.env.REACT_APP_QUICKO_ENABLED === '1' ? (
					<AiProvider>
						< CookiesProvider defaultSetOptions={{ path: '/' }}>
							<App />
						</CookiesProvider>
					</AiProvider>
				) : (
					<CookiesProvider defaultSetOptions={{ path: '/' }}>
						<App />
					</CookiesProvider>
				)}
			</AuthProvider>
		</Router >
		{/* </React.StrictMode> */}
	</>
);

reportWebVitals();