import { Col, Row, Accordion, Card, Button, Form } from "react-bootstrap";
import { Formik, Field } from "formik";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { baseServices, useAiContext } from "../../context/AiContext";
import { useEffect, useState, useTransition } from "react";
import "./AiQuotation.scss";
import { getCheckedServices } from "../../utils/quicko";
import { clone, cloneDeep } from 'lodash'

interface CustomToggleProps {
  children: React.ReactNode;
  eventKey: string;
  checked?: boolean;
  txtButton: string;
  flag?: string;
  data?: any;
  setIsGommeAccordionOpen?: any;
  setIsAssicurazineRcOpen?: any;
  setIsAssicurazioneKaskoOpen?: any;
  setIsFurtoIncendioOpen?: any;
  setIsVetturaSostitutivaOpen?: any;
}

const CustomToggle = ({ children, eventKey, checked, txtButton, flag = "", data = [], setIsGommeAccordionOpen, setIsAssicurazineRcOpen, setIsAssicurazioneKaskoOpen, setIsFurtoIncendioOpen, setIsVetturaSostitutivaOpen }: CustomToggleProps) => {
  const { setServices, } = useAiContext();
  const [isChecked, setIsChecked] = useState(data.some((f: any) => f.isChecked))
  const decoratedOnClick = useAccordionButton(eventKey);

  useEffect(() => {
    if (!isChecked) {

      setServices((prev: any) => {
        prev[flag]?.forEach(item => item.isChecked = false)
        return prev;
      });
    }
  }, [isChecked])

  const setDefaultValue = (e) => {
    setServices(serv => {
      serv = clone(serv)
      serv[flag][0].isChecked = e.target.checked

      return serv
    })
  }


  return (
    <Form.Check
      type="switch"
      label={txtButton}
      onClick={(e) => {
        e.stopPropagation();
        decoratedOnClick(e);
      }}
      id={`custom-toggle-${eventKey}`}
      onChange={(e) => {
        setIsChecked(e.target.checked)
        if (flag === 'gomme') {
          setIsGommeAccordionOpen(e.target.checked);
        }
        if (flag === 'assicurazioneRC') {
          setIsAssicurazineRcOpen(true) // https://support.interlogica.it/browse/HOR-458
        }
        if (flag === 'assicurazioneKasko') {
          setIsAssicurazioneKaskoOpen(e.target.checked)
        }
        if (flag === 'furtoIncendio') {
          setIsFurtoIncendioOpen(e.target.checked)
        }
        if (flag === 'vetturaSostitutiva') {
          setIsVetturaSostitutivaOpen(e.target.checked)
        }

        setDefaultValue(e)
      }}
      checked={isChecked}
    />
  );
};

const Services = () => {
  // const [numGomme, setNumGomme] = useState(1);
  // const [numGommeInvernali, setNumGommeInvernail] = useState(1);
  const [isGommeAccordionOpen, setIsGommeAccordionOpen] = useState(false);
  const [isAssicurazineRcOpen, setIsAssicurazineRcOpen] = useState(false);
  const [isAssicurazioneKaskoOpen, setIsAssicurazioneKaskoOpen] = useState(false);
  const [isFurtoIncendioOpen, setIsFurtoIncendioOpen] = useState(false);
  const [isVetturaSostitutivaOpen, setIsVetturaSostitutivaOpen] = useState(false);
  const MAX_TYRES_SET_QTY = 3

  const {
    services,
    services: { assicurazioneRC, assicurazioneKasko, furtoIncendio, gomme, vetturaSostitutiva },
    setQuotReqState,
    setActiveStep,
    setServices,
    activeStep } = useAiContext();

  const handleSubmit = (values, { setSubmitting }) => {

    if (activeStep < 3) {
      setActiveStep((prev) => prev + 1);
    }
    const checkedServices = getCheckedServices(values);

    setServices(values);
    setQuotReqState(prev => ({ ...prev, checkedServices }));
    setSubmitting(false);
  };

  useEffect(() => {
    setServices(cloneDeep(baseServices))
  }, [])

  useEffect(() => {
    setIsGommeAccordionOpen(gomme.some((g: any) => g.isChecked));
  }, [gomme]);

  useEffect(() => {
    setIsAssicurazineRcOpen(assicurazioneRC.some((g: any) => g.isChecked));
  }, [assicurazioneRC]);

  useEffect(() => {
    setIsAssicurazioneKaskoOpen(assicurazioneKasko.some((g: any) => g.isChecked));
  }, [assicurazioneKasko]);

  useEffect(() => {
    setIsFurtoIncendioOpen(furtoIncendio.some((g: any) => g.isChecked));
  }, [furtoIncendio]);

  const updateTrenoGomme = (e, values, index, type) => {
    let newValue = parseInt(e.target.value);
    if (newValue < 0) {
      newValue = 0;
    } else if (newValue > MAX_TYRES_SET_QTY) {
      newValue = MAX_TYRES_SET_QTY;
    }

    let updatedGommeOptions = [...values.gomme];
    updatedGommeOptions[index].data[type] = newValue

    setServices(prev => {
      prev.gomme = clone([...updatedGommeOptions])
      return {...prev}
    })
  }

  return (
    <Row id="services">
      <Formik
        initialValues={services}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ setFieldValue, values, handleSubmit }) => {
          return (
            <Form className="p-0" onSubmit={handleSubmit}>

              {/* Servizi Mobilità */}
              <Card className="mt-3 mb-5 p-0">
                <Card.Body className="p-0">
                  <Accordion defaultActiveKey="0" flush id="servizi_mobilita">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="accordion-titles-wrapper">Servizi Mobilità</div>
                      </Accordion.Header>
                      <Accordion.Body className="accordion-body">

                        {/*  Manutenzione */}
                        <Row className="odd">
                          <Col sm={12} className="d-flex flex-column checkbox-block">
                            <div className="custom-checkbox">
                              <label className="">
                                <Form.Check
                                  type="switch"
                                  name="manutenzione.isChecked"
                                  id='manutenzione'
                                  checked={true}
                                  disabled
                                  onChange={(e) => {
                                    setFieldValue("manutenzione.isChecked", e.target.checked);
                                    setServices(prev => {
                                      prev["manutenzione"].isChecked = e.target.checked
                                      return prev
                                    })
                                  }}
                                />
                                Manutenzione
                              </label>
                            </div>
                          </Col>
                        </Row>

                        {/* Gomme */}
                        <Accordion activeKey={isGommeAccordionOpen ? "1" : null}>
                          <Card>
                            <Card.Header>
                              <CustomToggle
                                eventKey="1"
                                data={values.gomme}
                                flag="gomme"
                                txtButton="Gomme"
                                setIsGommeAccordionOpen={setIsGommeAccordionOpen}
                              >
                                Gomme
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                              <Card.Body>
                                {gomme.map((option, index) => (
                                  <div key={index} className='custom-checkbox' style={{ "marginLeft": 20, marginTop: 10 }}>
                                    <label>
                                      <Field
                                        type="radio"
                                        className="custom-control-input"
                                        name={`gomme[${index}].isChecked`}
                                        checked={values.gomme[index].isChecked}
                                        onChange={(e) => {
                                          let updatedGommeOptions = [...values.gomme];
                                          updatedGommeOptions = updatedGommeOptions.map((item, idx) => ({
                                            ...item,
                                            isChecked: idx === index ? e.target.checked : false,
                                          }));

                                          setFieldValue("gomme", updatedGommeOptions);
                                          setServices(prev => {
                                            prev["gomme"] = updatedGommeOptions
                                            return prev
                                          })
                                        }}
                                      />
                                      {option.label}
                                      {values.gomme[index].isChecked  ?
                                        <Row className="ms-5">
                                          <Row>
                                            <Col>
                                              <Form.Group>
                                                <span>Treni Gomme estive: </span>
                                                <input
                                                  type="number"
                                                  className="table-input"
                                                  value={values.gomme[index].data.estive}
                                                  max={3}
                                                  disabled={values.gomme[index].unlimited}
                                                  onChange={(e) => {
                                                    updateTrenoGomme(e, values, index, 'estive')
                                                  }}
                                                />
                                              </Form.Group>
                                            </Col>
                                            <Col>
                                              <Form.Group>
                                                <span>Treni Gomme invernali: </span>
                                                <input
                                                  type="number"
                                                  className="table-input"
                                                  value={values.gomme[index].data.invernali}
                                                  max={3}
                                                  disabled={values.gomme[index].unlimited}
                                                  onChange={(e) => {
                                                    updateTrenoGomme(e, values, index, 'invernali')
                                                  }}
                                                />
                                              </Form.Group>
                                            </Col>
                                          </Row>
                                          <Form.Group>
                                            <Form.Check
                                              type="checkbox"
                                              label="Gomme illimitate"
                                              checked={values.gomme[index].unlimited}
                                              onChange={({ target }) => {
                                                let updatedGommeOptions = [...values.gomme];
                                                updatedGommeOptions[index].unlimited = target.checked

                                                setServices(prev => {
                                                  prev = { ...prev }
                                                  prev.gomme = [...updatedGommeOptions]
                                                  return prev
                                                })
                                              }} />
                                          </Form.Group>
                                        </Row>
                                        : ""}
                                    </label>
                                  </div>
                                ))}
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>

                        {/* Vettura sostitutiva */}
                        <Accordion activeKey={isVetturaSostitutivaOpen ? "2" : null}>
                          <Card className="odd">
                            <Card.Header className="odd">
                              <CustomToggle
                                eventKey="2"
                                data={values.vetturaSostitutiva}
                                flag="vetturaSostitutiva"
                                txtButton="Vettura Sostitutiva"
                                setIsVetturaSostitutivaOpen={setIsVetturaSostitutivaOpen}
                              >
                                Vettura Sostitutiva
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                              <Card.Body>
                                {vetturaSostitutiva.map((option, index) => {
                                  return (
                                    <div key={index} className='custom-checkbox' style={{ "marginLeft": 20 }}>
                                      <label>
                                        <Field
                                          type="radio"
                                          className="custom-control-input"
                                          name={`vetturaSostitutiva[${index}].isChecked`}
                                          checked={values.vetturaSostitutiva[index].isChecked}
                                          onChange={(e) => {
                                            let updatedVetturaSosOptions = [...values.vetturaSostitutiva];
                                            updatedVetturaSosOptions = updatedVetturaSosOptions.map((item, idx) => ({
                                              ...item,
                                              isChecked: idx === index ? e.target.checked : false,
                                            }));
                                            setFieldValue("vetturaSostitutiva", updatedVetturaSosOptions);
                                            setServices(prev => {
                                              prev["vetturaSostitutiva"] = updatedVetturaSosOptions
                                              return prev
                                            })
                                          }}
                                        />
                                        {option.label}
                                      </label>
                                    </div>
                                  )
                                })}
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Card.Body>
              </Card>


              {/* Servizi Assicurativi */}
              <Card className="mt-3 mb-5 p-0">
                <Card.Body className="p-0">
                  <Accordion defaultActiveKey="0" flush id="servizi_mobilita">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="accordion-titles-wrapper">Servizi Assicurativi</div>
                      </Accordion.Header>
                      <Accordion.Body className="accordion-body">

                        {/* assicurazioneRC */}

                        <Accordion className="odd" activeKey={isAssicurazineRcOpen ? "3" : null}>
                          <Card>
                            <Card.Header>
                              {/* <CustomToggle
                                eventKey="3"
                                data={values.assicurazioneRC}
                                flag="assicurazioneRC"
                                txtButton="Assicurazione RC"
                                setIsAssicurazineRcOpen={setIsAssicurazineRcOpen}

                              >

                                Assciruazione RCA
                              </CustomToggle> */}
                              <label>
                                <Form.Check
                                  type="switch"
                                  name="assicurazioneRC"
                                  id='assicurazioneRC'
                                  checked={true}
                                  disabled
                                />
                                Assicurazione RCA
                              </label>
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                              <Card.Body>
                                {assicurazioneRC.map((option, index) => (
                                  <div key={index} className='custom-checkbox' style={{ "marginLeft": 20 }}>
                                    <label>
                                      <Field
                                        type="radio"
                                        className="custom-control-input"
                                        name={`assicurazioneRC[${index}].isChecked`}
                                        checked={values.assicurazioneRC[index].isChecked}
                                        onChange={(e) => {
                                          let updatedAssicurazioneRCOptions = [...values.assicurazioneRC];
                                          updatedAssicurazioneRCOptions = updatedAssicurazioneRCOptions.map((item, idx) => ({
                                            ...item,
                                            isChecked: idx === index ? e.target.checked : false,
                                          }));
                                          setFieldValue("assicurazioneRC", updatedAssicurazioneRCOptions);
                                          setServices(prev => {
                                            prev["assicurazioneRC"] = updatedAssicurazioneRCOptions
                                            return prev
                                          })
                                        }}
                                      />
                                      {option.label}
                                    </label>
                                  </div>
                                ))}
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>

                        {/* assicurazioneKasko */}
                        <Accordion activeKey={isAssicurazioneKaskoOpen ? "4" : null}>
                          <Card className="odd">
                            <Card.Header className="odd">
                              <CustomToggle
                                eventKey="4"
                                data={values.assicurazioneKasko}
                                flag="assicurazioneKasko"
                                txtButton="Assicurazione Kasko"
                                setIsAssicurazioneKaskoOpen={setIsAssicurazioneKaskoOpen}
                              >
                                Assicurazione Kasko
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="4">
                              <Card.Body>
                                {assicurazioneKasko.map((option, index) => (
                                  <div key={index} className='custom-checkbox' style={{ "marginLeft": 20 }}>
                                    <label>
                                      <Field
                                        type="radio"
                                        className="custom-control-input"
                                        name={`assicurazioneKasko[${index}].isChecked`}
                                        checked={values.assicurazioneKasko[index].isChecked}
                                        onChange={(e) => {
                                          let updatedAssicurazioneKaskoOptions = [...values.assicurazioneKasko];
                                          updatedAssicurazioneKaskoOptions = updatedAssicurazioneKaskoOptions.map((item, idx) => ({
                                            ...item,
                                            isChecked: idx === index ? e.target.checked : false,
                                          }));
                                          setFieldValue("assicurazioneKasko", updatedAssicurazioneKaskoOptions);
                                          setServices(prev => {
                                            prev["assicurazioneKasko"] = updatedAssicurazioneKaskoOptions
                                            return prev
                                          })
                                        }}
                                      />
                                      {option.label}
                                    </label>
                                  </div>
                                ))}
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>

                        {/* furtoIncendio */}
                        <Accordion activeKey={isFurtoIncendioOpen ? "5" : null}>
                          <Card>
                            <Card.Header>
                              <CustomToggle
                                eventKey="5"
                                data={values.furtoIncendio}
                                flag="furtoIncendio"
                                txtButton="Furto e Incendio"
                                setIsFurtoIncendioOpen={setIsFurtoIncendioOpen}
                              >
                                furtoIncendio
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="5">
                              <Card.Body>
                                {furtoIncendio.map((option, index) => (
                                  <div key={index} className='custom-checkbox' style={{ "marginLeft": 20 }}>
                                    <label>
                                      <Field
                                        type="radio"
                                        className="custom-control-input"
                                        name={`furtoIncendio[${index}].isChecked`}
                                        checked={values.furtoIncendio[index].isChecked}
                                        onChange={(e) => {
                                          let updatedFurtoIncendioOptions = [...values.furtoIncendio];
                                          updatedFurtoIncendioOptions = updatedFurtoIncendioOptions.map((item, idx) => ({
                                            ...item,
                                            isChecked: idx === index ? e.target.checked : false,
                                          }));
                                          setFieldValue("furtoIncendio", updatedFurtoIncendioOptions);
                                          setServices(prev => {
                                            prev["furtoIncendio"] = updatedFurtoIncendioOptions
                                            return prev
                                          })
                                        }}
                                      />
                                      {option.label}
                                    </label>
                                  </div>
                                ))}
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>


                        {/*  assicurazionePAI */}
                        <Row className="odd">
                          <Col sm={12} className="d-flex flex-column checkbox-block">
                            <div className="custom-checkbox">
                              <label className="">
                                <Form.Check
                                  type="switch"
                                  name="assicurazionePAI.isChecked"
                                  id='assiscurazione-pai'
                                  checked={values.assicurazionePAI.isChecked}
                                  onChange={(e) => {
                                    setFieldValue("assicurazionePAI.isChecked", e.target.checked);
                                    setServices(prev => {
                                      prev["assicurazionePAI"].isChecked = e.target.checked
                                      return prev
                                    })
                                  }}
                                />
                                Assicurazione PAI
                              </label>
                            </div>
                          </Col>
                        </Row>

                        {/*  tutelaLegale */}
                        <Row>
                          <Col sm={12} className="d-flex flex-column checkbox-block">
                            <div className="custom-checkbox">
                              <label className="">
                                <Form.Check
                                  type="switch"
                                  name="tutelaLegale.isChecked"
                                  id='tutela-legale-pai'
                                  checked={values.tutelaLegale.isChecked}
                                  onChange={(e) => {
                                    setFieldValue("tutelaLegale.isChecked", e.target.checked);
                                    setServices(prev => {
                                      prev["tutelaLegale"].isChecked = e.target.checked
                                      return prev
                                    })
                                  }}
                                />
                                Tutela Legale
                              </label>
                            </div>
                          </Col>
                        </Row>

                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Card.Body>
              </Card>

              {activeStep === 2 && (
                <Row>
                  <div className="d-flex justify-content-center">
                    <Button className="confirm-btn" type="submit">
                      CONFERMA SERVIZI
                    </Button>
                  </div>
                </Row>
              )}
            </Form>
          )
        }}
      </Formik>
    </Row>
  );
};

export default Services;
