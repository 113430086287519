import { Table, Row, Col } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'

const TableSkeleton = ({ rows = 10 }) => {
    return (
        <Table bordered hover className="mt-3">
            <thead className="bg-light bg-white">
                <tr>
                    {[...Array(4)].map((el, i) => {
                        return <th key={i}>
                            <Row>
                                <Col><Skeleton /></Col>
                                <Col></Col>
                            </Row>
                        </th>
                    })}
                </tr>
            </thead>
            <tbody>
                {[...Array(rows)].map((el, i) => {
                    return <tr key={i}>
                        <td><Skeleton /> </td>
                        <td><Skeleton /> </td>
                        <td><Skeleton /> </td>
                        <td><Skeleton /> </td>
                    </tr>
                })}
            </tbody>
        </Table>
    );
}

export default TableSkeleton;