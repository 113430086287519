import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import { OpportunityFunnelValues } from "../../types";
import i18next from "i18next";

const OpportunitySmallPreview = ({ opportunity }: { opportunity: OpportunityFunnelValues }) => {
    const { t } = useTranslation()

    return (
        <Card >
            <Card.Body>
                <Card.Subtitle className="mb-2 text-muted">{t('opportunity_details')}</Card.Subtitle>
                <Card.Title>
                    {opportunity.entity ? (
                        <a href={'/opportunities/' + opportunity.entity.Id} target="_blank">{opportunity.name}</a>
                    ) : opportunity.name}
                </Card.Title>
                <Row className="mt-4">
                    <Col>
                        <b>{t('chanel')}</b><br />
                        {opportunity.Canale__c?.value ?? i18next.t('not_present')}
                    </Col>
                    <Col>
                        <b>{t('rental_company')}</b><br />
                        {opportunity.Noleggiatore__c?.value ?? i18next.t('not_present')}
                    </Col>
                    <Col>
                        <b>{t('commissions')}</b><br />
                        {opportunity.commissions}
                    </Col>
                    <Col>
                        <b>{t('typology')}</b><br />
                        {opportunity.Tipologia__c?.value ?? i18next.t('not_present')}
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={12}>
                        <b>{t('notes')}Note</b><br />
                        {opportunity.notes && opportunity.notes.length ? opportunity.notes : i18next.t('no_notes')}
                     </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default OpportunitySmallPreview;