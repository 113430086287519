import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Form, Card, Button } from 'react-bootstrap';

const UserActionsFilters = ({
	headerGroups,
	setAllFilters,
	loading,
	presetFilter = [],
}) => {
	const { t } = useTranslation()
	const initialState = {
		type: [],
		date: { from: '', to: '' },
	};

	// get existing user filters
	const savedFilters = sessionStorage.getItem('userActions-filters');
	const [filterState, setFilterState] = useState(
		savedFilters ? JSON.parse(savedFilters) : initialState
	);

	const applyFilters = (filterState) => {

		const filtersObjectArray: { id: string; value: any }[] = [];
		for (const [key, value] of Object.entries(filterState)) {
			filtersObjectArray.push({ id: key, value });
		}
		setAllFilters(filtersObjectArray);
	};

	useEffect(() => {
		if (!loading) {
			applyFilters(filterState);
		}

		if (presetFilter) {
			const stateWithDefaultFilter = Object.assign({}, initialState, {
				stato_corrente: [presetFilter],
			});
			setFilterState(stateWithDefaultFilter);
			applyFilters(stateWithDefaultFilter);
		}
	}, [loading]);

	const handleSearch = (e) => {
		applyFilters(filterState);
	};

	const handleReset = () => {
		setFilterState(initialState);
		applyFilters(initialState);
		sessionStorage.removeItem('userActions-filters');
	};

	// set the user filters consistent
	useEffect(() => {
		sessionStorage.setItem('userActions-filters', JSON.stringify(filterState));
		applyFilters(filterState);
	}, [filterState]);

	return (
		<Card className="filters-card">
			<Card.Header className="border-0">
				<Row>
					{headerGroups.map((headerGroup) =>
						headerGroup.headers.map((column) =>
							column.PrimaryFilter ? (
								<Col key={column.id} lg={6}>
									<Form.Group className="mb-3">
										<div key={column.id}>
											<Form.Label htmlFor={column.id}>
												{column.render('Header')}
											</Form.Label>
											{column.render('PrimaryFilter', {
												props: {
													type: column.inputType,
													filterState,
													setFilterState,
												},
											})}
										</div>
									</Form.Group>
								</Col>
							) : null
						)
					)}
				</Row>
			</Card.Header>

			<Card.Footer className="pt-0 border-0">
				<Row>
					<Col lg={12} className="open-filtri-- text-end">
						<Button className="filter-reset-btn" onClick={handleReset}>
							{t('reset_filters')}
						</Button>
					</Col>
				</Row>
			</Card.Footer>
		</Card>
	);
};

export default UserActionsFilters;