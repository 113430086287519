import { toast } from 'react-toastify';
// const { error: originalErrorFn } = toast;

// // monkey patching. Find another solution?
// // metti il default a autoclose:false !! e usa autoclose in toast che non sono error !!
// toast.error = (content: any) => {
// 	const options: any = { autoClose: false };
// 	return originalErrorFn(content, options);
// };

export default toast;
