import { VehicleFilterDTO } from "../api";
import _ from 'lodash'


export const normalizeVehicleList = (list: VehicleFilterDTO[]) => {
    const parse: any = JSON.parse(JSON.stringify(list))

    const modelLabels = {};
    parse.forEach(el => {
        // Fix brand name not always consistent
        if (el.marca) {
            el.marca = _.capitalize(el.marca);
            el.marca = el.marca!.replace('ë', 'e');
        }

        if (el.alimentazione) {
            el.alimentazione = _.capitalize(el.alimentazione);
        }

        if (el.modello) {
            el.modello_not_normalized = el.modello;

            // We normalize model names by applying some regex rules
            // saving the results to a modelLabels map to avoid re-applying
            // the same regex multiple times
            if ((el.modello_not_normalized in modelLabels) === false) {
                let modelloNormalized: string = el.modello
                    .replace(/\s\d{2,}\b/g, '')
                    .replace(/\s(I|V){1,}\b/g, '')
                    .replace(/benzina/gi, '')
                    .replace(/diesel/gi, '')
                    .replace(/-$/g, '')
                    .replace(/^doblo$/gi, 'Doblò')
                    .trim();



                modelloNormalized = modelloNormalized.length ? modelloNormalized : el.modello;
                modelLabels[el.modello_not_normalized] = modelloNormalized;
            }

            el.modello = modelLabels[el.modello_not_normalized];
        }

        if (el.city) {
            el.city = _.capitalize(el.city);
        }

        if (el.trazione) {
            el.trazione = _.capitalize(el.trazione)
                .replace('Anteriori', 'Anteriore')
                .replace('Posteriori', 'Posteriore')
        }

        if (el.cambio) {
            el.cambio = _.capitalize(el.cambio)
                .replace('Automatica', 'Automatico')
        }

        if (el.carrozzeria) {
            el.carrozzeria = _.capitalize(el.carrozzeria)
        }
    })

    return parse;
}
