import dayjs from "dayjs"
import { FORMAT_DATE_TIME, OPPORTUNITY_STATES_DATA } from "../../utils/constants"
import i18next from 'i18next';

export const opportunityColumnsData = [
    {
        accessor: 'Name',
        id: 'Name',
        Header: i18next.t('name'),
    },
    {
        accessor: 'Noleggiatore__c',
        id: 'Noleggiatore__c',
        Header: i18next.t('rental_company'),
    },
    {
        accessor: 'CreatedDate',
        id: 'CreatedDate',
        Header: i18next.t('creation_date'),
        Cell: ({ value }) => {
            return dayjs(value).format(FORMAT_DATE_TIME)
        }
    },
    {
        accessor: 'StageName',
        id: 'StageName',
        Header: i18next.t('state'),
        Cell: ({ value }) => {
            return OPPORTUNITY_STATES_DATA[value]?.label || value
        }
    }
]