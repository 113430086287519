import i18next from 'i18next';

import UserAziendeTableContainer from '../UserAziende/UserAziendeTableContainer';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import TableSkeleton from '../TableSkeleton/TableSkeleton';

function UserAziende({ columns, data, loading, error }) {
  return (
    <>
      <div className="pb-2 pt-5">
        <span className="display-6">
          {data ? i18next.t('my_companies') : ""}
        </span>
      </div>

      {!loading ? (
        <ErrorBoundary>
          <UserAziendeTableContainer
            columns={columns}
            data={data}
            loading={loading}
            error={error}
          ></UserAziendeTableContainer>
        </ErrorBoundary>
      ) : <TableSkeleton rows={3} />}
    </>
  )
}

export default UserAziende