/* eslint-disable jsx-a11y/alt-text */
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import dayjs from 'dayjs';
import i18next from 'i18next';

import { DatiVeicoloEntity, OptionalEntity } from '../../api';
import { useAiContext } from '../../context/AiContext';
import './QuickoPdfTemplate.scss';

export type PDFData = {
	lista_offerte: {
		id: string;
		durata: string;
		km_totali: string;
		servizi: {
			treno_di_gomme: string,
			kasko: string,
			rca: string,
			auto_sostitutiva: string,
			i_f: string,
		};
		anticipo: string;
	}[];
	pdf: {
		data_validita: string;
		name: string;
		userName: string;
		userSurname: string;
		userMail: string;
		offerType: string;
		offerId: string;
		importo_azienda: string;
		importo_privato: string;
		dealerDetail: {
			logo: string;
		};
	};
	optionals: OptionalEntity[];
	dati_veicolo: DatiVeicoloEntity;
}

function QuickoPdfTemplate({ data }) {
	const { quotReqState: { totalKm, rentalDuration, advancePayment, checkedServices } } = useAiContext();
	const { t } = useTranslation()


	let offer: any = {}

	offer.km_totali = totalKm;
	offer.durata = rentalDuration;
	offer.anticipo = advancePayment;
	offer.noleggiatore = data?.pdf?.processedRow?.original?.noleggiatore

	if (data?.pdf?.clientType === 'private') {
		offer.canone = (data?.pdf?.processedRow?.original?.canone * 1.22)?.toFixed(2)
	} else {
		offer.canone = (data?.pdf?.processedRow?.original?.canone)?.toFixed(2)
	}

	const isArvalPrivati = offer.noleggiatore === "arval privati"
	const maxlen = 500;
	let totlen = 0;
	data?.optionals?.map((optional) => (
		totlen += optional.descrizione.length
	))

	return (
		<div className="pdf-container">
			<div id="quickoPdf" className="pdf">
				<table>
					<tbody>
						<tr>
							<td className="col-8">
								<img src="/images/pdf/horizon-c.png" className="logo" />
								{data?.pdf?.dealerDetail?.logo ? (
									<img
										src={data?.pdf?.dealerDetail?.logo} className="logo-dealer"
									/>
								) : (
									''
								)}
								<div className='space-block'></div>
								<p className="mb-1 big">
									{t('dear')}{' '}{data?.pdf?.name?.replaceAll(' ', '\xa0\xa0')}
								</p>
								<p className="big">
									{t('following_our_rental_offer_for_vehicle')}
								</p>

								<table>
									<tbody>
										<tr>
											<td className="col-6 car-preview">
												{data?.image_list?.length && (
													<>
														{data?.image_list[0].base64 ? (
															<img src={data?.image_list[0].base64} />
														) : (
															<img
																src={data?.image_list[0]?.url?.replace(
																	'https://api.horizonautomotive.it',
																	`${process.env.REACT_APP_API_URL}/images`
																)}
															/>
														)}
													</>
												)}
												{/* {data?.image_list?.length ? (
													<img
														src={data?.image_list[0]?.url?.replace(
															'https://api.horizonautomotive.it',
															`${process.env.REACT_APP_API_URL}/images`
														)}
													/>
												) : (
													''
												)} */}
											</td>
											<td className="col-6 p-4">
												<div className="fw-bold">
													{data?.dati_veicolo?.marca}&nbsp;&nbsp;
													{data?.dati_veicolo?.modello.replaceAll(
														' ',
														'\xa0\xa0'
													)}
												</div>
												<div className="fw-bold">
													{data?.dati_veicolo?.allestimento.replaceAll(
														' ',
														'\xa0\xa0'
													)}
												</div>

												<p className="small m-0">
													{t('all_images_for_illustrative_purposes')}
												</p>
											</td>
										</tr>
									</tbody>
								</table>
								<hr className="light clear mx-5" />
								<table className="mt-3">
									<tbody>
										<tr>
											<td className="big-label">{t('rental_fee')}</td>
											<td className="big fw-bold">
												{offer?.canone?.toLocaleString('it') + ' € / mese'}

											</td>
										</tr>
										<tr>
											<td className="big-label">{t('duration')}</td>
											<td className="big fw-bold">{offer?.durata} {t('months')}</td>
										</tr>
										<tr>
											<td className="big-label">{t('km_included')}</td>

											<td className="big fw-bold">
												{isArvalPrivati ? "100.000km *" : `${offer?.km_totali?.toLocaleString('it')} / anno`}
											</td>
										</tr>
									</tbody>
								</table>

							</td>
							<td className="col-4">
								<table className="info-user table-border mb-3">
									<tbody>
										<tr>
											<td className="col-12">
												<div className="p-2 pt-0">
													{t('author')}
													<div className="fw-bold text-spacing">{data?.pdf?.userName + ' ' + data?.pdf?.userSurname}</div>
												</div>
											</td>
										</tr>
										<tr>
											<td className="col-12">
												<div className="p-2 pt-0">
													{t('mail')}
													<div className="fw-bold text-spacing">{data?.pdf?.userMail}</div>
												</div>
											</td>
										</tr>
										{data?.pdf?.userPhone ? (
											<tr>
												<td className="col-12">
													<div className="p-2 pt-0">
														{t('phone')}
														<div className="fw-bold text-spacing">{data?.pdf?.userPhone}</div>
													</div>
												</td>
											</tr>
										) : ''}
									</tbody>
								</table>
								<div className="mb-3">
									{t('reminder_number')}
									<div className="fw-bold">
										{Date.now().toString(36) +
											data?.id?.slice(-4) +
											Math.random().toString(36).substring(2)}
									</div>
								</div>

								<div className="mb-3">
									{t('recipient')}
									<div className="fw-bold">
										{data?.pdf?.name?.replaceAll(' ', '\xa0\xa0')}
									</div>
								</div>

								<div>
									{t('validity_date')}
									<div className="fw-bold">
										{data?.pdf?.data_validita || dayjs().add(3, 'weeks').format('DD/MM/YYYY')}
									</div>
								</div>

							</td>
						</tr>
					</tbody>
				</table>
				{isArvalPrivati ? (
					<div className="disclaimer-km">* 	{t('offer_includes_100000_km_for_contract_duration')}</div>
				) : ""}
				<hr className="big" />

				<table>
					<tbody>
						<tr>
							<td className="col-4 p-2">
								<img
									src="/images/pdf/ico1.png"
									className="float-start pdf-icon"
								/>
							</td>
							<td className="col-4 p-2">
								<img
									src="/images/pdf/ico2.png"
									className="float-start pdf-icon"
								/>
							</td>
							<td className="col-4 p-2">
								<img
									src="/images/pdf/ico3.png"
									className="float-start pdf-icon"
								/>
							</td>
						</tr>
					</tbody>
				</table>

				<hr className="big" />

				<table className="info">
					<tbody>
						<tr>
							<td className="col-4 col-border">
								<div className="p-2 pt-0  ">
									{(data ? [
										{ label: i18next.t('fuel_type'), value: data.dati_veicolo?.alimentazione },
										{ label: i18next.t('kw'), value: data.dati_veicolo?.kw ? `${data.dati_veicolo?.kw} kw` : undefined },
										{ label: i18next.t('cylinder_capacity'), value: data.dati_veicolo?.cilindrata },
										{ label: i18next.t('vehicle_transmission'), value: data.dati_veicolo?.cambio },
										{ label: i18next.t('vehicle_drive_system'), value: data.dati_veicolo?.trazione },
										{ label: i18next.t('doors'), value: data.dati_veicolo?.porte },
										{ label: i18next.t('seats'), value: data.dati_veicolo?.posti },
									] : []).map((spec, index) => {
										return spec.value ? (
											<div key={index}>
												{spec.label}: {spec.value}
											</div>
										) : null
									})}
								</div>


								<div className="gray-box fw-bold pe-0 w-100">
									{'Accessori e optionals aggiuntivi'.replaceAll(
										' ',
										'\xa0\xa0'
									)}
									&nbsp;&nbsp; ({data?.optionals?.length})
								</div>
								<div className="p-2 pt-0">

									{totlen < maxlen ?
										<ul>
											{data?.optionals?.map((optional, index: number) => (
												<li key={index}>
													{_.upperFirst(optional.descrizione.toLowerCase())}
												</li>
											))}
										</ul>
										:
										<span>{t('view_optionals_on_following_page')}</span>
									}

								</div>
							</td>
							<td className="col-4 col-border">
								<div className="p-2 pt-0">
									<ul>
										{checkedServices?.map((service, index) => (
											<li key={index}>{service}</li>
										))}
										{/* {data?.pdf?.services
											?.split(/[\n]+/)
											.map((service: string, index: number) => {
												service = service.trim()
												if (service.length) {
													return <li key={index}>{service}</li>
												}
												return null
											})} */}
									</ul>
								</div>
							</td>
							<td className="col-4">
								<div className="p-2  pt-0">
									<div className="float-start w-100 mb-2">
										<div className="float-start fw-bold">
											{t('total_rental_fee')} {" "}
											{data?.pdf?.clientType === 'company' ? i18next.t('vat_excluded') : i18next.t('vat_included')}
											{/* {(
												'Canone Totale' +
												(data?.pdf?.offerType === 'company'
													? ' IVA esclusa'
													: ' IVA inclusa')
											).replaceAll(' ', '\xa0\xa0')} */}
										</div>
										<div className="float-end fw-bold">
											{offer?.canone}
											{/* {offer[
												data?.pdf?.offerType === 'company'
													? 'importo_azienda'
													: 'importo_privato'
											]
												? offer[
													data?.pdf?.offerType === 'company'
														? 'importo_azienda'
														: 'importo_privato'
												]?.toLocaleString('it') + ' € *'
												: '-'} */}
										</div>
									</div>
									<hr className="light clear" />
									<div className="mt-2 float-start w-100 fw-bold">
										<div className="float-start big-label">{t('downpayment')}</div>
										<div className="float-end big mb-1">
											{offer.anticipo?.toLocaleString('it')} € **
										</div>
									</div>
									<hr className="light clear" />
									* {t('quoted_rate_excludes_additional_accessories')}
									<br />
									<br />
									{!isArvalPrivati && `** ${i18next.t('all_downpayments_exempt_from_vat')}`}
								</div>
							</td>
						</tr>
					</tbody>
				</table>

				<table className="mt-1" style={{}}>
					<tbody>
						<tr>
							<td className="footer">
								<img src="/images/pdf/logo-footer.png" className="float-end" />
								<br />
								{t('offer_valid_15_business_days')}
							</td>
						</tr>
					</tbody>
				</table>

				{totlen >= maxlen ?
					<>
						<table className='optionals'>
							<tbody>
								<tr>
									<td className="pt-3">
										<img src="/images/pdf/horizon-c.png" className="logo mb-0" />
									</td>
								</tr>
								<tr>
									<td className="">
										<span className="fw-bold big">
											{'Accessori e optionals aggiuntivi'.replaceAll(
												' ',
												'\xa0\xa0'
											)}
											&nbsp;&nbsp; ({data?.optionals?.length})
										</span>
										<ul>
											{data?.optionals?.map((optional, index: number) => (
												<li key={index}>
													{_.upperFirst(optional.descrizione.toLowerCase())}
												</li>
											))}
										</ul>
									</td>
								</tr>
							</tbody>
						</table>

						<table className="mt-5">
							<tbody>
								<tr>
									<td className="footer">
										<img src="/images/pdf/logo-footer.png" className="float-end" />
										<br />
										{t('offer_valid_15_business_days')}
									</td>
								</tr>
							</tbody>
						</table>
					</>
					: ""
				}

			</div>
		</div>
	);
}

export default QuickoPdfTemplate;
