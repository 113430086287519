import { Alert } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

const NotAuthorized = () => {
    const { t } = useTranslation()

    return (
        <div>
            <Alert variant="danger">
                <div>{t('not_authorized_to_access_this_page')}</div>
            </Alert>
        </div>
    );
}

export default NotAuthorized;
