import Container from 'react-bootstrap/Container';
import { Formik } from 'formik';
import { Card, Col, Form, Row } from 'react-bootstrap';
import Btn from '../../components/Btn/Btn';
import { useApiClient } from '../../hooks/useApiClient';
import { useEffect, useMemo, useState, useTransition } from 'react';
import VehicleDetails from '../../components/VehicleDetails/VehicleDetails';
import dayjs from 'dayjs';
import { useAuth } from '../../hooks/useAuth';
import ValidationError from '../../components/ValidationError/ValidationError';
import JatoVehicleSelector from '../../components/JatoVehicleSelector/JatoVehicleSelector';
import { ReactSelectOption, Roles } from '../../types';
import RequiredFormLabel from '../../components/RequiredFormLabel/RequiredFormLabel';
import OptionalsCard from '../../components/OptionalsCard/OptionalsCard';
import { generatePdf, mapServices } from '../../utils/pdfUtils';
import RestrictedRoute from '../../components/RestrictedRoute/RestrictedRoute';
import SpinnerButton from '../../components/SpinnerButton/SpinnerButton';
import { JatoVehicleInfo } from '../../api/models/VehicleEntity';
import _ from 'lodash'
import { useTranslation } from 'react-i18next';

let lastLoadedVehicle: { infos: JatoVehicleInfo, vehicleImages: any, id: string };

const VehicleOfferPage = () => {
    const { t } = useTranslation()
    const { user, userRole } = useAuth();
    const [isBusy, setBusy] = useState<boolean>(false)
    const { apiClient, handleApiResponse } = useApiClient();
    const [jatoBrands, setJatoBrands] = useState<(ReactSelectOption)[]>([])
    const [symIf, setSymIf] = useState("%");
    const [jatoSelectedVehicle, setJatoSelectedVehicle] = useState<{
        brand: ReactSelectOption | null,
        model: ReactSelectOption & { data: any } | null,
        images: string[]
    }>({ brand: null, model: null, images: [] })

    const initialValues = useMemo(() => {
        return {
            data_valid: dayjs().format('YYYY-MM-DD'),
            name: '',
            durata: '',
            anticipo: '',
            km_totali: '',
            importo_privato: '',
            importo_azienda: '',
            offerType: 'company',
            servizi: {
                treno_di_gomme: 'No',
                kasko: '',
                rca: '',
                auto_sostitutiva: 'No',
                i_f: '',
            },
            optionals: [
                {
                    codice_costruttore: "",
                    descrizione: "",
                    valore: 0,
                }
            ]
        }
    }, []);

    useEffect(() => {
        setBusy(true)
        handleApiResponse(apiClient.vehicleController.fuzzySearchGetBrandList(), setBusy)
            .then((data) => {
                data.sort();
                const opts = data.map((el) => {
                    return { label: el, value: el };
                });

                setJatoBrands(opts);
            })
    }, []);

    const onModelFilterchange = ((opt: any) => {
        setJatoSelectedVehicle({ brand: jatoSelectedVehicle.brand, model: opt, images: [] })
        handleApiResponse(apiClient.vehicleController.getVehicleImages(opt.value, opt.data.database))
            .then(res => {
                setJatoSelectedVehicle({ brand: jatoSelectedVehicle.brand, model: opt, images: res })
            })
    })

    const validateFn = (values) => {
        const errors: any = {};
        const REQUIRED_MESSAGE = 'Obbligatorio';

        if (!values.name) {
            errors.name = REQUIRED_MESSAGE;
        }

        if (!values.durata) {
            errors.durata = REQUIRED_MESSAGE;
        }

        if (!values.km_totali) {
            errors.km_totali = REQUIRED_MESSAGE;
        }

        return errors;
    };

    const getPdfData = async (offerta: typeof initialValues) => {
        setBusy(true)

        offerta = _.cloneDeep(offerta)
        if (offerta.servizi.i_f.length) {
            offerta.servizi.i_f = offerta.servizi.i_f + ' ' + symIf
        }

        try {
            let infos: JatoVehicleInfo;
            let vehicleImages: any = []

            if (lastLoadedVehicle && lastLoadedVehicle.id === jatoSelectedVehicle.model!.value) {
                infos = lastLoadedVehicle.infos;
                vehicleImages = lastLoadedVehicle.vehicleImages
            } else {
                infos = await handleApiResponse(
                    apiClient.vehicleController.getVehicleInfo(jatoSelectedVehicle.model!.value, jatoSelectedVehicle.model?.data.database))

                if (jatoSelectedVehicle.images.length) {
                    let vehicleImg = await handleApiResponse(apiClient.vehicleController.getJatoImageFile(jatoSelectedVehicle.images[0]))
                    vehicleImg = 'data:application/octet-stream;base64,' + vehicleImg;
                    vehicleImages.push({ base64: vehicleImg })
                } else {
                    vehicleImages.push({ url: '/images/placeholder.png' })
                }
            }

            lastLoadedVehicle = { infos, vehicleImages, id: jatoSelectedVehicle.model!.value }

            const pdfData = {
                lista_offerte: [
                    {
                        id: 'customPdf',
                        durata: offerta.durata,
                        km_totali: offerta.km_totali,
                        servizi: offerta.servizi,
                        anticipo: offerta.anticipo,
                        importo_azienda: offerta.importo_azienda,
                        importo_privato: offerta.importo_privato,
                    }
                ],
                pdf: {
                    data_validita: offerta.data_valid,
                    name: offerta.name,
                    userName: user.attributes.given_name,
                    userSurname: user.attributes.family_name,
                    userMail: user.attributes.email,
                    offerType: offerta.offerType,
                    offerId: 'customPdf',
                    dealerDetail: { logo: '' },
                    services: mapServices('', offerta.servizi),
                },
                dati_veicolo: {
                    marca: jatoSelectedVehicle.brand!.label,
                    modello: jatoSelectedVehicle.model!.label,
                    allestimento: jatoSelectedVehicle.model!.data.id_131,
                    alimentazione: infos?.fuelTypeToDisplay,
                    cambio: infos?.transmissionToDisplay,
                    cavalli: infos?.maximumPowerhpPs,
                    cilindrata: infos?.cylinderCapacity,
                    kw: infos?.maximumPowerKw,
                    porte: infos?.numberOfDoorsToDisplay,
                    posti: infos?.seats,
                },
                optionals: offerta.optionals.filter(el => el.descrizione.length > 0),
                image_list: vehicleImages
            }

            return pdfData;
        } finally {
            setBusy(false)
        }
    };

    const onSubmitFn = async (offerta: typeof initialValues, { setSubmitting }) => {
        getPdfData(offerta).then(pdfData => {
            window.dispatchEvent(new CustomEvent('download-pdf', { detail: { data: pdfData } }))
        })
    }

    return (
        <RestrictedRoute to={[Roles.Backoffice, Roles.Master, Roles.Admin, Roles.Sales]}>
            <Container fluid>
                <div className="display-6 my-4">
                    Crea offerta
                </div>

                <Formik
                    initialValues={{ ...initialValues }}
                    validate={validateFn}
                    onSubmit={onSubmitFn}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            <Card className="mt-2">
                                <Card.Body>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Intestatario preventivo
                                                    <span className="red-ast">*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="name"
                                                    value={values?.name || ''}
                                                />
                                                <ValidationError {...{ errors, touched, name: 'name' }} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <Form.Check
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={'company'}
                                                checked={values.offerType === 'company'}
                                                name="offerType"
                                                type="radio"
                                                label={t('company')}
                                                className="hor-check-box-big"
                                            />
                                        </Col>
                                        <Col md={2}>
                                            <Form.Check
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={'private'}
                                                checked={values.offerType === 'private'}
                                                name="offerType"
                                                type="radio"
                                                label={t('individual')}
                                                className="hor-check-box-big"
                                            />
                                        </Col>
                                    </Row>
                                    <div className="py-3">
                                        <hr />
                                    </div>

                                    <RequiredFormLabel label='Seleziona un veicolo' />
                                    <Row>
                                        <JatoVehicleSelector
                                            hideModelVariants={false}
                                            jatoBrands={jatoBrands}
                                            jatoSelectedVehicle={{
                                                brand: jatoSelectedVehicle.brand,
                                                model: jatoSelectedVehicle.model,
                                            }}
                                            onBrandFilterchange={(opt) => {
                                                setJatoSelectedVehicle({ model: null, brand: opt, images: [] })
                                            }}
                                            onModelFilterchange={onModelFilterchange}
                                        />
                                    </Row>

                                    <div className="py-3">
                                        <hr />
                                    </div>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Durata (mesi)
                                                    <span className="red-ast">*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    required
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="durata"
                                                    pattern="[0-9]*"
                                                    value={values?.durata || ''}
                                                />
                                                <ValidationError {...{ errors, touched, name: 'durata' }} />
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Km/anno
                                                    <span className="red-ast">*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    required
                                                    value={values?.km_totali || ''}
                                                    name="km_totali"
                                                    type="number"
                                                />
                                                <ValidationError {...{ errors, touched, name: 'km_totali' }} />
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Anticipo + IVA</Form.Label>
                                                <Form.Control
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values?.anticipo}
                                                    name="anticipo"
                                                    type="number"
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Canoni privati</Form.Label>
                                                <Form.Control
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values?.importo_privato || ''}
                                                    name="importo_privato"
                                                    type="number"
                                                />
                                                <ValidationError {...{ errors, touched, name: 'importo_privato' }} />
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Canone P.IVA</Form.Label>
                                                <Form.Control
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values?.importo_azienda || ''}
                                                    name="importo_azienda"
                                                    type="number"
                                                />
                                                <ValidationError {...{ errors, touched, name: 'importo_azienda' }} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Data validità</Form.Label>
                                                <Form.Control
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values?.data_valid || ''}
                                                    name="data_valid"
                                                    type="date"
                                                />
                                                <ValidationError {...{ errors, touched, name: 'data_valid' }} />
                                            </Form.Group>
                                        </Col>

                                        <div className="py-3">
                                            <hr />
                                        </div>

                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('set_of_tyres')}</Form.Label>
                                                <Form.Control
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values?.servizi?.treno_di_gomme || ''}
                                                    name="servizi.treno_di_gomme"
                                                    type="text"
                                                    className={`${errors.servizi?.treno_di_gomme && touched.servizi?.treno_di_gomme
                                                        ? 'error-border'
                                                        : ''
                                                        }`}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col md={5} className="pe-1">
                                            <Form.Group className="mb-3">
                                                <Form.Label>I/F</Form.Label>
                                                <Form.Control
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values?.servizi?.i_f}
                                                    name="servizi.i_f"
                                                    type="text"
                                                    pattern="[0-9,',']*"
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col md={1} className="ps-0">
                                            <Form.Group className="mt-2">
                                                <Form.Label />
                                                <Form.Select
                                                    name='sym_if'
                                                    value={symIf}
                                                    onChange={(e) => setSymIf(e.target.value)}
                                                >
                                                    <option key="%" value="%">
                                                        %
                                                    </option>
                                                    <option key="€" value="€">
                                                        €
                                                    </option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Auto sostitutiva</Form.Label>
                                                <Form.Control
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values?.servizi?.auto_sostitutiva || ''}
                                                    name="servizi.auto_sostitutiva"
                                                    type="text"
                                                    className={`${errors.servizi?.auto_sostitutiva && touched.servizi?.auto_sostitutiva
                                                        ? 'error-border'
                                                        : ''
                                                        }`}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col md={5}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>RCA (€)</Form.Label>
                                                <Form.Control
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values?.servizi?.rca}
                                                    name="servizi.rca"
                                                    pattern="[0-9,',']*"
                                                    type="text"
                                                    className={`${errors.servizi?.rca && touched.servizi?.rca
                                                        ? 'error-border'
                                                        : ''
                                                        }`}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Kasko (€)</Form.Label>
                                                <Form.Control
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values?.servizi?.kasko}
                                                    name="servizi.kasko"
                                                    pattern="[0-9,',']*"
                                                    type="text"
                                                    className={`${errors.servizi?.kasko && touched.servizi?.kasko
                                                        ? 'error-border'
                                                        : ''
                                                        }`}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            <div className="display-7 my-4">Optionals</div>

                            <OptionalsCard
                                values={values}
                                handleChange={handleChange}
                                hideCode={true}
                                addOptionDisabled={false}
                                onOptionAdded={() => { }}
                            />

                            <Btn
                                type="submit"
                                icon="arrow-right"
                                className="mt-4 float-end"
                                disabled={isSubmitting || isBusy}
                            >
                                <SpinnerButton label='Scarica preventivo' busy={isSubmitting || isBusy} />
                            </Btn>
                        </Form>
                    )}
                </Formik>

                <VehicleDetails />
            </Container>
        </RestrictedRoute>
    );
}


export default VehicleOfferPage;