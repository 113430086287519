import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import {updateConsent, sendLoginToGTM} from '../../utils/trackingGA4'
import './trackingConsent.scss'

function TrackingConsent() {
  const [show, setShow] = useState(false);
  const gaveConsent = localStorage.getItem('tracking_consent');
  const navigate = useNavigate();
  const { t } = useTranslation()

  const refreshPage = () => {
    navigate(0);
  }
  const handleAccept = () => {
    localStorage.setItem('tracking_consent', JSON.stringify(true))
    const rawIsFirstLogin = localStorage.getItem('isFirstLogin')
    const rawUserRole = localStorage.getItem('userRole')
    setShow(false)

    updateConsent()

    let isFirstLogin;
    if( rawIsFirstLogin !== null) {
      isFirstLogin = JSON.parse(rawIsFirstLogin)
    }

    let userRole;
    if(rawUserRole) {
      userRole = JSON.parse(rawUserRole)
    }
    isFirstLogin && sendLoginToGTM(true, userRole)
      // first login
      localStorage.setItem('isFirstLogin', JSON.stringify(false))
  };

  useEffect(() => {
    !gaveConsent && setShow(true);
  }, [])

  return (
      <Modal
        show={show}
        size="lg"
        centered
        keyboard
        animation
        contentClassName="cookie-consent-content"
      >
        <Modal.Header >
          <Modal.Title className='cookie-consent-title'>
          {t('data_processing_notice')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('site_uses_browsing_cookies_notice')}
          <ul className='cookie-consent-links'>
            <li>
              <a href={`/privacy-dealer/Informativa Dealer.docx`} target='_blank' rel="noreferrer">
               {t('dealer_data_processing')}</a>
            </li>
            <li>
              <a href={`/privacy-dipendenti/Informativa dipendenti Horizon.docx`} target='_blank' rel="noreferrer">
                {t('horizon_employee_data_processing')}</a>
            </li>
          </ul>
          <div className='cookie-consent-btn-container'>
            <Button
              variant="primary"
              onClick={handleAccept}
              className='cookie-consent-btn'
            >
             {t('i_understand')}
            </Button>
          </div>
        </Modal.Body>
      </Modal>

  );
}

export default TrackingConsent