/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VehicleBackAvailableDTO } from '../models/VehicleBackAvailableDTO';
import type { DocumentDeleteRequestDTO } from '../models/DocumentDeleteRequestDTO';
import type { VehicleCreateRequestDTO } from '../models/VehicleCreateRequestDTO';
import type { VehicleEntity } from '../models/VehicleEntity';
import type { VehicleFilterDTO } from '../models/VehicleFilterDTO'; 
import type { VehicleUpdateRequestDTO } from '../models/VehicleUpdateRequestDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import type { VehicleLastInsertedDTO } from '../models/VehicleLastInsertedDTO';
import type { VehicleQuickoQuotationRequestDTO } from '../models/VehicleQuickoQuotationRequestDTO';
import type { VehicleInEvidenzaDTO } from '../models/VehicleInEvidenzaDTO';
import type { VehicleWishlistDTO } from '../models/VehicleWishlistDTO';
import type { InformationRequestDTO } from '../models/InformationRequestDTO';
import type { FuzzySearchItem } from '../models/Fuzzy';

export class VehicleControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) { }


    // We do not use this, TODO: delete when confirmed
    /**
     * @returns VehicleEntity OK
     * @throws ApiError
     */
    public getVehicles(): CancelablePromise<Array<VehicleEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/vehicles',
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public postVehicles(
        requestBody?: VehicleCreateRequestDTO,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/vehicles',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
     * @returns VehicleBackAvailableDTO OK
     * @throws ApiError
     */
    public getVehiclesBackAvailable(): CancelablePromise<Array<VehicleBackAvailableDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/vehicles/back-available',
        });
    }

    /**
     * @returns VehicleBackAvailableDTO OK
     * @throws ApiError
     */
    public getLastestInsertedVehicle(): CancelablePromise<Array<VehicleLastInsertedDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/vehicles/latest-ins',
        });
    }

    /**
     * @returns VehicleFilterDTO OK
     * @throws ApiError
     */
    public getVehiclesFilter(): CancelablePromise<Array<VehicleFilterDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/vehicles/filter',
        });
    }

    /**
     * @param id
     * @returns VehicleEntity OK
     * @throws ApiError
     */
    public getVehicles1(
        id: string,
    ): CancelablePromise<VehicleEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/vehicles/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public putVehicles(
        id: string,
        requestBody?: VehicleUpdateRequestDTO,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/vehicles/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public deleteDocument(
        id: string,
        requestBody?: DocumentDeleteRequestDTO,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/vehicles/document-delete/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public deleteVehicles(
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/vehicles/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
     * @returns VehicleWishlistDTO OK
     * @throws ApiError
     */
    public getWishlist(username: string): CancelablePromise<Array<VehicleWishlistDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/vehicles/wishlist/{username}',
            path: {
                'username': username,
            },
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public postWishlist(
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/vehicles/wishlist/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public deleteWishlist(
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/vehicles/wishlist/{id}',
            path: {
                'id': id,
            },
        });
    }

    //  * @returns VehicleInEvidenzaDTO OK
    /**
     * @returns any OK
     * @throws ApiError
     */
    public getVehiclesInEvidenza(): CancelablePromise<Array<VehicleInEvidenzaDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/vehicles/in-evidenza',
        });
    }

    /**
    * @param requestBody
    * @returns any OK
    * @throws ApiError
    */
    public sendQuationRequest(
        id: string,
        requestBody?: InformationRequestDTO,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/vehicles/request-quotation/{id}',
            body: requestBody,
            mediaType: 'application/json',
            path: {
                'id': id,
            },
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
    * @param requestBody
    * @returns any OK
    * @throws ApiError
    */
    public getQuotationRequests(
        username: string
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/vehicles/request-quotation/{username}',
            mediaType: 'application/json',
            path: {
                'username': username,
            },
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
    * @param requestBody
    * @returns any OK
    * @throws ApiError
    */
    public getQuotationRequestsForAzienda(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/vehicles/request-quotation',
            mediaType: 'application/json',
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    public fuzzySearch(
        db: string,
        q: string,
        brand = ''
    ): CancelablePromise<FuzzySearchItem[]> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/jato/search',
            query: {
                'db': db,
                'q': q,
                'brand': brand
            },
        });
    }

    /**
     * @throws ApiError
     */
    public fuzzySearchOptionals(
        db: string,
        q: string
    ): CancelablePromise<any[]> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/jato/option',
            query: {
                'db': db,
                'q': q
            },
        });
    }

    /**
     * @throws ApiError
     */
    public fuzzySearchGetBrandList(): CancelablePromise<string[]> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/jato/list_brands'
        });
    }

    /**
     * @throws ApiError
     */
    public fuzzySearchGetBrandModelList(brand: string): CancelablePromise<any[]> {
        return this.httpRequest.request({
            method: 'GET',
            query: { brand },
            url: '/jato/list_brand_models'
        });
    }

    public getVehicleImages(jatoVehicleId: string, marketType: string): CancelablePromise<string[]> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/vehicles/jato-images',
            query: { jatoVehicleId, marketType },
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    public getVehicleInfo(jatoVehicleId: string, marketType: string): CancelablePromise<string[]> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/vehicles/jato-vehicle',
            query: { jatoVehicleId, marketType },
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    public getJatoImageFile(url: string): CancelablePromise<string[]> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/vehicles/jato-images-file',
            headers: {
                'Accept': 'image/jpeg'
            },
            query: { url },
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }


    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public quickoQuotation(
        requestBody?: VehicleQuickoQuotationRequestDTO,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/vehicles/quicko-quotation',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }
}
