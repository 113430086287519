import { useState, useCallback, useMemo } from 'react'
import { Column, useTable } from 'react-table';
import Table from 'react-bootstrap/Table';
import { Card, Row, Button, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { columnsData } from './Data/quotationColumns';
import { useAiContext } from '../../context/AiContext';
import { useAuth } from '../../hooks/useAuth';
import VehicleDetails from '../../components/VehicleDetails/VehicleDetails';
import { NOLEGGIATORI } from '../../utils/constants'
import toaster from '../../utils/toaster';

interface InputValue {
  commissioniEuro?: number;
  typeInput?: '€' | '%';
  noleggiatore: string
}

interface NoleggiatoreInfo {
  canoneInput?: number;
  commissioniEuro: number;
  commissioniPercentage: number;
}

interface Output {
  noleggiatore: NoleggiatoreInfo;
}

interface VehicleResultsProps {
  showModal: (type, params) => void;
  setDownlodPdfValues: (value) => void;
}

const LEASEPLAN_MIN_VALUE = 3.5
const LEASEPLAN_MAX_VALUE = 10

const VehicleResults: React.FC<VehicleResultsProps> = ({ showModal, setDownlodPdfValues }) => {
  const [inputValues, setInputValues] = useState<InputValue[]>([]);
  const [calcResults, setCalcResults] = useState<Output[]>([]);
  const [pdfData, setPdfData] = useState({} || null);
  const rentalDuration = Number(localStorage.getItem('rentalDuration'))
  const { quotations, showResults, error, setQuotations, existDiscount, setProcessedRow } = useAiContext()
  const { t } = useTranslation()

  const updateCanoneInput = useCallback((row, value) => {
    // Update the quotations array with the new inputCommissioni value for the specific row
    setQuotations(prevQuotations => {
      const updatedQuotations = prevQuotations.map((quotation, index) => {
        if (index === row.index) {
          return { ...quotation, inputCommissioni: value };
        }
        return quotation;
      });
      return updatedQuotations;
    });
  }, [setQuotations])

  const calculateCommissions = useCallback((row) => {
    const currentRow = row.original
    let newCanone: number = 0
    let newPercentage: number = 0
    let newCommissioni: number = 0

    const { inputCommissioni, originalCanone, discountedCanone, fullPriceVehicle } = currentRow;
    const baseCanone = existDiscount ? discountedCanone : originalCanone;
    const inputType = currentRow.typeInput;

    if (inputCommissioni) {
      if (inputType as string === "€") {
        // ARVAL LEASEPLAN calc on canone totale
        if (row.original.noleggiatore.toLowerCase() === NOLEGGIATORI.ARVAL_PRIVATI.toLowerCase() ||
          row.original.noleggiatore.toLowerCase() === NOLEGGIATORI.ARVAL_P_IVA.toLowerCase() ||
          row.original.noleggiatore.toLowerCase() === NOLEGGIATORI.LEASEPLAN.toLowerCase()) {
          newCanone = (inputCommissioni / rentalDuration) + baseCanone;
          newPercentage = (1 - (newCanone / baseCanone)) * -100;

          if (row.original.noleggiatore.toLowerCase() === NOLEGGIATORI.LEASEPLAN.toLowerCase()) {
            const maxValue = (baseCanone * rentalDuration * LEASEPLAN_MAX_VALUE) / 100
            const minValue = (baseCanone * rentalDuration * LEASEPLAN_MIN_VALUE) / 100

            if (inputCommissioni > maxValue || inputCommissioni < minValue) {
              toaster.error(`Il valore deve essere compreso fra ${minValue.toFixed(2)}€ ed ${maxValue.toFixed(2)}€`)
              return
            }
          }
        }

        // ALD e LEASYS calc on prezzo listino
        else if (row.original.noleggiatore.toLowerCase() === NOLEGGIATORI.ALD.toLowerCase() ||
          row.original.noleggiatore.toLowerCase() === NOLEGGIATORI.LEASYS_I_CARE.toLowerCase()
        ) {
          newCanone = (inputCommissioni / rentalDuration) + baseCanone;
          newPercentage = (inputCommissioni / fullPriceVehicle) * 100
        }

        if (newPercentage > 10) {
          toaster.error(`La percentuale di commissioni non può superare il 10%. Attualmente è al ${newPercentage.toFixed(2)}%`);
          return;
        }

        currentRow.canone = parseFloat(newCanone.toFixed(2));
        currentRow.commissioni = parseFloat(inputCommissioni.toFixed(2));
        currentRow.percentage = parseFloat(newPercentage.toFixed(2));

        setQuotations(prevQuotations => prevQuotations.map((quotation, index) => {
          if (index === row.index) {
            return currentRow;
          }
          return quotation;
        }));
      } else if (inputType as string === "%") {

        // ARVAL LEASEPLAN calc on canone totale
        if (row.original.noleggiatore.toLowerCase() === NOLEGGIATORI.ARVAL_PRIVATI.toLowerCase() ||
          row.original.noleggiatore.toLowerCase() === NOLEGGIATORI.ARVAL_P_IVA.toLowerCase() ||
          row.original.noleggiatore.toLowerCase() === NOLEGGIATORI.LEASEPLAN.toLowerCase()) {
          newCommissioni = (baseCanone * rentalDuration * inputCommissioni) / 100
          newCanone = (newCommissioni / rentalDuration) + baseCanone

          if (row.original.noleggiatore.toLowerCase() === NOLEGGIATORI.LEASEPLAN.toLowerCase()) {
            if (inputCommissioni > LEASEPLAN_MAX_VALUE || inputCommissioni < LEASEPLAN_MIN_VALUE) {
              toaster.error(`Il valore deve essere compreso fra ${LEASEPLAN_MIN_VALUE}% ed ${LEASEPLAN_MAX_VALUE}%`)
              return
            }
          }
        }
        // ALD e LEASYS calc on prezzo listino
        else if (row.original.noleggiatore.toLowerCase() === NOLEGGIATORI.ALD.toLowerCase() ||
          row.original.noleggiatore.toLowerCase() === NOLEGGIATORI.LEASYS_I_CARE.toLowerCase()
        ) {
          newCommissioni = (fullPriceVehicle * inputCommissioni) / 100
          newCanone = (newCommissioni / rentalDuration) + baseCanone
        }

        currentRow.canone = parseFloat(newCanone.toFixed(2));
        currentRow.commissioni = parseFloat(newCommissioni.toFixed(2));
        currentRow.percentage = parseFloat(inputCommissioni.toFixed(2));

        setQuotations(prevQuotations => prevQuotations.map((quotation, index) => {
          if (index === row.index) {
            return currentRow;
          }
          return quotation;
        }));
      }

      const inputElement = document.getElementById(`input-${row.id}`) as HTMLInputElement;
      if (inputElement) {
        inputElement.value = '';
      }

    }
  }, [existDiscount, rentalDuration, setQuotations])

  // Handle Download Quotation PDF
  const handleDownloadPdf = (row) => {
    setProcessedRow(row)
    showModal('downloadQuotationPdf', row)
  }

  const updateCalcType = useCallback((noleggiatore, typeInput, row) => {
    const inputElement = document.getElementById(`input-${row.id}`) as HTMLInputElement;
    if (inputElement) {
      inputElement.value = '';
    } else {
      console.error("Element not found");
    }

    setQuotations(prev => {
      const updatedQuotations = prev.map(quotation => {
        if (quotation.noleggiatore === noleggiatore) {
          return { ...quotation, typeInput: typeInput };
        }
        return quotation;
      });
      return updatedQuotations;
    });
  }, [setInputValues]);

  const columns: Column<any>[] = useMemo(() => columnsData.map((col) => {
    if (col.id === 'input') {
      return {
        ...col,
        Cell: ({ row }) => {
          return (
            <Row>
              <Col md={12}>
                <div className='d-flex'>
                  <div className='d-flex align-items-center pe-4'>
                    <input
                      type="radio"
                      name={`currencyType-${row.original.noleggiatore}`}
                      value="€"
                      checked={row.original?.typeInput === '€'}
                      onChange={() => updateCalcType(row.original.noleggiatore, '€', row)}
                      className="radio-inline custom-radio-button"
                      id="euro"
                    /><span className="radio-text">€</span>
                    <input
                      type="radio"
                      name={`currencyType-${row.original.noleggiatore}`}
                      value="%"
                      checked={row.original?.typeInput === '%'}
                      onChange={() => updateCalcType(row.original.noleggiatore, '%', row)}
                      className="radio-inline custom-radio-button"
                      id="percentage"
                    /><span className="radio-text">%</span>
                  </div>
                  <input
                    type="number"
                    key={row.id}
                    id={`input-${row.id}`}
                    className="table-input"
                    min={0}
                    onChange={(e) => {
                      const newValue = parseFloat(e.target.value);
                      const selectedType = row.original.typeInput;
                      const inputValue = e.target.value;
                      if (!isNaN(newValue) && newValue < 0) {
                        e.target.value = '0'
                      }
                      if (selectedType === '%') {
                        if (inputValue === '') {
                          e.target.value = '';
                        } else if (!isNaN(newValue) && newValue <= 10) {
                          updateCanoneInput(row, newValue);
                        } else if (!isNaN(newValue) && newValue > 10) {
                          e.target.value = "10";
                        }
                      } else {
                        if (!isNaN(newValue)) {
                          updateCanoneInput(row, newValue);
                        } else {
                          e.target.value = '';  // Handle the case for non-percentage types if needed
                        }
                      }
                    }}
                  />
                </div>
              </Col>
            </Row>
          )
        }
      }
    }
    else if (col.id === 'calc') {
      return {
        ...col,
        Cell: ({ row }) => {
          return (
            <>
              <Button className="results-btn" onClick={() => { calculateCommissions(row) }}>
                {t('calculate')}
              </Button>
            </>
          )
        }
      };
    } else if (col.id === 'downloadPDF') {
      return {
        ...col,
        Cell: ({ row }) => (
          <Button className="results-btn" onClick={() => {
            handleDownloadPdf(row)
          }}>
            {t('download')}
            {/* <FaFilePdf className="fs-5" /> */}
          </Button>
        )
      };
    }
    else if (col.accessor === 'commissioniEuro') {
      return {
        ...col,
        Cell: ({ row }) => <span>{calcResults[row.index]?.noleggiatore.commissioniEuro.toFixed(2) || ''} €</span>
      };
    } else if (col.accessor === 'commissioniPercentage') {
      return {
        ...col,
        Cell: ({ row }) => <span>{calcResults[row.index]?.noleggiatore.commissioniPercentage || ''} %</span>
      };
    }
    return col;
  }), [calcResults, calculateCommissions, updateCanoneInput]);

  const data = useMemo(() => quotations, [quotations]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <>
      {error && (
        <div className='error-inference'>{error}</div>
      )}
      {showResults && quotations.length > 0 ? (
        <Row>
          <Card className="p-0">
            <Table
              striped
              borderless
              hover
              responsive
              {...getTableProps()}
              className="vehicle-table m-0">
              <thead className="border-bottom">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                      <th
                        className="text-nowrap"
                        {...column.getHeaderProps({
                          className: column.className,
                        })}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card>
        </Row>
      ) : (
        "")}

      {showResults && quotations.length <= 0 && (
        <div>
          {t('no_offer_found_selected_vehicle')}
        </div>
      )}

      <div style={{ display: 'none' }}>
        <VehicleDetails data={pdfData} />
      </div>
    </>
  );
};

export default VehicleResults


