import ManageModal from './ManageModal';
import { useState } from 'react';
import i18next from 'i18next';  // Import the i18next instance
import { useTranslation } from 'react-i18next';

const columnsArray = [
	{
		accessor: 'item.vehicle_id',
		id: 'id_veicolo',
		Header: i18next.t('vehicle_id'),
		Cell: ({ value }: any) => {

			const { vehicle_id, db } = value;
			const [isModalVisible, setModalVisibility] = useState(false);
			const openModal = (value: any) => {
				setModalVisibility(true);
			};

			return (
				<div>
					<div onClick={() => openModal(value)}>
            <u>{vehicle_id}</u>
          </div>
					{isModalVisible && (
						<ManageModal
							isModalVisible
							setModalVisibility={setModalVisibility}
							vehicle_id={vehicle_id}
							db={db}
						/>
					)}
				</div>
			);
		},
	},
	{
		accessor: 'item.id_128',
		id: 'marca',
		Header: i18next.t('brand'),
	},
	{
		accessor: 'item.id_129',
		id: 'modello',
		Header: i18next.t('model'),
	},
	{
		accessor: 'item.id_131',
		id: 'allestimento',
		Header: i18next.t('fuel_type'),
	},
	{
		accessor: 'item.id_502',
		id: 'codice_veicolo',
		Header: i18next.t('vehicle_code'),
	},
	{
		accessor: 'item.id_903',
		id: 'costo_listino',
		Header: i18next.t('list_price'),
		Cell: ({ value }) => {
			return value.toLocaleString() + '€'
		}
	},
	{
		accessor: 'item.vehicle_type',
		id: 'vehicle_type',
		Header: i18next.t('availability'),
		Cell: ({ value }) => {
			const { t } = useTranslation()

			return value === 'CURRENT' ? t('can_order') : t('cannot_order');
		},
	},
	{
		accessor: 'score',
		id: 'score',
		Header: i18next.t('score'),
		Cell: (value) => {
			return `${Math.ceil((1 - value.row.original.score) * 100)}%`;
		},
	},
	{
		// hidden for now in the initialState in FuzzySearch
		accessor: 'year',
		id: 'year',
		Header: i18next.t('year'),
	},
];

export default columnsArray;
