import { Link } from 'react-router-dom';
import { BsPencilSquare } from 'react-icons/bs';
import { GTMsendEvent } from '../../utils/trackingGA4'
import { translateRoles } from '../../utils/translations';

export const columnsData = [
	{
		accessor: 'username',
		id: 'username',
		Header: 'Username',
		minWidth: 290,
	},
	{
		accessor: 'given_name',
		id: 'given_name',
		Header: 'Nome',
		width: 120,
	},
	{
		accessor: 'family_name',
		id: 'family_name',
		Header: 'Cognome',
		width: 140,
	},
	{
		accessor: 'role',
		id: 'role',
		Header: 'Ruolo',
		width: 120,
		Cell: (data: any) => (
			<div>{translateRoles(data.value)}</div>
		),
	},
	{
		accessor: 'dealer',
		id: 'dealer',
		Header: 'Dealer',
		width: 120,
	},
	{
		accessor: 'phone',
		id: 'phone',
		Header: 'Telefono',
		width: 120,
	},
	// {
	// 	accessor: 'created_date',
	// 	id: 'created_date',
	// 	Header: 'Data creazione',
	// },
	// {
	// 	accessor: 'last_modified_date',
	// 	id: 'last_modified_date',
	// 	Header: 'Ultima modifica',
	// },
	{
		accessor: 'phone_backup',
		id: 'phone_backup',
		Header: 'Backup telefono',
		width: 150,
	},
	{
		accessor: 'email_backup',
		id: 'email_backup',
		Header: 'Backup email',
		width: 290,
	},
	{
		accessor: 'actions',
		id: 'actions',
		Header: '',
		disableSortBy: true,
		headerClassName: 'column-sticky',
		className: 'column-sticky',
		width: 60,
		Cell: (data: any) => (
			<Link
			 to={`/manage-users/${data.row.original.username}`}
			 className="btn"
			 onClick={() => {
				GTMsendEvent({
					event: 'CTA_modify_user',
					evtCat: 'user',
					evtAct: 'modify',
					evtLab: `modify_user`
				})
			 }}
			 >
				<BsPencilSquare
					style={{ width: '1.2rem', height: '1.6rem' }}
					name="edit"
				/>
			</Link>
		),
	},
];
