import { NavLink } from 'react-router-dom';
import i18next from 'i18next';

import ColumnFilterSelect from '../VehicleTable/components/filters/ColumnFilterSelect';
import ColumnFilterMultiSelect from '../VehicleTable/components/filters/ColumnFilterMultiSelect';
import ColumnFilterInput from '../VehicleTable/components/filters/ColumnFilterInput';
import ColumnFilterDoubleInput from '../VehicleTable/components/filters/ColumnFilterDoubleInput';
import filterFn from '../VehicleTable/customFilterFn';
import Icon from '../Icon/Icon';
import sortCanoneMinimo from '../VehicleTable/customSorting'
import { VehicleStateEnum } from '../../api/models/Enums';
import { GTMsendEvent } from '../../utils/trackingGA4'
import { translateVehicleStates } from '../../utils/translations';


const currentStateType = [
	VehicleStateEnum.OPZIONATO,
	VehicleStateEnum.OSCURATO
];

export const columnsData = [
	{
		// this column is hidden (check VehicleTableContainer initialState)
		accessor: 'id',
		id: 'id',
		Header: '',
		disableSortBy: true,
		// enableSorting:false non funziona. Forse era solo per i global filters?
		// enableColumnFilter:false OPPURE disableFilterBy se esiste. Check
	},

	{
		accessor: 'stato_corrente.type', // si chiamava stato_veicolo
		id: 'stato_corrente',
		Header: i18next.t('state'),
		PrimaryFilter: ColumnFilterMultiSelect,
		filter: filterFn,
		width: 110,
		Cell: (data) => (
			// <Tippy
			// 	placement="top"
			// 	content={data.row.original.stato_corrente?.utente}
			// 	interactive
			// 	disabled={
			// 		data.value === VehicleStateEnum.DISPONIBILE ||
			// 		!data.row.original.stato_corrente?.utente
			// 	}
			// >
			<small className={'vehicle-state ' + data.value}>{translateVehicleStates(data.value)}</small>
			// </Tippy>
		),
	},
	{
		accessor: 'scadenza',
		id: 'scadenza',
		Header: i18next.t('expiration'),
		// disableSortBy: true,
		width: 130,
		Cell: (data: any) => {
			const dataScadenza = data.row.original.stato_corrente.data_scadenza;
			const now = Date.now();
			const endDate = new Date(dataScadenza);

			const currentType = data.row.original.stato_corrente.type;
			const differenceInMillis = endDate.getTime() - now;

			const seconds = Math.floor(differenceInMillis / 1000);
			const minutes = Math.floor(seconds / 60);
			const hours = Math.floor(minutes / 60);
			const days = Math.floor(hours / 24);


			const time = {
				days: days,
				hours: (hours - 2) % 24,
				minutes: minutes % 60,
				seconds: seconds % 60
			};

			return (
				<>
					{(currentStateType.includes(currentType)) ? <>
						<span>{time.days}g</span> {" "}
						<span>{time.hours + 2}h</span> {" "}
						<span>{time.minutes}m</span> {" "}
						{/* 	<span>{time.seconds}s</span> {" "} */}
					</>
						: ""}
				</>
			)
		}
	},
	{
		accessor: 'cliente',
		id: 'cliente',
		Header: i18next.t('customer'),
		// disableSortBy: true,
		width: 130,
		Cell: (data: any) => {
			const currentType = data.row.original.stato_corrente.type;
			const storico = data.row.original.storico_azioni;

			const getCliente = () => {
				// get latest action that matches the currentType that is either OPZIONATO or OSCURATO
				for (let i = storico.length - 1; i >= 0; i--) {
					const currentStorico = storico[i]
					if (currentType === 'OPZIONATO' && currentStorico.type === "OPZIONAMENTO") {
						return currentStorico.cliente
					} else if (currentType === 'OSCURATO' && currentStorico.type === "OSCURAMENTO") {
						return currentStorico.cliente
					}
				}
			}
			return (
				<>
					{(currentStateType.includes(currentType)) ? getCliente() : ""}
				</>
			)
		}
	},
	{
		width: 130,
		accessor: 'marca',
		id: 'marca',
		Header: i18next.t('brand'),
		PrimaryFilter: ColumnFilterMultiSelect,
		filter: filterFn,
	},
	{
		accessor: 'modello',
		id: 'modello',
		Header: i18next.t('model'),
		PrimaryFilter: ColumnFilterMultiSelect,
		filter: filterFn,
	},
	{
		width: 210,
		accessor: 'allestimento',
		id: 'allestimento',
		Header: i18next.t('car_setup'),
		SecondaryFilter: ColumnFilterMultiSelect,
		filter: filterFn,
	},
	{
		accessor: 'nr_ordine',
		id: 'nr_ordine',
		Header: i18next.t('commerial_order'),
		SecondaryFilter: ColumnFilterInput,
		inputType: 'text',
		filter: filterFn,
		width: 175,
	},
	{
		width: 180,
		accessor: 'codice_costruttore',
		id: 'codice_costruttore',
		Header: i18next.t('builder_code'),
		SecondaryFilter: ColumnFilterInput,
		inputType: 'text',
		filter: filterFn,
	},
	{
		width: 130,
		accessor: 'telaio', // NN633603418
		id: 'telaio',
		Header: i18next.t('vehicle_frame'),
		SecondaryFilter: ColumnFilterInput,
		inputType: 'text',
		filter: filterFn,
	},
	{
		width: 110,
		accessor: 'targa',
		id: 'targa',
		Header: i18next.t('vehicle_plate'),
		SecondaryFilter: ColumnFilterInput,
		inputType: 'text',
		filter: filterFn,
	},
	{
		accessor: 'dealer',
		id: 'dealer',
		Header: i18next.t('dealer'),
		width: 160,
	},
	{
		accessor: 'stato_disp',
		id: 'stato_disp',
		Header: i18next.t('availability_state'),
		PrimaryFilter: ColumnFilterMultiSelect,
		filter: filterFn,
		width: 150,
		Cell: (data) => {
			return data.row.original.stato_disp?.replace('_', ' ')
		}
	},
	{
		accessor: 'data_disp',
		id: 'data_disp',
		Header: i18next.t('estimated_delivery'),
		SecondaryFilter: ColumnFilterDoubleInput,
		inputType: 'date',
		filter: filterFn,
		width: 180,
	},
	{
		accessor: 'offerta_minima',
		id: 'offerta_minima',
		Header: i18next.t('min_rental_fee'),
		SecondaryFilter: ColumnFilterDoubleInput,
		sortType: sortCanoneMinimo,
		inputType: 'number',
		filter: filterFn,
		width: 180,
		Cell: (data) => {
			return data.row.original.offerta_minima.valore
				? `€${data.row.original.offerta_minima.valore} (${data.row.original.offerta_minima.noleggiatore})`
				: '';
		},
	},
	{
		width: 190,
		accessor: 'colore_esterno',
		id: 'colore_esterno',
		Header: i18next.t('external_color_code'),
		SecondaryFilter: ColumnFilterMultiSelect,
		filter: filterFn,
	},
	{
		width: 190,
		accessor: 'colore_interno',
		id: 'colore_interno',
		Header: i18next.t('internal_color_code'),
		SecondaryFilter: ColumnFilterMultiSelect,
		filter: filterFn,
	},
	{
		width: 120,
		accessor: 'city',
		id: 'city',
		Header: i18next.t('city'),
		SecondaryFilter: ColumnFilterSelect,
		filter: filterFn,
	},
	{
		accessor: 'data_immatricolazione',
		id: 'data_immatricolazione',
		Header: i18next.t('vehicle_registration_date'),
		SecondaryFilter: ColumnFilterDoubleInput,
		inputType: 'date',
		filter: filterFn,
		width: 190,
	},
	{
		width: 170,
		accessor: 'alimentazione',
		id: 'alimentazione',
		Header: i18next.t('fuel_type'),
		SecondaryFilter: ColumnFilterSelect,
		filter: filterFn,
	},
	{
		width: 140,
		accessor: 'cambio',
		id: 'cambio',
		Header: i18next.t('vehicle_gear'),
		SecondaryFilter: ColumnFilterSelect,
		filter: filterFn,
	},
	{
		width: 130,
		accessor: 'trazione',
		id: 'trazione',
		Header: i18next.t('vehicle_drive_system'),
		SecondaryFilter: ColumnFilterMultiSelect,
		filter: filterFn,
	},
	{
		width: 140,
		accessor: 'carrozzeria',
		id: 'carrozzeria',
		Header: i18next.t('vehicle_body'),
		SecondaryFilter: ColumnFilterMultiSelect,
		filter: filterFn,
	},
	{
		accessor: 'stato_corrente.utente',
		id: 'utente',
		Header: i18next.t('user'),
		width: 300,
		SecondaryFilter: ColumnFilterSelect,
		filter: filterFn,
	},
	{
		accessor: 'promo',
		id: 'promo',
		Header: i18next.t('promo'),
		width: 150,
	},
	{
		accessor: 'promo_note',
		id: 'promo_note',
		Header: i18next.t('promo_note'),
		disableSortBy: true,
		width: 190,
	},

	{
		width: 60,
		accessor: 'actions',
		id: 'actions',
		Header: '',
		disableSortBy: true,
		headerClassName: 'column-sticky',
		className: 'column-sticky text-end',
		Cell: (data: any) => {
			return (
				<NavLink
				to={`/details/${data.row.original.id}`}
				className="btn"
				onClick={() => {
						const datiVeicolo = data.row.original
						GTMsendEvent({
							event: 'select_item',
							item_id: datiVeicolo.nr_ordine,
							item_name: `${datiVeicolo.marca} ${datiVeicolo.allestimento}`,
							affiliation: datiVeicolo.dealer,
							item_category: datiVeicolo.marca,
							item_category2: `${datiVeicolo.modello} ${datiVeicolo.allestimento}`,
							item_category3: datiVeicolo.codice_costruttore,
							item_category4: datiVeicolo.stato_disp,
							price: Number(datiVeicolo.offerta_minima.valore || 0),
						})
					}}
				>
					<Icon
						style={{ width: '1.2rem', height: '1.2rem' }}
						name="arrow-right"
					/>
				</NavLink>
			)
		},
	},

];
