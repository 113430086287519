import React, { useEffect, useState } from 'react';
import { Col, Row, Accordion, Card, Button, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { Formik, Field, Form } from 'formik';
import { useAiContext } from '../../context/AiContext';
import * as Yup from 'yup';
import _ from 'lodash';
import toaster from '../../utils/toaster';
import { NOLEGGIATORI_QUOTATORE } from '../../utils/constants'
import { calcCommissions } from '../../utils/quicko';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';  // Import the i18next instance
import type { VehicleQuickoQuotationRequestDTO } from '../../api/models/VehicleQuickoQuotationRequestDTO';
import { useApiClient } from '../../hooks/useApiClient';

const validationSchema = Yup.object({
  rentalDuration: Yup.number()
    .moreThan(0, i18next.t('insert_valid_duration'))
    .required(i18next.t('insert_valid_duration')),

  totalKm: Yup.number()
    .moreThan(0, i18next.t('insert_valid_number_km'))
    .required(i18next.t('km_required')),

  advancePayment: Yup.number()
    // .moreThan(0, 'Inserisci un anticipo valido.')
    .required(i18next.t('downpayment_required')),
});

const advancePaymentOptions = [
  { value: 0, label: "0" },
  { value: 3000, label: "3000" },
  { value: 5000, label: "5000" },
  { value: 7000, label: "7000" },
  { value: 9000, label: "9000" },
];

const totalKmOptions = [
  { value: 10000, label: "10.000" },
  { value: 15000, label: "15.000" },
  { value: 20000, label: "20.000" },
  { value: 25000, label: "25.000" },
  { value: 30000, label: "30.000" },
];

const rentalDurationOptions = [
  { value: 24, label: "24" },
  { value: 36, label: "36" },
  { value: 48, label: "48" },
  { value: 60, label: "60" },
];

const CustomSelect = ({ options, field, form }) => {
  return (
    <Select
      options={options}
      name={field.name}
      value={options ? options.find(option => option.value === field.value) : ''}
      onChange={(option) => form.setFieldValue(field.name, option.value)}
      onBlur={field.onBlur}
    />
  );
};

interface UserSelection {
  rentalDuration: number;
  totalKm: number;
  advancePayment: number;
}

const VehicleSelection = () => {
  const { setShowResults, setQuotReqState, quotReqState, setQuotations, services, setExistDiscount, setFullPriceVehicle } = useAiContext()
  const { optionalTotals, vehicleState: { dati_veicolo } } = quotReqState
  const [userSelection, setUserSelection] = useState<UserSelection>({} as UserSelection)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const { apiClient, handleApi } = useApiClient();

  const handleSubmit = async (values) => {
    setUserSelection(values)
    const { rentalDuration, totalKm, advancePayment } = values
    setQuotReqState(prev => ({ ...prev, rentalDuration, totalKm, advancePayment }))
    localStorage.setItem('rentalDuration', rentalDuration)

    const rca_penale_risarcitoria = services.assicurazioneRC.find(option => option.isChecked)?.value || 0;
    const incendio_furto_scoperto_perc = services.furtoIncendio.find(option => option.isChecked)?.value || 0;
    const kasko_franchigia = services.assicurazioneKasko.find(option => option.isChecked)?.value || 0;
    const copertura_pai_YN = services.assicurazionePAI.isChecked ? 1 : 0;
    const tutela_legale_YN = services.tutelaLegale.isChecked ? 1 : 0;
    let auto_sostitutiva_gruppo = services.vetturaSostitutiva.find(option => option.isChecked)?.value || 0

    if (auto_sostitutiva_gruppo === 'citycar') {
      auto_sostitutiva_gruppo = 'city car'
    }

    const checkedGommeService = services.gomme.find(option => option.isChecked === true)
    const tipo_gomma = checkedGommeService ? checkedGommeService.value : 'Assente';
    let gomme_illimitate_YN = checkedGommeService ? checkedGommeService.unlimited : false
    let n_treno_gomme_estive = checkedGommeService ? (checkedGommeService.data?.estive || 0) : 0
    let n_treno_gomme_invernali = checkedGommeService ? (checkedGommeService.data?.invernali || 0) : 0

    let noleggiatori: string[] = []
    let lista_noleggiatori_quotatore = quotReqState.vehicleState.lista_noleggiatori_quotatore || []
    lista_noleggiatori_quotatore = lista_noleggiatori_quotatore.filter(el => el.is_checked)
    lista_noleggiatori_quotatore.forEach(el => {
      let nquot = Object.values(NOLEGGIATORI_QUOTATORE).find(n => n.name === el.noleggiatore)
      if (nquot) {
        noleggiatori.push(...(nquot.noleggiatori_quicko.map(n => n.toLowerCase())))
      }
    })

    const payload: VehicleQuickoQuotationRequestDTO = {
      rca_YN: rca_penale_risarcitoria === 0 ? 0 : 1,
      incendio_furto_YN: incendio_furto_scoperto_perc === 0 ? 0 : 1,
      kasko_YN: kasko_franchigia === 0 ? 0 : 1,
      allestimento: dati_veicolo.allestimento,
      anticipo_no_iva: advancePayment,
      marca: dati_veicolo.marca,
      modello: dati_veicolo.modello,
      prezzo_accessori_no_iva: optionalTotals,
      durata: rentalDuration,
      prezzo_listino_no_iva: dati_veicolo.prezzo_listino,
      incendio_furto_scoperto_perc,
      kasko_franchigia,
      km_annui: totalKm,
      rca_penale_risarcitoria,
      tipo_gomma,
      tutela_legale_YN,
      gomme_illimitate_YN,
      n_treno_gomme_estive,
      n_treno_gomme_invernali,
      copertura_pai_YN,
      auto_sostitutiva_gruppo,
      noleggiatori,

      carburante_principale_generico: dati_veicolo.alimentazione,
      numero_porte: dati_veicolo.porte,
      numero_posti: dati_veicolo.posti,
      potenza_cv: dati_veicolo.cavalli,
      tipo_cambio: dati_veicolo.cambio,
      tipo_carrozzeria: dati_veicolo.carrozzeria,
      vehicle_id: dati_veicolo.jato_id,
    }
    const additionRules = (quotation) => {
      const initCanone = quotation.canone

      switch (auto_sostitutiva_gruppo) {
        case "city car":
          quotation.canone += 10;
          break;
        case "media":
          quotation.canone += 15;
          break;
        case "superior":
          quotation.canone += 23;
          break;
        default:
          break;
      }

      if (incendio_furto_scoperto_perc === 0) {
        quotation.canone += 15
      }

      if (copertura_pai_YN) {
        quotation.canone += 1;
      }

      if (!gomme_illimitate_YN) {
        const totalGomme = n_treno_gomme_estive + n_treno_gomme_invernali

        switch (tipo_gomma) {
          case "quality":
            quotation.canone += 15 * (totalGomme);
            break;
          case "premium":
            quotation.canone += 20 * (totalGomme);
            break;
          default:
            break;
        }
      }
      return quotation
    }

    // fetch(process.env.REACT_APP_QUICKO_ENDPOINT || "", {
    //   method: "POST",
    //   body: JSON.stringify(payload),
    //   headers: {
    //     'X-Api-Key': process.env.REACT_APP_QUICKO_APIKEY!
    //   }
    // })
    handleApi(apiClient.vehicleController.quickoQuotation(payload))
      .then(responseQuotations => {
        setLoading(true)
        if (!_.isEmpty(responseQuotations)) {

          const updatedQuotations = responseQuotations.map((quotation, index) => {
            let discountedCanone: number = quotation.canone;

            const fullPriceVehicle = optionalTotals + dati_veicolo.prezzo_listino;
            setFullPriceVehicle(fullPriceVehicle)
            const existDiscount = dati_veicolo.extra_sconto_perc !== 0 && dati_veicolo.extra_sconto_perc != null && !isNaN(dati_veicolo.extra_sconto_perc);
            setExistDiscount(existDiscount);

            // https://support.interlogica.it/browse/HOR-463
            // Le abbiamo rimesse di nuovo :|
            quotation = additionRules(quotation)
            const commObj = calcCommissions(quotation, rentalDuration, optionalTotals, dati_veicolo)

            if (existDiscount) {
              // Calc discount canone
              // [(% * prezzo listino + optional ) / mesi ]*(-1).
              const discount = ((dati_veicolo.extra_sconto_perc / 100) * (dati_veicolo.prezzo_listino + optionalTotals)) / rentalDuration
              discountedCanone = quotation.canone - discount
            }

            const updatedQuotation = {
              ...quotation,
              discountedCanone: parseFloat(discountedCanone.toFixed(2)),
              originalCanone: parseFloat(quotation.canone.toFixed(2)),
              canone: parseFloat(commObj.updatedCanone.toFixed(2)),
              commissioni: parseFloat(commObj.commissionfee.toFixed(2)),
              typeInput: "€",
              percentage: 3,
              fullPriceVehicle: commObj.fullPriceVehicle
            }
            return updatedQuotation
          })

          setQuotations(updatedQuotations)
          setShowResults(true)
          setLoading(false)
        }
      })
      .catch(error => {
        console.error('Error querying the inference:', error)
        toaster.error(i18next.t('inference_not_possible'));
        setLoading(false)
      }
      );
  }

  return (
    <Row>
      <div className='p-0'>Selezione  durata/km/anticipo</div>
      <Formik
        initialValues={{
          rentalDuration: 0,
          totalKm: 0,
          advancePayment: 0,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {formik => {
          return (

            <Form className="p-0 pb-2">
              <Card className="mt-3">
                <Card.Body className='pt-0'>
                  <Accordion className="mt-4" flush>
                    <Row className="m-0">
                      <Col sm={4} className="pr-4">
                        <div>
                          <label htmlFor='rentalDuration'>
                            {t('rental_duration')}
                          </label>
                          <Field
                            component={CustomSelect}
                            name="rentalDuration"
                            options={rentalDurationOptions}
                          />
                          {formik.touched.rentalDuration && formik.errors.rentalDuration ? (
                            <div style={{ color: 'red' }}>{formik.errors.rentalDuration}</div>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={4} className="pr-4">
                        <div>
                          <label htmlFor='totalKm'>
                            {t('annual_km')}
                          </label>
                          <Field
                            component={CustomSelect}
                            name="totalKm"
                            options={totalKmOptions}
                          />
                          {formik.touched.totalKm && formik.errors.totalKm ? (
                            <div style={{ color: 'red' }}>{formik.errors.totalKm}</div>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={4} className="pr-4">
                        <div>
                          <label htmlFor='advancePayment'>
                            {t('downpayment')} + IVA
                          </label>
                          <Field
                            component={CustomSelect}
                            name="advancePayment"
                            options={advancePaymentOptions}
                          />
                          {formik.touched.advancePayment && formik.errors.advancePayment ? (
                            <div style={{ color: 'red' }}>{formik.errors.advancePayment}</div>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </Accordion>
                </Card.Body>
              </Card>

              <Row>
                <div className="d-flex justify-content-center pt-5 pb-5">
                  <Button type="submit" className="confirm-btn">
                    {t('confirm')}
                  </Button>
                </div>
              </Row>
            </Form>
          )
        }}
      </Formik>

      {loading ? (
        <div className='quotations-loader'>
          <Spinner animation="border" id="spinner" />
        </div>
      ) : ""}

    </Row>

  )
}

export default VehicleSelection