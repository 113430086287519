import './Btn.scss';
import Button from 'react-bootstrap/Button';
import Icon from '../Icon/Icon';
import { useNavigate } from 'react-router-dom';

function Btn(btnProps: any) {
	const {
		className = '',
		to,
		onClick,
		icon = '',
		children,
		type = 'button',
		...props
	} = btnProps;

	const navigate = useNavigate();
	const routeChange = () => {
		let path = to;
		navigate(path);
	};

	return (
		<div className={className + ' btn-wrapper'}>
			<Button
				{...props}
				type={type}
				onClick={to && !onClick ? routeChange : onClick}
				size="lg"
				variant="outline-primary"
			>
				<span className="align-middle">{children}</span>

				{icon ? <Icon className="ms-2" name={icon} /> : null}
			</Button>
		</div>
	);
}

export default Btn;
