import { createContext, useState, ReactNode, useContext, useEffect } from 'react';
import { mapServices } from '../utils/pdfUtils';
import { useAuth } from '../hooks/useAuth';
import { cloneDeep } from 'lodash'

interface ServicesOptions {
  label: string;
  labelRiepilogo: string;
  value: string | number;
  isChecked: boolean;
  quantity?: number;
  unlimited?: boolean;
  data?: any;
}

export interface ServicesState {
  assicurazioneRC: ServicesOptions[];
  assicurazioneKasko: ServicesOptions[];
  furtoIncendio: ServicesOptions[];
  gomme: ServicesOptions[];
  tutelaLegale: ServicesOptions;
  assicurazionePAI: ServicesOptions;
  manutenzione: ServicesOptions;
  vetturaSostitutiva: ServicesOptions[]
}

interface QuotReqState {
  checkedServices: string[];
  vehicleState: any;
  totalKm: number;
  rentalDuration: number;
  advancePayment: number;
  optionalTotals: number;
}

interface AiContextType {
  quotReqState: QuotReqState;
  setQuotReqState: React.Dispatch<React.SetStateAction<QuotReqState>>;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  services: ServicesState;
  setServices: (services: any) => void;
  showResults: boolean;
  setShowResults: (value: boolean) => void;
  quotations: any;
  setQuotations: (quotation: any) => void;
  downloadPdf: (row: any) => any;
  error: string,
  setError: (row: any) => any;
  existDiscount: boolean,
  setExistDiscount: (value: boolean) => any;
  fullPriceVehicle: number,
  setFullPriceVehicle: (value: number) => any;
  cleanAiContext: () => void;
  processedRow: any,
  setProcessedRow: (value: any) => void;
}

export const baseServices: ServicesState = {
  assicurazioneRC: [
    {
      value: 0,
      label: "RC nessuna penale risarcitoria",
      labelRiepilogo: "RC: nessuna penale risarcitoria",
      isChecked: true
    },
    {
      value: 250,
      label: "RC 250€ penale risarcitoria",
      labelRiepilogo: "RC: penale risarcitoria 250€",
      isChecked: false
    }
  ],
  vetturaSostitutiva: [
    {
      value: "citycar",
      label: "City car",
      labelRiepilogo: "Vettura Sostitutiva: City car",
      isChecked: false
    },
    {
      value: "media",
      label: "Media",
      labelRiepilogo: "Vettura Sostitutiva: Media",
      isChecked: false
    },
    {
      value: "superior",
      label: "Superior",
      labelRiepilogo: "Vettura Sostitutiva: Superior",
      isChecked: false
    },
  ],
  furtoIncendio: [
    {
      value: 0,
      label: "0%",
      labelRiepilogo: "Furto e Incendio: '0%'",
      isChecked: false
    },
    {
      value: 10,
      label: "10% ",
      labelRiepilogo: "Furto e Incendio: franchigia 10%",
      isChecked: true
    },
  ],
  assicurazioneKasko: [
    {
      value: 0,
      label: "0€",
      labelRiepilogo: "Kasko: franchigia 0 €",
      isChecked: false
    },
    {
      value: 500,
      label: "500€",
      labelRiepilogo: "Kasko: franchigia 500 €",
      isChecked: true
    }
  ],
  assicurazionePAI: {
    value: "si",
    label: "Assicurazione PAI",
    labelRiepilogo: "PAI: si",
    isChecked: false
  },
  tutelaLegale: {
    value: "si",
    label: "Tutela Legale",
    labelRiepilogo: "Tutela Legale: si",
    isChecked: false
  },
  manutenzione: {
    value: 'si',
    label: 'Manutenzione Ordinaria e Straordinaria',
    labelRiepilogo: "Manutenzione: si",
    isChecked: true
  },
  gomme: [
    {
      value: 'quality',
      label: 'Quality/Performance',
      labelRiepilogo: "Gomme: Quality/Performance",
      isChecked: false,
      quantity: 1,
      unlimited: false,
      data: { estive: 1, invernali: 1}
    },
    {
      unlimited: false,
      value: 'premium',
      label: 'Premium',
      labelRiepilogo: "Gomme: Premium",
      isChecked: false,
      quantity: 1,
      data: { estive: 1, invernali: 1}
    }
  ]
};

const defaultServices = cloneDeep(baseServices)

const defaultQuotReqState: QuotReqState = {
  checkedServices: [],
  vehicleState: {},
  totalKm: 0,
  rentalDuration: 0,
  advancePayment: 0,
  optionalTotals: 0
};

const defaultContextValue: AiContextType = {
  quotReqState: defaultQuotReqState,
  setQuotReqState: () => { },
  activeStep: 2,
  setActiveStep: () => { },
  services: defaultServices,
  setServices: () => { },
  showResults: false,
  setShowResults: () => { },
  quotations: [],
  setQuotations: () => { },
  downloadPdf: () => { },
  error: "",
  setError: () => { },
  existDiscount: false,
  setExistDiscount: () => { },
  fullPriceVehicle: 0,
  setFullPriceVehicle: (value: number) => { },
  cleanAiContext: () => { },
  processedRow: {},
  setProcessedRow: (value: any) => { },

};

const AiContext = createContext<AiContextType>(defaultContextValue);

export const AiProvider = ({ children }: { children: ReactNode }) => {
  const [quotReqState, setQuotReqState] = useState(defaultQuotReqState);
  const [activeStep, setActiveStep] = useState(defaultContextValue.activeStep);
  const [services, setServices] = useState<any>(defaultServices);
  const [processedRow, setProcessedRow] = useState<any>({});
  const [showResults, setShowResults] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [quotations, setQuotations] = useState<[]>([]);
  const [existDiscount, setExistDiscount] = useState<boolean>(false);
  const [fullPriceVehicle, setFullPriceVehicle] = useState<number>(defaultContextValue.fullPriceVehicle);
  const { user } = useAuth();

  useEffect(() => {
    // console.log('quotReqState: ', quotReqState);
    // console.log('fullPriceVehicle: ', fullPriceVehicle);
    // console.log('quotations: ', quotations);
    // console.log('processedRow: ', processedRow);
    // console.log('>>>> services: ', services);
    // console.log('>>>> quotations: ', quotations);
  }, [quotations])

  const cleanAiContext = () => {
    setQuotReqState(prev => {
      prev.checkedServices = []
      return prev
    })
    setServices(defaultServices)
    setQuotations([])
    setShowResults(false)
    setActiveStep(2)
  }

  const downloadPdf = async (row) => {
    const services = mapServices('', quotReqState.checkedServices);
    const pdfData = {
      lista_offerte: [
        {
          id: 'customPdf',
          durata: quotReqState.rentalDuration,
          km_totali: quotReqState.totalKm,
          // servizi: quotReqState.servizi,
          anticipo: quotReqState.advancePayment,
          // importo_azienda: quotations.canone,
          // importo_privato: quotReqState.importo_privato,
        }
      ],
      pdf: {
        data_validita: "test data_validita",
        name: "test name",
        userName: user.attributes.given_name,
        userSurname: user.attributes.family_name,
        userMail: user.attributes.email,
        offerType: "test offerType",
        offerId: 'customPdf',
        dealerDetail: { logo: '' },
        services,
      },
      dati_veicolo: quotReqState.vehicleState.dati_veicolo,
      optionals: quotReqState.vehicleState.optionals.filter(el => el.descrizione.length > 0),
      // image_list: vehicleState.vehicleState.image_list[0]
    }
    return pdfData
    // setPdfData(pdfData)
  }

  const aiContextData: AiContextType = {
    quotReqState,
    setQuotReqState,
    activeStep,
    setActiveStep,
    services,
    setServices,
    showResults,
    setShowResults,
    quotations,
    setQuotations,
    downloadPdf,
    error,
    setError,
    existDiscount,
    setExistDiscount,
    fullPriceVehicle,
    setFullPriceVehicle,
    cleanAiContext,
    processedRow,
    setProcessedRow
  };

  return <AiContext.Provider value={aiContextData}>{children}</AiContext.Provider>;
};

export const useAiContext = () => useContext(AiContext);

export default AiContext;
