import Restricted from '../../components/Restricted/Restricted';
import { Roles } from '../../types';
import { Link } from 'react-router-dom';
import { BsPencilSquare } from 'react-icons/bs';
import Icon from '../../components/Icon/Icon';
import { useAuth } from '../../hooks/useAuth';
import { GTMsendEvent } from '../../utils/trackingGA4'

export const LinkToForm = ({ data }) => {
	const { userRole } = useAuth();

	const handleClick = () => {
		GTMsendEvent({
			event: 'CTA_modify_dealer',
			evtCat: 'dealer',
			evtAct: 'modify',
			evtLab: `modify_dealer`
		})
	}
	return (
		<Restricted to={[Roles.Backoffice, Roles.Sales, Roles.Master, Roles.Admin]}>
			<div
				onClick={handleClick}
			>
				{userRole === Roles.Backoffice || userRole === Roles.Master || userRole === Roles.Admin ? (

					<Link to={`/manage-dealers/${data.row.original.id}`} className="btn">
						<BsPencilSquare
							style={{ width: '1.2rem', height: '1.6rem' }}
							name="edit"
						/>
					</Link>
				) : (
					<Link to={`/manage-dealers/${data.row.original.id}`} className="btn">
						<Icon
							style={{ width: '1.2rem', height: '1.2rem' }}
							name="arrow-right"
						/>
					</Link>
				)}
			</div>
		</Restricted>
	);
};
