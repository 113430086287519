/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type * as DTO from '../index';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';


export class AreaMarketingControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) { }

    /**
     * GET A ROOT FOLDER
     * @returns DTO.AreaAcademyFolderDTO OK
     * @throws ApiError
     */
    public getRootMarketingFolder(): CancelablePromise<Array<any>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/marketing',
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
     * GET A SINGLE FOLDER
     * @returns DTO.AreaMarketingFolderDTO OK
     * @throws ApiError
     */
    public getSingleFolder(id: string): CancelablePromise<Array<any>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/marketing/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
    * SEARCH
    * @returns DTO.MarketingObjectSearchRequestDTO OK
    * @throws ApiError
    */
    public searchQuery(requestBody: DTO.AreaObjectSearchRequestDTO): CancelablePromise<Array<any>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/marketing/search',
            body: requestBody,
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
     * CREATE NEW OBJECT
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public postFolder(
        requestBody?: DTO.AreaObjectCreateRequestDTO,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/marketing',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
     * EDIT FOLDER NAME
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public editFolderName(
        id: string,
        requestBody: DTO.AreaEditObjectDTO,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/marketing/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
     * DELETE FOLDER
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public deleteFolder(
        id?: DTO.AreaDeleteFolderDTO,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/marketing/{id}',
            // body: requestBody,
            path: {
                'id': id,
            },
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }
}
