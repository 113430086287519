import { ToastContainer as _ToastContainer, Slide } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

// const transition = cssTransition({
// 	collapseDuration: 100,
// 	enter: 'zoomIn',
// 	exit: 'zoomOut',
// });

const ToastContainer = () => {
	return (
		<_ToastContainer
			hideProgressBar={true}
			transition={Slide}
			newestOnTop={false}
			pauseOnFocusLoss={false}
			limit={5}
			theme="colored"
		/>
	);
};

export default ToastContainer;
