import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Spinner } from 'react-bootstrap';
import { BiDotsVerticalRounded, BiTrash } from "react-icons/bi";
import { FcFolder } from "react-icons/fc";
import Ratio from 'react-bootstrap/Ratio';
import Modal from 'react-bootstrap/Modal';
import { Storage } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

// local imports
import { AreaDataDTO } from '../../api'
import WordIcon from '../../icons/word_icon.svg'
import PptIcon from '../../icons/powerpoint_icon.svg'
import PdfIcon from '../../icons/pdf_icon.svg'
import Folder from '../../icons/folder_icon.svg'
import ExcelIcon from '../../icons/excel_icon.svg'
import ImageIcon from '../../icons/image_icon.svg'
import FileIcon from '../../icons/file_icon.svg'
import PsdIcon from '../../icons/psd_icon.svg'
import { useAuth } from '../../hooks/useAuth';
import '../../pages/Areas/areas.scss'


const iconExtensions = ['pdf', 'ppt', 'pptx', 'doc', 'docx', 'xlsx', 'xls', 'csv', 'psd', 'jpg', 'png', 'jpeg', 'svg', 'bmp'];


function DisplayVideo({ videos, viewFolderEdit, handleDeleteObject, handleMouseEnter, handleMouseLeave }) {
  const [showVideoFull, setShowVideoFull] = useState<boolean>(false)
  const [videoModal, setVideoModal] = useState({} as AreaDataDTO)
  const { userRole } = useAuth();
	const { t } = useTranslation()

  const onHideFn = () => {
    setShowVideoFull(false)
  }
  const handleShowVideoFull = (video) => {
    setVideoModal(video)
    setShowVideoFull(true)
  }
  return (
    <div>
      {videos?.length! > 0 ?
        <div>
          <div className='category-divider'>
            <span>{t('video')}</span>
          </div>
          <div className="videos-container">
            {videos?.map(video => (
              video.link ? (
                <div
                  className='video-wrapper'
                  onMouseEnter={() => handleMouseEnter(video)}
                  onMouseLeave={() => handleMouseLeave(video)}
                  key={video.id.toString()}

                >
                  <div className="ratio ratio-16x9">
                    <div className='overlay-screen'
                      onClick={() => handleShowVideoFull(video)}
                    ></div>

                    <iframe
                      src={video.link}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <div className='text-center d-flex justify-content-between'>
                    <div className='text-truncated' style={{ textDecoration: 'none' }}>
                      {video.title}
                    </div>
                    {(viewFolderEdit.id === video.id && viewFolderEdit.is_visible && (userRole === 'ADMIN' || userRole === 'MASTER')) ? (
                      <div className='text-end'>
                        <BiTrash className='delete-object' onClick={() => handleDeleteObject(video)} />
                      </div>
                    ) : ""}
                  </div>
                </div>
              ) : t('no_video_link')
            ))}
          </div>
          <Modal
            // size='xl'
            show={showVideoFull}
            onHide={onHideFn}
            centered
            keyboard
            animation
            dialogClassName="dialog"
            contentClassName="content"
          >
            <Ratio aspectRatio="16x9">
              <iframe
                src={videoModal.link}
                title={videoModal.title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </Ratio>
          </Modal>
        </div>
        : ""}
    </div>
  )
}

function DisplayFolders({ folders, viewFolderEdit, handleDeleteObject, handleMouseEnter, handleMouseLeave, handleOpenFolder, handleEditObject }) {
  const { userRole } = useAuth();
	const { t } = useTranslation()

  return (
    <div>
      {folders.length > 0 ?
        <div className='w-100 d-flex flex-wrap'>
          <div className='category-divider'>
            <span>{t('folders').toUpperCase()}</span>
          </div>
          {folders?.map(item => (
            <div
              className='folder-container btn btn-link'
              style={{ textDecoration: 'none' }}
              key={item.id.toString()}
              onMouseEnter={() => handleMouseEnter(item)}
              onMouseLeave={() => handleMouseLeave(item)}
            >
              {item.type.toLowerCase() === 'folder' && (
                <div
                  className='icon-container'
                  onClick={() => handleOpenFolder(item)}
                >
                  <img src={Folder} className="folder-icon-size" />
                </div>
              )}

              {/* Show title & edit & delete */}
              <div className='text-nowrap d-flex justify-content-between '>
                <div className='mb-4 ps-2 text-truncated' style={{ textDecoration: 'none' }}>
                  {item.title}
                </div>
                {(viewFolderEdit.id === item.id && viewFolderEdit.is_visible && (userRole === 'ADMIN' || userRole === 'MASTER')) ? (
                  <div className='text-end pe-1'>
                    <BiDotsVerticalRounded className='edit-object me-1' onClick={() => handleEditObject(item)} />
                    <BiTrash className='delete-object' onClick={() => handleDeleteObject(item)} />
                  </div>
                ) : ""}
              </div>
            </div>
          ))}
        </div>
        :
        ""}

    </div>
  )
}

function DisplayFiles({ files, getFileExtension, viewFolderEdit, handleDeleteObject, handleMouseEnter, handleMouseLeave, handleOpenFile, iconExtensions }) {
  const { userRole } = useAuth();
	const { t } = useTranslation()

  return (
    <div>
      {files.length > 0 ?
        <div className='w-100 d-flex flex-wrap'>
          <div className='category-divider'>
            <span>{t('files').toUpperCase()}</span>
          </div>
          {files?.map(item => (
            <div
              key={item.id.toString()}
              onMouseEnter={() => handleMouseEnter(item)}
              onMouseLeave={() => handleMouseLeave(item)}
              className='file-container btn btn-link'
              style={{ textDecoration: 'none' }}
            >
              {iconExtensions.includes(getFileExtension(item).toLowerCase()) ? (
                <div className='icon-container'>
                  <div
                    onClick={() => handleOpenFile(item)}
                  >
                    {getFileExtension(item).toLowerCase() === 'pdf' && (
                      <img src={PdfIcon} className="file-icon-size pdf-icon" />
                    )}
                    {getFileExtension(item).toLowerCase() === 'ppt' && (
                      <img src={PptIcon} className="file-icon-size word-icon" />
                    )}
                    {getFileExtension(item).toLowerCase() === 'pptx' && (
                      <img src={PptIcon} className="file-icon-size  word-icon" />
                    )}
                    {getFileExtension(item).toLowerCase() === 'doc' && (
                      <img src={WordIcon} className="file-icon-size word-icon" />
                    )}
                    {getFileExtension(item).toLowerCase() === 'docx' && (
                      <img src={WordIcon} className="file-icon-size word-icon" />
                    )}
                    {getFileExtension(item).toLowerCase() === 'xlsx' && (
                      <img src={ExcelIcon} className="file-icon-size word-icon" />
                    )}
                    {getFileExtension(item).toLowerCase() === 'xls' && (
                      <img src={ExcelIcon} className="file-icon-size word-icon" />
                    )}
                    {getFileExtension(item).toLowerCase() === 'csv' && (
                      <img src={ExcelIcon} className="file-icon-size word-icon" />
                    )}
                    {getFileExtension(item).toLowerCase() === 'psd' && (
                      <img src={PsdIcon} className="file-icon-size psd-icon" />
                    )}
                    {getFileExtension(item).toLowerCase() === 'jpg' && (
                      <img src={ImageIcon} className="file-icon-size image-icon" />
                    )}
                    {getFileExtension(item).toLowerCase() === 'jpeg' && (
                      <img src={ImageIcon} className="file-icon-size image-icon" />
                    )}
                    {getFileExtension(item).toLowerCase() === 'png' && (
                      <img src={ImageIcon} className="file-icon-size image-icon" />
                    )}
                    {getFileExtension(item).toLowerCase() === 'bmp' && (
                      <img src={ImageIcon} className="file-icon-size image-icon" />
                    )}
                    {getFileExtension(item).toLowerCase() === 'svg' && (
                      <img src={ImageIcon} className="file-icon-size image-icon" />
                    )}
                  </div>
                </div>
              ) : (
                item?.type.toLowerCase() !== 'folder' && (
                  <div className='icon-container icon-file-button'>
                    <Button
                      className="btn btn-link"
                      style={{ textDecoration: 'none' }}
                      onClick={() => handleOpenFile(item)}
                    >
                      <img src={FileIcon} className="file-icon-size" />
                    </Button>
                  </div>
                ))}

              {/* Show title & edit & delete */}
              <div className='text-nowrap d-flex justify-content-between '>
                <div className='ps-2 text-truncated' >
                  {item.title}
                </div>
                {(viewFolderEdit.id === item.id && viewFolderEdit.is_visible && (userRole === 'ADMIN' || userRole === 'MASTER')) ? (
                  <div className='text-end pe-1'>
                    <BiTrash className='delete-object' onClick={() => handleDeleteObject(item)} />
                  </div>
                ) : ""}
              </div>
            </div>
          ))}
        </div>
        :
        ""}
    </div>
  )
}



function DisplayData({ loadingSearch, loading, viewGrid, data, viewFolderEdit, handleMouseEnter, handleMouseLeave, handleDeleteObject, handleEditObject, handleOpenFolder, flagArea }) {

  const [videos, setVideos] = useState<AreaDataDTO[]>([])
  const [filesAndFolders, setFilesAndFolders] = useState<AreaDataDTO[]>([])
  const [files, setFiles] = useState<AreaDataDTO[]>([])
  const [folders, setFolders] = useState<AreaDataDTO[]>([])
  const navigate = useNavigate()
  const { userRole } = useAuth();



  useEffect(() => {
    const videos = data?.filter(item => item.type === 'video_url');
    const folders = data?.filter(item => item.type === 'folder').sort((a, b) => a.title.localeCompare(b.title)) || []
    const files = data?.filter(item => item.type === 'file').sort((a, b) => a.title.localeCompare(b.title)) || []
    const fileAndFolders = [].concat(folders, files)
    setVideos(videos)
    setFolders(folders)
    setFiles(files)
    setFilesAndFolders(fileAndFolders)
  }, [data])

  const handleOpenFile = async (item) => {
    const docViewerFileExtensions = ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'rtf', 'txt', 'pdf', 'csv']

    let storageBucket;

    if (flagArea === 'AreaAcademy') {
      storageBucket = process.env.REACT_APP_AWS_STORAGE_BUCKET_AREA_ACADEMY
    }
    if (flagArea === 'AreaMarketing') {
      storageBucket = process.env.REACT_APP_AWS_STORAGE_BUCKET_AREA_MARKETING
    }

    const downloadLink: string = await Storage.get(
      item.title, { bucket: storageBucket }
    );

    const fileExtension = getFileExtension(item)

    if (docViewerFileExtensions.includes(fileExtension)) {

      if (flagArea === 'AreaAcademy') {
        navigate(`/area-academy/file/${item.title}`, { state: { item, flagArea } });
      }

      if (flagArea === 'AreaMarketing') {
        navigate(`/area-marketing/file/${item.title}`, { state: { item, flagArea } });
      }

    } else {
      window.open(downloadLink, '_blank');
    }
  }

  const getFileExtension = (item) => {
    return item.title.toLowerCase().split('.').pop()
  }

  return (
    <div className='grid-files'>
      {loadingSearch &&
        <div className='text-center'>
          <span className='fs-1'>Ricerca in corso...</span>
          <Spinner animation="border" variant="info" />
        </div>
      }
      {loading &&
        <div className='text-center'>
          <span className='fs-1'>Caricamento...</span>
          <Spinner animation="border" variant="info" />
        </div>
      }

      {!loading && !loadingSearch &&
        <div>
          {/* SHOW VIDEOS */}
          <DisplayVideo
            videos={videos}
            viewFolderEdit={viewFolderEdit}
            handleDeleteObject={handleDeleteObject}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
          />
          {/* SHOW FILES AND FOLDERS */}
          <div className='d-flex flex-wrap'>
            {viewGrid ? (
              <div id="area-grid-object" className='w-100'>
                <DisplayFolders
                  folders={folders}
                  viewFolderEdit={viewFolderEdit}
                  handleDeleteObject={handleDeleteObject}
                  handleMouseEnter={handleMouseEnter}
                  handleMouseLeave={handleMouseLeave}
                  handleOpenFolder={handleOpenFolder}
                  handleEditObject={handleEditObject}
                />
                <DisplayFiles
                  files={files}
                  getFileExtension={getFileExtension}
                  viewFolderEdit={viewFolderEdit}
                  handleDeleteObject={handleDeleteObject}
                  handleMouseEnter={handleMouseEnter}
                  handleMouseLeave={handleMouseLeave}
                  handleOpenFile={handleOpenFile}
                  iconExtensions={iconExtensions}
                />
              </div>
            ) :
              // LIST LAYOUT (STACK)
              <Stack>
                {filesAndFolders?.map(item => (
                  <div key={item.id.toString()}>
                    <div
                      key={item.id.toString()}
                      id="area-stack-object"
                      className='d-flex align-items-center justify-content-between'
                      onMouseEnter={() => handleMouseEnter(item)}
                      onMouseLeave={() => handleMouseLeave(item)}
                    >
                      {item.type.toLowerCase() === 'folder' && (
                        <Button
                          className="text-decoration-none w-100"
                          variant='link'
                          onClick={() => handleOpenFolder(item)}
                        >
                          <div className='d-flex align-items-center'>
                            {item.type.toLowerCase() === 'folder' && (
                              <div>
                                <FcFolder className="folder-icon-size" />
                              </div>
                            )}

                            <div className='text-center' style={{ textDecoration: 'none' }}>
                              {item.title}
                            </div>
                          </div>
                        </Button>
                      )}

                      {iconExtensions.includes(getFileExtension(item).toLowerCase()) ? (
                        <Button
                          className="text-decoration-none w-100"
                          variant='link'
                          onClick={() => handleOpenFile(item)}
                        >
                          <div className='d-flex align-items-center'>
                            {getFileExtension(item).toLowerCase() === 'pdf' && (
                              <img src={PdfIcon} className="file-icon-size pdf-icon" />
                            )}
                            {getFileExtension(item).toLowerCase() === 'ppt' && (
                              <img src={PptIcon} className="file-icon-size word-icon" />
                            )}
                            {getFileExtension(item).toLowerCase() === 'pptx' && (
                              <img src={PptIcon} className="file-icon-size  word-icon" />
                            )}
                            {getFileExtension(item).toLowerCase() === 'doc' && (
                              <img src={WordIcon} className="file-icon-size word-icon" />
                            )}
                            {getFileExtension(item).toLowerCase() === 'docx' && (
                              <img src={WordIcon} className="file-icon-size word-icon" />
                            )}
                            {getFileExtension(item).toLowerCase() === 'xlsx' && (
                              <img src={ExcelIcon} className="file-icon-size word-icon" />
                            )}
                            {getFileExtension(item).toLowerCase() === 'xls' && (
                              <img src={ExcelIcon} className="file-icon-size word-icon" />
                            )}
                            {getFileExtension(item).toLowerCase() === 'csv' && (
                              <img src={ExcelIcon} className="file-icon-size word-icon" />
                            )}
                            {getFileExtension(item).toLowerCase() === 'psd' && (
                              <img src={PsdIcon} className="file-icon-size" />
                            )}
                            {getFileExtension(item).toLowerCase() === 'jpg' && (
                              <img src={ImageIcon} className="file-icon-size" />
                            )}
                            {getFileExtension(item).toLowerCase() === 'jpeg' && (
                              <img src={ImageIcon} className="file-icon-size" />
                            )}
                            {getFileExtension(item).toLowerCase() === 'png' && (
                              <img src={ImageIcon} className="file-icon-size" />
                            )}
                            {getFileExtension(item).toLowerCase() === 'bmp' && (
                              <img src={ImageIcon} className="file-icon-size" />
                            )}
                            {getFileExtension(item).toLowerCase() === 'svg' && (
                              <img src={ImageIcon} className="file-icon-size image-icon" />
                            )}
                            <div className='text-center ms-2' style={{ textDecoration: 'none' }}>
                              {item.title}
                            </div>
                          </div>
                        </Button>
                      ) : (
                        item.type.toLowerCase() !== 'folder' && (
                          <div className='icon-container'>
                            <Button
                              className="btn btn-link icon-file-button"
                              onClick={() => handleOpenFile(item)}
                            >
                              <div className='d-flex align-items-center'>
                                <img src={FileIcon} className="file-icon-size" />
                                <div className='text-center ms-2' style={{ textDecoration: 'none' }}>
                                  {item.title}
                                </div>

                              </div>
                            </Button>
                          </div>
                        ))}

                      {(userRole === 'ADMIN' || userRole === 'MASTER') ?
                        <div className='d-flex h-100 align-items-center'>
                          {item.type === 'folder' ?
                            <div className='fs-5 me-2 edit-object'>
                              <BiDotsVerticalRounded onClick={() => handleEditObject(item)} />
                            </div>
                            : ""}
                          <div className='fs-5 delete-object' >
                            <BiTrash onClick={() => handleDeleteObject(item)} />
                          </div>
                        </div>
                        : ""}
                    </div>
                    <div className='divider'></div>

                  </div>
                ))}
              </Stack>
            }
          </div>
        </div>

      }
    </div >
  )
}

export default DisplayData