import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useEffect, useMemo, useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { FileUploader } from 'react-drag-drop-files';
import { Storage } from 'aws-amplify';
import { FaTimes } from 'react-icons/fa';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import Btn from '../../components/Btn/Btn';
import { useApiClient } from '../../hooks/useApiClient';
import toaster from '../../utils/toaster';
import { CancelablePromise } from '../../api';
import { columnsData } from './columnsData';
import { Column } from 'react-table';
import TableContainer from '../../components/genericTable/TableContainer';
import { GTMsendEvent } from '../../utils/trackingGA4'
import { handleSym } from '../../utils';
import './OfferEditPage.scss';

const OfferEditPage = () => {
	const { id } = useParams<{ id: string }>();
	const { state } = useLocation();
	const setInit = () => {
		let offerta = { ...state?.offerta };
		offerta.servizi = { ...state?.offerta.servizi };
		offerta.provvigioni = handleSym(offerta.provvigioni, 'value');
		offerta.servizi.i_f = handleSym(offerta.servizi.i_f, 'value');
		return offerta;
	};
	const [initialValues, setInitialValues] = useState(() => setInit() || {});
	const { apiClient, handleApi } = useApiClient();
	const [files, setFiles] = useState<File[]>([]);
	const [uploadError, setUploadError] = useState<string | null>(null);
	const [response, setResponse]: [any, any] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [deletedFiles, setDeletedFiles] = useState<string[]>([]);
	const [symProvvigioni, setSymProvvigioni] = useState(handleSym(state?.offerta?.provvigioni, 'symbol') || "%");
	const [symIf, setSymIf] = useState(handleSym(state?.offerta?.servizi?.i_f, 'symbol') || "%");
	const fileTypes: string[] = ['pdf'];
	const { t } = useTranslation()

	useEffect(() => {
		handleApi(apiClient.vehicleController.getVehiclesFilter())
			.then((res: any[]) => {
				setLoading(false);

				if (!res) {
					return;
				}

				res.map((item: any) => {
					item.checked = !!state?.vehicle_ids?.includes(item.id);

					return res;
				});

				setResponse(res);
			})
			.catch((err) => {
				setLoading(false);
				setError(err);
			});
	}, []);

	const columns = useMemo(() => columnsData as Column<Object>[], []);

	const toggleRow = (id: string) => {
		setResponse([
			...response.map((item) => {
				if (item.id === id) {
					item.checked = !item.checked;
				}
				return item;
			}),
		]);
	};

	const validateFn = (values) => {
		const errors: any = {};
		const REQUIRED_MESSAGE = i18next.t('mandatory');

		if (!values.nome_offerta) {
			errors.nome_offerta = REQUIRED_MESSAGE;
		}

		if (!values.durata) {
			errors.durata = REQUIRED_MESSAGE;
		}

		if (!values.km_totali) {
			errors.km_totali = REQUIRED_MESSAGE;
		}

		return errors;
	};

	async function uploadFiles(offerta): Promise<void> {
		try {
			setUploadError(null);

			offerta.pdfLinks = [...(initialValues.pdfLinks || [])];

			for (const name of deletedFiles) {
				const a = await Storage.remove(name, {
					bucket: process.env.REACT_APP_AWS_STORAGE_BUCKET_OFFER,
				});

				const index: number = offerta.pdfLinks.indexOf(name);
				if (index > -1) {
					offerta.pdfLinks.splice(index, 1);
				}
			}

			setDeletedFiles([]);

			for (const file of files) {
				const uploadRes: any = await Storage.put(file.name, file, {
					contentType: file.type,
					bucket: process.env.REACT_APP_AWS_STORAGE_BUCKET_OFFER,
				});

				offerta.pdfLinks.push(uploadRes.key);
			}

			setInitialValues({
				...initialValues,
				pdfLinks: [...offerta.pdfLinks],
			});
			setFiles([]);
		} catch (error) {
			setUploadError(i18next.t('error_loading_file'));
		}
	}

	const onSubmitFn = async (offerta, { setSubmitting }) => {
		await uploadFiles(offerta);

		let provv = '';

		if (offerta.provvigioni) {
			if (offerta.provvigioni.includes('%', '€')) {
				provv = handleSym(offerta.provvigioni, 'value');
			} else {
				provv = offerta.provvigioni
			}

			if ('€' === symProvvigioni) {
				offerta.provvigioni = symProvvigioni + ' ' + provv
			} else {
				offerta.provvigioni = provv + ' ' + symProvvigioni
			}
		}

		let i_f = '';

		if (offerta.servizi.i_f) {
			if (offerta.servizi.i_f.includes('%', '€')) {
				i_f = handleSym(offerta.servizi.i_f, 'value');
			} else {
				i_f = offerta.servizi.i_f
			}

			if ('€' === symIf) {
				offerta.servizi.i_f = symIf + ' ' + i_f
			} else {
				offerta.servizi.i_f = i_f + ' ' + symIf
			}

		}

		let fn: CancelablePromise<any>;

		const vehicle_ids: string[] = response
			.filter((item: any) => item.checked)
			.map((item: any) => item.id);

		if (id === 'new') {
			fn = apiClient.leasysController.postLeasys({
				vehicle_ids,
				offerta,
			});
		} else {
			fn = apiClient.leasysController.putLeasys(id as string, {
				vehicle_ids,
				offerta,
			});
		}

		offerta.provvigioni = provv
		offerta.servizi.i_f = i_f

		handleApi(fn)
			.then((res) => {
				toaster.success(
					id === 'new'
						? i18next.t('new_offer_created_successfully')
						: i18next.t('offer_updated_successfully')
				);

				// update offer
				if (id !== 'new') {
					GTMsendEvent({
						event: 'update_offer',
						category: 'offer',
						action: 'update',
						label: 'update_offer',
					});
				}

				// new offer
				if (id === 'new') {
					GTMsendEvent({
						event: 'complete_new_offer',
						evtCat: 'offer',
						evtAct: 'complete',
						evtLab: 'new_offer',
					});
				}
				setSubmitting(false);
			})
			.catch((err) => {
				console.error('err', err);
				toaster.error(
					err.message || i18next.t('error_updating_data')
				);
			});
	};

	return (
		<Container fluid>
			<Breadcrumb>
				<Breadcrumb.Item linkAs={Link} linkProps={{ to: '/offers' }}>
					Lista offerte
				</Breadcrumb.Item>
				<Breadcrumb.Item active>
					{id === 'new' ? i18next.t('new_offer') : i18next.t('modify_offer')}
				</Breadcrumb.Item>
			</Breadcrumb>

			<div className="display-6 my-4">
				{id === 'new' ? i18next.t('new_offer') : i18next.t('modify_offer')}
			</div>

			<Formik
				initialValues={{ ...initialValues }}
				validate={validateFn}
				onSubmit={onSubmitFn}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
				}) => (
					<Form onSubmit={handleSubmit}>
						<Card className="mt-2">
							<Card.Body>
								<Row>
									<Col md={6}>
										<Form.Group className="mb-3">
											<Form.Label>
												{t('offer_name')}
												<span className="red-ast">*</span>
											</Form.Label>
											<Form.Control
												onChange={handleChange}
												onBlur={handleBlur}
												name="nome_offerta"
												value={values?.nome_offerta || ''}
												type="text"
												className={`${errors.nome_offerta && touched.nome_offerta
													? 'error-border'
													: ''
													}`}
											/>
											{errors.nome_offerta && touched.nome_offerta && (
												<small className="text-danger">
													{errors.nome_offerta as string}
												</small>
											)}
										</Form.Group>
									</Col>

									<Col md={6}>
										<Form.Group className="mb-3">
											<Form.Label>
												{t('duration')}
												<span className="red-ast">*</span>
											</Form.Label>
											<Form.Control
												onChange={handleChange}
												onBlur={handleBlur}
												name="durata"
												value={values?.durata || ''}
												type="number"
												className={`${errors.durata && touched.durata
													? 'error-border'
													: ''
													}`}
											/>
											{errors.durata && touched.durata && (
												<small className="text-danger">
													{errors.durata as string}
												</small>
											)}
										</Form.Group>
									</Col>

									<Col md={6}>
										<Form.Group className="mb-3">
											<Form.Label>
												{t('km_per_year')}
												<span className="red-ast">*</span>
											</Form.Label>
											<Form.Control
												onChange={handleChange}
												onBlur={handleBlur}
												value={values?.km_totali || ''}
												name="km_totali"
												type="number"
												className={`${errors.km_totali && touched.km_totali
													? 'error-border'
													: ''
													}`}
											/>
											{errors.km_totali && touched.km_totali && (
												<small className="text-danger">
													{errors.km_totali as string}
												</small>
											)}
										</Form.Group>
									</Col>

									<Col md={6}>
										<Form.Group className="mb-3">
											<Form.Label>{t('downpayment')} + {t('vat').toUpperCase()}</Form.Label>
											<Form.Control
												onChange={handleChange}
												onBlur={handleBlur}
												value={values?.anticipo}
												name="anticipo"
												type="number"
												className={`${errors.anticipo && touched.anticipo
													? 'error-border'
													: ''
													}`}
											/>
										</Form.Group>
									</Col>

									<Col md={6}>
										<Form.Group className="mb-3">
											<Form.Label>{t('private_rental_fees')}</Form.Label>
											<Form.Control
												onChange={handleChange}
												onBlur={handleBlur}
												value={values?.importo_privato || ''}
												name="importo_privato"
												type="number"
												className={`${errors.importo_privato && touched.importo_privato
													? 'error-border'
													: ''
													}`}
											/>
										</Form.Group>
									</Col>

									<Col md={6}>
										<Form.Group className="mb-3">
											<Form.Label>{t('business_rental_fee')}</Form.Label>
											<Form.Control
												onChange={handleChange}
												onBlur={handleBlur}
												value={values?.importo_azienda || ''}
												name="importo_azienda"
												type="number"
												className={`${errors.importo_azienda && touched.importo_azienda
													? 'error-border'
													: ''
													}`}
											/>
										</Form.Group>
									</Col>

									<Col md={6}>
										<Form.Group className="mb-3">
											<Form.Label>{t('rental_company')}</Form.Label>
											<Form.Control
												onChange={handleChange}
												onBlur={handleBlur}
												value={values?.noleggiatore || ''}
												name="noleggiatore"
												type="text"
												className={`${errors.noleggiatore && touched.noleggiatore
													? 'error-border'
													: ''
													}`}
											/>
										</Form.Group>
									</Col>

									<Col md={6}>
										<Form.Group className="mb-3">
											<Form.Label>{t('offer_id')}</Form.Label>
											<Form.Control
												onChange={handleChange}
												onBlur={handleBlur}
												value={values?.id_offerta || ''}
												name="id_offerta"
												type="text"
												className={`${errors.id_offerta && touched.id_offerta
													? 'error-border'
													: ''
													}`}
											/>
										</Form.Group>
									</Col>

									<Col md={6}>
										<Form.Group className="mb-3">
											<Form.Label>{t('rtc').toUpperCase()}</Form.Label>
											<Form.Control
												onChange={handleChange}
												onBlur={handleBlur}
												value={values?.id_quotazione || ''}
												name="id_quotazione"
												type="text"
												className={`${errors.id_quotazione && touched.id_quotazione
													? 'error-border'
													: ''
													}`}
											/>
										</Form.Group>
									</Col>

									<Col md={6}>
										<Form.Group className="mb-3">
											<Form.Label>{t('invoice_discount')} ({t('if')} {t('rtc').toUpperCase()})</Form.Label>
											<Form.Control
												onChange={handleChange}
												onBlur={handleBlur}
												value={values?.sconto_fattura || ''}
												name="sconto_fattura"
												type="number"
												className={`${errors.sconto_fattura && touched.sconto_fattura
													? 'error-border'
													: ''
													}`}
											/>
										</Form.Group>
									</Col>

									<Col md={6}>
										<Form.Group className="mb-3">
											<Form.Label>{t('extra_discount')} ({t('if')} {t('rtc').toUpperCase()})</Form.Label>
											<Form.Control
												onChange={handleChange}
												onBlur={handleBlur}
												value={values?.sconto_extra || ''}
												name="sconto_extra"
												type="number"
												className={`${errors.sconto_extra && touched.sconto_extra
													? 'error-border'
													: ''
													}`}
											/>
										</Form.Group>
									</Col>

									<Col md={5} className="pe-1">
										<Form.Group className="mb-3">
											<Form.Label>{t('commissions')}</Form.Label>
											<Form.Control
												onChange={handleChange}
												onBlur={handleBlur}
												value={values?.provvigioni || ''}
												name="provvigioni"
												pattern="[0-9,',']*"
												type="text"
												className={`${errors.provvigioni && touched.provvigioni
													? 'error-border'
													: ''
													}`}
											/>
										</Form.Group>
									</Col>

									<Col md={1} className="ps-0">
										<Form.Group className="mt-2">
											<Form.Label />
											<Form.Select
												name='sym_provvigioni'
												value={symProvvigioni}
												onChange={(e) => setSymProvvigioni(e.target.value)}
											>
												<option key="%" value="%">
													%
												</option>
												<option key="€" value="€">
													€
												</option>
											</Form.Select>
										</Form.Group>
									</Col>

									<Col md={6}>
										<Form.Group className="mb-3">
											<Form.Label>{t('offer_link_on_site')}</Form.Label>
											<Form.Control
												onChange={handleChange}
												onBlur={handleBlur}
												value={values?.link_offerta || ''}
												name="link_offerta"
												type="text"
												className={`${errors.link_offerta && touched.link_offerta
													? 'error-border'
													: ''
													}`}
											/>
										</Form.Group>
									</Col>

									<Col md={6}>
										<Form.Group className="mb-3">
											<Form.Label>{t('validity_date')}</Form.Label>
											<Form.Control
												onChange={handleChange}
												onBlur={handleBlur}
												value={values?.data_valid || ''}
												name="data_valid"
												type="date"
												className={`${errors.data_valid && touched.data_valid
													? 'error-border'
													: ''
													}`}
											/>
										</Form.Group>
									</Col>

									<Col md={6}>
										<Form.Group className="mb-3">
											<Form.Label>{t('set_of_tyres')}</Form.Label>
											<Form.Control
												onChange={handleChange}
												onBlur={handleBlur}
												value={values?.servizi?.treno_di_gomme || ''}
												name="servizi.treno_di_gomme"
												type="text"
												className={`${errors.treno_di_gomme && touched.treno_di_gomme
													? 'error-border'
													: ''
													}`}
											/>
										</Form.Group>
									</Col>

									<Col md={6}>
										<Form.Group className="mb-3">
											<Form.Label>{t('replacement_vehicle')}</Form.Label>
											<Form.Control
												onChange={handleChange}
												onBlur={handleBlur}
												value={values?.servizi?.auto_sostitutiva || ''}
												name="servizi.auto_sostitutiva"
												type="text"
												className={`${errors.auto_sostitutiva && touched.auto_sostitutiva
													? 'error-border'
													: ''
													}`}
											/>
										</Form.Group>
									</Col>

									<Col md={6}>
										<Form.Group className="mb-3">
											<Form.Label>{t('rca').toUpperCase()}</Form.Label>
											<Form.Control
												onChange={handleChange}
												onBlur={handleBlur}
												value={values?.servizi?.rca}
												name="servizi.rca"
												pattern="[0-9,',']*"
												type="text"
												className={`${errors.rca && touched.rca
													? 'error-border'
													: ''
													}`}
											/>
										</Form.Group>
									</Col>

									<Col md={6}>
										<Form.Group className="mb-3">
											<Form.Label>{t('kasko')}</Form.Label>
											<Form.Control
												onChange={handleChange}
												onBlur={handleBlur}
												value={values?.servizi?.kasko}
												name="servizi.kasko"
												pattern="[0-9,',']*"
												type="text"
												className={`${errors.kasko && touched.kasko
													? 'error-border'
													: ''
													}`}
											/>
										</Form.Group>
									</Col>

									<Col md={5} className="pe-1">
										<Form.Group className="mb-3">
											<Form.Label>{t('i_f').toUpperCase()}</Form.Label>
											<Form.Control
												onChange={handleChange}
												onBlur={handleBlur}
												value={values?.servizi?.i_f}
												name="servizi.i_f"
												type="text"
												pattern="[0-9,',']*"
												className={`${errors.i_f && touched.i_f
													? 'error-border'
													: ''
													}`}
											/>
										</Form.Group>
									</Col>

									<Col md={1} className="ps-0">
										<Form.Group className="mt-2">
											<Form.Label />
											<Form.Select
												name='sym_if'
												value={symIf}
												onChange={(e) => setSymIf(e.target.value)}
											>
												<option key="%" value="%">
													%
												</option>
												<option key="€" value="€">
													€
												</option>
											</Form.Select>
										</Form.Group>
									</Col>

									<Col md={6}>
										<Form.Group className="mt-4">
											<Form.Check
												onChange={handleChange}
												onBlur={handleBlur}
												value={values?.is_ecobonus || false}
												id="ecobonus"
												name="is_ecobonus"
												type="checkbox"
												label="Ecobonus"
												className={`${errors.is_ecobonus && touched.is_ecobonus
													? 'error-border'
													: ''
													}`}
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col>
										<Form.Group>
											<Form.Label>{t('documents_linked_to_offer')}</Form.Label>
											<ul>
												{initialValues.pdfLinks?.map(
													(name: string, index: number) => (
														<li key={index}>
															{deletedFiles.includes(name) ? (
																<del>{name}</del>
															) : (
																<>
																	{name}
																	<Link
																		to="#"
																		onClick={(e) => {
																			e.preventDefault();
																			setDeletedFiles([...deletedFiles, name]);
																		}}
																		className="mx-2"
																	>
																		<FaTimes />
																	</Link>
																</>
															)}
														</li>
													)
												)}
											</ul>
											<div className="file-uploader-container text-center">
												{files.length ? (
													<Button
														variant="secondary"
														onClick={() => {
															setFiles([]);
														}}
														className="btn btn-sm btn-outline file-uploader-clear-btn"
														type="button"
													>
														{t('delete')}
													</Button>
												) : (
													''
												)}
												<FileUploader
													multiple={true}
													classes="dropzone"
													types={fileTypes}
													handleChange={(f: { [key: string]: File }) => {
														setUploadError(null);

														const fileData: File[] = [];
														for (const key in f) {
															parseInt(key) >= 0 && fileData.push(f[key]);
														}

														setFiles(fileData);
													}}
													onTypeError={() => {
														setFiles([]);
														setUploadError(i18next.t('unsupported_file'));
													}}
													name="file"
												>
													<div className="fs-4">
														<div>
															{t('drag_and_drop_file_or_click_to_upload_then_save')}
														</div>
														<div className="fs-6 text-muted">
															{t('file_type')}: {fileTypes.join(', ')}
														</div>
													</div>

													{files?.length ? (
														<ul className="my-5">
															{files.map((file: File, k: number) => (
																<li key={k}> {file.name} </li>
															))}
														</ul>
													) : (
														''
													)}
													{uploadError ? (
														<p className="mt-4 text-danger">{uploadError}</p>
													) : (
														''
													)}
												</FileUploader>
											</div>
										</Form.Group>
									</Col>
								</Row>
							</Card.Body>
						</Card>

						<div className="display-7 mt-4">	{t('vehicle_associations')}</div>

						<TableContainer
							columns={columns}
							toggleRow={toggleRow}
							data={response}
							loading={loading}
							error={error}
							className="vehicle-table"
							useFixedColumn={true}
						></TableContainer>

						<Btn
							type="submit"
							icon="arrow-right"
							className="mt-4 float-end"
							disabled={isSubmitting}
						>
								{t('save')}
						</Btn>
					</Form>
				)}
			</Formik>
		</Container>
	);
};

export default OfferEditPage;
