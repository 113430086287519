import Table from 'react-bootstrap/Table';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { FaSortDown, FaSortUp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import { GTMsendEvent } from '../../../utils/trackingGA4'

const VehicleTable = ({
	getTableProps,
	headerGroups,
	getTableBodyProps,
	page,
	prepareRow,
	resultCount,
}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const currentURL = location.pathname;
	const { t } = useTranslation()

	return (
		<>
			<div className="table-results-container">
			{t('show')}:{' '}
				<span className="table-result-count">{resultCount}</span>
			</div>
			<Card>
				<div className='flipped-container flipped'>
					<Table
						striped
						borderless
						hover
						responsive
						{...getTableProps()}
						className="vehicle-table m-0"
					>
						<thead className="border-bottom">
							{headerGroups.map((headerGroup) => (
								<tr {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column: any) => (
										<th
											className="text-nowrap"
											{...column.getHeaderProps({
												...column.getSortByToggleProps(),
												className: column.className,
											})}
										>
											{column.render('Header')}
											{column.canSort ? (
												<span className="position-relative">
													{column.isSorted ? (
														column.isSortedDesc ? (
															<>
																<FaSortUp className="mt-1 opacity-25 position-absolute" />
																<FaSortDown className="mt-1 position-absolute" />
															</>
														) : (
															<>
																<FaSortUp className="mt-1 position-absolute" />
																<FaSortDown className="mt-1 opacity-25 position-absolute" />
															</>
														)
													) : (
														<>
															<FaSortUp className="mt-1 opacity-25 position-absolute" />
															<FaSortDown className="mt-1 opacity-25 position-absolute" />
														</>
													)}
												</span>
											) : null}
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{page.map((row, i) => {
								prepareRow(row);
								return (
									<tr
										{...row.getRowProps()}
										onDoubleClick={() => {
											navigate(`/details/${row.original.id}`)
											let position = ''
											if (currentURL === '/available-vehicles') {
												position = 'lista veicoli'
											} else if (currentURL === '/my-stock') {
												position = 'my stock'
											}

											const datiVeicolo = row?.original;
											GTMsendEvent({
												event: 'select_item',
												item_id: datiVeicolo.nr_ordine,
												item_name: `${datiVeicolo.marca} ${datiVeicolo.allestimento}`,
												affiliation: datiVeicolo.dealer,
												item_category: datiVeicolo.marca,
												item_category2: `${datiVeicolo.modello} ${datiVeicolo.allestimento}`,
												item_category3: datiVeicolo.codice_costruttore,
												item_category4: datiVeicolo.stato_disp,
												price: Number(datiVeicolo.offerta_minima.valore || 0),
											})
										}}
									>
										{row.cells.map((cell: any) => {
											return (
												<td
													{...cell.getCellProps({
														className: cell.column.className,
													})}
												>

													{cell.render('Cell')}
												</td>
											)
										})}
									</tr>
								);
							})}
						</tbody>
					</Table>
				</div>
			</Card>
		</>
	);
};

export default VehicleTable;
