import { useState, useEffect } from 'react';
import { Breadcrumb, Card, Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import AccountForm, { AccountFormInitValues } from '../../components/AccountForm/AccountForm';
import OpportunitiesTable from '../../components/OpportunitiesTable/OpportunitiesTable';
import Btn from '../../components/Btn/Btn';
import toaster from '../../utils/toaster';
import { useApiClient } from '../../hooks/useApiClient';
import { AccountEntitySearch } from '../../api/models/AccountEntity';
import { GTMsendEvent } from '../../utils/trackingGA4';

const AccountFormPage = () => {
    const { id } = useParams<{ id: string }>();
    const [accountFormInitValues, setAccountFormInitValues] = useState<AccountFormInitValues | null>(null);
    const { apiClient, handleApi } = useApiClient();
    const [response, setResponse] = useState<AccountEntitySearch | null>(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { t } = useTranslation()


    useEffect(() => {
        if (!id) {
            toaster.error('Account Id non specificato');
        } else {
            if (id === 'new') {
                setAccountFormInitValues({
                    Name: '',
                    FirstName: '',
                    LastName: '',
                    Email__c: '',
                    Phone: '',
                    CreatedDate: '',
                    RecordTypeId: '',
                    Codice_Fiscale__c: '',
                    Partita_IVA__c: '',
                    reference_partner: '',
                    owner: null
                })

                setLoading(false)
            } else {
                handleApi(apiClient.accountController.getAccount(id))
                    .then((res: { data: AccountEntitySearch[]; }) => {
                        if (!res.data.length) {
                            throw new Error('Account non trovato');
                        }

                        const data = res.data[0];

                        setResponse(data);
                        setAccountFormInitValues({
                            Id: data.Id || undefined,
                            Name: data.Name || '',
                            FirstName: data.FirstName || '',
                            LastName: data.LastName || '',
                            Email__c: data.Email__c || '',
                            Phone: data.Phone || '',
                            CreatedDate: data.CreatedDate || '',
                            RecordTypeId: data.RecordType?.Id || '',
                            Codice_Fiscale__c: data.Codice_Fiscale__c || '',
                            Partita_IVA__c: data.Partita_IVA__c || '',
                            reference_partner: data.Nome_partner__c,
                            owner: data.Owner
                        });
                    })
                    .catch((err) => {
                        toaster.error(
                            err.message || i18next.t('error_loading_data')
                        );
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }

        return () => { };
    }, []);

    return (
        <Container fluid>
            <Breadcrumb>
                <Breadcrumb.Item
                    href={'/my-accounts'}
                >
                    {t('accounts_list')}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    {id === 'new' ? i18next.t('new_account') : response?.Name}&nbsp;
                </Breadcrumb.Item>
            </Breadcrumb>
            {id === 'new' ? (
                <>
                    <Row>
                        <Col className="display-6">
                            {t('new_account')}
                        </Col>
                    </Row>

                    {!loading && accountFormInitValues && (
                        <Card className="mt-2">
                            <Card.Body>
                                <AccountForm
                                    enableDuplicateModal={true}
                                    initialValues={accountFormInitValues!}
                                    mode='create'
                                    afterSubmitFn={(id) => window.location.replace('/my-accounts/' + id)}
                                />
                            </Card.Body>
                        </Card>
                    )}
                </>
            ) : (
                <>
                    <Row className='mb-3'>
                        <Col xs={12} className="mt-4 mt-md-0 text-md-end">
                            <Btn
                                disabled={response && !response.RecordType}
                                onClick={() => {
                                    navigate(`/opportunities/new?account_id=${id}&from=account`)
                                    GTMsendEvent({
                                        event: 'CTA_create_new_opportunity',
                                        evtCat: 'account',
                                        evtAct: 'create',
                                        evtLab: 'new_opportunity'
                                    })
                                }}
                            >
                                <FaPlus />{t('create_opportunity')}
                            </Btn>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="display-6">
                            {response?.Name}
                        </Col>
                    </Row>

                    {!loading && accountFormInitValues && response && (
                        <div>
                            <Card className="mt-2">
                                <Card.Body>
                                    <AccountForm
                                        accountId={id}
                                        initialValues={accountFormInitValues}
                                        mode='edit'
                                        afterSubmitFn={(id) => {
                                            handleApi(apiClient.accountController.getAccount(id))
                                                .then(res => {
                                                    setResponse(res.data[0])
                                                })
                                        }}
                                    />
                                </Card.Body>
                            </Card>

                            <div className='mt-4'>
                                <div className="display-7">
                                {t('opportunities')}
                                </div>
                                <OpportunitiesTable query={{ accountId: response.Id }}></OpportunitiesTable>
                            </div>
                        </div>
                    )}
                </>
            )
            }
        </Container>
    );
};

export default AccountFormPage;
