import { NOLEGGIATORI } from './constants'


export const getCheckedServices = (values: any) => {
  const checkedServices = Object.entries(values).flatMap(([key, value]: [any, any]) => {
    const isServicesOptions = (val: any): any => val && 'isChecked' in val && 'label' in val;
    if (Array.isArray(value)) {
      return value.filter(option => option.isChecked).map(option => `${option.labelRiepilogo}`);
    } else if (isServicesOptions(value)) {
      return value.isChecked ? [`${value.label}: ${value.value || ''}`.trim()] : [];
    } else {
      return [];
    }
  });
  return checkedServices
}

export const calcCommissions = (quotation, rentalDuration, optionalTotals, dati_veicolo) => {
  const prezzoListino = dati_veicolo.prezzo_listino
  const fullPriceVehicle = optionalTotals + prezzoListino;
  let commissionfee = 0;
  let commissionPercentage = 3;

  const isArvalPartitaIva = quotation.noleggiatore.toLowerCase() === NOLEGGIATORI.ARVAL_P_IVA.toLowerCase()
  const isLeaseplan = quotation.noleggiatore.toLowerCase() === NOLEGGIATORI.LEASEPLAN.toLowerCase()
  const isArvalPrivati = quotation.noleggiatore.toLowerCase() === NOLEGGIATORI.ARVAL_PRIVATI.toLowerCase()
  const isLeasysICare = quotation.noleggiatore.toLowerCase() === NOLEGGIATORI.LEASYS_I_CARE.toLowerCase()
  const isLeasysNoleggioChiaro = quotation.noleggiatore.toLowerCase() === NOLEGGIATORI.LEASYS_NOLEGGIO_CHIARO.toLowerCase()
  const isAld = quotation.noleggiatore.toLowerCase() === NOLEGGIATORI.ALD.toLowerCase()

  switch (true) {
    // ARVAL P.IVA e LEASEPLAN: canone * mesi * 3%
    case isArvalPartitaIva || isLeaseplan:
      commissionfee = arvalAndLeaseplanCommissionFee(quotation.canone, rentalDuration, commissionPercentage)
      break;

    // ARVAL PRIVATI canone (DEDUCT VAT) * mesi * 3%
    case isArvalPrivati:
      commissionfee = arvalAndLeaseplanCommissionFee(quotation.canone, rentalDuration, commissionPercentage)
      break;

    // ALD e LEASYS I-CARE: costo veicolo (listino + optional) * 3%
    case isLeasysICare || isAld:
      commissionfee = aldAndleasysICareCommissionFee(fullPriceVehicle, commissionPercentage)
      break;

    // LEASYS NOLEGGIO CHIARO (veicoli Stellantis) vedi leasysNoleggioChiatoCommissionFee:
    case isLeasysNoleggioChiaro:
      commissionfee = leasysNoleggioChiatoCommissionFee(prezzoListino)
      break;
    default:
  }

  const updatedCanone = quotation.canone

  return {
    updatedCanone,
    commissionfee,
    commissionPercentage,
    fullPriceVehicle
  }
}

const arvalAndLeaseplanCommissionFee = (canone, rentalDuration, commissionPercentage) => {
  return canone * rentalDuration * (commissionPercentage / 100)
}

const aldAndleasysICareCommissionFee = (fullPriceVehicle, commissionPercentage) => {
  return fullPriceVehicle * (commissionPercentage / 100)
}

const leasysNoleggioChiatoCommissionFee = (prezzoListino) => {
  // prezzo di listino < 19k = comm 800€
  // prezzo di listino 19-29k = comm 1000€
  // prezzo di listino 29-45k = comm 1200€
  // prezzo di listino > 45k = comm 1400€
  let commissionFee
  if (prezzoListino < 19_000) {
    commissionFee = 800;
  } else if (prezzoListino >= 19_000 && prezzoListino < 29_000) {
    commissionFee = 1000;
  } else if (prezzoListino >= 29_000 && prezzoListino < 45_000) {
    commissionFee = 1200;
  } else if (prezzoListino >= 45_000) {
    commissionFee = 1400;
  }
  return commissionFee
}

const spreadCommissionfeeOnCanone = (canone, commissionfee, rentalDuration) => {
  return canone + (commissionfee / rentalDuration)
}

// export const tempAdditions = (quotation, services) => {
//   // TEMP Add costs according to the services selected--------------
//   const isPaiActive = services.assicurazionePAI.isChecked
//   const gomme = services.gomme.find(item => item.isChecked)
//   const gommeSelected = gomme?.value
//   const gommeQuantity = gomme?.quantity
//   const vetturaSosSelected = services.vetturaSostitutiva.find(item => item.isChecked)?.value

//   // pai: +1€ sul canone
//   // auto sostitutiva: - city car 10€ sul canone
//   //                   - media 15€ sul canone
//   //                   - superior 20€ sul canone
//   // gomme: - quality 10€ sul canone
//   //        - premium 15€ sul canone

//   if (isPaiActive) {
//     quotation.canone += 1;
//   }

//   switch (gommeSelected) {
//     case "Premium":
//       quotation.canone += 15 * (gommeQuantity || 1);
//       break;
//     case "Quality":
//       quotation.canone += 10 * (gommeQuantity || 1);
//       break;
//     default:
//       break;
//   }

//   switch (vetturaSosSelected) {
//     case "citycar":
//       quotation.canone += 10;
//       break;
//     case "media":
//       quotation.canone += 15;
//       break;
//     case "superior":
//       quotation.canone += 20;
//       break;
//     default:
//       break;
//   }
//   // TEMP Add costs according to the services selected--------------
// }

