import { useMemo, useCallback } from 'react'
import { useTable } from 'react-table';
import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';
import { Row, Card, Button } from 'react-bootstrap';

import { useAiContext } from "../../context/AiContext";
import { columnsData } from './Data/optionalsColumns'

const Optionals = ({ optionals: data }) => {
  const { setActiveStep, setQuotReqState } = useAiContext();
	const { t } = useTranslation()

  const handleCheckboxChange = useCallback((item) => {
    setQuotReqState(prev => ({
      ...prev,
      optionals: prev.vehicleState.optionals.map(opt =>
        opt.codice_costruttore === item.codice_costruttore
          ? { ...opt, isChecked: !opt.isChecked }
          : opt
      )
    }));
  }, []);

  const columns = useMemo(() => columnsData.map((col) => {
    if (col.id === 'checkbox') {
      return {
        ...col,
        Cell: ({ row }) => {
          return (
            <div className="custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                onChange={() => handleCheckboxChange(row.original)}
                checked={row.original.isChecked}
              />
            </div>
          )
        },
      };
    }
    return col;
  }), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <Row>
      <Card className='p-0'>
        <Table
          striped
          borderless
          hover
          responsive
          {...getTableProps()}
          className="vehicle-table m-0"
          {...getTableProps()

          }>
          <thead className="border-bottom">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    className="text-nowrap"
                    {...column.getHeaderProps({
                      className: column.className,
                    })}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
      <Row>
        <div className="d-flex justify-content-center pt-5">
          <Button className="confirm-btn" type="submit" onClick={() => {
            setActiveStep((prev) => prev + 1)
          }}>
            {t('confirm')}.toUpperCase()
          </Button>
        </div>
      </Row>

    </Row>
  );
};

export default Optionals


