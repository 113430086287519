/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserDTO } from '../models/UserDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) { }

    /**
     * @returns UserDTO OK
     * @throws ApiError
     */
    public getRegisterUsers(): CancelablePromise<Array<UserDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/register/users',
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
    * GET all users that have SALES role
    * @returns UserDTO OK
    * @throws ApiError
    */
    public getRegisterSales(): CancelablePromise<Array<UserDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/register/users/azienda-association',
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
  * @returns UserDTO OK
  * @throws ApiError
  */
    public getAziendeAssociatedSales(username: string): CancelablePromise<Array<UserDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/register/users/{username}/aziende',
            path: {
                'username': username,
            },
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }


    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public postRegisterUsers(
        requestBody?: UserDTO,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/register/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
     * @param username
     * @returns UserDTO OK
     * @throws ApiError
     */
    public getRegisterUsers1(
        username: string,
    ): CancelablePromise<UserDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/register/users/{username}',
            path: {
                'username': username,
            },
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
     * @param username
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putRegisterUsers(
        username: string,
        requestBody?: UserDTO,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/register/users/{username}',
            path: {
                'username': username,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
     * @param username
     * @returns void
     * @throws ApiError
     */
    public deleteRegisterUsers(
        username: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/register/users/{username}',
            path: {
                'username': username,
            },
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }
}
