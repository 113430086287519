import { useState, useEffect, useMemo } from 'react';
import { useApiClient } from '../../hooks/useApiClient';
import TableContainer from '../../components/genericTable/TableContainer';
import { columnsData } from './columnsData';
import { Column } from 'react-table';
import toaster from '../../utils/toaster';

import { Container, Row, Col } from 'react-bootstrap';
import Btn from '../../components/Btn/Btn';
import { FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { GTMsendEvent } from '../../utils/trackingGA4'

const UserListPage = () => {
	const { apiClient, handleApi } = useApiClient();

	const [response, setResponse]: [any, any] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const navigate = useNavigate();

	useEffect(() => {
		handleApi(apiClient.userController.getRegisterUsers())
			.then((res) => {
				setResponse(res);
				setLoading(false);
			})
			.catch((err) => {
				setError(err);
				setLoading(false);
				toaster.error(
					err.message || 'Errore non specificato durante il caricamento dati'
				);
			});
	}, []);

	const columns = useMemo(() => columnsData as Column<Object>[], []);
	const data = useMemo(() => response ?? [], [response]);

	return (
		<Container fluid>
			<Row>
				<Col md={6} className="display-6">
					Lista Utenti
				</Col>
				<Col md={6} className="mt-4 mt-md-0 text-md-end">
					<Btn
						onClick={() => {
							navigate('/manage-users/new')
							GTMsendEvent({
								event: 'CTA_create_new_user',
								evtCat: 'user',
								evtAct: 'create',
								evtLab: 'new_user'
							})
						}}
					>
						<FaPlus /> Crea nuovo utente
					</Btn>
				</Col>
			</Row>

			<TableContainer
				dblClickCallback={(row: any) =>
					navigate(`/manage-users/${row.username}`)
				}
				columns={columns}
				data={data}
				loading={loading}
				error={error}
			></TableContainer>
		</Container>
	);
};

export default UserListPage;
