import dayjs from 'dayjs';
import i18next from 'i18next';
import Select, { components } from 'react-select';
import { useState, useEffect } from 'react';
import { Form, Alert, Breadcrumb, Button, Card, Col, Container, FormControl, ListGroup, Modal, Row, Spinner } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useApiClient } from '../../hooks/useApiClient';
import { toast } from 'react-toastify';
import { AccountEntitySearch, EditOpportunityEntity, EditVehicle, OpportunityAttachment, OpportunityEntity, OpportunityField } from '../../api/models/AccountEntity';
import { FaDownload, FaLink } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import Restricted from '../../components/Restricted/Restricted';
import VehicleCardBody from '../../components/VehicleCardBody/VehicleCardBody';
import PdfIcon from '../../icons/pdf_icon.svg';
import WordIcon from '../../icons/word_icon.svg';
import AccountSmallPreview from '../../components/AccountSmallPreview/AccountSmallPreview';
import OpportinityDocumentUploaderModal from '../../components/OpportunityDocumentUploaderModal/OpportunityDocumentUploaderModal';
import SpinnerButton from '../../components/SpinnerButton/SpinnerButton';
import RequiredFormLabel from '../../components/RequiredFormLabel/RequiredFormLabel';
import Btn from '../../components/Btn/Btn';
import { ReactSelectOption, Roles } from '../../types';
import { ConfirmSellVehicleRequestDTO, OptionDTO, VehicleStateEnum, VehicleEntity } from '../../api';
import { FORMAT_DATE_TIME, OPPORTUNITY_STATES_DATA } from '../../utils/constants';
import { downloadBase64File, renderFloatAsStr, strToFloat } from '../../utils';
import { OpportunityStageEnum } from '../../api/models/Enums';
import { useAuth } from '../../hooks/useAuth';

const maxNofCopies = 15;

const Opportunity = () => {
	const { id } = useParams<{ id: string }>();
	const location = useLocation()
	const { userRole } = useAuth()
	const from = (new URLSearchParams(location.search)).get('from')
	const { apiClient, handleApi, handleApiResponse } = useApiClient();
	const [response, setResponse] = useState<OpportunityEntity | null>(null);
	const [currentState, setCurrentState] = useState<(ReactSelectOption & { cssClass: string }) | null>(null)
	const [availableStates, setAvailableStates] = useState<(ReactSelectOption & { cssClass: string })[]>([])
	const [accountResponse, setAccountResponse] = useState<AccountEntitySearch | null>(null)
	const [vehicleResponse, setVehicleResponse] = useState<VehicleEntity | null>(null)
	const [showStateChangeModal, setShowStateChangeModal] = useState<boolean>(false)
	const [showUploadDocsModal, setShowUploadDocsModal] = useState<boolean>(false)
	const [showDuplicateModal, setshowDuplicateModal] = useState<boolean>(false)
	const [showVehicleNotAvailableModal, setShowVehicleNotAvailableModal] = useState<boolean>(false)
	const [loading, setLoading] = useState(true);
	const [updating, setUpdating] = useState<boolean>(false)
	const [downloadingAttachment, setDownloadingAttachment] = useState<string | null>(null)
	const [duplicateCopies, setDuplicateCopies] = useState<ReactSelectOption | null>(null);
	const [duplicateCopiesRes, setDuplicateCopiesRes] = useState<string[] | undefined>()
	const [opportunityFields, setOpportunityFields] = useState<OpportunityField[]>([])
	const [opportunityValues, setOpportunityValues] = useState<{
		Name: string,
		Tipologia__c: ReactSelectOption | null,
		Canale__c: ReactSelectOption | null,
		Noleggiatore__c: ReactSelectOption | null,
		commissions: string,
		notes: string,
	}>({
		Name: '',
		Tipologia__c: null,
		Canale__c: null,
		Noleggiatore__c: null,
		commissions: '',
		notes: ''
	})
	const { t } = useTranslation()


	const duplicateOpts: ReactSelectOption[] = [...Array(maxNofCopies)].map((el, i) => {
		return { value: (i + 1).toString(), label: (i + 1).toString() }
	})

	const dismissStateChange = () => {
		setShowStateChangeModal(false)
		setCurrentState(availableStates.find(el => el.value === response?.StageName)!)

		setCurrentState({
			value: response!.StageName,
			...OPPORTUNITY_STATES_DATA[response!.StageName]
		})
	}

	const dismissDuplicateModal = () => {
		setshowDuplicateModal(false);
		setDuplicateCopies(null)
		setDuplicateCopiesRes(undefined);
	}

	const confirmDuplicate = () => {
		if (!id || !duplicateCopies) return;

		setUpdating(true)
		handleApiResponse(apiClient.opportunityController.duplicateOpportunity(id!, parseInt(duplicateCopies?.value)), setUpdating)
			.then(res => {
				setDuplicateCopiesRes(res.data);
			})
	}

	const downloadAttachment = async (a: OpportunityAttachment) => {
		setDownloadingAttachment(a.Id)
		const encoded = await handleApiResponse(apiClient.opportunityController.getOpportunityAttachment(a.Id));
		setDownloadingAttachment(null);

		downloadBase64File(encoded.toString(), a.Title);
	}

	const loadAccountData = async (accountId: string) => {
		const accountRes = await handleApi(apiClient.accountController.getAccount(accountId));

		if (!accountRes.data.length) {
			toast.error(i18next.t('account_not_found'))
			return
		}

		setAccountResponse(accountRes.data[0]);
	}

	const loadVehicleData = async (vehicleId: string) => {
		const vehicleRes = await handleApi(apiClient.vehicleController.getVehicles1(vehicleId));
		setVehicleResponse(vehicleRes);
	}

	const loadData = async () => {
		if (!id) return;

		setUpdating(true)

		let [opportinityDataRes, opportunityFieldsRes] = await Promise.all([
			handleApi(apiClient.opportunityController.getOpportunity(id)),
			handleApi(apiClient.opportunityController.getOpportunityFields())
		]);

		if (!opportinityDataRes.data.length) {
			throw new Error(i18next.t('opportunity_not_found'))
		}

		const opportinityRes: OpportunityEntity = opportinityDataRes.data[0]
		setResponse(opportinityRes);

		setOpportunityFields(opportunityFieldsRes)
		setOpportunityValues({
			Name: opportinityRes.Name,
			commissions: renderFloatAsStr(opportinityRes.Provvigioni__c.toString()),
			Tipologia__c: { label: opportinityRes.Tipologia__c, value: opportinityRes.Tipologia__c },
			Canale__c: { label: opportinityRes.Canale__c, value: opportinityRes.Canale__c },
			Noleggiatore__c: { label: opportinityRes.Noleggiatore__c, value: opportinityRes.Noleggiatore__c },
			notes: opportinityRes.Note__c || ''
		})

		opportinityRes.availableStates = opportinityRes.availableStates || [];

		const states = opportinityRes.availableStates?.map(el => {
			return { value: el, ...OPPORTUNITY_STATES_DATA[el] }
		})

		const currState = {
			value: opportinityRes.StageName,
			cssClass: OPPORTUNITY_STATES_DATA[opportinityRes.StageName]?.cssClass || 'primary',
			label: OPPORTUNITY_STATES_DATA[opportinityRes.StageName]?.label || opportinityRes.StageName,
		}

		setCurrentState(currState)
		setAvailableStates([...states, currState].filter(el => {
			if (opportinityRes.StageName === OpportunityStageEnum.suspended) {
				return true
			} else {
				if (el.value === OpportunityStageEnum.suspended) {
					return false
				}

				if (![Roles.Master, Roles.Backoffice, Roles.Admin].includes(userRole) &&
					opportinityRes.ID_Vehicle_HMP__c &&
					el.value === OpportunityStageEnum.closed_won
				) {
					return false
				}

				return true
			}
		}))

		// do not wait for these to improve performance
		loadAccountData(opportinityRes.AccountId);

		const vehicleId = opportinityRes.ID_Vehicle_HMP__c ?? null;
		if (vehicleId) {
			loadVehicleData(vehicleId)
		}
		setUpdating(false)
	}

	const changeStateClicked = () => {
		if (vehicleResponse && vehicleResponse.stato_corrente?.type === VehicleStateEnum.VENDUTO) {
			setShowVehicleNotAvailableModal(true)
		} else {
			setShowStateChangeModal(true)
		}
	}

	const getOriginalEditableObj = (): EditOpportunityEntity => {
		if (!response) {
			throw new Error('Response is not jet valorized')
		}

		return {
			name: response.Name,
			commissions: response.Provvigioni__c,
			channel: response.Canale__c,
			type: response.Tipologia__c,
			dealerName: response.Noleggiatore__c,
			note: response.Note__c || '',
			stageName: response.StageName,
			vehicle: getUpdateVehicleObj(),
		}
	}

	const getUpdateVehicleObj = (): EditVehicle => {
		return {
			vehicleId: response?.ID_Vehicle_HMP__c || null,
			customVehicle: response?.ID_Vehicle_HMP__c ? null : {
				brand: response?.Brand__c ?? '',
				model: response?.Modello__c ?? '',
			}
		}
	}

	const submitFn = async () => {
		let request: EditOpportunityEntity = Object.assign(getOriginalEditableObj(), {
			name: opportunityValues.Name,
			commissions: strToFloat(opportunityValues.commissions),
			channel: opportunityValues.Canale__c!.value,
			type: opportunityValues.Tipologia__c!.value,
			dealerName: opportunityValues.Noleggiatore__c!.value,
		})

		setUpdating(true)

		await handleApiResponse(apiClient.opportunityController.editOpportunity(id!, request), setUpdating)
		toast.success(i18next.t('updated_success'))

		loadData()
	}

	const updateOpportunityStage = async (stageName: string) => {
		if (!response) {
			return
		}

		setUpdating(true)

		const changingStage = stageName && response.StageName !== stageName;

		let request: EditOpportunityEntity = getOriginalEditableObj()
		let vehicleRes: VehicleEntity | undefined;

		if (response.ID_Vehicle_HMP__c) {
			vehicleRes = await handleApi(apiClient.vehicleController.getVehicles1(response.ID_Vehicle_HMP__c));
		}

		if (changingStage) {
			request.stageName = stageName;

			if (vehicleRes
				&& vehicleRes?.stato_corrente?.opportunityId
				&& (vehicleRes?.stato_corrente?.opportunityId !== response.Id)
			) {
				setShowVehicleNotAvailableModal(true)
				return
			}
		}


		try {
			await handleApi(apiClient.opportunityController.editOpportunity(id!, request))
		} catch (err: any) {
			if (err.body.messageList.includes('vehicle_no_longer_available')) {
				setShowStateChangeModal(false)
				setShowVehicleNotAvailableModal(true)
				return
			} else {
				toast.error(i18next.t('error_editing_opportunity'))
			}
		}

		if (changingStage) {
			const hasVehicle = response.ID_Vehicle_HMP__c || (response.Modello__c);

			if (hasVehicle) {
				try {
					if (accountResponse && vehicleRes && response.ID_Vehicle_HMP__c) {
						const vehicleState = vehicleRes.stato_corrente?.type as VehicleStateEnum;

						if ([
							OpportunityStageEnum.waiting_sign,
							OpportunityStageEnum.closed_by_customer,
							OpportunityStageEnum.closed_by_credit_score_check
						].includes(stageName as OpportunityStageEnum)
							&& VehicleStateEnum.OPZIONATO === vehicleState
							&& vehicleRes?.stato_corrente?.opportunityId === id
						) {
							// Se il veicolo è opzionato ed è stato opzionato a partire da questa opportunity che adesso
							// stiamo tentando di chiudere in modo fallimentare, togliamo l'opzionamento
							await handleApi(
								apiClient.vehicleActionsController.deleteVehiclesActions(response.ID_Vehicle_HMP__c)
							)
						}

						else if (stageName === OpportunityStageEnum.closed_won) {
							const closeRequest: ConfirmSellVehicleRequestDTO = {
								cliente: accountResponse?.Name,
								opportunityId: id!,
								pratica_credito: id!,
								vehicleId: response.ID_Vehicle_HMP__c,
								noleggiatore: response.Noleggiatore__c || '',
								ragione_sociale: accountResponse.Codice_Fiscale__c || accountResponse.Partita_IVA__c || '',
							}

							await handleApi(
								apiClient.vehicleActionsController.postVehiclesActionsConfirmSell(closeRequest)
							)
						}

						else if (stageName === OpportunityStageEnum.credit_score_check) {
							const optionRequest: OptionDTO = {
								cliente: accountResponse?.Name,
								opportunityId: id!,
								vehicleId: response.ID_Vehicle_HMP__c,
								ragione_sociale: accountResponse.Codice_Fiscale__c || accountResponse.Partita_IVA__c || '',
								noleggiatore: response.Noleggiatore__c || '',
								document: [],
							}

							await handleApi(
								apiClient.vehicleActionsController.postVehiclesActions(optionRequest)
							)
						}
					}
				} catch (e) {
					console.error(e)
					toast.error(i18next.t('error_occurred_after_status_change'))
					setUpdating(false)
					return
				}
			} else {
				if (![
					OpportunityStageEnum.closed_by_customer,
					OpportunityStageEnum.closed_by_credit_score_check
				].includes(stageName as OpportunityStageEnum)) {
					setShowVehicleNotAvailableModal(true)
					return
				}
			}
		}

		toast.success(i18next.t('executed_successfully'))
		loadData()
	}

	const confirmStateChange = async () => {
		await updateOpportunityStage(currentState?.value!)
		setShowStateChangeModal(false)
	}

	const updateNotes = () => {
		setUpdating(true)

		let request: EditOpportunityEntity = getOriginalEditableObj()
		request.note = opportunityValues.notes;

		handleApiResponse(apiClient.opportunityController.editOpportunity(id!, request), setUpdating)
			.finally(() => {
				loadData()
			})
	}

	useEffect(() => {
		if (!id) {
			toast.error(i18next.t('invalid_or_missing_opportunity_id'))
			return
		}

		loadData()
			.catch((err) => {
				toast.error(err.message || i18next.t('error_loading_opportunity'))
			})
			.finally(() => {
				setLoading(false);
			})
	}, []);

	return (
		<Restricted to={[Roles.Master, Roles.Backoffice, Roles.Sales, Roles.Dealer, Roles.Admin]}>
			<Breadcrumb>
				{from === 'account' ? (
					<>
						<Breadcrumb.Item
							href={'/my-accounts'}
						>
							{t('account_list')}
						</Breadcrumb.Item>
						<Breadcrumb.Item
							href={'/my-accounts/' + (accountResponse ? accountResponse.Id : '')}
						>
							{(accountResponse?.Name)}
						</Breadcrumb.Item>
						<Breadcrumb.Item active>
							{response?.Name}
						</Breadcrumb.Item>
					</>
				) : null}
			</Breadcrumb>
			{!loading && (
				<Container fluid>
					{!loading && response && (
						<>
							<div className="display-6 mb-1">
								<span>{response.Name}</span>
							</div>

							<div className="display-6 mb-4 d-flex" style={{ flexFlow: 'row-reverse' }}>
								{response.Modello__c && !response.ID_Vehicle_HMP__c && (
									<div className='align-self-end'>
										<Button onClick={() => {
											setshowDuplicateModal(true)
										}}>{t('duplicate')}</Button>
									</div>
								)}
							</div>

							<Card >
								<Card.Body>
									<Form autoComplete='off' onSubmit={(e) => {
										e.preventDefault()
										submitFn()
									}}>
										<Row>
											<Col md={6}>
												<Form.Group className="mb-3">
													<RequiredFormLabel label={i18next.t('name')} />
													<Form.Control type="text"
														name='Name'
														required
														value={opportunityValues.Name}
														onChange={({ target }) => {
															setOpportunityValues({
																...opportunityValues,
																Name: `HMP-${target.value.replace(/^HMP-?/, '')}`
															})
														}}
													/>
												</Form.Group>
											</Col>
											<Col md={3}>
												<RequiredFormLabel label={i18next.t('creation_date')} required={false} />
												<Form.Control type="text"
													disabled
													value={dayjs(response.CreatedDate).format(FORMAT_DATE_TIME)}
												/>
											</Col>
										</Row>
										<Row className='mt-2'>
											<Col md={6}>
												<Form.Group className="mb-3">
													<RequiredFormLabel label={i18next.t('commissions')} />
													<Form.Control type="text"
														name='commissions'
														required
														value={opportunityValues.commissions}
														onChange={({ target }) => {
															setOpportunityValues({
																...opportunityValues,
																commissions: renderFloatAsStr(target.value)
															})
														}}
													/>
												</Form.Group>
											</Col>
											{opportunityFields.map(el => {
												return (
													<Col md={6} key={el.name}>
														<Form.Group className="mb-3">
															<RequiredFormLabel label={el.label} />
															<Select
																name={el.name}
																placeholder={`Seleziona ${el.label.toLowerCase()}`}
																options={el.picklistValues}
																defaultValue={opportunityValues[el.name]}
																onChange={(opt: any) => {
																	setOpportunityValues({ ...opportunityValues, [el.name]: opt })
																}}
															/>
														</Form.Group>
													</Col>
												)
											})}
										</Row>

										<Btn
											type="submit"
											icon="arrow-right"
											className="float-end mt-4"
											disabled={updating}
										>
											{t('update')}
										</Btn>
									</Form>
								</Card.Body>
							</Card>

							<div className="py-2"><hr /></div>

							<Card>
								<Card.Body>
									<Card.Title>
										<b>	{t('state')}</b>
									</Card.Title>
									<Row>
										<Col md={6} lg={4}>
											<Select
												className='mt-2'
												options={availableStates}
												value={currentState}
												onChange={(opt) => setCurrentState(opt)}
												components={{
													Control: ({ children, ...props }) => {
														return <components.Control {...props}>
															<div
																style={{ width: '3px', height: '25px' }}
																className={'m-1 bg-' + (currentState?.cssClass || 'secondary')}></div>
															{children}
														</components.Control>
													},
													Option: (props) => {
														return <div className='d-flex align-items-center'>
															<div
																style={{ width: '3px', height: '25px' }}
																className={'m-1 bg-' + (props.data.cssClass || 'secondary')}></div>
															<components.Option {...props} />
														</div>
													}
												}}
											/>
										</Col>
										<Col md={12} className='mt-3'>
											<Button
												disabled={!currentState || !response || currentState.value === response.StageName || updating}
												onClick={changeStateClicked}
											>Cambia stato
											</Button>
										</Col>
									</Row>
								</Card.Body>
							</Card>

							{/* Account */}
							{accountResponse && from !== 'account' && (
								<>
									<div className="py-2"><hr /></div>
									<AccountSmallPreview account={accountResponse} />
								</>
							)}


							{/* Veicolo */}
							{response.ID_Vehicle_HMP__c ? (
								<div>
									{vehicleResponse && (
										<div>
											<div className="py-2"><hr /></div>
											<Card>
												<VehicleCardBody showLinkBtn data={vehicleResponse} showState></VehicleCardBody>
											</Card>
										</div>
									)}
								</div>
							) : (
								<div>
									<div className="py-2"><hr /></div>
									{response.Modello__c && (
										<Card>
											<Card.Body>
												<Card.Title>
													<b>	{t('vehicle')}</b>
												</Card.Title>
												<Row>
													<Col>
														<b>	{t('brand')}</b>
														<p>{response.Brand__c || response.Marca__c ||  i18next.t("not_available")}</p>
													</Col>
													<Col>
														<b>	{t('model')}</b>
														<p>{response.Modello__c || i18next.t("not_available")}</p>
													</Col>
												</Row>
											</Card.Body>
										</Card>
									)}
								</div>
							)}

							<div className="py-2"><hr /></div>

							{/* Note */}
							<Card>
								<Card.Body>
									<FormControl
										as="textarea"
										placeholder={`${i18next.t('add_notes')}...`}
										style={{ height: '200px' }}
										value={opportunityValues.notes}
										onChange={({ target }) => setOpportunityValues({ ...opportunityValues, notes: target.value })}
									/>

									<div className="mt-3">
										<Button onClick={updateNotes} disabled={updating}>
											<SpinnerButton label='Salva' busy={updating} />
										</Button>
									</div>
								</Card.Body>
							</Card>

							<div className="py-2"><hr /></div>

							{/* Allegati */}
							<div className="mb-3 d-flex justify-content-start">
								<Button onClick={() => setShowUploadDocsModal(true)} disabled={updating}>
									<SpinnerButton label={i18next.t('upload_documents')} busy={false} />
								</Button>
							</div>
							{response.attachments && response.attachments.length > 0 ? (
								<Card>
									<Card.Body>
										<ListGroup variant="flush">
											{(response.attachments ?? []).map((el, i) => {
												return (
													<ListGroup.Item key={el.Id}>
														<div className='justify-content-between align-items-center d-flex'>
															<div>
																{(el.Title.endsWith('doc') || el.Title.endsWith('docx')) ? (
																	<img width={35} src={WordIcon} className="file-icon-size word-icon" />
																) : (<img width={25} src={PdfIcon} className="file-icon-size pdf-icon" />)}
															</div>
															<div style={{ width: '100%' }} className='px-3'>{el.Title}</div>
															<div>
																<Button disabled={downloadingAttachment === el.Id} variant='link' onClick={() => {
																	downloadAttachment(el);
																}}>

																	{downloadingAttachment === el.Id ? <Spinner animation='border' size='sm' /> : <FaDownload />}
																</Button>
															</div>
														</div>
													</ListGroup.Item>
												)
											})}
										</ListGroup>
									</Card.Body>
								</Card>
							) : (
								<Alert>
									{t('no_uploaded_document')}
								</Alert>

							)}
						</>
					)}
				</Container>
			)
			}

			<Modal
				show={showDuplicateModal}
				onExited={() => dismissDuplicateModal()}
			>
				<Modal.Header>
					<Modal.Title>{t('duplicate_opportunity')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>


					{duplicateCopiesRes ? (<div>
						<ListGroup>
							{duplicateCopiesRes.map((el, i) => {
								return <ListGroup.Item key={el}>
									<Row className='d-flex justify-content-between'>
										<Col>Copia n.{i + 1}</Col>
										<Col className='d-flex justify-content-end'>
											<Button
												target='_blank'
												href={`/opportunities/${el}?from=account`}
											><FaLink></FaLink> {t('visualize')}
											</Button>
										</Col>
									</Row>
								</ListGroup.Item>
							})}
						</ListGroup>
					</div>) : (
						<div>
							<span>{t('enter_number_of_copies_to_make')}</span>
							<Row className='mt-3'>
								<Col md={6}>
									<Select
										placeholder={i18next.t('select_couples')}
										options={duplicateOpts}
										value={duplicateCopies}
										onChange={
											(opt: any) => {
												setDuplicateCopies(opt)
											}
										}
									></Select>
								</Col>
							</Row>
						</div>
					)
					}
				</Modal.Body >
				<Modal.Footer>
					{duplicateCopiesRes ? (
						<Button onClick={() => setshowDuplicateModal(false)}>
							{t('close')}
						</Button>
					) : (
						<>
							<Button variant='link' onClick={() => setshowDuplicateModal(false)} disabled={updating}>
								{t('cancel')}
							</Button>
							<Button onClick={confirmDuplicate} disabled={updating}>
								{updating ? <Spinner animation='border' size='sm' /> : 'Conferma'}
							</Button>
						</>
					)}
				</Modal.Footer>
			</Modal >

			<OpportinityDocumentUploaderModal
				show={showUploadDocsModal}
				opportunity={response ?? undefined}
				onHide={() => {
					setShowUploadDocsModal(false)
				}}
				onSuccess={() => {
					setShowUploadDocsModal(false)
					loadData()
				}}
			/>

			<Modal
				show={showStateChangeModal}
				onExit={() => dismissStateChange()}
			>
				<Modal.Header>
					<Modal.Title>Sei sicuro di voler cambiare lo stato?</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t('proceeding_opportunity_will_be_set_to_state')} "<b>{currentState?.label}</b>"
					{currentState?.value === OpportunityStageEnum.closed_won && (
						<span>{t('and_vehicle_in_state_sold')} "<b>{t('sold')}</b>"</span>
					)}

					{[
						OpportunityStageEnum.closed_by_customer,
						OpportunityStageEnum.closed_by_credit_score_check
					].includes(currentState?.value as OpportunityStageEnum) && (
							<span>{t('and_vehicle_in_state_sold')} "<b>{t('available')}</b>"</span>
						)}

					{currentState?.value === OpportunityStageEnum.credit_score_check && (
						<span>{t('and_vehicle_in_state_sold')} "<b>{t('optioned')}</b>"</span>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button variant='link' onClick={() => setShowStateChangeModal(false)} disabled={updating}>
					{t('cancel')}
					</Button>
					<Button onClick={confirmStateChange} disabled={updating}>
						{updating ? <Spinner animation='border' size='sm' /> : i18next.t('confirm')}
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal
				show={showVehicleNotAvailableModal}
				onHide={() => {
					dismissStateChange()
					setShowVehicleNotAvailableModal(false)
				}}
			>
				<Modal.Header>
					<Modal.Title>Veicolo non più disponibile</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Alert>
						{t('warning_selected_vehicle_not_available')}
					</Alert>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='link' onClick={() => setShowVehicleNotAvailableModal(false)}>
						{t('cancel')}
					</Button>
					<Button href={`/opportunities/${id}/edit?action=update_vehicle&from=opportunity`}>
						{t('change_vehicle')}
					</Button>
				</Modal.Footer>
			</Modal>
		</Restricted >
	)
}

export default Opportunity;