import ColumnFilterSelect from './components/filters/ColumnFilterSelect';
import ColumnFilterMultiSelect from './components/filters/ColumnFilterMultiSelect';
import ColumnFilterInput from './components/filters/ColumnFilterInput';
import filterFn from './customFilterFn';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { GTMsendEvent } from '../../utils/trackingGA4'
import i18next from 'i18next';

export const columnsDataCompany = [
	{
		// this column is hidden (check VehicleTableContainer initialState)
		accessor: 'id',
		id: 'id',
		Header: '',
		disableSortBy: true,
		// enableSorting:false non funziona. Forse era solo per i global filters?
		// enableColumnFilter:false OPPURE disableFilterBy se esiste. Check
	},
	{
		width: 130,
		accessor: 'marca',
		id: 'marca',
		Header: i18next.t('brand'),
		PrimaryFilter: ColumnFilterMultiSelect,
		filter: filterFn,
	},
	{
		accessor: 'modello',
		id: 'modello',
		Header: i18next.t('model'),
		PrimaryFilter: ColumnFilterMultiSelect,
		filter: filterFn,
		Cell: (data) => {
			return data.row.original.modello_not_normalized
		}
	},
	{
		width: 210,
		accessor: 'allestimento',
		id: 'allestimento',
		Header: i18next.t('car_setup'),
		SecondaryFilter: ColumnFilterMultiSelect,
		filter: filterFn,
	},
	{
		width: 130,
		accessor: 'telaio',
		id: 'telaio',
		Header: i18next.t('vehicle_frame'),
		SecondaryFilter: ColumnFilterInput,
		inputType: 'text',
		filter: filterFn,
	},
	{
		width: 110,
		accessor: 'targa',
		id: 'targa',
		Header: i18next.t('vehicle_plate'),
		SecondaryFilter: ColumnFilterInput,
		inputType: 'text',
		filter: filterFn,
	},
	{
		width: 190,
		accessor: 'colore_esterno',
		id: 'colore_esterno',
		Header: i18next.t('external_color_code'),
		SecondaryFilter: ColumnFilterMultiSelect,
		filter: filterFn,
	},
	{
		width: 190,
		accessor: 'colore_interno',
		id: 'colore_interno',
		Header: i18next.t('internal_color_code'),
		SecondaryFilter: ColumnFilterMultiSelect,
		filter: filterFn,
	},
	{
		width: 170,
		accessor: 'alimentazione',
		id: 'alimentazione',
		Header: i18next.t('fuel_type'),
		SecondaryFilter: ColumnFilterSelect,
		filter: filterFn,
	},
	{
		width: 140,
		accessor: 'cambio',
		id: 'cambio',
		Header: i18next.t('vehicle_gear'),
		SecondaryFilter: ColumnFilterSelect,
		filter: filterFn,
	},
	{
		width: 130,
		accessor: 'trazione',
		id: 'trazione',
		Header: i18next.t('vehicle_drive_system'),
		SecondaryFilter: ColumnFilterMultiSelect,
		filter: filterFn,
	},
	{
		width: 140,
		accessor: 'carrozzeria',
		id: 'carrozzeria',
		Header: i18next.t('vehicle_body'),
		SecondaryFilter: ColumnFilterMultiSelect,
		filter: filterFn,
	},
	{
		width: 60,
		accessor: 'actions',
		id: 'actions',
		Header: '',
		disableSortBy: true,
		headerClassName: 'column-sticky',
		className: 'column-sticky text-end',
		Cell: (data: any) => (

			data.row?.original?.in_wishlist ? (
			<button
				onClick={async () => {
					const datiVeicolo = data.row.original
					data.row.original.in_wishlist = false;
					data.row?.original?.deleteWishlist(data.row?.original.id);
						GTMsendEvent({
							event: 'wishlist',
							wishlist_action: 'remove',
							item_name: `${datiVeicolo.marca} ${datiVeicolo.allestimento}`,
							item_category: datiVeicolo.marca,
							item_category2: `${datiVeicolo.modello} ${datiVeicolo.allestimento}`,
							item_category3:  `${datiVeicolo.telaio} - ${datiVeicolo.targa}`,
							item_category4: `${datiVeicolo.alimentazione} ${datiVeicolo.carrozzeria}`,
							item_variant: `${datiVeicolo.cambio} - ${datiVeicolo.trazione}`,
							colour: `{datiVeicolo.colore_esterno} - ${datiVeicolo.colore_interno}`
						})
				}}
				type="button"
				className="btn btn-sm"
			>
				<FaHeart
					style={{ width: '1.2rem', height: '1.6rem' }}
				/>
			</button>) :
 			(<button
				onClick={async () => {
					const datiVeicolo = data.row.original
					data.row.original.in_wishlist = true;
					data.row?.original?.addWishlist(data.row?.original.id);
					GTMsendEvent({
						event: 'wishlist',
						wishlist_action: 'add',
						item_name: `${datiVeicolo.marca} ${datiVeicolo.allestimento}`,
						item_category: datiVeicolo.marca,
						item_category2: `${datiVeicolo.modello} ${datiVeicolo.allestimento}`,
						item_category3:  `${datiVeicolo.telaio} - ${datiVeicolo.targa}`,
						item_category4: `${datiVeicolo.alimentazione} ${datiVeicolo.carrozzeria}`,
						item_variant: `${datiVeicolo.cambio} - ${datiVeicolo.trazione}`,
						colour: `${datiVeicolo.colore_esterno} - ${datiVeicolo.colore_interno}`
					})
				}}
				type="button"
				className="btn btn-sm"
			>
				<FaRegHeart
					style={{ width: '1.2rem', height: '1.6rem' }}
				/>
			</button>)
		),
	},
];
