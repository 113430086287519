/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum VehicleStateEnum {
  DISPONIBILE = 'DISPONIBILE',
  OPZIONATO = 'OPZIONATO',
  OSCURATO = 'OSCURATO',
  VENDUTO = 'VENDUTO',
}

export enum VehcileAvailabilityEnum {
  TARGATO = 'TARGATO',
  TELAIATO = 'TELAIATO',
  IN_ARRIVO = 'IN ARRIVO',
  ORDINATO = 'ORDINATO',
}

export enum RoleEnum {
  ADMIN = 'ADMIN',
  BACKOFFICE = 'BACKOFFICE',
  SALES = 'SALES',
  DEALER = 'DEALER',
  MASTER = 'MASTER',
  AZIENDA = 'AZIENDA'
}

export enum HistoryActionTypeEnum {
  DISPONIBILE = 'DISPONIBILE',
  OPZIONAMENTO = 'OPZIONAMENTO',
  OPZIONAMENTO_CANCELLATO = 'OPZIONAMENTO_CANCELLATO',
  OPZIONAMENTO_RINNOVATO = 'OPZIONAMENTO_RINNOVATO',
  OPZIONAMENTO_SCADUTO = 'OPZIONAMENTO_SCADUTO',
  OSCURAMENTO = 'OSCURAMENTO',
  OSCURAMENTO_RINNOVATO = 'OSCURAMENTO_RINNOVATO',
  OSCURAMENTO_CANCELLATO = 'OSCURAMENTO_CANCELLATO',
  VENDITA = 'VENDITA',
}

export enum InformationServicesListEnum {
  MANUTEZIONE = 'Manutenzione',
  GOMME = "Gomme",
  RCA = "RCA",
  I_F = "I/F",
  KASCO = "Kasco",
  VETTURA = "Vettura sostitutiva",
}

export enum AreaObjectTypeEnum {
  FILE = 'file',
  VIDEO_URL = "video_url",
  FOLDER = "folder",
}

export enum OpportunityStageEnum {
  waiting_sign = 'Attesa firma',
  proposal = 'Proposal',
  qualification = 'Qualification',
  closed_won = 'Closed Won',
  closed_by_customer = 'Chiusa persa per ripensamento cliente',
  closed_by_credit_score_check = 'Chiusa persa per credito',
  credit_score_check = 'Al credito',
  suspended = 'Sospesa',
  negotiation = "Negotiation"
}