import { useEffect, useState } from 'react';
import {
	useTable,
	useGlobalFilter,
	useSortBy,
	usePagination,
	TableOptions,
	useAsyncDebounce,
	useBlockLayout,
	SortingRule,
} from 'react-table';
import { useTranslation } from 'react-i18next';
import { Form, Card, Table, Pagination, Row, Col } from 'react-bootstrap';
import { FaMinus, FaSortDown, FaSortUp } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import './generic-table.scss';
import TableSkeleton from '../TableSkeleton/TableSkeleton';

const TableContainer = ({
	dblClickCallback = (row: any) => { },
	useFixedColumn = false,
	pageIndex = 0,
	className = '',
	columns,
	data,
	loading,
	error,
	hasFilter = true,
	initPageSize = 10,
	noBorder = false,
	isMinorPagination = false,
	dbClickType = 'double',
	onSortChange = (rule: SortingRule<Object>[]) => {},
	showModalFunc = (type: any, params?: any) => { },
	...props
}) => {
	const { t } = useTranslation()
	const tableOptions: TableOptions<Object> = {
		columns,
		data,
		autoResetPage: false,
		autoResetGlobalFilter: false,
		initialState: { pageSize: initPageSize, hiddenColumns: ['id'] },
		...props,
	};

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		// rows, // non si usa perchè ormai le rows stanno in page
		prepareRow,
		state,
		preGlobalFilteredRows,
		setGlobalFilter,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
	} = useTable(
		tableOptions,
		useGlobalFilter,
		useSortBy,
		useFixedColumn ? useBlockLayout : () => { },
		usePagination
		// useFilters,
	);

	const [value, setValue] = useState(state.globalFilter);

	const onChange = useAsyncDebounce((value) => {
		gotoPage(0);
		setGlobalFilter(value || undefined);
	}, 200);

	useEffect(() => {
		if (onSortChange) {
			// @ts-ignore
			onSortChange(state.sortBy);
		}
	}, [state.sortBy])

	return (
		<>
			{hasFilter && (
				<Row className="mt-4">
					<Col md={6} xl={4}>
						<Form.Control
							size="lg"
							placeholder={t('search') + '...'}
							value={value}
							onChange={(e) => {
								setValue(e.target.value);
								onChange(e.target.value);
							}}
						/>
					</Col>
				</Row>
			)}

			{loading && (
				<TableSkeleton rows={initPageSize}/>
			)}

			{!loading && !error && (
				<Row>
					<Col>
						<Card className={'mt-4' + (noBorder ? ' table-no-border' : '')}>
							<Table
								striped
								borderless
								hover
								responsive
								{...getTableProps()}
								className={className + ' m-0'}
							>
								<thead className="border-bottom">
									{headerGroups.map((headerGroup) => (
										<tr {...headerGroup.getHeaderGroupProps()}>
											{headerGroup.headers.map((column: any) => (
												<th
													className="text-nowrap"
													{...column.getHeaderProps({
														...column.getSortByToggleProps(),
														className: column.className,
													})}
												>
													{column.render('Header')}
													{column.canSort ? (
														<SortingControls column={column} />
													) : null}
												</th>
											))}
										</tr>
									))}
								</thead>
								<tbody {...getTableBodyProps()}>
									{page.map((row, i) => {
										prepareRow(row);
										return (
											<tr
												className="role-button"
												onClick={() => {
													dbClickType == 'single' && dblClickCallback && dblClickCallback(row.original)
												}}
												onDoubleClick={() =>
													dbClickType == 'double' && dblClickCallback && dblClickCallback(row.original)
												}
												{...row.getRowProps()}
											>
												{row.cells.map((cell: any) => (
													<td
														className="text-nowrap"
														{...cell.getCellProps({
															className: cell.column.className,
														})}
													>
														{cell.column.type === 'document' ? (
															cell.value.length ?
																<Link
																	to="#"
																	onClick={(e) => {
																		e.preventDefault();
																		cell.value.length &&
																			showModalFunc('offerDocuments', cell.value);
																	}}
																> {cell.render('Cell')} </Link> : <FaMinus />) : cell.render('Cell')
														}
													</td>
												))}
											</tr>
										);
									})}
								</tbody>
							</Table>
						</Card>

						{!isMinorPagination ? (
							<div className="mt-2 float-end">
								<Pagination>
									<Form.Select
										className="w-auto"
										value={state.pageSize}
										onChange={(e) => {
											setPageSize(Number(e.target.value));
										}}
									>
										{[2, 5, 10, 20].map((pageSize) => (
											<option key={pageSize} value={pageSize}>
												{t('show')} {pageSize}
											</option>
										))}
									</Form.Select>

									<Form.Label className="text-nowrap mx-3 mt-2">
									Pagina
									{/* {t('page')} */}
										<strong className="ms-1">
											{state.pageIndex + 1} di {pageOptions.length}
										</strong>
									</Form.Label>

									<Pagination.First
										onClick={() => gotoPage(0)}
										disabled={!canPreviousPage}
									/>
									<Pagination.Prev
										onClick={() => previousPage()}
										disabled={!canPreviousPage}
									/>
									<Pagination.Next
										onClick={() => nextPage()}
										disabled={!canNextPage}
									/>
									<Pagination.Last
										onClick={() => gotoPage(pageCount - 1)}
										disabled={!canNextPage}
									/>
								</Pagination>
							</div>
						) : // <div className="mt-1 float-end minor-pagination-wrapper">
							// 	<Pagination className="minor-pagination">
							// 		<Form.Label className="text-nowrap page-labels">
							// 			Page
							// 			<strong className="ms-1">
							// 				{state.pageIndex + 1} of {pageOptions.length}
							// 			</strong>
							// 		</Form.Label>
							// 		<Pagination.First
							// 			onClick={() => gotoPage(0)}
							// 			disabled={!canPreviousPage}
							// 		/>
							// 		<Pagination.Prev
							// 			onClick={() => previousPage()}
							// 			disabled={!canPreviousPage}
							// 		/>
							// 		<Pagination.Next
							// 			onClick={() => nextPage()}
							// 			disabled={!canNextPage}
							// 		/>
							// 		<Pagination.Last
							// 			onClick={() => gotoPage(pageCount - 1)}
							// 			disabled={!canNextPage}
							// 		/>
							// 	</Pagination>
							// </div>
							null}
					</Col>
				</Row>
			)}
		</>
	);
};

export default TableContainer;

const SortingControls = ({ column }) => {
	return (
		<span className="position-relative">
			{column.isSorted ? (
				column.isSortedDesc ? (
					<>
						<FaSortUp className="mt-1 opacity-25 position-absolute" />
						<FaSortDown className="mt-1 position-absolute" />
					</>
				) : (
					<>
						<FaSortUp className="mt-1 position-absolute" />
						<FaSortDown className="mt-1 opacity-25 position-absolute" />
					</>
				)
			) : (
				<>
					<FaSortUp className="mt-1 opacity-25 position-absolute" />
					<FaSortDown className="mt-1 opacity-25 position-absolute" />
				</>
			)}
		</span>
	);
};
