import { Modal } from 'react-bootstrap';
import i18next from 'i18next';

import { useApiClient } from '../../hooks/useApiClient';
import AccountForm from '../AccountForm/AccountForm';
import OpportunityCreation from '../OpportunityCreation/OpportunityCreation';

const OpportunityModals = ({
  validation,
  modalType,
  setAccountSearchOpt,
  setAccountModalOpened,
  setFunnelData,
  funnelData,
  show,
  onHide,
  account,
  setOpportunityValues,
  opportunityValues,
  opportunityFields,
}) => {

  const { apiClient, handleApiResponse } = useApiClient();

  const getModalHeader = () => {
    switch (modalType) {
      case 'account':
        return i18next.t('create_new_account');
      case 'opportunity':
        return i18next.t('create_opportunity');
      default:
        return i18next.t('error_unrecognized_operation');
    }
  };

  const getModalBody = () => {
    let initialValues;
    let mode;

    if (modalType === 'account') {
      mode = 'create';
      initialValues = {
        Codice_Fiscale__c: '',
        Name: '',
        RecordTypeId: '',
        Email__c: '',
        Phone: '',
        Partita_IVA__c: '',
        reference_partner: '',
        CreatedDate: '',
      };
    }
    if (modalType === 'opportunity') {
      mode = 'create';
    }

    switch (modalType) {
      case 'account':
        return (
          <AccountForm
            mode={mode}
            initialValues={initialValues}
            afterSubmitFn={(id) => {
              setAccountSearchOpt(null);
              setAccountModalOpened(false);

              handleApiResponse(apiClient.accountController.getAccount(id))
                .then(res => {
                  setFunnelData({ ...funnelData, account: res.data[0] });
                });
            }}
          />
        );
      case 'opportunity':
        return (
          <OpportunityCreation
            validation={validation}
            account={account}
            setOpportunityValues={setOpportunityValues}
            opportunityValues={opportunityValues}
            opportunityFields={opportunityFields}
            onHide={onHide}
          />
        )
      default:
        return null;
    }
  };

  return (
    <Modal show={show} onHide={onHide} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>{getModalHeader()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {getModalBody()}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OpportunityModals;