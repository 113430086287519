import { Card, ListGroup, Row, Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const HomePageCarouselPlaceholder = () => {
    return (
        <div style={{ overflowX: 'scroll', gap: 20 }} className="d-flex">
            {[...Array(6)].map((el, i) => {
                return (
                    <div key={i}>
                        <Card>
                            <Card.Body>
                                <div className="mb-3"><Skeleton height={200} width={300} /></div>
                                <Card.Title>
                                    <Row>
                                        <Col><Skeleton /></Col>
                                        <Col></Col>
                                    </Row>
                                </Card.Title>
                                <Card.Text>
                                    <Skeleton count={3} />
                                </Card.Text>
                            </Card.Body>
                            <ListGroup className="list-group-flush">
                                <ListGroup.Item><Skeleton /></ListGroup.Item>
                                <ListGroup.Item><Skeleton /></ListGroup.Item>
                                <ListGroup.Item><Skeleton /></ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </div>
                )
            })}
        </div>
    );
}

export default HomePageCarouselPlaceholder;