import './App.scss';
import { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import { Roles } from '../../types';
import { useAuth } from '../../hooks/useAuth';
import SideMenu from '../SideMenu/SideMenu';
import RestrictedRoute from '../RestrictedRoute/RestrictedRoute';
import HomePage from '../../pages/HomePage/HomePage';
import VehicleListPage from '../../pages/VehicleListPage/VehicleListPage';
import OfferListPage from '../../pages/OfferListPage/OfferListPage';
import DetailsPage from '../../pages/DetailsPage/DetailsPage';
import NotFound from '../../pages/NotFound/NotFound';
import NotAuthorized from '../../pages/NotAuthorized/NotAuthorized';
import TopLoadingBar from '../TopLoadingBar/TopLoadingBar';
import ImportPage from '../../pages/ImportPage/ImportPage';
import AutobizPage from '../../pages/AutobizPage/AutobizPage';
import ToastContainer from '../ToastContainer/ToastContainer';
import DetailsFormPage from '../../pages/DetailsFormPage/DetailsFormPage';
import DealerListPage from '../../pages/DealerListPage/DealerListPage';
import UserListPage from '../../pages/UserListPage/UserListPage';
import DealerFormPage from '../../pages/DealerFormPage/DealerFormPage';
import UserFormPage from '../../pages/UserFormPage/UserFormPage';
import OfferEditPage from '../../pages/OfferEditPage/OfferEditPage';
import MyStockPage from '../../pages/MyStockPage/MyStockPage';
import VehiclePromoListPage from '../../pages/VehiclePromoListPage/VehiclePromoListPage';
import QuickoVehiclesPage from '../../pages/QuickoVehiclesPage/QuickoVehiclesPage';
import WishlistPage from '../../pages/WishlistPage/WishlistPage';
import AreaAcademy from '../../pages/Areas/AreaAcademyPage/AreaAcademy';
import AreaMarketing from '../../pages/Areas/AreaMarketingPage/AreaMarketing';
import EmbedFile from '../../pages/EmbedFilePage/EmbedFile';
import QuotationList from '../../pages/QuotationListPage/QuotationList';
import TrackingConsent from '../../components/TrackingConsent/TrackingConsent';
import FuzzyRadar from '../../pages/FuzzyRadar/FuzzyRadar';
import AccountListPage from '../../pages/AccountListPage/AccountListPage';
import AccountFormPage from '../../pages/AccountFormPage/AccountFormPage';
import Opportunity from '../../pages/OpportunityPage/OpportunityPage';
import MyCompaniesPage from '../../pages/MyCompaniesPage/MyCompaniesPage';
import CreateOpportunityPage from '../../pages/CreateOpportunityPage/CreateOpportunityPage';
import VehicleOfferPage from '../../pages/VehicleOfferPage/VehicleOfferPage';
import Wizard from '../../pages/Wizard/Wizard';

function App() {
	const [toggled, setToggled] = useState(false);
	const { userRole } = useAuth();

	const location = useLocation();
	const currentURL = location.pathname;

	const handleToggleSidebar = (value) => {
		setToggled(value);
	};

	return (
		<div className="app">

			<TopLoadingBar />
			<ToastContainer />
			{!currentURL.includes('/privacy') ?
				<div>
					<TrackingConsent />
					<SideMenu toggled={toggled} handleToggleSidebar={handleToggleSidebar} />
				</div>
				: ""}
			<main className="p-2 p-lg-5 p-md-4">
				<button
					type="button"
					onClick={() => handleToggleSidebar(!toggled)}
					className="btn btn-sidemenu-toggle mb-3"
				>
					<FaBars />
				</button>
				<Routes>
					<Route path="/" element={userRole === Roles.Azienda ? <Navigate replace to="/available-vehicles" /> : <HomePage />} />
					<Route path="/available-vehicles" element={<VehicleListPage />} />
					<Route path="/my-companies" element={<RestrictedRoute to={[Roles.Backoffice, Roles.Sales, Roles.Master, Roles.Admin]}><MyCompaniesPage /></RestrictedRoute>} />
					<Route path="/my-accounts" element={<RestrictedRoute to={[Roles.Backoffice, Roles.Sales, Roles.Master, Roles.Admin]}><AccountListPage /></RestrictedRoute>} />
					<Route path="/my-accounts/:id" element={<RestrictedRoute to={[Roles.Backoffice, Roles.Sales, Roles.Master, Roles.Admin]}><AccountFormPage /></RestrictedRoute>} />
					<Route path="/opportunities/new" element={<RestrictedRoute to={[Roles.Backoffice, Roles.Sales, Roles.Master, Roles.Admin]}><CreateOpportunityPage /></RestrictedRoute>} />
					<Route path="/opportunities/:id" element={<RestrictedRoute to={[Roles.Backoffice, Roles.Sales, Roles.Master, Roles.Admin]}><Opportunity /></RestrictedRoute>} />
					<Route path="/opportunities/:id/edit" element={<RestrictedRoute to={[Roles.Backoffice, Roles.Sales, Roles.Master, Roles.Admin]}><CreateOpportunityPage /></RestrictedRoute>} />
					<Route path="/promo-vehicles" element={<VehiclePromoListPage />} />
					<Route path="/quicko-vehicles" element={<QuickoVehiclesPage />} />
					<Route path="/details/new" element={<DetailsFormPage />} />
					<Route path="/details/:id" element={<DetailsPage />} />
					<Route path="/details/:id/edit" element={<DetailsFormPage />} />
					<Route path="/details/:id/clone" element={<DetailsFormPage />} />
					<Route path="/my-stock" element={<MyStockPage />} />
					<Route path="/wishlist/" element={<WishlistPage />} />
					<Route path="/wishlist/:username" element={<WishlistPage />} />
					<Route path="/vehicle-offer" element={<RestrictedRoute to={[Roles.Backoffice, Roles.Master, Roles.Admin, Roles.Sales]}><VehicleOfferPage /></RestrictedRoute>} />
					<Route path="/offers" element={<OfferListPage />} />
					<Route path="/offers/:id" element={<RestrictedRoute to={[Roles.Backoffice, Roles.Master, Roles.Admin]}><OfferEditPage /></RestrictedRoute>} />
					<Route path="/manage-dealers" element={<RestrictedRoute to={[Roles.Backoffice, Roles.Sales, Roles.Master, Roles.Admin]}><DealerListPage /></RestrictedRoute>} />
					<Route path="/manage-dealers/:id" element={<RestrictedRoute to={[Roles.Backoffice, Roles.Sales, Roles.Master, Roles.Admin]}><DealerFormPage /></RestrictedRoute>} />
					<Route path="/manage-users" element={<RestrictedRoute to={[Roles.Backoffice, Roles.Master, Roles.Admin]}><UserListPage /></RestrictedRoute>} />
					<Route path="/manage-users/:username" element={<RestrictedRoute to={[Roles.Backoffice, Roles.Master, Roles.Admin]}><UserFormPage /></RestrictedRoute>} />
					<Route path="/import" element={<RestrictedRoute to={[Roles.Master, Roles.Admin]}><ImportPage /></RestrictedRoute>} />
					<Route path="/autobiz" element={<RestrictedRoute to={[Roles.Backoffice, Roles.Sales, Roles.Master, Roles.Admin]}><AutobizPage /></RestrictedRoute>} />
					<Route path="/personal-area/:username" element={<UserFormPage />} />
					<Route path="/area-academy" element={<AreaAcademy />} />
					<Route path="/area-academy/:id" element={<AreaAcademy />} />
					<Route path="/area-marketing" element={<AreaMarketing />} />
					<Route path="/area-marketing/:id" element={<AreaMarketing />} />
					<Route path="/area-academy/file/:title" element={<EmbedFile />} />
					<Route path="/area-marketing/file/:title" element={<EmbedFile />} />
					<Route path="/quotation-request-list" element={<RestrictedRoute to={[Roles.Azienda]}><QuotationList /></RestrictedRoute>} />
					<Route path="/privacy-dealer/:title" element={<EmbedFile />} />
					<Route path="/privacy-dipendenti/:title" element={<EmbedFile />} />
					<Route path="/privacy-dealer/" element={<EmbedFile />} />
					<Route path="/privacy-dipendenti/" element={<EmbedFile />} />
					{(process.env.REACT_APP_WIZARD_ENABLED === '1') && (
						<Route path="/wizard" element={<RestrictedRoute to={[Roles.Master, Roles.Admin, Roles.Backoffice, Roles.Sales]}><Wizard /></RestrictedRoute>} />
					)}
					<Route path="/search/" element={<RestrictedRoute to={[Roles.Master, Roles.Admin]}><FuzzyRadar /></RestrictedRoute>} />
					<Route path="not-authorized" element={<NotAuthorized />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
			</main>
		</div>
	);
}

export default App;
