import { useState, useEffect } from 'react';
import i18next from 'i18next';
import { Breadcrumb, Col, Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useApiClient } from '../../hooks/useApiClient';
import OpportunityFunnel, { FunnelStep } from '../../components/OpportunityFunnel/OpportunityFunnel';
import { AccountEntitySearch, OpportunityEntity } from '../../api/models/AccountEntity';
import { ConfirmSellVehicleRequestDTO, OptionDTO, VehicleStateEnum, VehicleEntity } from '../../api';
import { toast } from 'react-toastify';
import { OpportunityStageEnum } from "../../api/models/Enums";
import CardPlaceholder from '../../components/CardPlaceholder/CardPlaceholder';
import { useAuth } from '../../hooks/useAuth';
import { Roles } from '../../types';
import { useTranslation } from 'react-i18next';

const CreateOpportunityPage = () => {
	let { id: opportunityId } = useParams<{ id: string }>();
	const {  userRole } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const params = new URLSearchParams(location.search)
	const action = params.get('action') ?? '' // create_option | confirm_sell | update_vehicle | send_docs
	const [loading, setLoading] = useState(true);
	const [nextState, setNextState] = useState<OpportunityStageEnum>()
	const [account, setAccount] = useState<AccountEntitySearch | undefined>()
	const [vehicle, setVehicle] = useState<VehicleEntity | undefined>()
	const [opportunity, setOpportunity] = useState<OpportunityEntity | undefined>()
	const { apiClient, handleApiResponse, handleApi } = useApiClient();
	const [canChange, setCanChange] = useState<FunnelStep[]>([])
	opportunityId = location.pathname.includes('new') ? 'new' : opportunityId
	const [breadCrumbFrom, setBreadCrumbFrom] = useState<string>(params.get('from') || 'account')
	const [mustComplete, setMustComplete] = useState<FunnelStep[]>([])
	const [pageTitle, setPageTitle] = useState<string>('')
	const saveLabels = {
		confirm_sell: 'Conferma vendita'
	}
	const { t } = useTranslation()

	const getPrevPage = () => {
		if (breadCrumbFrom === 'account') {
			return ('/my-accounts/' + account?.Id)
		}

		if (breadCrumbFrom === 'opportunity') {
			return ('/opportunities/' + opportunityId)
		}

		return ('/details/' + vehicle?.id)
	}

	const afterSubmitFn = (id) => {
		setLoading(true)
		afterSubmitAction(id).finally(() => {
			setLoading(false)
		})
	}

	const afterSubmitAction = async (id: string) => {
		const opRes = await handleApi(apiClient.opportunityController.getOpportunity(id))

		if (!opRes.data.length) {
			toast.error(i18next.t('internal_error_missing_data_for_option_confirmation'))
			return
		}

		const opportunity: OpportunityEntity = opRes.data[0];

		if (action === 'create_option' ||
			(opportunityId === 'new' && opportunity.StageName === OpportunityStageEnum.credit_score_check)
		) {
			try {
				const accountRes = account || (await handleApi(apiClient.accountController.getAccount(opportunity.AccountId))).data[0]

				if (!accountRes) {
					toast.error(i18next.t('missing_data_to_option_vehicle'))
					return
				}

				if (opportunity.ID_Vehicle_HMP__c) {
					const optionRequest: OptionDTO = {
						cliente: accountRes?.Name,
						opportunityId: id,
						vehicleId: opportunity.ID_Vehicle_HMP__c,
						ragione_sociale: accountRes.Codice_Fiscale__c || accountRes.Partita_IVA__c || '',
						noleggiatore: opportunity.Noleggiatore__c || '',
						document: [],
					}

					await handleApi(
						apiClient.vehicleActionsController.postVehiclesActions(optionRequest)
					)
				}

				navigate(getPrevPage())

			} catch (err) {
				toast.error(i18next.t('unable_to_option_vehicle'))
			}
		} else if (action === 'confirm_sell') {
			if (![Roles.Master, Roles.Backoffice, Roles.Admin].includes(userRole)) {
				console.warn(userRole + ' non può confermare vendita di un veicolo')
				return
			}

			try {
				const accountRes = account || (await handleApi(apiClient.accountController.getAccount(opportunity.AccountId))).data[0];

				if (!accountRes) {
					toast.error(i18next.t('missing_data_to_confirm_vehicle_sale'))
					return
				}

				if (opportunity.ID_Vehicle_HMP__c) {
					const closeRequest: ConfirmSellVehicleRequestDTO = {
						cliente: accountRes?.Name,
						opportunityId: id,
						pratica_credito: id,
						vehicleId: opportunity.ID_Vehicle_HMP__c,
						noleggiatore: opportunity.Noleggiatore__c || '',
						ragione_sociale: accountRes.Codice_Fiscale__c || accountRes.Partita_IVA__c || '',
					}

					await handleApi(
						apiClient.vehicleActionsController.postVehiclesActionsConfirmSell(closeRequest)
					)
				}

				navigate(getPrevPage())
			} catch (err) {
				console.error(err)
				toast.error(i18next.t('unable_to_option_vehicle'))
			}
		} else {
			navigate(getPrevPage())
		}
	}

	const loadData = async () => {
		let nextState = OpportunityStageEnum.waiting_sign;

		// Setting title
		if (opportunityId === 'new') {
			if (action === 'confirm_sell') {
				setPageTitle(i18next.t('confirm_sale'))
			} else if (action === 'create_option') {
				setPageTitle(i18next.t('insert_option'))
			} else {
				setPageTitle(i18next.t('create_opportunity'))
			}
		} else {
			if (action === 'confirm_sell') {
				setPageTitle(i18next.t('confirm_sale'))
			} else if (action === 'create_option') {
				setPageTitle(i18next.t('insert_option'))
			} else {
				setPageTitle(i18next.t('modify_opportunity'))
			}
		}

		// Setting must complete
		if (opportunityId === 'new') {
			if (['create_option', 'confirm_sell'].includes(action!)) {
				setMustComplete(['account', 'vehicle', 'opportunity', 'upload_docs'])
			} else if (action === 'send_docs') {
				setMustComplete(['account', 'vehicle', 'opportunity', 'send_docs'])
			} else {
				setMustComplete(['account', 'vehicle', 'opportunity', 'send_or_upload_docs'])
			}
		} else {
			if (action === 'confirm_sell') {
				setMustComplete(['account', 'vehicle'])
			} else if (action === 'update_vehicle') {
				setMustComplete(['vehicle'])
			} else if (vehicle) {
				setMustComplete(['vehicle'])
			}
		}

		// Setting next opportunity state
		if (action === 'create_option') {
			nextState = OpportunityStageEnum.credit_score_check
		} else if (action === 'confirm_sell') {
			nextState = OpportunityStageEnum.closed_won
		}

		setNextState(nextState)

		if (opportunityId === 'new') {
			if (params.has('account_id')) {
				setCanChange(['vehicle', 'send_docs', 'upload_docs', 'opportunity'])
				const accountRes = await handleApiResponse(apiClient.accountController.getAccount((params.get('account_id') || '')))
				setAccount(accountRes.data[0])
			}

			else if (params.has('vehicle_id')) {
				if (action === 'send_docs') {
					setCanChange(['account', 'send_docs', 'opportunity'])
				} else {
					setCanChange(['account', 'upload_docs', 'opportunity'])
				}


				const vehicleRes = await handleApiResponse(apiClient.vehicleController.getVehicles1(params.get('vehicle_id') || ''))
				setVehicle(vehicleRes)
			}
		} else if (opportunityId) {
			const opportunityRes = await handleApiResponse(apiClient.opportunityController.getOpportunity(opportunityId))

			if (opportunityRes.data.length > 0) {
				const op: OpportunityEntity = opportunityRes.data[0]

				if ([OpportunityStageEnum.closed_won, OpportunityStageEnum.closed_by_credit_score_check,
				OpportunityStageEnum.closed_by_customer].includes(op.StageName)) {
					navigate(`/opportunities/${op.Id}`)
					return;
				}

				setOpportunity(op)

				const [accountRes, vehicleRes] = await Promise.all([
					apiClient.accountController.getAccount(op.AccountId),
					op.ID_Vehicle_HMP__c ? apiClient.vehicleController.getVehicles1(op.ID_Vehicle_HMP__c) : Promise.resolve(),
				])

				if (vehicleRes) {
					if (vehicleRes.stato_corrente?.type && [VehicleStateEnum.OPZIONATO, VehicleStateEnum.VENDUTO].includes(vehicleRes.stato_corrente!.type)) {
						if (vehicleRes.stato_corrente?.type === VehicleStateEnum.OPZIONATO
							&& vehicleRes.stato_corrente?.opportunityId === opportunityId
						) {
							setCanChange(['upload_docs'])
							setVehicle(vehicleRes)
						} else {
							// se il veicolo non è piu disponibile, offrire la possibilità di modificarlo
							if (action === 'update_vehicle') {
								setCanChange(['vehicle'])
							} else {
								setCanChange(['upload_docs', 'vehicle'])
							}

							setVehicle(undefined)
						}

					} else {
						setCanChange(['upload_docs'])
						setVehicle(vehicleRes)
					}
				} else {
					setCanChange(['upload_docs'])
					setVehicle(undefined)
				}

				setAccount(accountRes.data[0])

			} else {
				toast.error(i18next.t('opportunity_not_found'))
			}
		}

		setLoading(false)
	}

	useEffect(() => {
		loadData()
	}, []);

	return (
		<Container fluid>
			<Breadcrumb>
				{breadCrumbFrom === 'account' && (
					<>
						<Breadcrumb.Item
							href={'/my-accounts'}
						>
							{t('account_list')}
						</Breadcrumb.Item>
						<Breadcrumb.Item
							href={'/my-accounts/' + (account ? account.Id : '')}
						>
							{account ? account.Name : '...'}
						</Breadcrumb.Item>
					</>
				)}

				{breadCrumbFrom === 'vehicle' && (
					<>
						<Breadcrumb.Item
							href='/available-vehicles'
						>
							{t('vehicles_list')}
						</Breadcrumb.Item>
						<Breadcrumb.Item
							href={'/details/' + (vehicle ? vehicle.id : '')}
						>
							{vehicle ? `${vehicle.dati_veicolo?.marca} ${vehicle.dati_veicolo?.modello}` : '...'}
						</Breadcrumb.Item>
					</>
				)}

				{breadCrumbFrom === 'opportunity' && (
					<>
						<Breadcrumb.Item
							href={'/my-accounts'}
						>
							{t('account_list')}
						</Breadcrumb.Item>
						<Breadcrumb.Item
							href={'/my-accounts/' + (account ? account.Id : '')}
						>
							{account ? account.Name : '...'}
						</Breadcrumb.Item>
						<Breadcrumb.Item
							href={'/opportunities/' + (opportunityId)}
						>
							{opportunity ? opportunity.Name : '...'}
						</Breadcrumb.Item>
					</>
				)}
				<Breadcrumb.Item active>
					{pageTitle}
				</Breadcrumb.Item>
			</Breadcrumb>
			<Row>
				<Col md={6} className="display-6">
					{pageTitle}
				</Col>
			</Row>

			{!loading ? (
				<div className='mt-4'>
					{opportunityId === 'new' ? (
						<div>
							<OpportunityFunnel
								startFrom={'account'}
								mustComplete={mustComplete}
								canChange={canChange}
								vehicle={vehicle}
								account={account}
								nextState={nextState}
								saveLabel={action ? saveLabels[action] : 'Crea'}
								onQuit={() => window.location.replace(getPrevPage())}
								afterSubmitFn={afterSubmitFn}
							/>
						</div>
					) : (
						<div>
							<OpportunityFunnel
								opportunity={opportunity}
								startFrom={action === 'update_vehicle' ? 'vehicle' : 'upload_docs'}
								mustComplete={mustComplete}
								canChange={canChange}
								vehicle={vehicle}
								account={account}
								nextState={nextState}
								saveLabel={action ? saveLabels[action] : 'Salva'}
								onQuit={() => window.location.replace(getPrevPage())}
								afterSubmitFn={afterSubmitFn}
							/>
						</div>
					)}
				</div>
			) : (
				<div className="mt-3">
					<CardPlaceholder />
				</div>
			)}
		</Container>
	);
};

export default CreateOpportunityPage;