        /* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { AccountEntitySearch, CreateAccountEntity, EditAccountEntity, GetAccountQuery, OpportunityField } from '../models/AccountEntity';

export class AccountControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) { }

    public getAccounts(query: GetAccountQuery): CancelablePromise<{ totalCount: number, data: AccountEntitySearch[] }> {
        if (!query.limit) {
            query.limit = 50;
        }

        return this.httpRequest.request({
            method: 'GET',
            url: '/register/account',
            query,
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    public getAccount(id: string): CancelablePromise<{ totalCount: number, data: AccountEntitySearch[] }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/register/account',
            query: { accountId: id, limit: 1 },
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    public getAccountFields(): CancelablePromise<{ data: OpportunityField[] }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/register/account/fields',
            // query: { accountId: id},
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    public createAccount(body: CreateAccountEntity): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/register/account',
            body,
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    public editAccount(id: string, body: EditAccountEntity): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/register/account/' + id,
            body,
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    public getAccountTypes(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/register/account/recordType',
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }
}
