import { useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { Container, Col, Row } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { useApiClient } from '../../hooks/useApiClient';
import TableContainer from '../../components/genericTable/TableContainer';
import { columnsData } from './columnsData';
import Restricted from '../../components/Restricted/Restricted';
import { Roles } from '../../types';
import Btn from '../../components/Btn/Btn';
import { GTMsendEvent } from '../../utils/trackingGA4'

const DealerListPage = () => {
	const { apiClient, handleApi } = useApiClient();

	const [response, setResponse]: [any, any] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const navigate = useNavigate();

	useEffect(() => {
		handleApi(apiClient.dealerController.getRegisterDealers())
			.then((res) => {
				setResponse(res);
				setLoading(false);
			})
			.catch((err) => {
				setError(err);
				setLoading(false);
			});
	}, []);

	const columns = useMemo(() => columnsData as Column<Object>[], []);
	const data = useMemo(() => response ?? [], [response]);

	const hanndleCreatenewDealer = () => {
		navigate('/manage-dealers/new')
		GTMsendEvent({
			event: 'CTA_create_new_dealer',
			evtCat: 'dealer',
			evtAct: 'create',
			evtLab: 'new_dealer'
		})

	}
	return (
		<Container fluid>
			<Row>
				<Col md={6} className="display-6">
					Lista dealer
				</Col>
				<Restricted to={[Roles.Backoffice, Roles.Master, Roles.Admin]}>
					<Col md={6} className="mt-4 mt-md-0 text-md-end">
						<Btn
							// to="/manage-dealers/new"
							onClick={hanndleCreatenewDealer}
						>
							<FaPlus /> Crea nuovo dealer
						</Btn>
					</Col>
				</Restricted>
			</Row>

			<TableContainer
				dblClickCallback={(row: any) => navigate(`/manage-dealers/${row.id}`)}
				columns={columns}
				data={data}
				loading={loading}
				error={error}
			></TableContainer>
		</Container>
	);
};

export default DealerListPage;
