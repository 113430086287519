import dayjs from "dayjs";
import { FORMAT_DATE_TIME, OPPORTUNITY_STATES_DATA } from "../../utils/constants";
import { Link } from "react-router-dom";
import Icon from "../Icon/Icon";
import i18next from 'i18next';

const columnsArray = [
	{
		accessor: 'Name',
		id: 'Name',
		Header: i18next.t('name'),
	},
	{
		accessor: 'Brand__c',
		id: 'Brand__c',
		Header: i18next.t('brand'),
		Cell: (data: any) => {
			const hmpId = data.row.original.ID_Vehicle_HMP__c
			return hmpId ? (
				<a href={`/details/${hmpId}`}>{data.value}</a>
			) : <span>{data.value}</span>

		}
	},
	{
		accessor: 'Modello__c',
		id: 'Modello__c',
		Header: i18next.t('model'),
		Cell: (data: any) => {
			const hmpId = data.row.original.ID_Vehicle_HMP__c
			return hmpId ? (
				<a href={`/details/${hmpId}`}>{data.value}</a>
			) : <span>{data.value}</span>

		}
	},
	{
		accessor: 'Noleggiatore__c',
		id: 'Noleggiatore__c',
		Header: i18next.t('rental_company'),
	},
	{
		accessor: 'CreatedDate',
		id: 'CreatedDate',
		Header: i18next.t('creation_date'),
		Cell: ({ value }) => {
			return dayjs(value).format(FORMAT_DATE_TIME)
		}
	},
	{
		accessor: 'StageName',
		id: 'StageName',
		Header: i18next.t('state'),
		Cell: ({ value }) => {
			return OPPORTUNITY_STATES_DATA[value]?.label || value
		}
	},
	{
		accessor: 'actions',
		id: 'actions',
		Header: '',
		disableSortBy: true,
		headerClassName: 'column-sticky',
		className: 'column-sticky',
		width: 60,
		Cell: (data: any) => (
			<Link
				to={`/opportunities/${data.row.original.Id}?from=account`}
				className="btn"
				onClick={() => {
					// GTMsendEvent({
					// 	event: 'CTA_open_opportunity',
					// 	evtCat: 'account',
					// 	evtAct: 'open',
					// 	evtLab: `open_opportunity`
					// })
				}}
			>
				<Icon name="arrow-right" style={{ width: '1.2rem', height: '1.2rem' }} />
			</Link>
		),
	},
];

export default columnsArray;
