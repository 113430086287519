import React, { useMemo } from 'react'
import { Column, useTable } from 'react-table';
import { Card, Row, Table} from 'react-bootstrap';
import i18next from 'i18next';  // Import the i18next instance

import { columnsData } from './Data/carsColumns'
 
const VehicleSearch = () => {

  const columns = useMemo(() => columnsData as Column<Object>[], []);
  const data = useMemo(() => {
    const tableData = [
      {
        marca: "Audi",
        modello: "Q5",
        versione: "40TFSI S-Tronic",
        prezzo: 60000,
        codice_costruttore: "4516161",
        alimentazione: "Diesel",
        select: i18next.t('choose')
      },
      {
        marca: "BMW",
        modello: "3 Series",
        versione: "320d xDrive",
        prezzo: 45000,
        codice_costruttore: "4522002",
        alimentazione: "Diesel",
        select: i18next.t('choose')
      },
      {
        marca: "Mercedes-Benz",
        modello: "C-Class",
        versione: "C 200",
        prezzo: 55000,
        codice_costruttore: "4533003",
        alimentazione: "Benzina",
        select: i18next.t('choose')
      },
      {
        marca: "Tesla",
        modello: "Model 3",
        versione: "Standard Range Plus",
        prezzo: 48000,
        codice_costruttore: "4544004",
        alimentazione: "Elettrica",
        select: i18next.t('choose')
      },
      {
        marca: "Ford",
        modello: "Mustang",
        versione: "Mach-E",
        prezzo: 70000,
        codice_costruttore: "4555005",
        alimentazione: "Elettrica",
        select: i18next.t('choose')
      },
      {
        marca: "Toyota",
        modello: "RAV4",
        versione: "Hybrid",
        prezzo: 35000,
        codice_costruttore: "4566006",
        alimentazione: "Ibrida",
        select: i18next.t('choose')
      },
      {
        marca: "Chevrolet",
        modello: "Camaro",
        versione: "SS",
        prezzo: 75000,
        codice_costruttore: "4577007",
        alimentazione: "Benzina",
        select: i18next.t('choose')
      }
    ];
    return tableData ?? [];
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <Row id="table-cars">
      <Card>
        <Table
          striped
          borderless
          hover
          responsive
          {...getTableProps()}
          className="vehicle-table m-0"
          {...getTableProps()

          }>
          <thead className="border-bottom">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    className="text-nowrap"
                    {...column.getHeaderProps({
                      className: column.className,
                    })}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="rowtr">
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
    </Row>
  );
};

export default VehicleSearch


