import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

const isObject = (value) => {
	return typeof value === 'object' && !Array.isArray(value) && value !== null;
};
const isFromDoubleInput = (value) => {
	return isObject(value) && Object.keys(value).length === 2;
};
const isObjectWithFilterValuesEmpty = (obj) => {
	return Object.values(obj).filter((val) => val).length === 0;
};
const isFilterValueEmpty = (filterVal) => {
	// filterValue may be an array or an object
	return (
		filterVal.length === 0 ||
		(isObject(filterVal) && isObjectWithFilterValuesEmpty(filterVal))
	);
};

const customFilterFn = (rows, [columnId], filterValue) => {

	return isFilterValueEmpty(filterValue)
		? rows
		: rows.filter((row) => {
				if (Array.isArray(filterValue)) {
					// lo stato è diventato un oggetto :(
					if (columnId === 'stato_corrente') {
						return filterValue.some(
							(val) => row.original[columnId]?.type === val
						);
					}

					// per tutti gli altri array
					return filterValue.some((val) => row.original[columnId] === val);
				}

				if (
					typeof filterValue === 'string' ||
					typeof filterValue === 'number'
				) {
					if (columnId === 'utente') {
						if (row.original?.stato_corrente) {
							return row.original?.stato_corrente[columnId] === filterValue;
						}

						return false;
					}

					return row.original[columnId] === filterValue;
				}

				if (isFromDoubleInput(filterValue)) {
					const currentVal = row.original[columnId];
					const from = filterValue.from;
					const to = filterValue.to;

					// it's a number (offerta_minima è un oggetto, offerta_minima.valore è un number)
					if (columnId === 'offerta_minima') {
						if (from && to)
							return currentVal.valore >= from && currentVal.valore <= to;
						if (from && !to) return currentVal.valore >= from;
						if (!from && to) return currentVal.valore <= to;
					}
					// it's date
					else {
						if (from && to)
							return dayjs(currentVal).isBetween(from, to, 'day', '[]');
						if (from && !to) return dayjs(currentVal).isSameOrAfter(from);
						if (!from && to) return dayjs(currentVal).isSameOrBefore(to);
					}
				}

				// return true;
		  });
};

export default customFilterFn;
