import i18next from 'i18next';  // Import the i18next instance
import { NOLEGGIATORI } from "../../../utils/constants";

export const columnsData = [
	{
		// this column is hidden
		accessor: 'id',
		id: 'id',
		Header: '',
		disableSortBy: true,
	},
	{
		accessor: 'noleggiatore',
		id: 'noleggiatore',
		Header: i18next.t('rental_company'), // noleggiatore
		width: 250,
		Cell: (data) => {
			let val = data?.row?.original?.noleggiatore.toUpperCase()
			if (val === NOLEGGIATORI.LEASYS_NOLEGGIO_CHIARO) {
				val = 'LEASYS NOLEGGIO CHIARO'
			}

			return <small>{val}</small>
		}
	},
	{
		accessor: 'canone',
		id: 'canone',
		Header: i18next.t('rental_fee'),
		width: 5000,
		Cell: (data) => {
			const vatPriced = data.value * 1.22
			let percErr = data.row.original.abs_perc_error

			if (typeof percErr === 'undefined') {
				percErr = null
			}

			if (percErr === null || percErr >= 10) {
				percErr = 'grey'
			} else if (percErr >= 5 && percErr < 10) {
				percErr = 'orange'
			} else {
				percErr = '#22ff00'
			}

			const TrafficLight = () => {
				return <span style={{
					display: 'inline-block',
					width: 10,
					height: 10,
					borderRadius: 999,
					backgroundColor: percErr
				}}></span>
			}

			if (data.row.values.noleggiatore === NOLEGGIATORI.ARVAL_PRIVATI.toLowerCase()) {
				return (<>
					<TrafficLight /> <small>€ {vatPriced.toFixed(2)}</small>
					<small style={{ fontSize: "11px", color: "#a5a5a5" }}>&nbsp;(iva inclusa)</small>
				</>)
			} else {
				return (<>
					<TrafficLight />    <small>€ {data.value}</small>
					<small style={{ fontSize: "11px", color: "#a5a5a5" }}>{` (+iva ${vatPriced.toFixed(2)})`}</small>
				</>)
			}

		}
	},
	{
		accessor: 'commissioni',
		id: 'commissioni',
		Header: i18next.t('commissions'),
		width: 110,
		Cell: (data) => {
			return (
				<>
					<small>€ {data.value}</small>
				</>
			)
		}
	},
	{
		accessor: 'percentage',
		id: 'percentage',
		Header: '%',
		width: 110,
	},
	{
		accessor: 'Input',
		id: 'input',
		Header: i18next.t('commissions_€/%'),
		width: 110,
	},
	{
		accessor: 'calc',
		id: 'calc',
		Header: '',
	},
	// {
	// 	accessor: 'downloadPDF',
	// 	id: 'downloadPDF',
	// 	Header: '',
	// },
];
