import { SortingRule } from "react-table";
import { OpportunityEntity } from "../api/models/AccountEntity";

export type Role =
	| 'ADMIN'
	| 'BACKOFFICE'
	| 'SALES'
	| 'DEALER'
	| 'MASTER'
	| 'AZIENDA';

export enum Roles {
	Admin = 'ADMIN',
	Backoffice = 'BACKOFFICE',
	Sales = 'SALES',
	Dealer = 'DEALER',
	Master = 'MASTER',
	Azienda = 'AZIENDA',
}

export type SelectOptions = {
	value: string;
	label: string;
};

export interface TableContainerProps {
	columns: any;
	data: any;
	loading: any;
	setDownloadData?: any;
	error: any;
	dbClickType?: string;
	presetFilter?: any;
	onSortChange?: (rule: SortingRule<Object>[]) => void,
	dblClickCallback?: () => void,
}

export type ReactSelectOption = {
	value: string;
	label: string;
};

export type AccountTypeOption = ReactSelectOption & { value: 'Azienda' | 'Privato' | 'Libero Professionista'};

export interface PaginatorObject {
  total_pages: number;
  current_page: number;
  first_page: number;
  last_page: number;
  previous_page: number;
  next_page: number;
  has_previous_page: boolean;
  has_next_page: boolean;
  total_results: number;
  results: number;
  first_result: number;
  last_result: number;
}

export type OpportunityFunnelValues = {
	entity?: OpportunityEntity | null,
	Tipologia__c: ReactSelectOption | null,
	Canale__c: ReactSelectOption | null,
	Noleggiatore__c: ReactSelectOption | null,
	commissions: string,
	notes: string,
	name: string
}