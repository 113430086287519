import { useState, useEffect } from 'react';
import {
	Col,
	Row,
	Form,
	Card,
	Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const QuotationListFilters = ({
	headerGroups,
	setAllFilters,
	loading,
	presetFilter }) => {
	const { t } = useTranslation()

	const initialState = {
		marca: [],
		modello: [],
		durata: '',
		km: '',
		data_inserimento: { from: '', to: '' },
	}

	const savedFilters = sessionStorage.getItem('quotationList-filters')
	const [filterState, setFilterState] = useState(savedFilters ? JSON.parse(savedFilters) : initialState);
	const applyFilters = (currentState) => {
		// filtersObjectArray wants an array of objects with key-value pair id and value
		const filtersObjectArray: { id: string; value: any }[] = [];
		for (const [key, value] of Object.entries(currentState)) {
			filtersObjectArray.push({ id: key, value });
		}
		setAllFilters(filtersObjectArray);
	};

	useEffect(() => {
		if (!loading) {
			applyFilters(filterState);
		}

		if (presetFilter) {
			const stateWithDefaultFilter = Object.assign({}, initialState, {
				stato_corrente: [presetFilter],
			});
			setFilterState(stateWithDefaultFilter);
			applyFilters(stateWithDefaultFilter);
		}
	}, [loading]);

	const handleSearch = (e) => {
		applyFilters(filterState);
	};

	const handleReset = () => {
		setFilterState(initialState);
		applyFilters(initialState);
		sessionStorage.removeItem("quotationList-filters");
	};

	useEffect(() => {
		sessionStorage.setItem("quotationList-filters", JSON.stringify(filterState));
		applyFilters(filterState);
	}, [filterState]);


	return (
		<Card className="filters-card">
			<Card.Header className="border-0">
				<Row>
					{headerGroups.map((headerGroup) =>
						headerGroup.headers.map((column) =>
							column.PrimaryFilter ? (
								<Col key={column.id} lg={6}>
									<Form.Group className="mb-3">
										<div key={column.id}>
											<Form.Label htmlFor={column.id}>
												{column.render('Header')}
											</Form.Label>
											{column.render('PrimaryFilter', {
												props: {
													filterState,
													setFilterState,
													type: column.inputType
												},
											})}
										</div>
									</Form.Group>
								</Col>
							) : null
						)
					)}
					<Col lg={6} className="text-end d-flex justify-content-end align-items-center">
						{/* <Btn onClick={handleSearch} icon="arrow-right">
							Cerca
						</Btn> */}
						<Button className="filter-reset-btn" onClick={handleReset}>
							{t('reset_filters')}
						</Button>
					</Col>
				</Row>
			</Card.Header>
		</Card>
	);
};

export default QuotationListFilters;
