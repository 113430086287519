import { useState, useEffect, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { Column } from 'react-table';
import { useAuth } from '../../hooks/useAuth';
import { useApiClient } from '../../hooks/useApiClient';
import { userAziendeColumnsData } from '../../components/UserAziende/userAziendeColumnsData';
import UserAziende from '../../components/UserAziende/UserAziende';

const MyCompaniesPage = () => {
	// the username  must be an email address
	const { apiClient, handleApi } = useApiClient();
	const [responseAziende, setResponseAziende]: [any, any] = useState([])
	const [loadingAziende, setLoadingAziende] = useState(true);
	const [errorAziende, setErrorAziende] = useState(null);
	const { userRole, user } = useAuth();

	useEffect(() => {
		// GET AZIENDE ASSOCIATED TO THE SALES USER LOGGED IN
		if (userRole === 'SALES' || userRole === 'MASTER' || userRole === 'BACKOFFICE') {
			handleApi(apiClient.userController.getAziendeAssociatedSales(user.username))
				.then((res) => {
					setResponseAziende(res)
				})
				.catch((err) => {
					setErrorAziende(err)
					console.error('An error occured while fetching users', err)
				})
				.finally(() => {
					setLoadingAziende(false)
				})
			}
	}, []);

	const aziendeColumns = useMemo(() => userAziendeColumnsData as Column<Object>[], []);
	const aziendeData = useMemo(() => responseAziende ?? [], [responseAziende]);

	return (
		<Container fluid>
			{/* MY AZIENDE SECTION */}
			{['MASTER', 'BACKOFFICE', 'SALES'].includes(userRole) &&
				<UserAziende
					columns={aziendeColumns}
					data={aziendeData}
					loading={loadingAziende}
					error={errorAziende}
				/>}
		</Container>
	);
};

export default MyCompaniesPage;
