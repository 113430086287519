import { Alert, Button, Col, Modal, Row, } from "react-bootstrap"
import { toast } from "react-toastify"
import { Storage } from "aws-amplify"
import { useEffect, useMemo, useState } from "react"
import { Column } from "react-table"
import { useTranslation } from 'react-i18next';
import dayjs from "dayjs"
import i18next from 'i18next';

import { FORMAT_DATE_TIME, OPPORTUNITY_STATES_DATA, OPPORTUNITY_SUPPORTED_FILE_TYPES } from "../../utils/constants"
import { useApiClient } from "../../hooks/useApiClient"
import { OpportunityEntity } from "../../api/models/AccountEntity"
import MultiFileUploader from "../MultiFileUploader/MultiFileUploader"
import { randomString } from "../../utils"
import SpinnerButton from "../SpinnerButton/SpinnerButton"
import OpportunitiesTable from "../OpportunitiesTable/OpportunitiesTable"
import { VehicleEntity } from "../../api"

const OpportunityDocumentUploaderModal =  ({ show, opportunity, vehicle, onHide, onSuccess, labels = {
    title: i18next.t('upload_documents'),

    body: '',
    confirmBtn: i18next.t('confirm'),
} }: {
    show: boolean
    opportunity?: OpportunityEntity
    vehicle?: VehicleEntity
    onHide: any
    labels?: { title: string, body: string, confirmBtn: string }
    onSuccess: () => void
}) => {
    const { apiClient, handleApiResponse } = useApiClient();
    const [uploadFiles, setUploadFiles] = useState<File[]>([])
    const [uploading, setUploading] = useState<boolean>(false)
    const [selectedOpportunity, setSelectedOpportunity] = useState<OpportunityEntity | undefined>()
    const { t } = useTranslation()

    const uploadDocuments = async () => {
        if (!selectedOpportunity) {
            return
        }

        if (uploadFiles.length) {
            setUploading(true)
            let uploadOps: any[] = []
            let filesToUploadList: {
                file: File
                key: string
                filename: string
            }[] = []

            uploadFiles.forEach(file => {
                const ext = file.name.split('.').pop()
                const key = `opportunity_uploads/${randomString(10)}.${ext}`
                filesToUploadList.push({ file, filename: file.name, key })
            })

            uploadOps = filesToUploadList.map(obj => {
                return Storage.put(obj.key, obj.file, {
                    contentType: obj.file.type,
                    bucket: process.env.REACT_APP_AWS_STORAGE_BUCKET_SALESFORCE_IMPORT,
                });
            })

            try {
                await Promise.all(uploadOps)
            } catch (e) {
                console.error(e);
                toast.error(i18next.t('error_loading_file'))
                setUploading(false)
                return
            }

            const att = filesToUploadList.map(el => {
                return {filename: el.filename, key: `public/${el.key}`}
            })

            await handleApiResponse(apiClient
                    .opportunityController
                    .addOpportunityAttachments(selectedOpportunity.Id , att
            ), setUploading)

            toast.success(i18next.t('file_uploaded_success'))
            setUploadFiles([])
            onSuccess()
        }
    }

    const rowClicked = (row: any) => {
        setSelectedOpportunity(row)
    }

    const columnsData = useMemo(() => {
        return [
            {
                accessor: 'Name',
                id: 'Name',
                Header: i18next.t('name'),
            },
            {
                accessor: 'Noleggiatore__c',
                id: 'Noleggiatore__c',
                Header: i18next.t('rental_company'),
            },
            {
                accessor: 'CreatedDate',
                id: 'CreatedDate',
                Header: i18next.t('creation_date'),
                Cell: ({ value }) => {
                    return dayjs(value).format(FORMAT_DATE_TIME)
                }
            },
            {
                accessor: 'StageName',
                id: 'StageName',
                Header: i18next.t('state'),
                Cell: ({ value }) => {
                    return OPPORTUNITY_STATES_DATA[value]?.label || value
                }
            },
        ] as Column[]
    }, [])

    useEffect(() => {
        setSelectedOpportunity(opportunity)
    }, [opportunity])

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{labels.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {labels.body}
                    {selectedOpportunity ? (
                        <div>
                            {!opportunity && selectedOpportunity && (
                                <Alert variant="secondary">
                                    <Row>
                                        <Col>
                                            <span>{t('opportunity')}</span> <br />
                                            <b>{selectedOpportunity.Name}</b>
                                        </Col>
                                        <Col>
                                            <span>{t('state')}</span> <br />
                                            <b>{OPPORTUNITY_STATES_DATA[selectedOpportunity.state].label}</b>
                                        </Col>
                                    </Row>
                                </Alert>
                            )}

                            <MultiFileUploader
                                maxSize={10 * 1024 * 1024}
                                files={uploadFiles}
                                setFiles={setUploadFiles}
                                types={OPPORTUNITY_SUPPORTED_FILE_TYPES}
                            ></MultiFileUploader>
                        </div>
                    ) : (
                        <>
                            {vehicle && (
                                <OpportunitiesTable
                                    dblClickCallback={rowClicked}
                                    customColumnsData={columnsData}
                                    query={{ vehicleId: vehicle.id!.toString() }} />
                            )}
                        </>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={uploadDocuments} disabled={uploadFiles.length === 0 || uploading}>
                    <SpinnerButton label={labels.confirmBtn} busy={uploading} />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default OpportunityDocumentUploaderModal;