import { useState, useEffect, useMemo } from 'react';
import { FaFileCsv } from 'react-icons/fa';
import { ExportToCsv } from 'export-to-csv';
import { useApiClient } from '../../hooks/useApiClient';
import VehicleTableContainer from '../../components/VehicleTable/VehicleTableContainer';
import { Column } from 'react-table';
import { columnsData } from '../../components/VehicleTable/columnsData';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import toaster from '../../utils/toaster';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { GTMsendEvent } from '../../utils/trackingGA4'
import { normalizeVehicleList } from '../../utils/vehicleList';
import i18next from 'i18next';

const VehiclePromoListPage = () => {
	const { apiClient, handleApi } = useApiClient();

	const [response, setResponse]: [any, any] = useState(null); 
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	let downloadData: any;

	const setDownloadData = (data: any) => {
		downloadData = data;
	};

	const hanldeExportButton = async () => {
		const options = {
			filename: i18next.t('list_vehicles_promo'),
		};

		const csvExporter = new ExportToCsv(options);
		csvExporter.generateCsv(downloadData);

		// send event to GA4
		GTMsendEvent({
			event: "CTA_download_file",
			evtCat: 'lista_veicoli_promo',
			evtAct: "download",
			evtLab: `${options.filename}.csv`
		})
	};

	useEffect(() => {

		handleApi(apiClient.vehicleController.getVehiclesFilter())
			.then((res: any[]) => {
				res = res.filter(vehicle => vehicle.promo === true)
				res = normalizeVehicleList(res);

				res.sort((a, b) => {
					if (a.stato_corrente.type === 'DISPONIBILE') return -1;
					if (b.stato_corrente.type === 'DISPONIBILE') return 1;
					return 0;
				})

				setResponse(res);
				setLoading(false);
			})
			.catch((err) => {
				setError(err);
				setLoading(false);
				toaster.error(
					err.message || 'Errore non specificato durante il caricamento dati'
				);
			});
	}, []);

	const columns = useMemo(() => columnsData as Column<Object>[], []);
	const data = useMemo(() => response ?? [], [response]);

	return (
		<Container fluid>
			<Row>
				<Col md={6} className="display-6">
					Lista Veicoli in Promo
				</Col>

				<Col md={6} className="mt-4 mt-md-0 text-md-end">
					<Button className="btn me-3" onClick={hanldeExportButton}>
						<FaFileCsv className="fs-5" /> SCARICA
					</Button>
				</Col>
			</Row>

			<ErrorBoundary>
				<VehicleTableContainer
					columns={columns}
					data={data}
					setDownloadData={setDownloadData}
					loading={loading}
					error={error}
				></VehicleTableContainer>
			</ErrorBoundary>
		</Container>



	);
};

export default VehiclePromoListPage;
