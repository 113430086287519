import { useState, useEffect } from 'react';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import _ from 'lodash';
import { useAuth } from '../../hooks/useAuth';
import { Formik } from 'formik';
import Btn from '../../components/Btn/Btn';
import dayjs from 'dayjs';
import Select from 'react-select';
import { useApiClient } from '../../hooks/useApiClient';
import { FORMAT_DATE_TIME } from '../../utils/constants';
import { AccountTypeOption, ReactSelectOption, Roles } from '../../types';
import { CreateAccountEntity, RecordType } from '../../api/models/AccountEntity';
import RequiredFormLabel from '../RequiredFormLabel/RequiredFormLabel';
import ValidationError from '../ValidationError/ValidationError';
import { toast } from 'react-toastify';
import { GTMsendEvent } from '../../utils/trackingGA4';
import CardPlaceholder from '../CardPlaceholder/CardPlaceholder';
import MissingAccountRecordTypeAlert from '../MissingAccountRecordTypeAlert/MissingAccountRecordTypeAlert';

export type AccountFormInitValues = CreateAccountEntity & { CreatedDate: string; Id?: string; }

const AccountForm = ({
    accountId,
    mode,
    initialValues,
    RecordType,
    enableDuplicateModal = false,
    afterSubmitFn,
}: {
    accountId?: string;
    mode: 'edit' | 'create' | 'readonly';
    initialValues: AccountFormInitValues;
    RecordType?: RecordType;
    enableDuplicateModal?: boolean;
    afterSubmitFn?: (accountId: string) => void;
}) => {
    const { apiClient, handleApi } = useApiClient();
    const { userRole, user } = useAuth();
    const [loading, setLoading] = useState(true);
    const [accountType, setAccountType] = useState<AccountTypeOption | null>(null);
    const [error, setError] = useState(null);
    const [accountTypes, setAccountTypes] = useState<AccountTypeOption[]>([]);
    const [partners, setPartners] = useState<ReactSelectOption[]>([])
    const [partner, setPartner] = useState<ReactSelectOption | null>(null)
    const validateFn = (values: AccountFormInitValues) => {
        const errors: any = {};
        const REQUIRED_MESSAGE = 'Obbligatorio';

        if (_.isEmpty(values.RecordTypeId)) {
            errors.RecordTypeId = REQUIRED_MESSAGE;
        } else {
            const recordType = getRecordTypeFromId(values.RecordTypeId)!;

            if (recordType.label === 'Privato' && values.Codice_Fiscale__c === '') {
                errors.Codice_Fiscale__c = REQUIRED_MESSAGE
            }

            if (recordType.label !== 'Privato' && values.Partita_IVA__c === '') {
                errors.Partita_IVA__c = REQUIRED_MESSAGE
            }

            if (_.isEmpty(partner)) {
                errors.reference_partner = REQUIRED_MESSAGE
            }

            if (recordType.label === 'Azienda') {
                if (values.Name === '') {
                    errors.Name = REQUIRED_MESSAGE;
                }
            } else {
                if (values.FirstName === '') {
                    errors.FirstName = REQUIRED_MESSAGE;
                }
                if (values.LastName === '') {
                    errors.LastName = REQUIRED_MESSAGE;
                }
            }
        }
        return errors
    };

    const getRecordTypeFromId = (id: string) => {
        const recordType = accountTypes.find(el => el.value === id);
        return recordType
    }

    const submitFn = async (values: AccountFormInitValues) => {
        if (mode === 'readonly') {
            return;
        }

        // Request to backend
        let requestBody: any = {
            Codice_Fiscale__c: null,
            Partita_IVA__c: null,
            RecordTypeId: values.RecordTypeId,
            Email__c: values.Email__c,
            Phone: values.Phone,
            reference_partner: partner?.value
        }

        const recordType = getRecordTypeFromId(values.RecordTypeId);

        if (!recordType) {
            // Error out
            return
        }

        if (recordType.label === 'Privato') {
            requestBody.Codice_Fiscale__c = values.Codice_Fiscale__c
        } else {
            requestBody.Partita_IVA__c = values.Partita_IVA__c
        }

        if (recordType.label === 'Azienda') {
            requestBody.Name = values.Name
        } else {
            requestBody.FirstName = values.FirstName
            requestBody.LastName = values.LastName
        }

        let accountIdRefresh = ''

        try {
            if (mode === 'edit' && accountId) {
                accountIdRefresh = accountId;
                await handleApi(apiClient.accountController.editAccount(accountId, requestBody));
                toast.success('Modificato con successo')
            } else if (mode === 'create') {
                GTMsendEvent({
                    event: 'complete_new_account',
                    category: 'account',
                    action: 'complete',
                    label: 'new_account'
                })

                let resp = await handleApi(apiClient.accountController.createAccount(requestBody))
                accountIdRefresh = resp.id;
                toast.success('Creato con successo')
            }
        } catch (err: any) {
            let errMsg = err?.body?.messageList ? err?.body?.messageList[0] : '';
            if (errMsg === 'duplicated_account') {
                toast.error('Un account con la medesima P.IVA o CF è già presente')
            } else if (errMsg.endsWith('was not found on Saleforce')) {
                toast.error(_.capitalize(errMsg))
            } else {
                toast.error('Impossibile creare o modificare account');
            }

            return
        }

        if (afterSubmitFn) {
            afterSubmitFn(accountIdRefresh)
        }
    }

    useEffect(() => {
        if (!RecordType) {
            Promise.all([
                handleApi(apiClient.accountController.getAccountTypes()),
                handleApi(apiClient.dealerController.getRegisterDealers()),
                handleApi(apiClient.userController.getRegisterUsers1(user.username)),
                handleApi(apiClient.accountController.getAccountFields())
            ])
                .then((res) => {
                    const [accRes, dealerRes, userRes, accountFields] = res;

                    let accTyps = accRes.data.map(el => {
                        return { label: el.Name, value: el.Id }
                    });

                    if (['edit', 'readonly'].includes(mode)) {
                        const accTyp = accTyps.find(el => el.value === initialValues.RecordTypeId)!;
                        setAccountType(accTyp)
                    }

                    setAccountTypes(accTyps);

                    // OLD whenreference_partner was filled with dealers
                    // let partners: ReactSelectOption[] = dealerRes.map(el => {
                    //     return { label: el.ragione_sociale, value: el.id }
                    // })

                    // partners = partners.filter(el => {
                    //     return userRes.reference_partner.includes(el.value)
                    // })

                    let partners = accountFields[0]?.picklistValues.filter(el => {
                        return userRes.reference_partner.includes(el.value)
                    })


                    setPartners(partners)
                    setPartner(partners.find(el => el.label === initialValues.reference_partner) || null)
                })
                .catch((err) => {
                    setError(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            //@ts-ignore
            setAccountType({ label: RecordType.Name, value: RecordType.Id })
            setLoading(false);
        }
    }, [])

    return (
        <div>
            {loading ? (
                <CardPlaceholder />
            ) : (
                <Formik
                    initialValues={initialValues}
                    validate={validateFn}
                    onSubmit={submitFn}
                >
                    {
                        ({ errors, values, touched, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
                            <Form autoComplete='off' onSubmit={handleSubmit}>
                                <Row>
                                    {initialValues.Id && !accountType && (
                                        <MissingAccountRecordTypeAlert accountId={initialValues.Id} />
                                    )}
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <RequiredFormLabel label='Tipo' required={mode !== 'readonly'} />
                                            <Select
                                                name="RecordTypeId"
                                                isDisabled={['edit', 'readonly'].includes(mode)}
                                                placeholder='Seleziona tipo'
                                                options={accountTypes}
                                                defaultValue={accountType}
                                                onChange={(opt: any) => {
                                                    setAccountType(opt)
                                                    setFieldValue('RecordTypeId', opt.value)
                                                }}
                                            />
                                            <ValidationError {...{ errors, touched, name: 'RecordTypeId' }} />
                                        </Form.Group>
                                    </Col >
                                </Row >
                                <hr />

                                {
                                    accountType !== null && (
                                        <div>
                                            <Row>
                                                {accountType?.label === 'Privato' ? (
                                                    <Col md={6}>
                                                        <Form.Group className="mb-3">
                                                            <RequiredFormLabel label='Codice fiscale' required={mode !== 'readonly'} />
                                                            <Form.Control type="text"
                                                                disabled={['readonly'].includes(mode) || (mode === 'edit' && [Roles.Master, Roles.Backoffice].includes(userRole))}
                                                                onChange={handleChange}
                                                                name='Codice_Fiscale__c'
                                                                required
                                                                value={values.Codice_Fiscale__c || ''}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                ) : (
                                                    <Col md={6}>
                                                        <Form.Group className="mb-3">
                                                            <RequiredFormLabel label='Partita IVA' required={mode !== 'readonly'} />
                                                            <Form.Control type="text"
                                                                name='Partita_IVA__c'
                                                                disabled={['readonly'].includes(mode) || (mode === 'edit' && [Roles.Master, Roles.Backoffice].includes(userRole))}
                                                                required
                                                                onChange={handleChange}
                                                                value={values.Partita_IVA__c || ''}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                )}
                                            </Row>
                                            <hr />
                                            <Row>
                                                {accountType?.label === 'Azienda' ? (
                                                    <Col md={6}>
                                                        <Form.Group className="mb-3">
                                                            <RequiredFormLabel label='Intestazione' required={mode !== 'readonly'} />
                                                            <Form.Control type="text"
                                                                name='Name'
                                                                required
                                                                disabled={['readonly'].includes(mode)}
                                                                onChange={handleChange}
                                                                value={values.Name}
                                                            />
                                                            <ValidationError {...{ errors, touched, name: 'Name' }} />
                                                        </Form.Group>
                                                    </Col>
                                                ) : (
                                                    <>
                                                        <Col md={6}>
                                                            <Form.Group className="mb-3">
                                                                <RequiredFormLabel label='Nome' required={mode !== 'readonly'} />
                                                                <Form.Control type="text"
                                                                    name='FirstName'
                                                                    required
                                                                    disabled={['readonly'].includes(mode)}
                                                                    onChange={handleChange}
                                                                    value={values.FirstName}
                                                                />
                                                                <ValidationError {...{ errors, touched, name: 'FirstName' }} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group className="mb-3">
                                                                <RequiredFormLabel label='Cognome' required={mode !== 'readonly'} />
                                                                <Form.Control type="text"
                                                                    name='LastName'
                                                                    required
                                                                    disabled={['readonly'].includes(mode)}
                                                                    onChange={handleChange}
                                                                    value={values.LastName}
                                                                />
                                                                <ValidationError {...{ errors, touched, name: 'LastName' }} />
                                                            </Form.Group>
                                                        </Col>
                                                    </>
                                                )}
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Telefono</Form.Label>
                                                        <Form.Control type="text"
                                                            name='Phone'
                                                            disabled={['readonly'].includes(mode)}
                                                            onChange={handleChange}
                                                            value={values.Phone}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control type="email"
                                                            name='Email__c'
                                                            disabled={['readonly'].includes(mode)}
                                                            onChange={handleChange}
                                                            autoComplete="off"
                                                            value={values.Email__c}
                                                        />
                                                        <ValidationError {...{ errors, touched, name: 'Email' }} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <div className='mt-4'></div>
                                            <hr />
                                            <div className='mt-4'></div>
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Group className="mb-3">
                                                        <RequiredFormLabel label='Filiale' required={mode !== 'readonly'} />
                                                        <Select
                                                            name="reference_partner"
                                                            isDisabled={['readonly'].includes(mode)}
                                                            placeholder='Seleziona partner'
                                                            options={partners}
                                                            defaultValue={partner}
                                                            onChange={(opt: any) => {
                                                                setPartner(opt)
                                                            }}
                                                        />
                                                        <ValidationError {...{ errors, touched, name: 'reference_partner' }} />
                                                    </Form.Group>
                                                </Col>
                                                {mode !== 'create' ? (
                                                    <Col md={3}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Data creazione</Form.Label>
                                                            <Form.Control type="text"
                                                                value={dayjs(values.CreatedDate).format(FORMAT_DATE_TIME)}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                ) : null}
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <div>Owner dell'account: {initialValues?.owner?.Name}</div>
                                                    <div>Filiale dell'owner: {initialValues?.owner?.Filiale__c}</div>
                                                </Col>
                                            </Row>
                                            {
                                                mode !== 'readonly' && (
                                                    <Btn
                                                        type="submit"
                                                        icon="arrow-right"
                                                        className="float-end"
                                                        disabled={loading || isSubmitting}
                                                    >
                                                        {mode === 'edit' ? 'Aggiorna' : 'Crea'}
                                                    </Btn>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </Form >
                        )}
                </Formik >
            )}
        </div >
    );
};

export default AccountForm;
