import React from "react"
import { Col, Row, Accordion, Card, Form, Button } from "react-bootstrap";
import Select from "react-select";
import { useTranslation } from 'react-i18next';

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" }
];

interface VehicleSearch1Props {
  fields?: any[]; // Replace `any[]` with a more specific type if you know the structure of `fields`
}

const VehicleSearch1: React.FC<VehicleSearch1Props> = () => {
  const { t } = useTranslation()

  return (
    // TODO: understand how the unit measure work
    <Row className="pb-5" id="car-selection">
      <Card className="mt-3">
        <Card.Body className="pt-0 pb-0">
          <Accordion className="mt-4" flush>
            <Row className="m-0 dropdown-wrapper">
              <Col className="select-dropdown">
                <Form.Group className="mb-3">
                  <div>
                    <Form.Label htmlFor="de">
                      {t('brand')}
                    </Form.Label>
                    <Select
                      className="multiselect"
                      name={"test"}
                      value={options[0]}
                      options={options}
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col className="select-dropdown">
                <Form.Group className="mb-3">
                  <div>
                    <Form.Label htmlFor="de">
                      {t('model')}
                    </Form.Label>
                    <Select
                      className="multiselect"
                      name={"test"}
                      value={options[0]}
                      options={options}
                    />
                  </div>
                </Form.Group>

              </Col>
              <Col className="select-dropdown">
                <Form.Group className="mb-3">
                  <div>
                    <Form.Label htmlFor="de">
                      {t('fuel_type')}
                    </Form.Label>
                    <Select
                      className="multiselect"
                      name={"test"}
                      value={options[0]}
                      options={options}
                    />
                  </div>
                </Form.Group>

              </Col>
            </Row>
          </Accordion>
          <Row>
            <div className="d-flex justify-content-center pt-5">
              <Button className="confirm-btn" onClick={() => {
                // TODO api call to get the filtered vehicles
              }}>
                {t('search_version')}
              </Button>
            </div>
          </Row>
        </Card.Body>

      </Card>
    </Row>

  )
}

export default VehicleSearch1