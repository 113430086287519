import { NavLink } from 'react-router-dom';
import i18next from 'i18next';

import Icon from '../Icon/Icon';

export const userAziendeColumnsData = [
	{
		accessor: 'id',
		id: 'id',
		Header: '',
		disableSortBy: true,
	},
	{
		accessor: 'username',
		id: 'username',
		Header: i18next.t('username'),
		minWidth: 360,
	},
	{
		accessor: 'family_name',
		id: 'family_name',
		Header: i18next.t('username'),
		minWidth: 180,
	},
	{
		accessor: 'given_name',
		id: 'given_name',
		Header: i18next.t('name'),
		minWidth: 180
	},
	{
		accessor: 'business_name',
		id: 'business_name',
		Header: i18next.t('business_name'),
		minWidth: 230
	},
	{
		accessor: 'company_detail',
		id: 'company_detail',
		Header: i18next.t('typology'),
		minWidth: 170
	},
	{
		accessor: 'is_active_email',
		id: 'is_active_email',
		Header: i18next.t('email_opt_in'),
		minWidth: 160,
		Cell: ({ value }) => {
			return value ? 'Si' : 'No'
		}
	},
	{
		accessor: 'phone',
		id: 'phone',
		Header: i18next.t('phone'),
		minWidth: 180
	},
	{
		accessor: 'dealer',
		id: 'dealer',
		Header: i18next.t('dealer'),
		minWidth: 190
	},
	{
		width: 60,
		accessor: 'actions',
		id: 'actions',
		Header: '',
		disableSortBy: true,
		headerClassName: 'column-sticky',
		className: 'column-sticky text-end',
		Cell: (data: any) => {
			// TODO: temp to replace with Redux
			const storeBusinessName = (): void => localStorage.setItem('business_name', data.row.values.business_name)
			return (
				<NavLink to={`/wishlist/${data.row.original.username}`} className="btn" onClick={storeBusinessName}>
					<Icon
						style={{ width: '1.2rem', height: '1.2rem' }}
						name="arrow-right"
					/>
				</NavLink>
			)
		},
	},
];
