import {
	useTable,
	useGlobalFilter,
	useFilters,
	useSortBy,
	usePagination,
	TableOptions,
	useBlockLayout,
} from 'react-table';

import QuotationListFilters from './components/QuotationListFilters';
import QuotationListTable from './components/QuotationListTable';
import GenericPagination from '../genericTable/GenericPagination';
import './VehicleTable.scss';

interface TableContainerProps {
	columns: any;
	data: any;
	loading: any;
	error: any;
	presetFilter?: any;
}

const QuotationListTableContainer: React.FC<TableContainerProps> = ({
	columns,
	data,
	loading,
	error,
	presetFilter = null,
}) => {
	const tableOptions: TableOptions<Object> = {
		columns,
		data,
		initialState: { pageSize: 20, hiddenColumns: ['id'] },
	};

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		preGlobalFilteredRows,
		setAllFilters,
		page,
		rows,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
	} = useTable(
		tableOptions,
		useGlobalFilter,
		useFilters,
		useSortBy,
		usePagination,
		useBlockLayout
	);

	return (
		<>
				<QuotationListFilters
					headerGroups={headerGroups}
					setAllFilters={setAllFilters}
					loading={loading}
					presetFilter={presetFilter}
				></QuotationListFilters>

			{!loading && !error && (
				<>
					<QuotationListTable
						getTableProps={getTableProps}
						headerGroups={headerGroups}
						getTableBodyProps={getTableBodyProps}
						page={page}
						prepareRow={prepareRow}
						resultCount={rows.length}
					></QuotationListTable>

					<GenericPagination
						gotoPage={gotoPage}
						canPreviousPage={canPreviousPage}
						previousPage={previousPage}
						nextPage={nextPage}
						canNextPage={canNextPage}
						pageCount={pageCount}
						state={state}
						pageOptions={pageOptions}
						setPageSize={setPageSize}
					></GenericPagination>
				</>
			)}

			{error && (
				<>
					<div className="table-error-message">{error.message}</div>
				</>
			)}
		</>
	);
};

export default QuotationListTableContainer;
