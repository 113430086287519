import { Card } from 'react-bootstrap';
import { FaSortDown, FaSortUp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Table from 'react-bootstrap/Table';

const UserActionsTable = ({
	getTableProps,
	headerGroups,
	getTableBodyProps,
	page,
	prepareRow,
	resultCount,
}) => {
	const { t } = useTranslation()

	return (
		<>
			<div className="table-results-container">
				{t('n_order')}:{' '}
				<span className="table-result-count">{resultCount}</span>
			</div>
			<Card>
				<div className='flipped-container flipped'>
				<Table
					striped
					borderless
					hover
					responsive
					{...getTableProps()}
					className="user-table m-0"
				>
					<thead className="border-bottom">
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column: any) => (
									<th
										className="text-nowrap"
										{...column.getHeaderProps({
											...column.getSortByToggleProps(),
											className: column.className,
										})}
									>
										{column.render('Header')}
										{column.canSort ? (
											<span className="position-relative">
												{column.isSorted ? (
													column.isSortedDesc ? (
														<>
															<FaSortUp className="mt-1 opacity-25 position-absolute" />
															<FaSortDown className="mt-1 position-absolute" />
														</>
													) : (
														<>
															<FaSortUp className="mt-1 position-absolute" />
															<FaSortDown className="mt-1 opacity-25 position-absolute" />
														</>
													)
												) : (
													<>
														<FaSortUp className="mt-1 opacity-25 position-absolute" />
														<FaSortDown className="mt-1 opacity-25 position-absolute" />
													</>
												)}
											</span>
										) : null}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row, i) => {
							prepareRow(row);
							return (
								<tr
									{...row.getRowProps()}
									// onDoubleClick={() => navigate(`/details/${row.original.id}`)}
								>
									{row.cells.map((cell: any) => (
										<td
											{...cell.getCellProps({
												className: cell.column.className,
											})}
										>
											{cell.render('Cell')}
										</td>
									))}
								</tr>
							);
						})}
					</tbody>
				</Table>
				</div>
			</Card>
		</>
	);
};

export default UserActionsTable;