import { ListGroup, Col, Row, Button, } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const MultiFileUploader =  ({ files, setFiles, types, maxSize = undefined }: {
    files: File[],
    setFiles: (files: File[]) => void,
    types: string[],
    maxSize?: number
}) => {
    const { t } = useTranslation()

    return <div>
        <FileUploader
            types={types}
            multiple
            classes="dropzone-small"
            handleChange={(fileList: File[]) => {
                const result = [...files, ...Array.from(fileList)]

                let totSize = 0
                result.forEach(el => { totSize += el.size })

                // @ts-ignore
                if (typeof maxSize !== 'undefined' && totSize > maxSize) {
                    toast.error(`Dimensione di caricamento massima superata (max ${maxSize / 1024 / 1024} MB)`);
                    return
                }

                setFiles([...files, ...Array.from(fileList)])
            }}
            onTypeError={() => {
                toast.error(i18next.t('unsuppoerted_multiple_files'));
            }}
        >
            <div className="fs-4 text-center">
                <div>
                    {t('click_or_drag_multiple_files')}
                </div>
                <div>
                    <small>({types.join(', ')})</small>
                </div>
            </div>
        </FileUploader>

        <Row>
            <Col md={6}>
                {files.length > 0 && (
                    <div className="mt-4">
                        <b>{t('loaded_files')}</b>
                        <ListGroup variant="flush">
                            {files.map((el, i) => {
                                return <ListGroup.Item key={i}>
                                    <Row className='d-flex align-items-center'>
                                        <Col>{el.name}</Col>
                                        <Col className="text-end">
                                            <Button
                                                size='sm'
                                                onClick={() => {
                                                    setFiles(files.filter((el, j) => i !== j))
                                                }}
                                                variant='danger'>
                                                {t('remove')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            })}
                        </ListGroup>
                    </div>
                )}
            </Col>
        </Row>
    </div>
}


export default MultiFileUploader;