/* eslint-disable jsx-a11y/alt-text */
import _ from 'lodash';
import dayjs from 'dayjs';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { DatiVeicoloEntity, OptionalEntity } from '../../api';
import { useTranslation } from 'react-i18next';

import { FORMAT_DATE } from '../../utils/constants';
import { generatePdf } from '../../utils/pdfUtils';
import './VehicleDetails.scss';

export type PDFData = {
	lista_offerte: {
		id: string;
		durata: string;
		km_totali: string;
		servizi: {
			treno_di_gomme: string,
			kasko: string,
			rca: string,
			auto_sostitutiva: string,
			i_f: string,
		};
		anticipo: string;
	}[];
	pdf: {
		data_validita: string;
		name: string;
		userName: string;
		userSurname: string;
		userMail: string;
		offerType: string;
		offerId: string;
		importo_azienda: string;
		importo_privato: string;
		dealerDetail: {
			logo: string;
		};
	};
	optionals: OptionalEntity[];
	dati_veicolo: DatiVeicoloEntity;
}

function VehicleDetails(props) {
	const maxlen = 500;
	const [data, setData] = useState<any>({})
	const [filename, setFilename] = useState<string | null>(null)
	const [offer, setoffer] = useState<any>({})
	const [specifications, setSpecifications] = useState<any[]>([])
	const { t } = useTranslation()

	useEffect(() => {
		if (Object.values(data).length && Object.values(offer).length && specifications.length && filename) {
			generatePdf(() => {
				setData({})
				setoffer({})
				setFilename(null)
				setSpecifications([])
			}, '#pdfContent', filename)
		}
	}, [data, specifications, filename, offer])

	const downloadPdf = (event: CustomEvent) => {
		const { data, filename = 'preventivo.pdf' } = event.detail;
		data.totlen = data?.optionals?.reduce((acc, optional) => acc + optional.descrizione.length, 0);

		setData(data)
		setFilename(filename)
		setoffer(data?.lista_offerte?.find((o) => o.id === data?.pdf?.offerId) || {})
		setSpecifications([
			{ label: i18next.t('fuel_type'), value: data.dati_veicolo?.alimentazione },
			{ label: i18next.t('kw'), value: data.dati_veicolo?.kw ? `${data.dati_veicolo?.kw} kw` : undefined },
			{ label: i18next.t('cylinder_capacity'), value: data.dati_veicolo?.cilindrata },
			{ label: i18next.t('vehicle_transmission'), value: data.dati_veicolo?.cambio },
			{ label: i18next.t('vehicle_drive_system'), value: data.dati_veicolo?.trazione },
			{ label: i18next.t('doors'), value: data.dati_veicolo?.porte },
			{ label: i18next.t('seats'), value: data.dati_veicolo?.posti },
		])
	}

	useEffect(() => {
		// @ts-ignore
		window.addEventListener('download-pdf', downloadPdf)

		return () => {
			// @ts-ignore
			window.removeEventListener('download-pdf', downloadPdf)
		}
	}, [])

	return (
		<div className="pdf-container">
			<div id="pdfContent" className="pdf">
				<table>
					<tbody>
						<tr>
							<td className="col-8">
								<img src="/images/pdf/horizon-c.png" className="logo" />
								{data?.pdf?.dealerDetail?.logo ? (
									<img
										src={data?.pdf?.dealerDetail?.logo} className="logo-dealer"
									/>
								) : (
									''
								)}
								<div className='space-block'></div>
								<p className="mb-1 big">
									{t('dear')}{' '}{data?.pdf?.name?.replaceAll(' ', '\xa0\xa0')}
								</p>
								<p className="big">
									{t('following_our_rental_offer_for_vehicle')}
								</p>

								<table>
									<tbody>
										<tr>
											<td className="col-6 car-preview">
												{data?.image_list?.length && (
													<>
														{data?.image_list[0].base64 ? (
															<img src={data?.image_list[0].base64} />
														) : (
															<img
																src={data?.image_list[0]?.url?.replace(
																	'https://api.horizonautomotive.it',
																	`${process.env.REACT_APP_API_URL}/images`
																)}
															/>
														)}
													</>
												)}
											</td>
											<td className="col-6 p-4">
												<div className="fw-bold">
													{data?.dati_veicolo?.marca}&nbsp;&nbsp;
													{data?.dati_veicolo?.modello.replaceAll(
														' ',
														'\xa0\xa0'
													)}
												</div>
												<div className="fw-bold">
													{data?.dati_veicolo?.allestimento.replaceAll(
														' ',
														'\xa0\xa0'
													)}
												</div>

												<p className="small m-0">
													{t('all_images_for_illustrative_purposes')}
												</p>
											</td>
										</tr>
									</tbody>
								</table>
								<hr className="light clear mx-5" />
								<table className="mt-3">
									<tbody>
										<tr>
											<td className="big-label">{t('rental_fee')}</td>
											<td className="big fw-bold">
												{offer[
													data?.pdf?.offerType === 'company'
														? i18next.t('business_fee')
														: i18next.t('private_fee')
												]
													? offer[
														data?.pdf?.offerType === 'company'
															? i18next.t('business_fee')
															: i18next.t('private_fee')
													]?.toLocaleString('it') + ' € / mese'
													: '-'}
											</td>
										</tr>
										<tr>
											<td className="big-label">Durata</td>
											<td className="big fw-bold">{offer?.durata} {t('months')}</td>
										</tr>
										<tr>
											<td className="big-label">Km inclusi</td>
											<td className="big fw-bold">
												{offer?.km_totali?.toLocaleString('it')} / {t('year')}
											</td>
										</tr>
									</tbody>
								</table>
							</td>
							<td className="col-4">
								<table className="info-user table-border mb-3">
									<tbody>
										<tr>
											<td className="col-12">
												<div className="p-2 pt-0">
													{t('author')}
													<div className="fw-bold text-spacing">{data?.pdf?.userName + ' ' + data?.pdf?.userSurname}</div>
												</div>
											</td>
										</tr>
										<tr>
											<td className="col-12">
												<div className="p-2 pt-0">
													{t('mail')}
													<div className="fw-bold text-spacing">{data?.pdf?.userMail}</div>
												</div>
											</td>
										</tr>
										{data?.pdf?.userPhone ? (
											<tr>
												<td className="col-12">
													<div className="p-2 pt-0">
														{t('phone')}
														<div className="fw-bold text-spacing">{data?.pdf?.userPhone}</div>
													</div>
												</td>
											</tr>
										) : ''}
									</tbody>
								</table>
								<div className="mb-3">
									{t('reminder_number')}
									<div className="fw-bold">
										{Date.now().toString(36) +
											data?.id?.slice(-4) +
											Math.random().toString(36).substring(2)}
									</div>
								</div>
								{/*<hr className="mx-0 mt-1 mb-3" />*/}
								<div className="mb-3">
									{t('validity_date')}
									<div className="fw-bold">
										{data?.pdf?.name?.replaceAll(' ', '\xa0\xa0')}
									</div>
								</div>

								<div>
									{t('months')}
									<div className="fw-bold">
										{data?.pdf?.data_validita || dayjs().add(3, 'weeks').format(FORMAT_DATE)}
									</div>
								</div>

								{/*<hr className="mx-0 mb-0" />*/}


							</td>
						</tr>
					</tbody>
				</table>

				<hr className="big" />

				<table>
					<tbody>
						<tr>
							<td className="col-4 p-2">
								<img
									src="/images/pdf/ico1.png"
									className="float-start pdf-icon"
								/>
							</td>
							<td className="col-4 p-2">
								<img
									src="/images/pdf/ico2.png"
									className="float-start pdf-icon"
								/>
							</td>
							<td className="col-4 p-2">
								<img
									src="/images/pdf/ico3.png"
									className="float-start pdf-icon"
								/>
							</td>
						</tr>
					</tbody>
				</table>

				<hr className="big" />

				<table className="info">
					<tbody>
						<tr>
							<td className="col-4 col-border">
								<div className="p-2 pt-0  ">
									{(data ? [
										{ label: i18next.t('fuel_type'), value: data.dati_veicolo?.alimentazione },
										{ label: i18next.t('kw'), value: data.dati_veicolo?.kw ? `${data.dati_veicolo?.kw} kw` : undefined },
										{ label: i18next.t('cylinder_capacity'), value: data.dati_veicolo?.cilindrata },
										{ label: i18next.t('vehicle_transmission'), value: data.dati_veicolo?.cambio },
										{ label: i18next.t('vehicle_drive_system'), value: data.dati_veicolo?.trazione },
										{ label: i18next.t('doors'), value: data.dati_veicolo?.porte },
										{ label: i18next.t('seats'), value: data.dati_veicolo?.posti },
									] : []).map((spec, index) => {
										return spec.value ? (
											<div key={index}>
												{spec.label}: {spec.value}
											</div>
										) : null
									})}
								</div>

								<div className="gray-box fw-bold pe-0 w-100">
									{'Accessori e optionals aggiuntivi'.replaceAll(
										' ',
										'\xa0\xa0'
									)}
									&nbsp;&nbsp; ({data?.optionals?.length})
								</div>
								<div className="p-2 pt-0">

									{data.totlen < maxlen ?
										<ul>
											{data?.optionals?.map((optional, index: number) => (
												<li key={index}>
													{_.upperFirst(optional.descrizione.toLowerCase())} {optional.valore ? (optional.valore.toLocaleString() + '€') : ''}
												</li>
											))}
										</ul>
										:
										<span>{t('view_optionals_on_following_page')}</span>
									}

								</div>
							</td>
							<td className="col-4 col-border">
								<div className="p-2 pt-0">
									<ul>
										{data?.pdf?.services
											?.split(/[\n]+/)
											.map((service: string, index: number) => {
												service = service.trim()
												if (service.length) {
													return <li key={index}>{service}</li>
												}

												return null
											})}
									</ul>
								</div>
							</td>
							<td className="col-4">
								<div className="p-2  pt-0">
									<div className="float-start w-100 mb-2">
										<div className="float-start fw-bold">
											{'Canone totale'.replaceAll(' ', '\xa0\xa0')} <br />
											{(
												(data?.pdf?.offerType === 'company' ? i18next.t('vat_excluded') : i18next.t('vat_included'))
											).replaceAll(' ', '\xa0\xa0')}
										</div>
										<div className="float-end fw-bold">
											{offer[
												data?.pdf?.offerType === 'company'
												? i18next.t('business_fee')
												: i18next.t('private_fee')
											]
												? offer[
													data?.pdf?.offerType === 'company'
													? i18next.t('business_fee')
													: i18next.t('private_fee')
												]?.toLocaleString('it') + ' € *'
												: '-'}
										</div>
									</div>
									<hr className="light clear" />
									<div className="mt-2 float-start w-100 fw-bold">
										<div className="float-start big-label">{t('downpayment')}</div>
										<div className="float-end big mb-1">
											{offer.anticipo?.toLocaleString('it')} € **
										</div>
									</div>
									<hr className="light clear" />
									* {t('quoted_rate_excludes_additional_accessories')}
									<br />
									<br />
									* {t('all_downpayments_exempt_from_vat')}
								</div>
							</td>
						</tr>
					</tbody>
				</table>

				<table className="mt-1" style={{}}>
					<tbody>
						<tr>
							<td className="footer">
								<img src="/images/pdf/logo-footer.png" className="float-end" />
								<br />
								{t('offer_valid_15_business_days')}
							</td>
						</tr>
					</tbody>
				</table>

				{data.totlen >= maxlen ?
					<>
						<table className='optionals'>
							<tbody>
								<tr>
									<td className="pt-3">
										<img src="/images/pdf/horizon-c.png" className="logo mb-0" />
									</td>
								</tr>
								<tr>
									<td className="">
										<span className="fw-bold big">
											{'Accessori e optionals aggiuntivi'.replaceAll(
												' ',
												'\xa0\xa0'
											)}
											&nbsp;&nbsp; ({data?.optionals?.length})
										</span>
										<ul>
											{data?.optionals?.map((optional, index: number) => (
												<li key={index}>
													{_.upperFirst(optional.descrizione.toLowerCase())}
												</li>
											))}
										</ul>
									</td>
								</tr>
							</tbody>
						</table>

						<table className="mt-5">
							<tbody>
								<tr>
									<td className="footer">
										<img src="/images/pdf/logo-footer.png" className="float-end" />
										<br />
											{t('offer_valid_15_business_days')}
									</td>
								</tr>
							</tbody>
						</table>
					</>
					: ""
				}

			</div>
		</div>
	);
}

export default VehicleDetails;
