import { Col, Form, FormControl, Row } from 'react-bootstrap';
import Select from 'react-select';

import RequiredFormLabel from '../RequiredFormLabel/RequiredFormLabel';
import Btn from '../../components/Btn/Btn';
import { renderFloatAsStr } from '../../utils';

const OpportunityCreation = ({
  account,
  setOpportunityValues,
  opportunityValues,
  opportunityFields,
  validation,
  onHide
}) => {
  return (
    <>
      <Row className='mt-4 mb-3'>
        <Col xs={3}>
          <Form.Group>
            <RequiredFormLabel label='Nome' />
            <Form.Control type="text"
              disabled
              value={'HMP-' + (account ? `${account?.Name}-` : '')}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group>
            <Form.Label>&nbsp;</Form.Label>
            <Form.Control type="text"
              name='name'
              required
              placeholder='Inserisci un nome'
              value={account?.name}
              onChange={({ target }) => {
                setOpportunityValues({
                  ...opportunityValues,
                  name: target?.value
                })
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <RequiredFormLabel label='Provvigioni' />
            <Form.Control type="text"
              name='commissions'
              required
              value={opportunityValues.commissions}
              onChange={({ target }) => {
                setOpportunityValues({
                  ...opportunityValues,
                  commissions: renderFloatAsStr(target?.value)
                })
              }}
            />
          </Form.Group>
        </Col>
        {opportunityFields?.map(el => {
          return (
            <Col md={6} key={el.name}>
              <Form.Group className="mb-3">
                <RequiredFormLabel label={el.label} />
                <Select
                  name="channel"
                  placeholder={`Seleziona ${el.label.toLowerCase()}`}
                  options={el.picklistValues}
                  defaultValue={opportunityValues[el.name]}
                  onChange={(opt: any) => {
                    setOpportunityValues({ ...opportunityValues, [el.name]: opt })
                  }}
                />
              </Form.Group>
            </Col>
          )
        })}
      </Row>
      <Row>
        <Col md={12}>
          <FormControl
            as="textarea"
            placeholder="Aggiungi note..."
            onChange={({ target }) => setOpportunityValues({ ...opportunityValues, notes: target.value })}
            style={{ height: '200px' }}
          />
        </Col>
      </Row>

      <Row className='mt-3'>
        <Col md={12}>
          <Btn
            disabled={validation.opportunity === false}
            type="submit"
            icon="arrow-right"
            className="float-end"
            onClick={() => onHide()}
          >
            Crea
          </Btn>
        </Col>
      </Row>
    </>
  );
}

export default OpportunityCreation;