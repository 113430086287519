import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import _ from 'lodash';
import { FileUploader } from 'react-drag-drop-files';
import { Formik } from 'formik';
import { Button, Card, Col, Form, Row, Spinner, Table } from 'react-bootstrap';
import dayjs, { Dayjs } from 'dayjs';
import { ExportToCsv } from 'export-to-csv';
import { FaCheck, FaDownload } from 'react-icons/fa';
import { Storage } from 'aws-amplify';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import Restricted from '../Restricted/Restricted';
import Btn from '../Btn/Btn';
import toaster from '../../utils/toaster';
import FormikValueObserver from '../Observer/FormikValueObserver';
import { useNavigateTo, useRefresh } from '../../hooks/useRefresh';
import { noopFn, translateServices, appendUniqueDataToFilename } from '../../utils';
import { Roles } from '../../types';
import { CancelablePromise } from '../../api';
import { useAuth } from '../../hooks/useAuth';
import { DocumentDTO } from '../../api/models/DocumentDTO';
import { GTMsendEvent } from '../../utils/trackingGA4';
import { FORMAT_DATE, FORMAT_DATE_TIME } from '../../utils/constants';
import {translateStoricoAzioneStates} from '../../utils/translations';

const addBusinessDays = (today: Dayjs, daysNum) => {
	let totalBusinessDaysAdded = 0;
	let current: Dayjs = today;

	for (let i = 0; totalBusinessDaysAdded < daysNum; i++) {
		current = current.add(1, 'day');
		if (current.day() !== 6 && current.day() !== 0) totalBusinessDaysAdded++;
	}

	return current!;
};

interface Servizi {
	value: string;
	label: string;
}

interface StatoServizi {

	gomme?: boolean,
	rca?: boolean,
	kasko?: boolean,
	furto_incendio?: boolean,
	auto_sostitutiva?: boolean
}

interface OptionActionModalProps {
	show: boolean;
	onHide: () => void;
	modalType: string;
	data?: any;
	servizi?: Servizi[];
	details?: any;
	actionMethods?: any;
	loading?: boolean;
}

function OptionActionModal({
	show,
	onHide,
	modalType = "",
	data,
	servizi = [] as Servizi[],
	details,
	actionMethods,
	loading = false,
}: OptionActionModalProps) {
	const { userRole } = useAuth();
	const { t } = useTranslation()
	const { user } = useAuth();
	const [symProvvigioni, setSymProvvigioni] = useState("%");
	const [inEvidenza, setInEvidenza] = useState(data?.inEvidenza)
	const [file, setFile] = useState<File>(new File([""], ''));
	const [document, setDocument] = useState<DocumentDTO>({ name: "", provvigioni: "" });
	const [uploadError, setUploadError] = useState<string | null>(null);
	const [statoServizi, setStatoServizi] = useState<StatoServizi>({ gomme: false, rca: false, kasko: false, furto_incendio: false, auto_sostitutiva: false })
	const fileTypes: string[] = ['pdf'];
	const refresh = useRefresh();
	const goBack = useNavigateTo('/available-vehicles');

	const observer = (<FormikValueObserver onChange={(form, initialValues) => {
		if (form.values.offerId && details?.lista_offerte?.length) {
			details?.lista_offerte.forEach((row) => {
				let services = '';
				if (form.values.offerId == row.id) {
					form.values.services = mapServices(services, row.servizi);
				}
			})
		}
	}} />)

	let initialValues, validateFn, actionButton, modalTitle, modalBody;

	const successMessages = {
		createOption: i18next.t('option_created_success'),
		confirmSell: i18next.t('sales_confirmed_success'),
		hideOption: i18next.t('vehicle_hid_success'),
		releaseOption: i18next.t('removed_hide_success'),
		renewOption: i18next.t('renewed_option_success'),
		deleteOptions: i18next.t('deleted_option_success'),
		deleteVehicle: i18next.t('deleted_vehicle_success'),
		hideRenew: i18next.t('renewed_hide_success'),
		uploadPdfActions: i18next.t('uploaded_success'),
		toggleInEvidenza: inEvidenza ? i18next.t('removed_highlight_success') : i18next.t('vehicle_highligh_success'),
		uploadAreaMarketingFileModal: i18next.t('upload_marketing_success'),
		uploadAreaAcademyFileModal: i18next.t('upload_academy_success'),
		requestInformations: i18next.t('quoation_request_success'),
	};
	// common success messages
	['createAreaMarketingFolder', 'createAreaAcademyFolder'].forEach(key => successMessages[key] = i18next.t('folder_saved_success'));
	['editAreaMarketingFolder', 'editAreaAcademyFolder'].forEach(key => successMessages[key] = i18next.t('folder_new_name_success'));
	['addAreaMarketingVideo', 'addAreaAcademyVideo'].forEach(key => successMessages[key] = i18next.t('video_added_success'));
	['editAreaMarketingVideo', 'editAreaAcademyVideo'].forEach(key => successMessages[key] = i18next.t('video_info_edit_success'));
	['deleteAreaMarketingObject', 'deleteAreaAcademyObject'].forEach(key => successMessages[key] = i18next.t('deleted_success'));

	async function uploadFiles(values): Promise<void> {
		try {
			setUploadError(null);

			const filename = appendUniqueDataToFilename(file?.name, values)

			const uploadRes: any = await Storage.put(filename, file, {
				contentType: file?.type,
				bucket: process.env.REACT_APP_AWS_STORAGE_BUCKET_VEHICLE,
			});

			document.name = uploadRes.key;

			setFile(new File([""], ''));
		} catch (error) {
			console.error('error: ', error);
			setUploadError(i18next.t('error_load_file'));
		}
	}

	const uploadAreaAcademyFile = async (): Promise<void> => {
		try { 
			setUploadError(null);

			const uploadRes: any = await Storage.put(file?.name, file, {
				contentType: file?.type,
				bucket: process.env.REACT_APP_AWS_STORAGE_BUCKET_AREA_ACADEMY,
			});

			setFile(new File([""], ''));
		} catch (error) {
			console.error('error: ', error);
			setUploadError(i18next.t('error_load_file'));
		}
	}

	const uploadAreaMarketingFile = async (): Promise<void> => {
		try {
			setUploadError(null);
			const test = await Storage.put(file?.name, file, {
				contentType: file?.type,
				bucket: process.env.REACT_APP_AWS_STORAGE_BUCKET_AREA_MARKETING,
			});

			setFile(new File([""], ''));
		} catch (error) {
			setUploadError(i18next.t('error_load_file'));
		}
	}

	function exportHistory() {
		const options = {
			filename: i18next.t('history_actions')
		};

		const csvExporter = new ExportToCsv(options);
		const exportData = [['Cliente', 'Utente', 'Tipo', 'Data']];

		exportData.push(
			...details.storico_azioni.map((row: any, i: number) => [
				row.cliente,
				row.utente,
				row.type,
				dayjs(row.timestamp).format(FORMAT_DATE_TIME),
			])
		);

		csvExporter.generateCsv(exportData);
	}

	function mapServices(services: string, dettListaOfferteServ: any) {
		Object.entries(dettListaOfferteServ).forEach(([key, value], index) => {
			let valService = (value as string);
			if (valService) {
				if (!isNaN(parseInt(valService)) || (valService).includes('€')) {
					if ((valService).split(" ").length < 2) {
						value = parseInt(valService).toLocaleString('it') + ' €';
					} else {
						if ((valService).includes('€')) {
							value = valService.replace('€', '') + ' €';
						}
					}
				}

				services += translateServices(key) + ": " + value;

				if (index < Object.entries(dettListaOfferteServ).length - 1) {
					services += '\n';
				}
			}
		});
		return services;
	}

	// Modal data & validations
	if (modalType === 'createOption') {
		modalTitle = i18next.t('insert_option');

		initialValues = {
			cliente: '',
			ragione_sociale: '',
			pratica_credito: '',
			n_estensione: data.extensionNum,
			noleggiatore: '',
			document: [],
		};

		validateFn = (values) => {
			const errors: any = {};
			if (!values.cliente) errors.cliente = i18next.t('mandatory');
			if (file.size === 0 && (userRole === Roles.Sales || userRole === Roles.Backoffice)) errors.pdfLinks = i18next.t('mandatory');
			if (!document.provvigioni) errors.name = i18next.t('mandatory');

			return errors;
		};
	}
	//
	else if (modalType === 'hideOption') {
		modalTitle = i18next.t('hide_vehicle');

		initialValues = { cliente: '', ragione_sociale: '' };

		validateFn = (values) => {
			const errors: any = {};
			if (!values.cliente) errors.cliente = i18next.t('mandatory');
			return errors;
		};
	}
	//
	else if (modalType === 'renewOption') {
		modalTitle = i18next.t('renew_option');
		initialValues = {};
		validateFn = noopFn;
	}
	//
	else if (modalType === 'confirmSell') {
		modalTitle = i18next.t('confirm_sale');

		initialValues = {
			cliente: '',
			ragione_sociale: '',
			noleggiatore: '',
			pratica_credito: '',
		};

		validateFn = (values) => {
			const errors: any = {};
			if (!values.cliente) errors.cliente = i18next.t('mandatory');
			if (!values.pratica_credito) errors.pratica_credito = i18next.t('mandatory');
			return errors;
		};
	}
	//
	else if (modalType === 'downloadPdf') {
		modalTitle = i18next.t('download_pdf');
		initialValues = {
			offerId: details?.lista_offerte && details?.lista_offerte[0]?.id,
			offerType: 'company',
			userName: user?.attributes.given_name ?? '',
			userSurname: user?.attributes.family_name ?? '',
			userMail: user?.attributes.email ?? '',
			userPhone: user?.attributes['custom:phone'] ?? '',
			name: '',
		};
		let services = '';
		services = mapServices(services, details?.lista_offerte[0]?.servizi);
		initialValues.services = services;
		actionButton = i18next.t('download');
		validateFn = (values) => {
			const errors: any = {};
			if (!values.name) errors.name = i18next.t('mandatory');
			return errors;
		};
	}
	//
	else if (modalType === 'releaseOption') {
		modalTitle = i18next.t(i18next.t('remove_hiding'));
		initialValues = {};
		validateFn = noopFn;
	}
	//
	else if (modalType === 'deleteOptions') {
		modalTitle = i18next.t('delete_option');
		initialValues = { document_name: details.stato_corrente.document };
		validateFn = noopFn;
	}
	//
	else if (modalType === 'deleteVehicle') {
		modalTitle = i18next.t('delete_vehicle');
		actionButton = i18next.t('yes');
		initialValues = {};
		validateFn = noopFn;
	}
	//
	else if (modalType === 'historyActions') {
		modalTitle = i18next.t('vehicle_history');
		initialValues = {};
		validateFn = noopFn;
	}
	//
	else if (modalType === 'uploadPdfActions') {
		modalTitle = i18next.t('upload_pdf');
		initialValues = JSON.parse(JSON.stringify(details.dettagli_veicolo));
		initialValues.optionals = details?.optionals;
		initialValues.stato_corrente = details?.stato_corrente;
		initialValues.document = details?.dettagli_veicolo.document;
		validateFn = (values) => {
			const errors: any = {};
			if (file.size === 0) errors.pdfLinks = i18next.t('mandatory');
			if (!document.provvigioni) errors.name = i18next.t('mandatory');

			return errors;
		};
	}
	//
	else if (modalType === 'offerDocuments') {
		modalTitle = i18next.t('documents');
		initialValues = {};
		validateFn = noopFn;
	}
	//
	else if (modalType === 'hideRenew') {
		modalTitle = i18next.t('renew_hide');
		initialValues = { cliente: '', ragione_sociale: '' };
		validateFn = (values) => {
			const errors: any = {};
			if (!values.cliente) errors.cliente = i18next.t('mandatory');
			return errors;
		};
	}
	//
	else if (modalType === 'toggleInEvidenza') {
		if (data.inEvidenza) {
			modalTitle = i18next.t('confirm_remove_highlight');
		} else {
			modalTitle = i18next.t('confirm_highlight_vehicle')
		}

		actionButton = i18next.t('yes');
		initialValues = { inEvidenza: data.inEvidenza };
	}
	//
	else if (modalType === 'createAreaAcademyFolder' || modalType === 'createAreaMarketingFolder') {
		// if there is no data from the server then display add folder dialog
		// First Folder creation
		modalTitle = i18next.t('add_folder');
		actionButton = i18next.t('save');
		initialValues = { folderName: '' };
		validateFn = (values) => {
			const errors: any = {};
			// if (values.folderName.length < 3) errors.folderName = 'Il Nome della cartella deve eavere almeno 3 caratteri';
			if (!values.folderName) errors.folderName = i18next.t('mandatory');
			return errors;
		};
	}
	//
	else if (modalType === 'editAreaAcademyFolder' || modalType === 'editAreaMarketingFolder') {
		if (details?.type === 'folder') {
			modalTitle = i18next.t('rename_folder');
			actionButton = i18next.t('save');
			initialValues = { folderName: details.folderName, id: details.id, oldName: details.folderName };
			validateFn = (values) => {
				const errors: any = {};
				// if (values.folderName.length < 3) errors.folderName = 'Il Nome della cartella deve eavere almeno 3 caratteri';
				if (!values.folderName) errors.folderName = i18next.t('mandatory_field');
				return errors;
			};
		}
	}
	//
	else if (modalType === 'deleteAreaAcademyObject' || modalType === 'deleteAreaMarketingObject') {
		if (details?.object?.type === 'video_url') {
			modalTitle = i18next.t('confirm_delete_video');
			modalBody = i18next.t('request_confirm_delete_video')
		} else if (details?.object?.type === 'folder') {
			modalTitle = i18next.t('confirm_delete_folder');
			modalBody = i18next.t('request_confirm_delete_folder')
		} else if (details?.object?.type === 'file') {
			modalTitle = i18next.t('confirm_delete_file');
			modalBody = i18next.t('request_confirm_delete_file')
		}
		actionButton = i18next.t('yes')
		initialValues = { id: details?.object?.id };
	}
	//
	else if (modalType === 'addAreaAcademyVideo' || modalType === 'addAreaMarketingVideo') {
		modalTitle = i18next.t('add_video_link');
		actionButton = i18next.t('save');
		initialValues = { videoLink: '', videoTitle: '' };
		validateFn = (values) => {
			const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
			const match = values.videoLink.match(regExp);

			const errors: any = {};
			if (!match) errors.videoLink = i18next.t('invalid_youtube_url')
			if (!values.videoLink) errors.videoLink = i18next.t('link_mandatory');
			if (!values.videoTitle) errors.videoTitle = i18next.t('title_mandatory');

			return errors;
		}
	}
	//
	else if (modalType === 'editAreaAcademyVideo' || modalType === 'editAreaMarketingVideo') {
		modalTitle = i18next.t('edit_video_info');
		actionButton = i18next.t('save');
		initialValues = { videoTitle: details.videoTitle, videoLink: details.videoLink, id: details.id, oldName: details.objectName };
		validateFn = (values) => {
			const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
			const match = values.videoLink.match(regExp);
			const errors: any = {};
			if (!values.videoTitle) errors.folderName = i18next.t('mandatory_field');
			if (!match) errors.videoLink = i18next.t('invalid_youtube_url');
			if (!values.videoLink) errors.videoLink = i18next.t('link_mandatory');
			return errors;
		};
	}
	//
	else if (modalType === 'uploadAreaAcademyFileModal') {
		modalTitle = i18next.t('upload_file');
		initialValues = { can_download: true }
		validateFn = (values) => {
			const errors: any = {};
			if (file.size === 0) errors.pdfLinks = i18next.t('mandatory');
			return errors;
		};
	}
	//
	else if (modalType === 'uploadAreaMarketingFileModal') {
		modalTitle = i18next.t('upload_file');
		initialValues = { can_download: true }
		validateFn = (values) => {
			const errors: any = {};
			if (file.size === 0) errors.pdfLinks = i18next.t('mandatory');
			return errors;
		};
	}
	//
	else if (modalType === 'requestInformations') {
		modalTitle = i18next.t('quotation_request');
		actionButton = i18next.t('send');
		initialValues = {
			km: 0,
			durata: 0,
			anticipo: 0,
			servizi: {
				rca: { label: i18next.t('deductible'), value: "" },
				kasko: { label: i18next.t('deductible'), value: "" },
				furto_incendio: { label: i18next.t('deductible'), value: "" },
				gomme: { label: i18next.t('quantity'), value: 0 },
				auto_sostitutiva: { label: i18next.t('category'), value: "" },
			},
		}
		validateFn = (values) => {
			const errors: any = {};
			if (!values.km) errors.km = i18next.t('km_field_mandatory');
			if (!values.durata) errors.durata = i18next.t('duration_field_mandatory');

			return errors;
		};
	}

	else if (modalType === 'viewVehicleOptionals') {
		modalTitle = `${i18next.t('optional_of_the_vehicle')} ${details.vehicle_id}`;
	}

	else if (modalType === 'downloadQuotationPdf') {
		modalTitle = i18next.t('download_quotation_pdf');
		const processedRow = details
		const privatiOrAzienda = processedRow.original.noleggiatore.includes("privati") ? "private" : "company"
		initialValues = {
			name: '',
			clientType: privatiOrAzienda,
			processedRow
		};

		actionButton = i18next.t('download_pdf');
		validateFn = (values) => {
			const errors: any = {};
			if (!values.name) errors.name = i18next.t('mandatory');
			return errors;
		};
	}

	else {
		modalTitle = '...';
		initialValues = {};
		validateFn = noopFn;
	}

	const onSubmitFn = async (values, { setSubmitting }) => {
		if (modalType === 'uploadPdfActions' || modalType === 'createOption') {
			if (document.provvigioni) {
				if ('€' === symProvvigioni) {
					document.provvigioni = symProvvigioni + ' ' + document.provvigioni
				} else {
					document.provvigioni = document.provvigioni + ' ' + symProvvigioni
				}
			}
			await uploadFiles(values);
			if (document.name) {
				values.document.push(document);
			}
		}

		if (modalType === 'uploadAreaMarketingFileModal') {
			values.file = file
			await uploadAreaMarketingFile();
		}

		if (modalType === 'uploadAreaAcademyFileModal') {
			values.file = file
			await uploadAreaAcademyFile();
		}

		const apiRequest: CancelablePromise<any> = actionMethods[modalType](values);

		const deleteFileOrFolder = () => {
			return {
				event: `delete_${details?.object?.type}`,
				evtCat: modalType?.includes('Academy') ? 'Academy' : 'Marketing',
				evtAct: 'delete',
				evtLab: details?.object?.title
			}
		}

		apiRequest &&
			apiRequest
				.then((res) => {
					setSubmitting(false);
					toaster.success(successMessages[modalType]);

					// map modalType to the right GTM params to send
					const actionType = {
						'createOption': { event: 'complete_actions_sale', actionType: 'inserisci_opzione' },
						'renewOption': { event: 'complete_actions_sale', actionType: 'rinnova_opzione' },
						'deleteOptions': { event: 'complete_actions_sale', actionType: 'cancella_opzione' },
						'deleteVehicle': { event: 'complete_actions_sale', actionType: 'elimina_veicolo' },
						'hideOption': { event: 'complete_actions_sale', actionType: 'oscura_veicolo' },
						'releaseOption': { event: 'complete_actions_sale', actionType: 'rimuovi_oscuramento' },
						'hideRenew': { event: 'complete_actions_sale', actionType: 'rinnova_oscuramento' },
						'confirmSell': { event: 'complete_actions_sale', actionType: 'conferma_vendita' },
						'toggleInEvidenza': { event: 'complete_actions_sale', actionType: inEvidenza ? 'rimuovi_in_evidenza' : 'metti_in_evidenza' },
						'uploadPdfActions': { event: 'complete_upload_PDF', evtCat: 'offerta', evtAct: 'upload', evtLab: file.name },
						'createAreaMarketingFolder': { event: 'complete_actions_marketing', actionType: 'aggiungi cartella', componentName: values.folderName },
						'createAreaAcademyFolder': { event: 'complete_actions_academy', actionType: 'aggiungi cartella', componentName: values.folderName },
						'addAreaMarketingVideo': { event: 'complete_actions_marketing', actionType: 'aggiungi video', componentName: values.videoTitle },
						'addAreaAcademyVideo': { event: 'complete_actions_academy', actionType: 'aggiungi video', componentName: values.videoTitle },
						'uploadAreaMarketingFileModal': { event: 'complete_actions_marketing', actionType: 'aggiungi file', componentName: values?.file?.name },
						'uploadAreaAcademyFileModal': { event: 'complete_actions_academy', actionType: 'aggiungi file', componentName: values?.file?.name },
						'editAreaMarketingFolder': { event: 'complete_modify_folder', evtCat: 'Marketing', evtAct: 'modify', evtLab: values.folderName },
						'editAreaAcademyFolder': { event: 'complete_modify_folder', evtCat: 'Academy', evtAct: 'modify', evtLab: values.folderName },
						'deleteAreaMarketingObject': deleteFileOrFolder(),
						'deleteAreaAcademyObject': deleteFileOrFolder(),
						'requestInformations': {
							event: "complete_ask_quotes",
							item_name: `${details?.details?.datiVeicolo?.marca} ${details?.details?.datiVeicolo?.allestimento}`,
							item_category: values.km,
							item_category2: values.durata,
							advance: values.anticipo,
							item_category3: `RCA ${values?.servizi?.rca.value}`,
							item_category4: `Kasko ${values?.servizi?.kasko.value}`,
							item_category5: `Furto/Incendio ${values?.servizi?.furto_incendio.value}`,
							item_category6: `Gomme ${values?.servizi?.gomme.value}`,
							item_category7: `Auto sostitutiva ${values?.servizi?.auto_sostitutiva.value}`,
							note_gerarchiche: `Note generiche ${values?.note_generiche}`
						}
					}

					// send Event to GA4
					GTMsendEvent({
						...actionType[modalType]
					})

					onHide();

					if (modalType === 'deleteVehicle') {
						goBack();
					} else {
						refresh();
					}
				})
				.catch((err) => {
					console.error(err);
					toaster.error(
						err.message || i18next.t('error_sending_data')
					);
					onHide();
				});
	};

	const onHideFn = () => {
		setFile(new File([""], ''))
		onHide();
	}

	return (
		<Modal size="lg" show={show} onHide={onHideFn} >
			{loading ? (
				<>
					<Modal.Header>
						<Modal.Title>{t('loading')}...</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="text-center">
							<Spinner animation="border" />
						</div>
					</Modal.Body>
				</>
			) : (
				<Formik
					initialValues={initialValues}
					onSubmit={onSubmitFn}
					validate={validateFn}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
						setFieldValue,
					}) => {
						let body;
						if (modalType === 'createOption') {
							body = (
								<>
									{observer}
									<Row>
										<Col md={6}>
											<Form.Group className="mb-3">
												<Form.Label>
													{t('customer')}
													<span className="red-ast">*</span>
												</Form.Label>
												<Form.Control
													onChange={handleChange}
													onBlur={handleBlur}
													name="cliente"
													value={values.cliente}
													type="text"
													className={`${errors.cliente && touched.cliente ? 'error-border' : ''
														}`}
												/>
												{errors.cliente && touched.cliente && (
													<small className="text-danger">
														{errors.cliente as string}
													</small>
												)}
											</Form.Group>
										</Col>

										<Col md={6}>
											<Form.Group className="mb-3">
												<Form.Label>{t('business_name')}</Form.Label>
												<Form.Control
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.ragione_sociale}
													name="ragione_sociale"
													type="text"
												/>
											</Form.Group>
										</Col>

										<Col md={6}>
											<Form.Group className="mb-3">
												<Form.Label>
													{t('credit_application_code_or_customer_code')}
												</Form.Label>
												<Form.Control
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.pratica_credito}
													name="pratica_credito"
													type="text"
												/>
											</Form.Group>
										</Col>

										<Col md={6}>
											<Form.Group className="mb-3">
												<Form.Label>{t('rental_company')}</Form.Label>
												<Form.Control
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.noleggiatore}
													name="noleggiatore"
													type="text"
												/>
											</Form.Group>
										</Col>


										<Restricted to={[Roles.Backoffice, Roles.Sales, Roles.Master, Roles.Admin]}>
											<Col md={12}>
												<div className="file-uploader-container-opt text-center">

													<FileUploader
														classes="dropzone"
														types={fileTypes}
														handleChange={(file) => {
															setUploadError(null);
															setFile(file);
														}}
														onTypeError={() => {
															setFile(new File([""], ''));
															setUploadError(i18next.t('unsupported_file'));
														}}
														name="file"
													>
														<div className="fs-4">
															<div>
																{t('quotation_pdf_association_text')}
															</div>
															<div className="fs-6 text-muted">
																{t('file_type')}: {fileTypes.join(', ')}
															</div>
														</div>


														{file?.size > 0 ? (
															<ul className="my-5">
																<li key="name"> {file.name} </li>
															</ul>
														) : (
															''
														)}
														{uploadError ? (
															<p className="mt-4 text-danger">{uploadError}</p>
														) : (
															''
														)}
													</FileUploader>
													{file.size > 0 ? (
														<Button
															variant="secondary"
															onClick={() => {
																setFile(new File([""], ''));
															}}
															className="btn btn-sm btn-outline file-uploader-clear-btn"
															type="button"
														>
															{t('delete')}
														</Button>
													) : (
														''
													)}
												</div>
												{errors.pdfLinks && (
													<small className="text-danger">
														{errors.pdfLinks as string}
													</small>
												)}
											</Col>
											<Col md={5} className="pe-1 mt-5">
												<Form.Group className="mb-3">
													<Form.Label>
														{t('commissions')}
													</Form.Label>
													<Form.Control
														onChange={(e) => setDocument({ name: "", provvigioni: e.target.value, is_active: true })}
														onBlur={handleBlur}
														name="provvigioni"
														value={document.provvigioni}
														type="text"
														id="provvigioni"
														pattern="[0-9,',']*"
														className={`${errors.provvigioni && touched.provvigioni ? 'error-border' : ''
															}`}
													/>
													{errors.name && (
														<small className="text-danger">
															{errors.name as string}
														</small>
													)}
												</Form.Group>
											</Col>
											<Col md={2} className="ps-0 mt-5">
												<Form.Group className="mt-2">
													<Form.Label />
													<Form.Select
														name='sym_provvigioni'
														value={symProvvigioni}
														onChange={(e) => setSymProvvigioni(e.target.value)}
													>
														<option key="%" value="%">
															%
														</option>
														<option key="€" value="€">
															€
														</option>
													</Form.Select>
												</Form.Group>
											</Col>
										</Restricted>

										<Col md={6}>
											<div style={{ marginTop: '2.5rem' }}>
												<span style={{ marginRight: '0.4rem' }}>
													{t('remaining_extensions')}
												</span>
												<span style={{ fontWeight: '600' }}>
													{1 - data.extensionNum}
												</span>
											</div>
										</Col>

									</Row></>
							);
						}
						if (modalType === 'hideOption') {
							body = (
								<>
									{observer}
									<Row>
										<Col md={6}>
											<Form.Group className="mb-3">
												<Form.Label>
													{t('customer')}
													<span className="red-ast">*</span>
												</Form.Label>
												<Form.Control
													onChange={handleChange}
													onBlur={handleBlur}
													name="cliente"
													value={values.cliente}
													type="text"
													className={`${errors.cliente && touched.cliente ? 'error-border' : ''
														}`}
												/>
												{errors.cliente && touched.cliente && (
													<small className="text-danger">
														{errors.cliente as string}
													</small>
												)}
											</Form.Group>
										</Col>

										<Col md={6}>
											<Form.Group className="mb-3">
												<Form.Label>{t('business_name')}</Form.Label>
												<Form.Control
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.ragione_sociale}
													name="ragione_sociale"
													type="text"
												/>
											</Form.Group>
										</Col>
									</Row></>
							);
						}
						if (modalType === 'renewOption') {
							const expirationDay: Dayjs = addBusinessDays(data.today, 5);

							body = (
								<p className="m-0">
									{t('business_name')}
									Sei sicuro di voler rinnovare l'opzione? (l'opzione scadrà il giorno{' '}
									<span className="font-weight-bold">
										{expirationDay.format(FORMAT_DATE)}
									</span>
									)
								</p>
							);
						}

						if (modalType === 'confirmSell') {
							body = (
								<>
									{observer}
									<Row>
										<Col md={6}>
											<Form.Group className="mb-3">
												<Form.Label>
													{t('customer')}
													<span className="red-ast">*</span>
												</Form.Label>
												<Form.Control
													onChange={handleChange}
													onBlur={handleBlur}
													name="cliente"
													value={values.cliente}
													type="text"
													className={`${errors.cliente && touched.cliente ? 'error-border' : ''
														}`}
												/>
												{errors.cliente && touched.cliente && (
													<small className="text-danger">
														{errors.cliente as string}
													</small>
												)}
											</Form.Group>
										</Col>

										<Col md={6}>
											<Form.Group className="mb-3">
												<Form.Label>{t('business_name')}</Form.Label>
												<Form.Control
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.ragione_sociale}
													name="ragione_sociale"
													type="text"
												/>
											</Form.Group>
										</Col>

										<Col md={6}>
											<Form.Group className="mb-3">
												<Form.Label>{t('rental_company')}</Form.Label>
												<Form.Control
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.noleggiatore}
													name="noleggiatore"
													type="text"
												/>
											</Form.Group>
										</Col>

										<Col md={6}>
											<Form.Group className="mb-3">
												<Form.Label>
													{t('credit_application_code_or_customer_code')}
													<span className="red-ast">*</span>
												</Form.Label>
												<Form.Control
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.pratica_credito}
													name="pratica_credito"
													type="text"
													className={`${errors.pratica_credito && touched.pratica_credito
														? 'error-border'
														: ''
														}`}
												/>
												{errors.pratica_credito && touched.pratica_credito && (
													<small className="text-danger">
														{errors.pratica_credito as string}
													</small>
												)}
											</Form.Group>
										</Col>
									</Row></>
							);
						}

						if (modalType === 'hideRenew') {
							const expirationDay: Dayjs = addBusinessDays(data.today, 5);

							body = (
								<>
									{observer}
									<Row>
										<p className="mb-4">
											Sei sicuro di voler rinnovare l'oscuramento (l'oscuramento
											scadrà il giorno{' '}
											<span className="font-weight-bold">
												{expirationDay.format(FORMAT_DATE)}
											</span>
											)?
										</p>
									</Row>
									<Row>
										<Col md={6}>
											<Form.Group className="mb-3">
												<Form.Label>
													{t('customer')}
													<span className="red-ast">*</span>
												</Form.Label>
												<Form.Control
													onChange={handleChange}
													onBlur={handleBlur}
													name="cliente"
													value={values.cliente}
													type="text"
													className={`${errors.cliente && touched.cliente
														? 'error-border'
														: ''
														}`}
												/>
												{errors.cliente && touched.cliente && (
													<small className="text-danger">
														{errors.cliente as string}
													</small>
												)}
											</Form.Group>
										</Col>

										<Col md={6}>
											<Form.Group className="mb-3">
												<Form.Label>{t('business_name')}</Form.Label>
												<Form.Control
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.ragione_sociale}
													name="ragione_sociale"
													type="text"
												/>
											</Form.Group>
										</Col>
									</Row>
								</>
							);
						}
						if (modalType === 'releaseOption') {
							body = (
								<p className="m-0">
									{t('are_you_sure_you_want_to_remove_highlight')}
								</p>
							);
						}
						if (modalType === 'deleteOptions') {
							body = (
								<p className="m-0">{t('are_you_sure_you_want_to_remove_option')}</p>
							);
						}
						if (modalType === 'deleteVehicle') {
							body = (
								<p className="m-0">{t('are_you_sure_you_want_to_remove_vehicle')}</p>
							);
						}
						if (modalType === 'historyActions') {
							body = (
								<>
									{observer}
									<Table striped borderless hover responsive>
										<thead className="border-bottom">
											<tr>
												<th>{t('date')}</th>
												<th>{t('type')}</th>
												<th>{t('user')}</th>
												<th>{t('customer')}</th>
											</tr>
										</thead>
										<tbody className="border-bottom">
											{details.storico_azioni.map((row: any, i: number) => (
												// AGGIUNTO_AI_PREFERITI must not be visible inside the storico_azioni of the single vehicle
												row.type !== 'AGGIUNTO_AI_PREFERITI' ?
													<tr key={i}>
														<td>{dayjs(row.timestamp).format(FORMAT_DATE_TIME)}</td>
														<td>
															{translateStoricoAzioneStates(row.type)}
															{/* {_.capitalize(row.type.toLowerCase().replace('_', ' '))} */}
														</td>
														<td>{row.utente}</td>
														<td>{row.cliente}</td>
													</tr>
													: ""
											))}
										</tbody>
									</Table></>
							);
						}
						if (modalType === 'uploadPdfActions') {
							body = (
								<>
									{observer}
									<Row>
										<Col md={12} className="pe-1">
											<Form.Group>
												<div className="file-uploader-container-modal text-center">
													<FileUploader
														classes="dropzone"
														types={fileTypes}
														handleChange={(file) => {
															setUploadError(null);
															setFile(file);
														}}
														onTypeError={() => {
															setFile(new File([""], ''));
															setUploadError(i18next.t('unsupported_file'));
														}}
														name="file"
													>
														<div className="fs-4">
															<div>
																{t('quotation_pdf_association_text')}
															</div>
															<div className="fs-6 text-muted">
																{t('file_type')}: {fileTypes.join(', ')}
															</div>
														</div>

														{file?.size > 0 ? (
															<ul className="my-5">
																<li key="name">{file.name}</li>
															</ul>
														) : (
															''
														)}
														{uploadError ? (
															<p className="mt-4 text-danger">{uploadError}</p>
														) : (
															''
														)}
													</FileUploader>
													{file.size > 0 ? (
														<Button
															variant="secondary"
															onClick={() => {
																setFile(new File([""], ''));
															}}
															className="btn btn-sm btn-outline file-uploader-clear-btn"
															type="button"
														>
															{t('delete')}
														</Button>
													) : (
														''
													)}
												</div>
												{errors.pdfLinks && (
													<small className="text-danger">
														{errors.pdfLinks as string}
													</small>
												)}
											</Form.Group>
										</Col>

										<Col md={5} className="pe-1 mt-5">
											<Form.Group className="mb-3">
												<Form.Label>
													{t('commissions')}
												</Form.Label>
												<Form.Control
													onChange={(e) => setDocument({ name: "", provvigioni: e.target.value, is_active: true })}
													onBlur={handleBlur}
													name="provvigioni"
													value={document.provvigioni}
													type="string"
													id="provvigioni"
													pattern="[0-9,',']*"
													className={`${errors.provvigioni && touched.provvigioni ? 'error-border' : ''
														}`}
												/>
												{errors.name && (
													<small className="text-danger">
														{errors.name as string}
													</small>
												)}
											</Form.Group>
										</Col>
										<Col md={2} className="ps-0 mt-5">
											<Form.Group className="mt-2">
												<Form.Label />
												<Form.Select
													name='sym_provvigioni'
													value={symProvvigioni}
													onChange={(e) => setSymProvvigioni(e.target.value)}
												>
													<option key="%" value="%">
														%
													</option>
													<option key="€" value="€">
														€
													</option>
												</Form.Select>
											</Form.Group>
										</Col>
									</Row>
								</>
							);
						}
						if (modalType === 'downloadPdf') {
							body = (
								<>
									{observer}
									<Row>
										<Col md={12} className="pe-1">
											<Form.Group className="mb-3">
												<Form.Label>
													{t('quotation_recipient')}
													<span className="red-ast">*</span>
												</Form.Label>
												<Form.Control
													onChange={handleChange}
													onBlur={handleBlur}
													name="name"
													value={values.name}
													type="text"
													id="name"
													className={`${errors.name && touched.name ? 'error-border' : ''
														}`}
												/>
												{errors.name && touched.name && (
													<small className="text-danger">
														{errors.name as string}
													</small>
												)}
											</Form.Group>
										</Col>
										<Col md={12} className="pe-1">
											<div className="h5">
												{t('quotation_creator')}
											</div>
										</Col>
										<Col md={6} className="pe-1">
											<Form.Group className="mb-3">
												<Form.Label>
													{t('name')}
												</Form.Label>
												<Form.Control
													onChange={handleChange}
													onBlur={handleBlur}
													name="userName"
													value={values.userName}
													type="text"
													id="userName"
												/>
											</Form.Group>
										</Col>
										<Col md={6} className="pe-1">
											<Form.Group className="mb-3">
												<Form.Label>
													{t('surname')}
												</Form.Label>
												<Form.Control
													onChange={handleChange}
													onBlur={handleBlur}
													name="userSurname"
													value={values.userSurname}
													type="text"
													id="userSurname"
												/>
											</Form.Group>
										</Col>
										<Col md={6} className="pe-1">
											<Form.Group className="mb-3">
												<Form.Label>
													{t('mail')}
												</Form.Label>
												<Form.Control
													onChange={handleChange}
													onBlur={handleBlur}
													name="userMail"
													value={values.userMail}
													type="text"
													id="userMail"
												/>
											</Form.Group>
										</Col>
										<Col md={6} className="pe-1">
											<Form.Group className="mb-3">
												<Form.Label>
													{t('phone')}
												</Form.Label>
												<Form.Control
													onChange={handleChange}
													onBlur={handleBlur}
													name="userPhone"
													value={values.userPhone}
													type="text"
													id="userPhone"
												/>
											</Form.Group>
										</Col>
									</Row>
									<div className="mt-3">
										<Form.Label>
											{t('available_price_combinations')}
											<span className="red-ast">*</span>
										</Form.Label>

										<Card>
											{details?.lista_offerte?.length ? (
												<Table
													striped
													hover
													borderless
													responsive
													className="m-0"
												>
													<thead>
														<tr>
															<th></th>
															<th>{t('private_rental_fee').toUpperCase()}</th>
															<th>{t('business_rental_fee').toUpperCase()}</th>
															<th>{t('downpayment').toUpperCase()}ANTICIPO</th>
															<th>{t('annual_included_km').toUpperCase()}</th>
															<th>{t('rental_company').toUpperCase()}</th>
															<th>{t('duration').toUpperCase()}</th>
															<th>{t('ecobonus').toUpperCase()}</th>
														</tr>
													</thead>
													<tbody>
														{details?.lista_offerte?.map((o, i) => (
															<tr key={i}>
																<td>
																	<Form.Check
																		onChange={handleChange}
																		onBlur={handleBlur}
																		value={o.id}
																		checked={values.offerId === o.id}
																		name="offerId"
																		type="radio"
																		className="hor-check-box-big"
																	/>
																</td>
																<td>
																	<div className="h5 m-0">
																		{o.importo_privato ? (
																			<div className="text-nowrap">
																				€{' '}
																				{o.importo_privato?.toLocaleString('it', {
																					minimumFractionDigits: 2,
																				})}
																			</div>
																		) : (
																			'-'
																		)}
																	</div>
																</td>
																<td>
																	<div className="h5 m-0">
																		{o.importo_azienda ? (
																			<div className="text-nowrap">
																				€{' '}
																				{o.importo_azienda?.toLocaleString('it', {
																					minimumFractionDigits: 2,
																				})}
																			</div>
																		) : (
																			'-'
																		)}
																	</div>
																</td>
																<td>
																	{o.anticipo ? (
																		<div className="text-nowrap">
																			€ {o.anticipo?.toLocaleString('it')}
																		</div>
																	) : (
																		'€ ' + 0
																	)}
																</td>
																<td>{o.km_totali?.toLocaleString('it')}</td>
																<td>{o.noleggiatore}</td>
																<td>{o.durata} MESI</td>
																<td>{o.is_ecobonus ? <FaCheck /> : ''}</td>
															</tr>
														))}
													</tbody>
												</Table>
											) : (
												<div className="m-4 text-muted">
													{t('no_offers_linked_to_this_vehicle')}
												</div>
											)}
										</Card>
									</div>

									<Row className="mt-4">
										<Col md={6}>
											<Form.Group className="mb-3">
												<Form.Label>
													{t('rental_fee')}
													<span className="red-ast">*</span>
												</Form.Label>

												<Row>
													<Col md={6}>
														<Form.Check
															onChange={handleChange}
															onBlur={handleBlur}
															value={'company'}
															checked={values.offerType === 'company'}
															name="offerType"
															type="radio"
															label="Azienda"
															className="hor-check-box-big"
														/>
													</Col>
													<Col md={6}>
														<Form.Check
															onChange={handleChange}
															onBlur={handleBlur}
															value={'private'}
															checked={values.offerType === 'private'}
															name="offerType"
															type="radio"
															label="Privato"
															className="hor-check-box-big"
														/>
													</Col>
												</Row>
											</Form.Group>
										</Col>

										<Col md={6}>
											<Form.Group className="mb-3">
												<Form.Label>Servizi inclusi</Form.Label>
												<Form.Control
													as="textarea"
													onChange={handleChange}
													onBlur={handleBlur}
													name="services"
													value={values.services}
													type="text"
													disabled={true}
												/>
											</Form.Group>
										</Col>
									</Row>
								</>
							);
						}
						if (modalType === 'offerDocuments') {
							body = (
								<>
									{observer}
									<Table striped borderless hover responsive>
										<thead className="border-bottom">
											<tr>
												<th>{t('name')}</th>
												<th></th>
											</tr>
										</thead>
										<tbody className="border-bottom">
											{details.map((row: string, i: number) => (
												<tr key={i}>
													<td>{row}</td>
													<td className="text-end">
														<button
															onClick={async () => {
																const downloadLink: string = await Storage.get(
																	row,
																	{
																		bucket:
																			process.env
																				.REACT_APP_AWS_STORAGE_BUCKET_OFFER,
																	}
																);

																window.open(downloadLink, '_blank');
															}}
															type="button"
															className="btn btn-sm"
														>
															<FaDownload />
														</button>
													</td>
												</tr>
											))}
										</tbody>
									</Table></>
							);
						}
						if (modalType === 'toggleInEvidenza') {
							body = (
								<p className="m-0">{inEvidenza ? `Sei sicuro di voler togliere dai veicoli in evidenza questo veicolo?` : `Sei sicuro di voler mettere in evidenza questo veicolo?`}</p>
							);
						}
						if (modalType === 'createAreaAcademyFolder' || modalType === 'createAreaMarketingFolder') {
							body = (
								<>
									<div className='createAreaFolder'>
										<div className="form-layout">
											<Col md={12} className="pe-1">
												<Form.Group className="mb-3">
													<Form.Label>
														{t('folder_name')}
													</Form.Label>
													<Form.Control
														onChange={handleChange}
														onBlur={handleBlur}
														name="folderName"
														value={values.folderName}
														type="text"
														id="folderName"
													/>
													{errors.folderName && touched.folderName && (
														<small className="text-danger">
															{errors.folderName as string}
														</small>
													)}
												</Form.Group>
											</Col>
										</div>
									</div>
								</>
							);
						}
						if (modalType === 'editAreaAcademyFolder' || modalType === 'editAreaMarketingFolder') {
							body = (
								<div className='editAreaFolder'>
									<div className="form-layout">
										<Col md={12} className="pe-1">
											<Form.Group className="mb-3">
												<Form.Label>
													{t('folder_name')}
												</Form.Label>
												<Form.Control
													onChange={handleChange}
													onBlur={handleBlur}
													name="folderName"
													value={values.folderName}
													type="text"
													id="folderName"
												/>
												{errors.folderName && touched.folderName && (
													<small className="text-danger">
														{errors.folderName as string}
													</small>
												)}
											</Form.Group>
										</Col>
									</div>
								</div>
							);
						}
						if (modalType === 'deleteAreaAcademyObject' || modalType === 'deleteAreaMarketingObject') {
							body = (
								body = (
									<p className="m-0">{modalBody}</p>
								)
							);
						}
						if (modalType === 'addAreaAcademyVideo' || modalType === 'addAreaMarketingVideo') {
							body = (
								<>
									<div className='addAreaVideo'>
										<div className="form-layout">
											<Col md={12} className="pe-1">
												<Form.Group className="mb-3">
													<Form.Label>
														{t('link')}
													</Form.Label>
													<Form.Control
														onChange={handleChange}
														onBlur={handleBlur}
														name="videoLink"
														value={values.videoLink}
														type="text"
														id="videoLink"
													/>
													{errors.videoLink && touched.videoLink && (
														<small className="text-danger">
															{errors.videoLink as string}
														</small>
													)}

												</Form.Group>
											</Col>
											<Col md={12} className="pe-1">
												<Form.Group className="mb-3">
													<Form.Label>
														{t('title')}
													</Form.Label>
													<Form.Control
														onChange={handleChange}
														onBlur={handleBlur}
														name="videoTitle"
														value={values.videoTitle}
														type="text"
														id="videoTitle"
													/>
													{errors.videoTitle && touched.videoTitle && (
														<small className="text-danger">
															{errors.videoTitle as string}
														</small>
													)}
												</Form.Group>
											</Col>

										</div>
									</div>

								</>
							);
						}
						// keep this in case the customer will need to edit link and title of the video
						if (modalType === 'editAreaAcademyVideo' || modalType === 'editAreaMarketingVideo') {
							body = (
								<div className='editAreaVideo'>
									<div className="form-layout">
										<Col md={12} className="pe-1">
											<Form.Group className="mb-3">
												<Form.Label>
													{t('video_title')}
												</Form.Label>
												<Form.Control
													onChange={handleChange}
													onBlur={handleBlur}
													name="videoTitle"
													value={values.videoTitle}
													type="text"
													id="videoTitle"
												/>
												{errors.videoTitle && touched.videoTitle && (
													<small className="text-danger">
														{errors.videoTitle as string}
													</small>
												)}
											</Form.Group>
										</Col>
										<Col md={12} className="pe-1">
											<Form.Group className="mb-3">
												<Form.Label>
													{t('link_video')}
												</Form.Label>
												<Form.Control
													onChange={handleChange}
													onBlur={handleBlur}
													name="videoLink"
													value={values.videoLink}
													type="text"
													id="videoLink"
												/>
												{errors.videoLink && touched.videoLink && (
													<small className="text-danger">
														{errors.videoLink as string}
													</small>
												)}
											</Form.Group>
										</Col>
									</div>
								</div>
							);
						}
						if (modalType === 'uploadAreaMarketingFileModal' || modalType === 'uploadAreaAcademyFileModal') {
							body = (
								<>
									<div className='uploadAreaFile'>
										<div className="form-layout">
											<FileUploader
												classes="dropzone"
												handleChange={(file) => {
													setUploadError(null);
													setFile(file);
												}}
												onTypeError={() => {
													setFile(new File([""], ''));
													setUploadError(i18next.t('unsupported_file'));
												}}
												name="file"
											>
												<div className="fs-4">
													<div>
														{t('click_or_drag_file')}
													</div>
												</div>
												{file?.size > 0 ? (
													<ul className="my-5">
														<li key="name"> {file.name} </li>
													</ul>
												) : (
													''
												)}
												{uploadError ? (
													<p className="mt-4 text-danger">{uploadError}</p>
												) : (
													''
												)}
											</FileUploader>
											{file.size > 0 ? (
												<div className='d-flex justify-content-end text-right'>
													<Button
														variant="danger"
														onClick={() => {
															setFile(new File([""], ''));
														}}
														className="my-3 btn btn-sm btn-outline file-uploader-clear-btn"
														type="button"
													>
														{t('delete')}
													</Button>
												</div>
											) : (
												''
											)}
											<Col md={6}>
												<Form.Group className="my-3">
													<Form.Label>
														{t('can_download_file')}?
													</Form.Label>
													<Form.Check
														name="can_download"
														type="switch"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.can_download}
														checked={values.can_download}
														disabled={false}
														className="hor-form-check-switch"
													/>
												</Form.Group>
											</Col>
										</div>
									</div>
								</>
							);
						}
						if (modalType === 'requestInformations') {
							body = (
								<>
									<div className='request-quotation'>
										<div className="form-layout">
											<Col md={12} className="pe-1">
												<Form.Group className="mb-3">
													<Form.Label>
														{t('total_km')}
													</Form.Label>
													<Form.Control
														onChange={handleChange}
														onBlur={handleBlur}
														name="km"
														value={values.km}
														type="number"
														id="km"
													/>
													{errors.km && touched.km && (
														<small className="text-danger">
															{errors.km as string}
														</small>
													)}

												</Form.Group>
											</Col>
											<Col md={12} className="pe-1">
												<Form.Group className="mb-3">
													<Form.Label>
														{t('duration')}
													</Form.Label>
													<Form.Control
														onChange={handleChange}
														onBlur={handleBlur}
														name="durata"
														value={values.durata}
														type="number"
														id="durata"
													/>
													{errors.durata && touched.durata && (
														<small className="text-danger">
															{errors.durata as string}
														</small>
													)}

												</Form.Group>
											</Col>
											<Col md={12} className="pe-1">
												<Form.Group className="mb-3">
													<Form.Label>
														{t('downpayment')}
													</Form.Label>
													<Form.Control
														onChange={handleChange}
														onBlur={handleBlur}
														name="anticipo"
														value={values.anticipo}
														type="number"
														id="anticipo"
													/>
												</Form.Group>
											</Col>


											{/* RCA */}
											<Col md={12} className="pe-1">
												<Form.Group className="mb-3">
													<Row>
														<Col md={6} className="pe-1">
															<Form.Label>
																{t('liability_insurance')}
															</Form.Label>
															<Form.Select aria-label="" onChange={e => {
																const newValue = e.target.value;
																setStatoServizi(prev => ({
																	...prev,
																	rca: newValue === 'si'
																}));
															}}>
																<option value="no">{t('no').toUpperCase()}</option>
																<option value="si">{t('yes').toUpperCase()}</option>
															</Form.Select>
														</Col>
														<Col md={6} className="pe-1">

															<Form.Label>
																{t('deductible')}
															</Form.Label>
															<Form.Control
																onChange={handleChange}
																onBlur={handleBlur}
																name="servizi.rca.value"
																value={values?.servizi?.rca.value}
																type="text"
																id="rca"
																disabled={!statoServizi?.rca ? true : false}
															/>
														</Col>
													</Row>
												</Form.Group>
											</Col>
											{/* kasko */}
											<Col md={12} className="pe-1">
												<Form.Group className="mb-3">
													<Row>
														<Col md={6} className="pe-1">
															<Form.Label>
																{t('comprehensive_insurance')}
															</Form.Label>
															<Form.Select aria-label="" onChange={e => {
																const newValue = e.target.value;
																setStatoServizi(prev => ({
																	...prev,
																	kasko: newValue === 'si'
																}));
															}}>
																<option value="no">{t('no').toUpperCase()}NO</option>
																<option value="si">{t('yes').toUpperCase()}SI</option>
															</Form.Select>
														</Col>
														<Col md={6} className="pe-1">
															<Form.Label>
																{t('deductible')}
															</Form.Label>
															<Form.Control
																onChange={handleChange}
																onBlur={handleBlur}
																name="servizi.kasko.value"
																value={values.servizi.kasko.value}
																type="text"
																id="kasko"
																disabled={!statoServizi.kasko ? true : false}
															/>
														</Col>
													</Row>
												</Form.Group>
											</Col>

											{/* furto incendio */}
											<Col md={12} className="pe-1">
												<Form.Group className="mb-3">
													<Row>
														<Col md={6} className="pe-1">
															<Form.Label>
																{t('theft_fire')}
															</Form.Label>
															<Form.Select aria-label="" onChange={e => {
																const newValue = e.target.value;
																setStatoServizi(prev => ({
																	...prev,
																	furto_incendio: newValue === 'si'
																}));
															}}>
																<option value="no">{t('no').toUpperCase()}NO</option>
																<option value="si">{t('yes').toUpperCase()}SI</option>
															</Form.Select>
														</Col>
														<Col md={6} className="pe-1">

															<Form.Label>
																{t('deductible')}
															</Form.Label>
															<Form.Control
																onChange={handleChange}
																onBlur={handleBlur}
																name="servizi.furto_incendio.value"
																value={values.servizi.furto_incendio.value}
																type="text"
																id="furto_incendio"
																disabled={!statoServizi.furto_incendio ? true : false}
															/>
														</Col>
													</Row>
												</Form.Group>
											</Col>

											{/* Gomme */}
											<Col md={12} className="pe-1">
												<Form.Group className="mb-3">
													<Row>
														<Col md={6} className="pe-1">
															<Form.Label>
																{t('tyres')}
															</Form.Label>
															<Form.Select aria-label="" onChange={e => {
																const newValue = e.target.value;
																setStatoServizi(prev => ({
																	...prev, // Spread the previous state to retain other properties
																	gomme: newValue === 'si' // Update the 'gomme' property based on the selected value
																}));
															}}>
																<option value="no">{t('no').toUpperCase()}NO</option>
																<option value="si">{t('yes').toUpperCase()}SI</option>
															</Form.Select>
														</Col>
														<Col md={6} className="pe-1">

															<Form.Label>
																{t('tyres_quantity')}
															</Form.Label>
															<Form.Control
																onChange={handleChange}
																onBlur={handleBlur}
																name="servizi.gomme.value"
																value={values.servizi.gomme.value}
																type="number"
																id="gomme"
																disabled={!statoServizi.gomme ? true : false}
															/>
														</Col>
													</Row>
												</Form.Group>
											</Col>
											{/* Auto Sostitutiva */}
											<Col md={12} className="pe-1">
												<Form.Group className="mb-3">
													<Row>
														<Col md={6} className="pe-1">
															<Form.Label>
																{t('replacement_vehicle')}
															</Form.Label>
															<Form.Select aria-label="" onChange={e => {
																const newValue = e.target.value;
																setStatoServizi(prev => ({
																	...prev,
																	auto_sostitutiva: newValue === 'si'
																}));
															}}>
																<option value="no">{t('no').toUpperCase()}NO</option>
																<option value="si">{t('yes').toUpperCase()}SI</option>
															</Form.Select>
														</Col>
														<Col md={6} className="pe-1">
															<Form.Label>
																{t('category')}
															</Form.Label>
															<Form.Control
																onChange={handleChange}
																onBlur={handleBlur}
																name="servizi.auto_sostitutiva.value"
																value={values.servizi.auto_sostitutiva.value}
																type="text"
																id="auto_sostitutiva"
																disabled={!statoServizi.auto_sostitutiva ? true : false}
															/>
														</Col>
													</Row>
												</Form.Group>
											</Col>
											{/* <Col md={12} className="pe-1">
											<Form.Group className="mb-3">
												<Form.Label>
													Servizi
												</Form.Label>
												<Select
													onBlur={handleBlur}
													onChange={(opt) => {
														setFieldValue('elenco_servizi', opt)
													}}
													name="servizi"
													closeMenuOnSelect={false}
													components={animatedComponents}
													defaultValue={[]}
													isMulti
													options={servizi} />
												{errors.elenco_servizi && touched.elenco_servizi && (
													<small className="text-danger">
														{errors.elenco_servizi as string}
													</small>
												)}

											</Form.Group>
										</Col> */}
											<Col md={12} className="pe-1">
												<Form.Group className="mb-3">
													<Form.Label>
														{t('generic_notes')}
													</Form.Label>
													<Form.Control
														onChange={handleChange}
														onBlur={handleBlur}
														name="note_generiche"
														value={values.note_generiche}
														type="text"
														id="note_generiche"
													/>

												</Form.Group>
											</Col>

										</div>
									</div>

								</>
							);
						}
						if (modalType === 'viewVehicleOptionals') {
							body = (
								<ul>
									{details?.optionals?.map((optional, index) => {
										return (
											<li key={index}>{optional}</li>
										)
									})}
								</ul>
							);
						}
						if (modalType === "downloadQuotationPdf") {
							body = (
								<>
									<Col md={12} className="pe-1">
										<Form.Group className="mb-3">
											<Form.Label>
												{t('quotation_recipient')}
												<span className="red-ast">*</span>
											</Form.Label>
											<Form.Control
												onChange={handleChange}
												onBlur={handleBlur}
												name="name"
												value={values.name}
												type="text"
												id="name"
												className={`${errors.name && touched.name ? 'error-border' : ''
													}`}
											/>
											{errors.name && touched.name && (
												<small className="text-danger">
													{errors.name as string}
												</small>
											)}
										</Form.Group>
									</Col>

									{/* Radio buttons for selecting between "Privati" and "Azienda" */}
									<Col md={12} className="pe-1">
										<Form.Group className="mb-3">
											<Form.Label>{t('client_type')}</Form.Label>
											<div>
												<Form.Check
													inline
													label="Privati"
													type="radio"
													id="privati"
													name="clientType"
													value="private"
													onChange={handleChange}
													checked={values.clientType === 'private'}
												/>
												<Form.Check
													inline
													label="Azienda"
													type="radio"
													id="azienda"
													name="clientType"
													value="company"
													onChange={handleChange}
													checked={values.clientType === 'company'}
												/>
											</div>
										</Form.Group>
									</Col>
								</>
							)

						}

						return (
							<Form onSubmit={handleSubmit}>
								<Modal.Header closeButton>
									<Modal.Title>{modalTitle}</Modal.Title>
								</Modal.Header>
								<Modal.Body>{body}</Modal.Body>
								<Modal.Footer>
									<Button variant="link" onClick={onHideFn} disabled={isSubmitting}>
										{t('close')}
									</Button>

									{modalType === 'historyActions' ? (
										<Restricted to={[Roles.Backoffice, Roles.Master, Roles.Admin]}>
											<Btn
												type="button"
												onClick={exportHistory}
												icon="arrow-right"
											>
												{t('export')}
											</Btn>
										</Restricted>
									) : (
										''
									)}

									{/* Add all the modalTypes that should not include the action button */}
									{['offerDocuments', 'historyActions', 'viewVehicleOptionals'].includes(modalType) ? (
										''
									) : (
										<Btn
											type="submit"
											icon="arrow-right"
											disabled={isSubmitting}
										>

											{actionButton || 'Salva'}
										</Btn>
									)}
								</Modal.Footer>
							</Form>
						);
					}}
				</Formik>)}
		</Modal>
	);
}

export default OptionActionModal;
