import { Accordion } from 'react-bootstrap';
import {
	useTable,
	useGlobalFilter,
	useFilters,
	useSortBy,
	usePagination,
	TableOptions,
	useBlockLayout,
	Row,
	Cell,
} from 'react-table';

import VehicleFilters from './components/VehicleFilters';
import VehicleTable from './components/VehicleTable';
import VehiclePagination from './components/VehiclePagination';
import './VehicleTable.scss';

interface TableContainerProps {
	columns: any;
	data: any;
	loading: any;
	setDownloadData?: any;
	error: any;
	presetFilter?: any;
}

const VehicleTableContainer: React.FC<TableContainerProps> = ({
	columns,
	data,
	loading,
	error,
	setDownloadData = null,
	presetFilter = null,
}) => {
	const tableOptions: TableOptions<Object> = {
		columns,
		data,
		initialState: { pageSize: 10, hiddenColumns: ['id'] },
	};

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		setAllFilters,
		page,
		rows,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
	} = useTable(
		tableOptions,
		useGlobalFilter,
		useFilters,
		useSortBy,
		usePagination,
		useBlockLayout
	);

	if (!!setDownloadData) {
		if (data?.length) {
			const cells: Cell[][] = rows
				.map((row: Row<Object>) => {
					prepareRow(row);
					return row;
				})
				.map((row: Row<Object>) => row.cells);

			const csvData: any[][] = [];

			if (cells.length) {
				csvData.push(cells[0].map((cell: Cell) => cell.render('Header')));
			}

			csvData.push(
				...cells.map((cell: Cell[]) =>
					cell.map((cell: Cell) => {
						if (cell.value && typeof cell.value === 'string') {
							cell.value = cell.value.replace(/\n/g, ' ');
						}

						if (cell.column.id === 'offerta_minima') {
							if (cell.value?.valore) {
								return `€${cell.value?.valore} (${cell.value?.noleggiatore})`;
							} else {
								return '';
							}
						} else {
							return cell.value || '';
						}
					})
				)
			);

			setDownloadData(csvData);
		}
	}

	return (
		<>
			<Accordion className="mt-4" flush>
				<VehicleFilters
					headerGroups={headerGroups}
					setAllFilters={setAllFilters}
					loading={loading}
					presetFilter={presetFilter}
				></VehicleFilters>
			</Accordion>

			{!loading && !error && (
				<>
					<VehicleTable
						getTableProps={getTableProps}
						headerGroups={headerGroups}
						getTableBodyProps={getTableBodyProps}
						page={page}
						prepareRow={prepareRow}
						resultCount={rows.length}
					></VehicleTable>

					<VehiclePagination
						gotoPage={gotoPage}
						canPreviousPage={canPreviousPage}
						previousPage={previousPage}
						nextPage={nextPage}
						canNextPage={canNextPage}
						pageCount={pageCount}
						state={state}
						pageOptions={pageOptions}
						setPageSize={setPageSize}
					></VehiclePagination>
				</>
			)}

			{error && (
				<>
					<div className="table-error-message">{error.message}</div>
				</>
			)}
		</>
	);
};

export default VehicleTableContainer;