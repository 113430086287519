import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../Icon/Icon';
import dayjs from 'dayjs';
import { FORMAT_DATE_TIME } from '../../utils/constants';
import i18next from 'i18next';  // Import the i18next instance


const columnsArray = [
	{
		accessor: 'RecordType.Name',
		id: 'Type', 
		Header: 'Tipo',
		disableSortBy: true,
	},
	{
		accessor: 'Name',
		id: 'Name',
		Header: i18next.t('name'),
		disableSortBy: true,
	},
	{
		accessor: 'CreatedDate',
		id: 'CreatedDate',
		Header: i18next.t('date'),
		Cell: (({ value }) => {
			const date = dayjs(value);
			return date.format(FORMAT_DATE_TIME)
		})
	},
	{
		accessor: 'Phone',
		id: 'Phone',
		Header: i18next.t('phone'),
		disableSortBy: true,
	},
	{
		accessor: 'cf_piva',
		id: 'cf_piva',
		Header:  i18next.t('cf_piva_short'),
		disableSortBy: true,
	},
	{
		accessor: 'Email__c',
		id: 'Email__c',
		Header:  i18next.t('email'),
		disableSortBy: true,
	},
	{
		width: 60,
		accessor: 'actions',
		id: 'actions',
		Header: '',
		disableSortBy: true,
		headerClassName: 'column-sticky',
		className: 'column-sticky text-end',
		Cell: (data: any) => {
			return (
				<Link
					to={`/my-accounts/${data.row.original.Id}`}
					className="btn"
				>
					<Icon
						style={{ width: '1.2rem', height: '1.2rem' }}
						name="arrow-right"
					/>
				</Link>
			)
		},
	},
];

export default columnsArray;
