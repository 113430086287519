import { useState, useEffect, useMemo } from 'react';
import { Form, Button, Card, Col, Container, Row, Spinner, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';
import { FaPlus } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import _ from 'lodash'
import i18next from 'i18next';

import columnsData from '../../components/AccountList/columnsData';
import Restricted from '../../components/Restricted/Restricted';
import toaster from '../../utils/toaster';
import Btn from '../../components/Btn/Btn';
import ServerPaginatedTable from '../../components/ServerPaginatedTable/ServerPaginatedTable';
import TableSkeleton from '../../components/TableSkeleton/TableSkeleton';
import { useApiClient } from '../../hooks/useApiClient';
import { AccountTypeOption, ReactSelectOption, Roles } from '../../types';
import { AccountEntitySearch } from '../../api/models/AccountEntity';
import './AccountListPage.scss';

type FilterQuery = {
	q: string;
	recordType: ReactSelectOption | null;
	orderDate: string
}

const AccountListPage = () => {
	const GENERIC_ERROR = i18next.t('error_loading_data');
	const { apiClient, handleApi } = useApiClient();
	const [filterQuery, setFilterQuery] = useState<FilterQuery>({ q: '', recordType: null, orderDate: 'desc' })
	const [response, setResponse] = useState<any>(null);
	const [accountTypes, setAccountTypes] = useState<AccountTypeOption[]>([]);
	const [loading, setLoading] = useState(true);
	const [tableIsBusy, setTableIsBusy] = useState<boolean>(false)
	const [error, setError] = useState<any>(null);
	const navigate = useNavigate();
	const { t } = useTranslation()

	const refreshTable = (opts = { skip: 0, limit: 20 }) => {
		const values = filterQuery;
		setTableIsBusy(true)


		handleApi(apiClient.accountController.getAccounts({
			q: values.q,
			record_type: values.recordType?.value,
			orderDate: values.orderDate,
			...opts,
		}))
			.then(res => {
				res.data = res.data.map((el: AccountEntitySearch) => {
					return { ...el, cf_piva: el.Codice_Fiscale__c || el.Partita_IVA__c || '' }
				})

				setResponse(res)
				setError(null)
			})
			.catch(err => {
				if (err.body.messageList.includes('custom:reference_partner not found')) {
					setError('dealer_partner_not_found')
				} else {
					toaster.error(err.message || GENERIC_ERROR);
				}
			})
			.finally(() => {
				setTableIsBusy(false)
			})
	}

	useEffect(() => {
		setError(null)

		Promise.all([
			handleApi(apiClient.accountController.getAccountTypes())
		])
			.then(res => {
				let [accTypesRes] = res;

				let accTyps = accTypesRes.data.map(el => {
					return { label: el.Name, value: el.Id }
				});

				setAccountTypes(accTyps);
			})
			.catch((err) => {
				if (err.body.messageList.includes('dealer partner not found')
					|| err.body.messageList.includes('dealer_partner_not_found')
					|| err.body.messageList.includes('custom:reference_partner not found')
				) {
					setError('dealer_partner_not_found')
				} else {
					toaster.error(err.message || GENERIC_ERROR);
				}
			})
			.finally(() => {
				setLoading(false);
			});

		return () => { };
	}, []);

	useEffect(() => {
		refreshTable();
	}, [filterQuery.orderDate, filterQuery.recordType])

	const columns = useMemo(() => columnsData as Column<Object>[], []);
	const data = useMemo(() => response?.data ?? [], [response]);

	return (
		<Container fluid>
			<Restricted
				to={[
					Roles.Master,
					Roles.Backoffice,
					Roles.Sales,
					Roles.Dealer,
					Roles.Admin,
				]}
			>
				<Row className='mb-4'>
					<Col md={6} className="display-6">
						{t('accounts_list')}
					</Col>
					<Col md={6} className="mt-4 mt-md-0 text-md-end">
						<Btn
							disabled={error}
							onClick={() => {
								navigate('/my-accounts/new')
							}}
						>
							<FaPlus />
							{t('create_new_account')}
						</Btn>
					</Col>
				</Row>

				{!error && (
					<Row>
						<Col>
							<Card>
								<Card.Body>
									<Form onSubmit={(e) => {
										e.preventDefault()
										refreshTable()
									}}>
										<Row className='mb-3'>
											<Col md={3}>
												<Form.Group>
													<Select
														isClearable
														name="recordType"
														placeholder={t('select_type')}
														options={accountTypes}
														value={filterQuery.recordType}
														onChange={(opt: any) => {
															const filter = { ...filterQuery, recordType: opt }
															setFilterQuery(filter)
														}}
													/>
												</Form.Group>
											</Col>
											<Col md={6}>
												<Form.Group>
													<Form.Control
														onChange={({ target }) => {
															setFilterQuery({ ...filterQuery, q: target.value })
														}}
														placeholder={t('search_account')}
														// TODO: //Cerca account (nome, email, cf o p.iva...)
														type="search"
														value={filterQuery.q}
														name='q'
													/>
												</Form.Group>
											</Col>
										</Row>
										<Row>
											<Col>
												<Button disabled={tableIsBusy} type='submit'>
													{tableIsBusy ? (<Spinner animation='border' size='sm' />) : t('search')}
												</Button>
											</Col>
										</Row>
									</Form>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				)}

				{loading ? <TableSkeleton /> : (
					<>
						{error === 'dealer_partner_not_found' ? (
							<div className="mt-3">
								<Alert>
									{t('user_has_no_specified_reference_partner')}
								</Alert>
							</div>
						) : (
							<ServerPaginatedTable
								forceMaxResults={2000}
								onSortChange={(rules: any) => {
									rules = _.groupBy(rules, 'id');

									if (rules.CreatedDate) {
										const filter = { ...filterQuery, orderDate: rules.CreatedDate[0].desc ? 'desc' : 'asc' }
										setFilterQuery(filter);
									}
								}}
								totalCount={response ? response.totalCount : 0}
								loadFn={(skip, limit) => {
									refreshTable({ skip, limit });
								}}
								columns={columns}
								data={data}
								loading={tableIsBusy}
								error={null}
							></ServerPaginatedTable>
						)}
					</>
				)}
			</Restricted>
		</Container >
	);
};

export default AccountListPage;
