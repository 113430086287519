import { FaCheck, FaFilePdf, FaLink, FaMinus } from 'react-icons/fa';
import { formatDate } from '../../utils';
import i18next from 'i18next';

export const columnsData = [
	{
		accessor: 'id',
		id: 'id',
		Header: '',
		disableSortBy: true,
	},
	{
		accessor: 'importo_privato',
		id: 'importo_privato',
		Header: i18next.t('private_rental_fee').toUpperCase(),
		width: 120,
		Cell: ({ value }) =>
			<div className="h5 m-0">
				{value ? (
					<div className="text-nowrap">
						€{' '}
						{value?.toLocaleString('it', {
							minimumFractionDigits: 2,
						})}
					</div>
				) : (
					'-'
				)}
			</div>,
	},
	{
		accessor: 'importo_azienda',
		id: 'importo_azienda',
		Header: i18next.t('business_rental_fee').toUpperCase(),
		width: 120,
		Cell: ({ value }) =>
			<div className="h5 m-0">
				{value ? (
					<div className="text-nowrap">
						€{' '}
						{value?.toLocaleString('it', {
							minimumFractionDigits: 2,
						})}
					</div>
				) : (
					'-'
				)}
			</div>,
	},
	{
		accessor: 'anticipo',
		id: 'anticipo',
		Header: i18next.t('downpayment').toUpperCase(),
		width: 120,
		Cell: ({ value }) =>
			value
				? `€ ${value?.toLocaleString('it',{
						minimumFractionDigits: 2,
				  })}`
				: '',
	},
	{
		accessor: 'km_totali',
		id: 'km_totali',
		Header: i18next.t('km_per_year').toUpperCase(),
		width: 120,
		Cell: ({ value }) => value.toLocaleString('it'),
	},
	{
		accessor: 'noleggiatore',
		id: 'noleggiatore',
		Header: i18next.t('rental_company').toUpperCase(),
		width: 120,
	},
	{
		accessor: 'durata',
		id: 'durata',
		Header: i18next.t('duration').toUpperCase(),
		width: 120,
		Cell: ({ value }) =>
			value
				? `${value} MESI` : '',
	},
	{
		accessor: 'is_ecobonus',
		id: 'is_ecobonus',
		Header: i18next.t('ecobonus').toUpperCase(),
		width: 120,
		Cell: ({ value }) =>
			value
				? <FaCheck /> : '',
	},
	{
		accessor: 'provvigioni',
		id: 'provvigioni',
		Header: i18next.t('commissions').toUpperCase(),
		width: 120,
	},
	{
		accessor: 'pdfLinks',
		id: 'pdfLinks',
		Header: i18next.t('documents').toUpperCase(),
		type: 'document',
		width: 120,
		Cell: ({ value }) => (value.length ? (
				<FaFilePdf />
		) : (
			<FaMinus />
		)),
	},
	{
		accessor: 'link_offerta',
		id: 'link_offerta',
		Header: i18next.t('offer_link').toUpperCase(),
		width: 120,
		Cell: ({ value }) => (value ? (
				<a
					target="_blank"
					title={i18next.t('link_to_horizon_website')}
					href={value}
					rel="noreferrer"
				>
					<FaLink />
				</a>
			) : (
				''
			)),
	},
	{
		accessor: 'data_valid',
		id: 'data_valid',
		Header: i18next.t('validity_date').toUpperCase(),
		width: 120,
		Cell: ({ value }) => value ? formatDate(value, 'it-IT') : '',
	},
	{
		accessor: 'sconto_fattura',
		id: 'sconto_fattura',
		Header: i18next.t('invoice_discount').toUpperCase(),
		width: 120,
	},
	{
		accessor: 'sconto_extra',
		id: 'sconto_extra',
		Header: i18next.t('extra_discount').toUpperCase(),
		width: 120,
	},
	{
		accessor: 'id_quotazione',
		id: 'id_quotazione',
		Header: i18next.t('flag_rtc').toUpperCase(),
		width: 120,
				Cell: ({ value }) => (!value || value.toLowerCase() === 'no') ? 'no' : 'si',
	},
	{
		accessor: 'id_offerta',
		id: 'id_offerta',
		Header: i18next.t('offer_id').toUpperCase(),
		width: 120,
	},
	{
		accessor: 'servizi.auto_sostitutiva',
		id: 'auto_sostitutiva',
		Header: i18next.t('replacement_vehicle').toUpperCase(),
		width: 120,
	},
	{
		accessor: 'servizi.treno_di_gomme',
		id: 'treno_di_gomme',
		Header: i18next.t('set_of_tyres').toUpperCase(),
		width: 120,
	},
	{
		accessor: 'servizi.kasko',
		id: 'kasko',
		Header: i18next.t('kasko').toUpperCase(),
		width: 120,
		Cell: ({ value }) =>
			value
				? `€ ${value?.toLocaleString('it',{
						minimumFractionDigits: 2,
				  })}`
				: '',
	},
	{
		accessor: 'servizi.rca',
		id: 'rca',
		Header: i18next.t('rca').toUpperCase(),
		width: 120,
		Cell: ({ value }) =>
			value
				? `€ ${value?.toLocaleString('it',{
						minimumFractionDigits: 2,
				  })}`
				: '',
	},
	{
		accessor: 'servizi.i_f',
		id: 'i_f',
		Header: i18next.t('i_f').toUpperCase(),
		width: 120,
		Cell: ({ value }) =>
			value
				? `${value?.toLocaleString('it',{
						minimumFractionDigits: 2,
				  })}`
				: '',
	}

];

