import React from 'react'
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useAiContext } from '../../context/AiContext';

const ButtonSelect = () => {
  const { activeStep, setActiveStep} = useAiContext();
  const { t } = useTranslation()

  return (
    <Button
      className="calc-btn"
      onClick={() => {
        if(activeStep < 3) {
          setActiveStep((prev) => prev + 1)
        }
        // TODO save the vehicle to the context or redux store
        // GTMsendEvent({
        //   event: 'CTA_storico',
        //   evtCat: 'storico',
        //   evtAct: 'click',
        //   evtLab: response.dati_veicolo.allestimento
        // });
      }}
    >
      {t('choose')}.toUpperCase()

    </Button>)
}

export default ButtonSelect