import { Navigate } from 'react-router-dom';

import { useAuth } from '../../hooks/useAuth';
import { Roles } from '../../types';

const RestrictedRoute = ({ to, children }) => {
	const { userRole } = useAuth();

	if (userRole === Roles.Admin) {
		return <>{children}</>;
	}

	if (to.includes(userRole)) {
		return <>{children}</>;
	}

	return <Navigate to="/not-authorized" replace />;
};

export default RestrictedRoute;
