import { useState, useEffect, useMemo } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import _ from 'lodash';
import { ExportToCsv } from 'export-to-csv';
import { useLocation, useNavigate } from 'react-router-dom';
import { Column } from 'react-table';
import { FaFileCsv, FaPlus } from 'react-icons/fa';
import { useApiClient } from '../../hooks/useApiClient';
import VehicleTableContainer from '../../components/VehicleTable/VehicleTableContainer';
import { columnsData } from '../../components/VehicleTable/columnsData';
import { columnsDataCompany } from '../../components/VehicleTable/columnsDataCompany';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import toaster from '../../utils/toaster';
import Restricted from '../../components/Restricted/Restricted';
import { Roles } from '../../types';
import { GTMsendEvent } from '../../utils/trackingGA4'
import Btn from '../../components/Btn/Btn';
import { useAuth } from '../../hooks/useAuth';
import { useRefresh } from '../../hooks/useRefresh';
import './VehicleListPage.scss'; 
import { normalizeVehicleList } from '../../utils/vehicleList';

const VehicleListPage = () => {
	const { apiClient, handleApi } = useApiClient();
	const location = useLocation();
	const { userRole, user } = useAuth();
	const [response, setResponse]: [any, any] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const navigate = useNavigate();
	let filter = location?.state?.filter;
	const refresh = useRefresh();

	let downloadData: any;

	const setDownloadData = (data: any) => {
		downloadData = data;
	};

	const hanldeExportButton = async () => {
		const options = {
			filename: 'lista_veicoli',
		};

		const csvExporter = new ExportToCsv(options);
		csvExporter.generateCsv(downloadData);

		GTMsendEvent({
			event: "CTA_download_file",
			evtCat: 'lista_veicoli',
			evtAct: "download",
			evtLab: `${options.filename}.csv`
		})
	};

	const addWishlistFunc = (vehicleId) => {
		handleApi(apiClient.vehicleController.postWishlist(vehicleId))
			.then((res) => {
				toaster.success('Il veicolo è stato aggiunto ai preferiti, il tuo consulente Horizon ti contatterà');
				setLoading(false);
				refresh();
			})
			.catch((err) => {
				setError(err);
				setLoading(false);
				toaster.error(
					err.message || 'Errore non specificato durante il salvataggio dei dati'
				);
			});
	};

	const deleteWishlistFunc = (vehicleId) => {
		handleApi(apiClient.vehicleController.deleteWishlist(vehicleId))
			.then((res) => {
				toaster.success('Il veicolo è stato rimosso con successo dai preferiti');
				setLoading(false);
				refresh();
			})
			.catch((err) => {
				setError(err);
				setLoading(false);
				toaster.error(
					err.message || "Errore non specificato durante l'eliminazione dei dati"
				);
			});
	};

	useEffect(() => {
		handleApi(apiClient.vehicleController.getVehiclesFilter())
			.then((res) => {
				res.sort((a, b) => {
					if (a.stato_corrente.type === "DISPONIBILE" && b.stato_corrente.type !== "DISPONIBILE") {
						return -1;
					}
					if (b.stato_corrente.type === "DISPONIBILE" && a.stato_corrente.type !== "DISPONIBILE") {
						return 1;
					}
					return a.data_inserimento > b.data_inserimento ? -1 : b.data_inserimento > a.data_inserimento ? 1 : 0;
				});
				if (userRole === Roles.Azienda) {
					res = res.filter((item) => "DISPONIBILE" === item.stato_corrente.type)
				}

				res = normalizeVehicleList(res);

				handleApi(apiClient.vehicleController.getWishlist(user.username))
					.then((item) => {
						res.forEach((elem) => {
							elem.addWishlist = addWishlistFunc;
							elem.deleteWishlist = deleteWishlistFunc;
							elem.in_wishlist = false;
							item.forEach((row) => {
								if (row.id === elem.id) {
									elem.in_wishlist = true;
								}
							});
						});

						setResponse(res);
						setLoading(false);
					})
					.catch((err) => {
						setError(err);
						setLoading(false);
						toaster.error(
							err.message || 'Errore non specificato durante il caricamento dati'
						);
					});
			})
			.catch((err) => {
				setError(err);
				setLoading(false);
				toaster.error(
					err.message || 'Errore non specificato durante il caricamento dati'
				);
			});

		return () => {
			if (filter) {
				sessionStorage.removeItem('vehicleAvailable-filters');
			}
		}
	}, []);

	const columns = useMemo(() => columnsData as Column<Object>[], []);
	const columnsCompany = useMemo(() => columnsDataCompany as Column<Object>[], []);
	const data = useMemo(() => response ?? [], [response]);

	const mergeFilters = (originalFilter, initialFilter) => {
		const mergedFilter = { ...initialFilter };
		for (const key in originalFilter) {
			if (originalFilter.hasOwnProperty(key) && initialFilter.hasOwnProperty(key)) {
				mergedFilter[key] = originalFilter[key];
			}
		}
		return mergedFilter;
	}

	const initialFilter = {
		stato_corrente: [],
		marca: [],
		stato_disp: [],
		modello: [],
		allestimento: [],
		city: [],
		data_disp: { from: '', to: '' },
		offerta_minima: { from: '', to: '' },
		carrozzeria: [],
		colore_esterno: [],
		colore_interno: [],
		telaio: '',
		data_immatricolazione: { from: '', to: '' },
		targa: '',
		alimentazione: '',
		cambio: '',
		trazione: [],
		nr_ordine: '',
		codice_costruttore: '',
		utente: '',
	};

	let presetFilter;

	if (!_.isEmpty(filter)) {
		presetFilter = mergeFilters(filter, initialFilter);
	}

	return (
		<Container fluid>
			<Row>
				<Col md={6} className="display-6">
					Lista Veicoli
				</Col>

				<Col md={6} className="mt-4 mt-md-0 text-md-end">
					<Button className="btn me-3" onClick={hanldeExportButton}>
						<FaFileCsv className="fs-5" /> SCARICA
					</Button>
					<Restricted to={[Roles.Master, Roles.Admin]}>
						<Btn
							// to="/details/new"
							onClick={() => {
								GTMsendEvent({
									event: 'CTA_add_vehicle',
									evtCat: 'vehicle_list',
									evtAct: 'add',
									evtLab: `vehicle`
								})
								navigate("/details/new");
							}}
						>
							<FaPlus /> Aggiungi veicolo
						</Btn>
					</Restricted>
				</Col>
			</Row>

			<Restricted to={[Roles.Azienda]}>
				<ErrorBoundary>
					<VehicleTableContainer
						columns={columnsCompany}
						data={data}
						setDownloadData={setDownloadData}
						loading={loading}
						error={error}
					></VehicleTableContainer>
				</ErrorBoundary>
			</Restricted>

			<Restricted to={[Roles.Master, Roles.Backoffice, Roles.Sales, Roles.Dealer, Roles.Admin]}>
				<ErrorBoundary>
					<VehicleTableContainer
						columns={columns}
						data={data}
						setDownloadData={setDownloadData}
						loading={loading}
						error={error}
						presetFilter={presetFilter}
					></VehicleTableContainer>
				</ErrorBoundary>
			</Restricted>



		</Container>
	);
};

export default VehicleListPage;
