import { useNavigate, useLocation } from 'react-router-dom';

export const useRefresh = () => {
	const navigate = useNavigate();
	const location = useLocation();

	// https://github.com/remix-run/react-router/issues/7416
	const refresh = () => {
		// window.location.reload();
		// navigate(0);
		navigate('/');
		setTimeout(() => navigate(location.pathname), 10); // , 10
	};

	return refresh;
};

export const useNavigateTo = (path) => {
	const navigate = useNavigate();

	return () => {
		navigate(path);
	};
};
