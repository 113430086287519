import { FieldArray } from "formik";
import { Card, Table, Row, Col, Button, Form } from "react-bootstrap";
import { useMemo } from "react";
import { FaTrash } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

import { OptionalDTO } from "../../api";
import i18next from "i18next";

const OptionalsCard = ({ values, handleChange, addOptionDisabled, onOptionAdded, hideCode = false }) => {
    	const { t } = useTranslation()

    const newOptional: OptionalDTO = useMemo(() => {
        return {
            codice_costruttore: "",
            descrizione: "",
            valore: 0,
        };
    }, []);

    return (
        <Card>
            <Card.Body>
                <FieldArray name="optionals">
                    {({ remove, push }) => (
                        <>
                            <Table responsive="lg" borderless>
                                <thead>
                                    <tr>
                                        {hideCode === false && <th>{t('code')}</th>}
                                        <th className="w-50">{hideCode ? i18next.t('name_and_description') :  i18next.t('description')}</th>
                                        <th>{t('price')}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {values.optionals.map((o: any, i: number) => (
                                        <tr key={i}>
                                            {hideCode === false && (
                                                <td className="align-top">
                                                    <Form.Group>
                                                        <Form.Control
                                                            value={
                                                                values.optionals[i].codice_costruttore
                                                            }
                                                            type="text"
                                                            onChange={handleChange}
                                                            name={`optionals.${i}.codice_costruttore`}
                                                        />
                                                    </Form.Group>
                                                </td>
                                            )}

                                            <td className="align-top">
                                                <Form.Group>
                                                    <Form.Control
                                                        value={values.optionals[i].descrizione}
                                                        type="text"
                                                        onChange={handleChange}
                                                        name={`optionals.${i}.descrizione`}
                                                    />
                                                </Form.Group>
                                            </td>
                                            <td className="align-top">
                                                <Form.Group>
                                                    <Form.Control
                                                        value={values.optionals[i].valore}
                                                        type="number"
                                                        onChange={handleChange}
                                                        name={`optionals.${i}.valore`}
                                                    />
                                                </Form.Group>
                                            </td>
                                            <td className="align-top">
                                                {values.optionals.length > 1 ||
                                                    !addOptionDisabled ? (
                                                    <Button
                                                        onClick={() => {
                                                            if (values.optionals.length === 1) {
                                                                push(newOptional);
                                                            }
                                                            remove(i);
                                                        }}
                                                        variant="light"
                                                        className="btn   btn-sm"
                                                    >
                                                        <FaTrash />
                                                    </Button>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <Row>
                                <Col className="text-center">
                                    <Button
                                        disabled={addOptionDisabled}
                                        onClick={() => {
                                            push(newOptional);
                                            onOptionAdded(newOptional);
                                        }}
                                        variant="light"
                                        className="btn btn-sm"
                                    >
                                       {t('add')}
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )}
                </FieldArray>
            </Card.Body>
        </Card>
    )
}

export default OptionalsCard;