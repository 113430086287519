import { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { Storage } from 'aws-amplify';
import { Button, Breadcrumb, Spinner } from 'react-bootstrap';
import { FaFileDownload } from 'react-icons/fa';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import toaster from '../../utils/toaster';
import { GTMsendEvent } from '../../utils/trackingGA4';
import './EmbedFile.scss';

interface DocViewerProps {
  uri: string
}
interface Item {
  title: string,
  can_download: boolean
}

function EmbedFile() {
  const { title } = useParams<{ title: string }>();
  const navigate = useNavigate()
  const [downloadLink, setDownloadLink] = useState<string>("")
  const [encodedComponentLink, setEncodedComponentLink] = useState<string>("")
  const { state } = useLocation();
  const location = useLocation();
	const { t } = useTranslation()

	const currentURL = location.pathname;
  const [item, setItem] = useState<Item>(state ? state.item : {})
  // const item = state ? state.item : {};
  const flagArea = state ? state.flagArea : "";

  const handleDownloadFile = () => {

    let category = 'no category';

    if(flagArea === 'AreaAcademy') {
      category = 'area_academy'
    }

    if(flagArea === 'AreaMarketing') {
      category = 'area_marketing'
    }

    if(location.pathname.includes("privacy-dealer")) {
      category = 'privacy-dealer'
    }

    if(location.pathname.includes("privacy-dipendenti")) {
      category = 'privacy-dipendenti'
    }

    GTMsendEvent({
      event: "CTA_download_file",
      evtCat: category,
      evtAct: "download",
      evtLab: `${item.title}`
    })

    if (item?.can_download) {
      window.open(downloadLink, '_blank');
    } else {
      toaster.error(i18next.t('cannot_download_file'));
    }
  }

  const handleGoBack = () => {
    navigate(-1)
  }

  useEffect(() => {
    const callFile = async () => {

      // NB
      // privacy files opened from the sidebar do not pass state (flagArea and item) because cannot use Link from react-router
      // since they use the same component <EmbedFile> and each click from sidebar would require a full reaload or the component would not change the state
      // when changing from privacy dealer and privacy dipendenti

      let storageBucket;
      let downloadLink;

      switch (flagArea) {
        case 'AreaAcademy':
          storageBucket = process.env.REACT_APP_AWS_STORAGE_BUCKET_AREA_ACADEMY
          break;
        case 'AreaMarketing':
          storageBucket = process.env.REACT_APP_AWS_STORAGE_BUCKET_AREA_MARKETING
          break;
        case 'TrattamentoDatiDealer':
          storageBucket = process.env.REACT_APP_AWS_STORAGE_BUCKET_VEHICLE
          break;
        case 'TrattamentoDatiDipendenti':
          storageBucket = process.env.REACT_APP_AWS_STORAGE_BUCKET_VEHICLE
          break;

        // default case should catch the privacy links opened from the sidebar that do not pass state
        default:
          storageBucket = process.env.REACT_APP_AWS_STORAGE_BUCKET_VEHICLE;
          // setItem({
          //   can_download: true,
          //   title: title || ""
          // })

          item.can_download = true;
          item.title = title || ""
          break;
      }

      if (item) {
        downloadLink = await Storage.get(
          item?.title, { bucket: storageBucket }
        );
        }
      const encodedComponentLink = encodeURIComponent(downloadLink)
      const encodeURILink = encodeURI(downloadLink)
      setEncodedComponentLink(encodedComponentLink)
      // setDocs([{ uri: encodedComponentLink }])
      // setEncodeURILink(encodeURILink)
      setDownloadLink(downloadLink)
    }
    callFile()
  }, [])

  useEffect(() => {

  } , [item])

  return (
    <div>
      	{!currentURL.includes('/privacy') ?
      <Breadcrumb>
        <Breadcrumb.Item
          linkAs={Link}
          onClick={handleGoBack}
          style={{ position: 'relative', top: '-3px' }}>
          {t('back')}
        </Breadcrumb.Item>
      </Breadcrumb>
      : "" }

      {downloadLink ?
        <div className='d-flex flex-wrap' id='embed-doc'>
          <div className='d-flex flex-wrap justify-content-between w-100 mb-5'>
            <div className='fw-bold fs-2'>{title}</div>
            {item?.can_download ?
              <Button
                className="mx-2 align-text-bottom btn"
                onClick={handleDownloadFile}
              >
                <FaFileDownload className="fs-5" />
                {t('download')}
              </Button>
              : ""}
          </div>

          <div className='iframe-container d-flex w-100 position-relative'>

            <iframe
              src={`https://drive.google.com/viewer?embedded=true&hl=en-US&url=${encodedComponentLink}&t=${Date.now().toString()}`}
              width="500px"
              // height="900px"
              style={{ height: '80vh' }}
            ></iframe>
            <div className='hide-drive-btn'></div>
          </div>
        </div>
        :
        <div className='text-center'>
          <span className='fs-1'> {t('loading')}...</span>
          <Spinner animation="border" variant="info" />
        </div>
      }
    </div>
  )
}

export default EmbedFile