import { LinkToForm } from './LinkToForm';
import i18next from 'i18next';

export const columnsData = [
	{
		accessor: 'id',
		id: 'id',
		Header: '',
		disableSortBy: true,
	},
	{
		accessor: 'ragione_sociale',
		id: 'ragione_sociale',
		Header: i18next.t('business_name'),
		minWidth: 160,
		Cell: ({ value }) => {
			return value.replace('_', ' ');
		},
	},
	{
		accessor: 'indirizzo',
		id: 'indirizzo',
		Header: i18next.t('address'),
		minWidth: 150,
	},
	{
		accessor: 'cf',
		id: 'cf',
		Header: i18next.t('fiscal_code'),
		width: 170,
	},
	{
		accessor: 'p_iva',
		id: 'p_iva',
		Header: i18next.t('p_iva'),
		width: 130,
	},
	{
		accessor: 'tipologia_dealer',
		id: 'tipologia_dealer',
		Header: i18next.t('dealer_typology'),
	},
	{
		accessor: 'marchio',
		id: 'marchio',
		Header: i18next.t('brand'),
	},
	{
		accessor: 'noleggiatori_convenzionati',
		id: 'noleggiatori_convenzionati',
		Header: i18next.t('affiliated_rental_companies'),
		width: 300,
	},
	{
		accessor: 'actions',
		id: 'actions',
		Header: '',
		disableSortBy: true,
		headerClassName: 'column-sticky',
		className: 'column-sticky',
		width: 60,
		Cell: (data: any) => <LinkToForm data={data} />,
	},
];
