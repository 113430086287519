import { useEffect, useMemo, useState } from "react";
import { Alert } from "react-bootstrap";
import { Column } from "react-table";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { useApiClient } from "../../hooks/useApiClient";
import columnsData from "./columnsData";
import toaster from '../../utils/toaster';
import { GetOpportunityQuery } from "../../api/models/AccountEntity";
import ServerPaginatedTable from "../ServerPaginatedTable/ServerPaginatedTable";
import TableSkeleton from "../TableSkeleton/TableSkeleton";

const OpportunitiesTable = ({ query, customColumnsData, dblClickCallback = (row: any) => { }, }: {
    query: GetOpportunityQuery,
    customColumnsData?: Column[]
    dblClickCallback?: any
}) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState<any>(null);
    const { apiClient, handleApi } = useApiClient();
    const { t } = useTranslation()

    useEffect(() => {
        handleApi(apiClient.opportunityController.getOpportunities({ ...query, skip: 0, limit: 20 }))
            .then((res) => {
                //@ts-ignore
                setResponse(res);
            })
            .catch((err) => {
                setError(err);
                toaster.error(
                    err.message || i18next.t('error_loading_data')
                );
            })
            .finally(() => {
                setLoading(false);
            });
    }, [])

    const columns = useMemo(() => customColumnsData || columnsData, []);
    const data = useMemo(() => response ? response.data : [], [response]);

    return (
        <>
            {!loading && response ? (
                <div>
                    {data.length ? (
                        <ServerPaginatedTable
                            dbClickType="single"
                            dblClickCallback={dblClickCallback}
                            totalCount={response.totalCount}
                            columns={columns}
                            forceMaxResults={2000}
                            loadFn={(skip, limit) => {
                                handleApi(apiClient.opportunityController.getOpportunities({ ...query, skip, limit }))
                                    .then((res) => {
                                        //@ts-ignore
                                        setResponse(res);
                                    })
                            }}
                            error={error}
                            data={data}
                            loading={loading}
                        ></ServerPaginatedTable>
                    ) : (
                        <div className="mt-2">
                            <Alert>{t('no_opportunity_found')}</Alert>
                        </div>
                    )}
                </div>
            ) : <TableSkeleton />}
        </>
    )
}

export default OpportunitiesTable;