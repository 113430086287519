import { Col, Row, Accordion, Card, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAiContext } from '../../context/AiContext';

const DisplayOptionals: React.FC = () => {
  const { quotReqState: { vehicleState: { optionals } } } = useAiContext();
  const { t } = useTranslation()

  const selectedOptionals = optionals.map(item => ({ ...item, isChecked: true })).filter(opt => opt.isChecked);
  return (
    <Col id="added_optionals" sm={6}>
      <div className="title">{t('added_optional')}</div>
      {Array.isArray(selectedOptionals) && selectedOptionals.length > 0 ? (
        selectedOptionals.map((option, index) => (
          <div key={index} className="d-flex optional-wrapper separation-line">
            <div className="optional-description">{option.descrizione}</div>
            <div>€ {option.valore}</div>
          </div>
        ))
      ) : (
        <span>{t('no_optional')}</span>
      )}
    </Col>
  );
};

const DisplayServices: React.FC = () => {
  const { quotReqState: { checkedServices } } = useAiContext();
  const { t } = useTranslation()

  return (
    <Col id="added_services" sm={6}>
      <div className="title">{t('added_services')}</div>
      {Array.isArray(checkedServices) && checkedServices.length > 0 ? (
        <div >
          {checkedServices.map((service, index) => {
            return (
              <div key={index} className="service-wrapper separation-line">{service}</div>
            )
          })}
        </div>
      ) : (
        <div>{t('no_service_selected')}</div>
      )}
    </Col>
  );
};

const Riepilogo: React.FC<any> = ({ accordionInfoFields }) => {
  const { activeStep } = useAiContext();
  const { t } = useTranslation()

  const DisplayCarInfo: React.FC = () => {
    return <div className="accordion-titles-wrapper">
      {accordionInfoFields?.map(({ title, value }) => (
        <div className='accordion-title' key={value}>
          <div className='title'>{title}</div>
          <div>{value}</div>
        </div>
      ))}
    </div>
  }

  return (
    <Row id="riepilogo">
      <div>{t('summary')}</div>
      <Card className="mt-3 mb-5 p-0 quotation-data ">
        <Card.Body className='p-0'>
          <Accordion className="" flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header
                className={activeStep === 1 ? "custom-accordion-header" : ""}
              >
                <DisplayCarInfo />
              </Accordion.Header>
              {activeStep > 1 &&
                <Accordion.Body>
                  <Row className="details-list d-flex">
                    <Container>
                      <Row className="">
                        <DisplayOptionals />
                        <DisplayServices />
                      </Row>
                    </Container>
                  </Row>
                </Accordion.Body>
              }
            </Accordion.Item>
          </Accordion>
        </Card.Body>
      </Card>
    </Row>

  )
}

export default Riepilogo