const sortCanoneMinimo = (rowA, rowB, id, desc) => {
  let a = rowA.values.offerta_minima.valore;
  let b = rowB.values.offerta_minima.valore;

  // filter out ther null values
  if (!desc) {
    if (a === null) {
      return 1;
    }
    if (b === null) {
      return -1;
    }
    if (a === b) {
      return 0;
    }
    return a < b ? -1 : 1;
  }  else {
    if (a === null) {
      return -1;
    }
    if (b === null) {
      return 1;
    }
    if (a === b) {
      return 0;
    }
    return a < b ? -1 : 1;
  }
}

export default sortCanoneMinimo;
