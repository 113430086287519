import { HistoryActionTypeEnum } from '../api';
import { SelectOptions } from '../types/';

export const transformEmailForUrl = (str) => {
  // should remove '.' and '@' from email address string and replace it with '-' to be used in url safely
};

export const arrayPrepend = (arr, val) => {
  var newArr = arr.slice();
  newArr.unshift(val);
  return newArr;
};

export const emailIsValid = (email) => {
  return /\S+@\S+\.\S+/.test(email)
}

export const renderFloatAsStr = (str: string) => {
  str = str.replaceAll('.', ',')
  str = str.replace(/[^\d,]/g, '')
  return str.split(',', 2).join(',')
}

/**
 * Parses a string as a float, dealing with commas and dots
 * @param str
 * @returns
 */
export const strToFloat = (str: string) => {
  str = str.replace(',', '.')
  return Number.isNaN(parseFloat(str)) ? 0 : parseFloat(str)
}

export function removeDuplicates<T>(arr: Iterable<T>): T[] {
  const s: Set<T> = new Set(arr);
  return [...s];
}

// prepare options from ARRAY for the select tags to be fed to the Select component from react-select
export const arrayToOptions = (array: string[]): SelectOptions[] => {
  return array.map((item: string) => {
    return {
      value: item,
      label: item || ''
    }
  })
}
// prepare options from STRING for the select tags to be fed to the Select component from react-select
export const stringToOption = (str: string, value: string = '') => {
  return {
    value: str,
    label: str || '',
    originalValue: value
  };
};


export const isObj = <T>(arg: T): boolean => {
  if (typeof arg === 'object' && !Array.isArray(arg) && arg !== null) {
    return true;
  }
  return false
}

export const isNotEmptyObject = <T>(arg: T): boolean => {
  if (isObj(arg) && !!Object.keys(arg as keyof T).length) {
    return true
  }
  return false
}

export const formatDate = (dateString: string, locale: string) => {
  // locale = 'en-US' or 'de-DE' or 'it-IT etc...
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  };

  return date.toLocaleDateString(locale, options);
};

export const translateServices = (service: string) => {
  let t = '';

  switch (service) {
    case 'treno_di_gomme':
      t = 'Treno di gomme'
      break;
    case 'auto_sostitutiva':
      t = 'Auto sostitutiva'
      break;
    case 'kasko':
      t = 'Kasko'
      break;
    case 'rca':
      t = 'RCA'
      break;
    case 'i_f':
      t = 'I/F'
      break;
  }

  return t;
}

export const noopFn = () => { };

export const calcRemaingOptionTime = (user, storico) => {
  const actionTypesOptionableVehicle = [
    HistoryActionTypeEnum.OPZIONAMENTO_CANCELLATO,
    HistoryActionTypeEnum.OPZIONAMENTO_SCADUTO
  ];

  let canBeOptioned: boolean = false
  let timestamp;

  for (let i = storico.length - 1; i >= 0; i--) {
    const currentStorico = storico[i]
    // check if username is the same as the one who did the action
    if (user.username === currentStorico.utente) {
      // check if the action is either OPZIONAMENTO_CANCELLATO or OPZIONAMENTO_SCADUTO
      if (actionTypesOptionableVehicle.includes(currentStorico.type)) {
        const threeDaysInMillis = 3 * 24 * 60 * 60 * 1000;
        const timestampInMillis = new Date(currentStorico.timestamp).getTime();
        const targetTimestamp = new Date(currentStorico.timestamp).getTime() + threeDaysInMillis;

        const isOlderThanThreeDays = (Date.now() - timestampInMillis) > threeDaysInMillis;
        if (isOlderThanThreeDays) {
          canBeOptioned = true;
        } else {
          timestamp = targetTimestamp
          canBeOptioned = false;
        }
        break;
      } else {
        canBeOptioned = true
      }
    } else {
      canBeOptioned = true
    }
  }
  return { canBeOptioned, timestamp }
}

export const appendUniqueDataToFilename = (filename, values) => {
  // we append the nr_ordine and the timestamp because we cannot upload files with the same name on S3
  const filenameSplit = filename.split('.')
  const extension = filenameSplit.pop()
  const rowFilename = filenameSplit.shift()
  const timestamp = new Date().toISOString().replace(/\.\d{3}Z$/, '');
  return `${rowFilename}---${values.nr_ordine}---${timestamp}.${extension}`;
}

export const getCleanFilename = (rowFilename) => {
  const arrayFromSplit = rowFilename.split('---')
  if (arrayFromSplit.length > 0) {
    return arrayFromSplit[0]
  }
}

export const getCookieValue = (cookieName) => {
  const cookies = document.cookie.split('; ');
  for (const cookie of cookies) {
    const [name, value] = cookie.split('=');

    if (name === cookieName) {
      return decodeURIComponent(value);
    }
  }
  return null;
}

export const randomString = (length: number) => {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return result;
}

export const getUrl = (to: string, q: { [key: string]: string } = {}) => {
  const params = new URLSearchParams();

  for (let k in q) {
    params.set(k, q[k])
  }

  let url = to;

  if (Object.keys(q).length > 0) {
    url += '?' + params.toString()
  }

  return url;
}

export const handleSym = (value, cs) => {
  let val = '';
  switch (cs) {
    case 'value':
      if (value?.includes('€')) {
        val = value?.slice(1, value.lenght);
      } else {
        val = value?.slice(0, -1);
      }
      break;
    case 'symbol':
      if (value?.includes('€')) {
        val = value?.slice(0, 1);
      } else {
        val = value?.slice(-1);
      }
      break;
    default:
      break;
  }

  return val?.trim();
}

export const downloadBase64File = (base64String: string, filename: string) => {
  // Split the data URI to get content type and base64 data
  // const parts = base64String.split(';base64,');
  // const contentType = parts[0].split(':')[1];

  const mimeString = 'application/octet-stream';

  // Create an anchor element (a tag)
  const link = document.createElement('a');

  link.href = 'data:' + mimeString + ';base64,' + base64String;
  link.download = filename;
  link.click();

  // Revoke the object URL to avoid memory leaks (important)
  setTimeout(() => link.remove(), 1000);
}