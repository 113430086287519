import { useEffect, useState } from "react";
import { Pagination, Form } from "react-bootstrap";
import Paginator from "paginator";
import { useTranslation } from 'react-i18next';

import { PaginatorObject, TableContainerProps } from "../../types";
import TableContainer from "../genericTable/TableContainer"

/**
 * 💡 forceMaxResults: C'è un limite al numero di risultati che è possibile skippare
 * su Salesforce, che al momento di scrittura di questo commento è pari a 2.000
 *
 *  Il paramentro forceMaxResults ci consente forzatamente di non superare mai questo limite
 */

const ServerPaginatedTable = ({ loadFn, totalCount, forceMaxResults = false, ...props }: TableContainerProps & {
    loadFn: (skip: number, limit: number) => void;
    totalCount: number;
    forceMaxResults?: boolean | number
}) => {
    const pageSize = 20;
    const [pgnt, setPgnt] = useState<PaginatorObject>();
    const { t } = useTranslation()

    const getTotalCount = (count) => {
        if (forceMaxResults && typeof forceMaxResults === 'number' && count > forceMaxResults) {
            return forceMaxResults;
        }

        return count;
    }

    const gotoPage = (val: number, size = pageSize) => {
        const pg = new Paginator(size, 1);
        let newPgt: PaginatorObject = pg.build(getTotalCount(totalCount), val)

        loadFn(newPgt.first_result, size);
        setPgnt(newPgt)
    }

    const movePage = (next: boolean) => {
        if (!pgnt) return;

        const pg = new Paginator(pageSize, 1);

        let newPgt: PaginatorObject
        let nextPage = next ? pgnt.next_page : pgnt.previous_page

        newPgt = pg.build(getTotalCount(totalCount), nextPage)
        loadFn(newPgt.first_result, pageSize);

        setPgnt(newPgt)
    }

    useEffect(() => {
        const pg = new Paginator(pageSize, 1);
        const res = pg.build(getTotalCount(totalCount), 1);

        setPgnt(res);
    }, [totalCount])

    return (<>
        <div style={{ opacity: props.loading ? 0.6 : 1 }}>
            <TableContainer
                {...props}
                manualSortBy={true}
                isMinorPagination
                loading={props.loading}
                hasFilter={false}
                columns={props.columns.map(el => {
                    return { ...el }
                })}
                initPageSize={pageSize}>
            </TableContainer>
        </div>

        <div className="mt-2 float-end" style={{ pointerEvents: props.loading ? 'none' : 'all' }}>
            <Pagination>
                {/* <Form.Select
                    className="w-auto"
                    value={pageSize}
                    onChange={(e) => {
                        setPageSize(Number(e.target.value));
                        gotoPage(pgnt?.current_page!, Number(e.target.value))
                    }}
                >
                    {[10, 20].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            Mostra {pageSize}
                        </option>
                    ))}
                </Form.Select> */}
                <Form.Label className="text-nowrap mx-3 mt-2">
                Pagina
                {/* {t('page')} */}
                    <strong className="ms-1">
                        {pgnt?.current_page} {t('of')} {pgnt?.total_pages}
                    </strong>
                </Form.Label>

                <Pagination.First
                    onClick={() => gotoPage(1)}
                    disabled={!pgnt?.has_previous_page}
                />
                <Pagination.Prev
                    onClick={() => movePage(false)}
                    disabled={!pgnt?.has_previous_page}
                />
                <Pagination.Next
                    onClick={() => movePage(true)}
                    disabled={!pgnt?.has_next_page}
                />
                <Pagination.Last
                    onClick={() => gotoPage(pgnt?.total_pages!)}
                    disabled={!pgnt?.has_next_page}
                />
            </Pagination>
        </div>
    </>
    )
}

export default ServerPaginatedTable;