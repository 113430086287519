import { useState, useEffect, useMemo } from 'react';
import { Alert, Button, Card, Col, Form, Nav, Row, Spinner } from 'react-bootstrap';
import { Storage } from 'aws-amplify';
import { FaCheck, FaInfoCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import ReactQuill from 'react-quill';
import i18next from 'i18next';
import _ from 'lodash';

import { emailIsValid, randomString, removeDuplicates, strToFloat } from '../../utils';
import { OpportunityFunnelValues, ReactSelectOption } from '../../types';
import { useApiClient } from '../../hooks/useApiClient';
import { AccountEntitySearch, OpportunityEntity, OpportunityField } from '../../api/models/AccountEntity';
import { VehicleStateEnum, VehicleEntity, VehicleFilterDTO, OpportunityCreateRequestDTO, OpportunityUpdateRequestDTO } from '../../api';
import { OPPORTUNITY_STATES_DATA, OPPORTUNITY_SUPPORTED_FILE_TYPES } from '../../utils/constants';
import { OpportunityStageEnum } from "../../api/models/Enums";
import { opportunityColumnsData } from './opportunityColumnsData'
import AccountSmallPreview from '../AccountSmallPreview/AccountSmallPreview';
import SelectOptWithSubtitle from '../SelectOptWithSubtitle/SelectOptWithSubtitle';
import MultiFileUploader from '../MultiFileUploader/MultiFileUploader';
import VehicleCardBody from '../VehicleCardBody/VehicleCardBody';
import toaster from '../../utils/toaster';
import RequiredFormLabel from '../RequiredFormLabel/RequiredFormLabel';
import JatoVehicleSelector from '../JatoVehicleSelector/JatoVehicleSelector';
import OpportunitiesTable from '../OpportunitiesTable/OpportunitiesTable';
import OpportunitySmallPreview from '../OpportunitySmallPreview/OpportunitySmallPreview';
import CardPlaceholder from '../CardPlaceholder/CardPlaceholder';
import MissingAccountRecordTypeAlert from '../MissingAccountRecordTypeAlert/MissingAccountRecordTypeAlert';
import OpportunityModals from '../Opportunity/OpportunityModals';
import 'react-quill/dist/quill.snow.css'; 
import './OpportunityFunnel.scss';

type AccountSelectOpt = ReactSelectOption & { account: AccountEntitySearch; subtitle: string; };
type VehicleSelectOpt = ReactSelectOption & { vehicle: VehicleFilterDTO; subtitle: string; };

export type FunnelStep = 'account' | 'vehicle' | 'upload_docs' | 'send_docs' | 'opportunity' | 'send_or_upload_docs'

// const emailTemplate = i18next.t('rental_application_instructions_email_template');
const emailTemplate = `
	Spett.le Cliente,
	<br><br>
	con la presente le invio l'elenco della documentazione da produrre per poter istruire la pratica di noleggio. <br><br>Le chiedo di mandare tutto compilato, come da indicazioni, al suo referente commerciale.
	<br><br>
	In attesa di un suo gentile riscontro resto a disposizione per ogni dubbio.
	<br><br>
	Cordiali saluti
	<br><br>
	`;


const NavItem = ({ title, text, eventKey, isValid = false, mustComplete, showIsOptional = false, forceRequired = false }: {
	forceRequired?: boolean;
	showIsOptional?: boolean;
	eventKey: string;
	title: string;
	text: string;
	mustComplete: FunnelStep[];
	isValid?: boolean;
}) => {
	return (
		<Nav.Item >
			<Nav.Link eventKey={eventKey} className='text-start text-muted text-success'>
				<div>
					{isValid && <span><FaCheck className='text-success'></FaCheck> &nbsp;</span>}
					<b>
						<RequiredFormLabel label={title} required={forceRequired || mustComplete.includes(eventKey as FunnelStep)} />
					</b>
				</div>
				<div className='text-sm'>
					{text}
				</div>
				{/* <Badge bg={mustComplete.includes(eventKey as FunnelStep) ? 'primary' : 'secondary'}>
						{mustComplete.includes(eventKey as FunnelStep) ? 'Richiesto' : ' Facoltativo'}
					</Badge> */}
			</Nav.Link>
		</Nav.Item>
	)
}

const OpportunityFunnel = ({ account, vehicle, canChange, saveLabel, mustComplete, startFrom, opportunity, nextState = OpportunityStageEnum.waiting_sign, onQuit, afterSubmitFn }: {
	account?: AccountEntitySearch
	vehicle?: VehicleEntity
	opportunity?: OpportunityEntity
	canChange: FunnelStep[]
	mustComplete: FunnelStep[]
	startFrom: FunnelStep
	saveLabel?: string
	nextState?: OpportunityStageEnum
	onQuit?: () => void
	afterSubmitFn: (opportunityId: string) => void
}) => {

	const [funnelData, setFunnelData] = useState<{
		account?: AccountEntitySearch
		vehicle?: VehicleEntity
	}>({ account, vehicle })

	const { t } = useTranslation()
	const [finalState, setFinalState] = useState<OpportunityStageEnum>(nextState as OpportunityStageEnum)
	const [documentationFolder, setDocumentationFolder] = useState<ReactSelectOption[]>([]);
	const { apiClient, handleApiResponse } = useApiClient();
	const [loading, setLoading] = useState(true);
	const [sending, setSending] = useState(false)
	const [sendFiles, setsendFiles] = useState<File[]>([])
	const [modalType, setModalType] = useState(''); // account, contact, product
	const [sendData, setSendData] = useState<{
		enabled: boolean
		message: string
		to: ReactSelectOption | null
		dealer: ReactSelectOption | null
		dealerDocs: string[]
		cc: ReactSelectOption[]
	}>({ to: null, enabled: false, dealer: null, cc: [], message: emailTemplate, dealerDocs: [] })
	const [dealerFolderData, setDealerFolderData] = useState<any[]>([])
	const [canSave, setCanSave] = useState<boolean>(false)
	const [validation, setValidation] = useState<{
		account: boolean
		vehicle: boolean
		upload_docs: boolean
		send_docs: boolean
		opportunity: boolean
	}>({ account: false, vehicle: false, upload_docs: false, send_docs: false, opportunity: false })
	const [uploadFiles, setUploadFiles] = useState<File[]>([])
	const [accountModalOpened, setAccountModalOpened] = useState<boolean>(false)
	const [activeKey, setActiveKey] = useState<FunnelStep>('account')
	const [vehicleSearchOpt, setVehicleSearchOpt] = useState<ReactSelectOption | null>(null)
	const [accountSearchOpt, setAccountSearchOpt] = useState<AccountSelectOpt | null>(null)
	const [vehicleSearchResult, setVehicleSearchResult] = useState<VehicleSelectOpt[]>([])
	const [vehicleSource, setVehicleSource] = useState<string>('hmp')
	const [jatoBrands, setJatoBrands] = useState<(ReactSelectOption)[]>([])
	const [opportunityFields, setOpportunityFields] = useState<OpportunityField[]>([])
	const [jatoSelectedVehicle, setJatoSelectedVehicle] = useState<{
		brand: ReactSelectOption | null,
		model: ReactSelectOption | null,
		images: string[]
	}>({ brand: null, model: null, images: [] })
	const [opportunityValues, setOpportunityValues] = useState<OpportunityFunnelValues>({
		Tipologia__c: null,
		Canale__c: null,
		Noleggiatore__c: null,
		commissions: '',
		notes: '',
		name: '',
	})

	const opprtunityColumns = useMemo(() => {
		return opportunityColumnsData
	}, [])

	useEffect(() => {
		if (vehicleSource === 'external' && jatoBrands.length === 0) {
			handleApiResponse(apiClient.vehicleController.fuzzySearchGetBrandList())
				.then((data) => {
					data.sort();
					const opts = data.map((el) => {
						return { label: el, value: el };
					});

					setJatoBrands(opts);
				})
		}
	}, [vehicleSource])

	useEffect(() => {
		if (funnelData.account && funnelData.account.Email__c) {
			setSendData({
				...sendData, to: {
					value: funnelData.account.Email__c,
					label: funnelData.account.Email__c
				}
			})
		}

		setCanSave(validateFn())
	}, [funnelData])

	useEffect(() => {
		setCanSave(validateFn())
	}, [sendData, uploadFiles, jatoSelectedVehicle, opportunityValues])

	// useEffect(() => {
	// }, [accountModalOpened])

	const loadData = async () => {
		if (canChange.includes('vehicle')) {
			handleApiResponse(apiClient.vehicleController.getVehiclesFilter())
				.then(vehicleFilterRes => {
					const filtered: VehicleFilterDTO[] = vehicleFilterRes.filter((el) => el.stato_corrente.type === VehicleStateEnum.DISPONIBILE)

					const opts: VehicleSelectOpt[] = filtered.map(el => {
						return {
							label: `${el.marca} ${el.modello || ''}`,
							value: `${el.id} ${el.codice_costruttore} ${el.nr_ordine}`,
							subtitle: `Ord. Commerciale: ${el.nr_ordine}`,
							vehicle: el,
						}
					})

					setVehicleSearchResult(opts)
				})

		}

		if (canChange.includes('opportunity')) {
			handleApiResponse(apiClient.opportunityController.getOpportunityFields())
				.then(opportunityFieldsRes => {

					setOpportunityFields(opportunityFieldsRes)
				})
		}

		setLoading(false)
	}

	useEffect(() => {
		// ask user tab dismiss confirmation
		// window.onbeforeunload = () => true;

		setActiveKey(startFrom!)

		findDocumentationFolder();

		if (account) {
			setFunnelData({ ...funnelData, account })
		}

		if (vehicle) {
			setVehicleSource('hmp')
		}

		if (mustComplete.includes('send_docs')) {
			setSendData({ ...sendData, enabled: true })
		}

		loadData()
	}, []);

	useEffect(() => {
		setSendData(prevData => ({
			...prevData,
			message: i18next.t('rental_application_instructions_email_template')
		}));
	}, [t]);

	const uppercCaseStrArr = (arr: string[]) => {
		return arr.map(el => el.trim().toUpperCase())
	}

	const loadDealerDocs = async (opt) => {
		if (!funnelData.account) return;

		const folderId = opt.value

		setDealerFolderData([])
		setSendData({ ...sendData, dealer: opt, dealerDocs: [] })

		const folderData = await handleApiResponse(apiClient.areaAcademyController.getSingleFolder(folderId))
		let dealerFolderData = folderData.data.filter(el => el.type === 'file');

		const accountType = funnelData.account.RecordType.Name;
		let accountTypeToSearch: { [key: string]: string[] } = {
			'Libero Professionista': uppercCaseStrArr(['liberi professionisti/società di persone']),
			'Privato': uppercCaseStrArr(['Privati']),
			'Azienda': uppercCaseStrArr(['aziende (società di capitali)']),
		};

		const accountTypeFolder = folderData.data.find(el => el.type === 'folder' && accountTypeToSearch[accountType].includes(el.title.toUpperCase()))

		if (accountTypeFolder) {
			const accountTypeFolderContent = await handleApiResponse(apiClient.areaAcademyController.getSingleFolder(accountTypeFolder.id))
			const accountTypeFolderData = accountTypeFolderContent.data.filter(el => el.type === 'file')
			dealerFolderData = [...dealerFolderData, ...accountTypeFolderData]
		}

		setDealerFolderData(dealerFolderData)
		setSendData({ ...sendData, dealer: opt, dealerDocs: dealerFolderData.map(el => el.title) })
	}

	const findDocumentationFolder = async () => {
		if (documentationFolder.length === 0) {
			try {
				const res = await handleApiResponse(apiClient.areaAcademyController.getRootAcademyFolder())
				const noleggiatoriFolder = res.data.find(el => el.title.toUpperCase() === 'NOLEGGIATORI')

				if (noleggiatoriFolder) {
					const nFolderRes = await handleApiResponse(apiClient.areaAcademyController.getSingleFolder(noleggiatoriFolder.id))
					const docFolder = nFolderRes.data.find(el => el.title.toUpperCase() === 'DOCUMENTAZIONE');

					if (docFolder) {
						const nDocFolderRes = await handleApiResponse(apiClient.areaAcademyController.getSingleFolder(docFolder.id))

						setDocumentationFolder(nDocFolderRes.data
							.filter(el => el.type === 'folder')
							.map(el => {
								return {
									label: el.title,
									value: el.id
								}
							}))
					}
				}
			} catch (err) {
				console.error(err)
			}
		}
	}

	const accountSearch = _.debounce((q: string, cb) => {
		setTimeout(() => {
			handleApiResponse(apiClient.accountController.getAccounts({ q }))
				.then(res => {
					let list: AccountEntitySearch[] = res.data;
					const opts: AccountSelectOpt[] = list.map(el => ({
						value: el.Id,
						label: el.Name,
						account: el,
						subtitle: el?.RecordType?.Name || el.Email__c || ''
					}))

					cb(opts)
				})
		})
	}, 500)

	const onModelFilterchange = (opt: any) => {
		setJatoSelectedVehicle({ brand: jatoSelectedVehicle.brand, model: opt, images: [] })
		handleApiResponse(apiClient.vehicleController.getVehicleImages(opt.value, opt.data.database))
			.then(res => {
				setJatoSelectedVehicle({ brand: jatoSelectedVehicle.brand, model: opt, images: res })
			})
	}

	const vehicleSearch = (q: string, cb) => {
		_.debounce(() => {
			const res = vehicleSearchResult.filter(el => {
				const val = q.toLowerCase()
				return el.value.toLowerCase().includes(val) || el.label.toLowerCase().includes(val)
			})

			cb(res)
		}, 400)()
	}

	const setVehicleSearch = (opt: any) => {
		setVehicleSearchOpt(opt)

		setFunnelData({ ...funnelData, vehicle: undefined })
		handleApiResponse(apiClient.vehicleController.getVehicles1(opt.vehicle.id))
			.then((res: VehicleEntity) => {
				setFunnelData({ ...funnelData, vehicle: res })
			})
	}

	const getVehicleLabel = (v: VehicleEntity) => {
		return ((v?.dati_veicolo?.marca || '') + ' ' + (v?.dati_veicolo?.modello || ''))
	}

	const getDocumentsCountLabel = (arr: any[]) => {
		return arr.length ? arr.length + ` document${arr.length === 1 ? 'o' : 'i'}` : i18next.t('no_document')
	}

	const validateFn = () => {
		let sendDataIsValid = (sendData.enabled) ? (sendData.to !== null && sendData.message.length > 0
			&& sendData.dealerDocs.length > 0) : true;

		let accountStatus = mustComplete.includes('account') ? (funnelData.account !== undefined) : true
		let vehicleStatus = mustComplete.includes('vehicle') ? (funnelData.vehicle !== undefined || jatoSelectedVehicle.model !== null) : true
		let uploadStatus = mustComplete.includes('upload_docs') ? (uploadFiles.length > 0) : true;
		let sendDocsStatus = mustComplete.includes('send_docs') ? sendDataIsValid : true
		let opportunityStatus = mustComplete.includes('opportunity') ? (
			opportunityValues.Canale__c !== null && opportunityValues.Tipologia__c !== null
			&& strToFloat(opportunityValues.commissions) >= 0 && opportunityValues.name.length > 0
		) : true

		setValidation({
			account: accountStatus,
			vehicle: vehicleStatus,
			upload_docs: uploadStatus,
			send_docs: sendDocsStatus,
			opportunity: opportunityStatus,
		})

		if ((uploadFiles.length > 0) && nextState === OpportunityStageEnum.waiting_sign) {
			setFinalState(OpportunityStageEnum.credit_score_check)
		}

		if ((uploadFiles.length === 0) && nextState === OpportunityStageEnum.waiting_sign) {
			setFinalState(OpportunityStageEnum.waiting_sign)
		}

		let result = accountStatus && vehicleStatus && sendDataIsValid && uploadStatus && opportunityStatus;

		if (mustComplete.length > 1 && !mustComplete.includes('send_docs') && !mustComplete.includes('upload_docs')) {
			result = result && (sendData.enabled || uploadFiles.length > 0)
		}

		return result
	};

	const uploadFilesToBucket = async () => {
		let filesToUploadList: { filename: string, key: string, file: File, type: string }[] = []

		const uploadContent = {
			'sendFiles': { data: sendFiles },
			'uploadFiles': { data: uploadFiles }
		}

		for (const type in uploadContent) {
			const fileArray: { data: File[], type: 'sendFiles' | 'uploadFiles' } = uploadContent[type]

			if (fileArray.data.length) {
				let uploadOps: any[] = []

				fileArray.data.forEach(file => {
					const ext = file.name.split('.').pop()
					const key = `opportunity_uploads/${randomString(10)}.${ext}`
					filesToUploadList.push({ type, file, filename: file.name, key })
				})

				uploadOps = filesToUploadList.map(obj => {
					return Storage.put(obj.key, obj.file, {
						contentType: obj.file.type,
						bucket: process.env.REACT_APP_AWS_STORAGE_BUCKET_SALESFORCE_IMPORT,
					});
				})

				try {
					await Promise.all(uploadOps)
				} catch (e) {
					console.error(e);
					toaster.error(i18next.t('error_loading_file'))
					throw e;
				}
			}
		}

		return filesToUploadList.map(el => {
			return { ...el, key: `public/${el.key}` }
		})
	}

	const hideModal = () => {
		setAccountModalOpened(false)

		if (activeKey === 'opportunity' && validation.opportunity && opportunityValues.Noleggiatore__c) {
			const docFolder = documentationFolder.find(f => f.label === opportunityValues.Noleggiatore__c?.value)

			if (docFolder) {
				loadDealerDocs(docFolder)
			} else {
				setDealerFolderData([])
				setSendData({ ...sendData, dealer: null, dealerDocs: [] })
			}
		}
	};

	const submitFn = async () => {
		if (!validateFn()) return;
		setSending(true);

		let filesToUploadList = await uploadFilesToBucket()

		const selectedOpportunity = opportunityValues.entity || opportunity

		if (selectedOpportunity) {
			// edit
			let updateRequest: any = {
				stageName: finalState,
			}

			if (canChange.includes('vehicle')) {
				updateRequest.vehicle = {
					vehicleId: null,
					customVehicle: null,
				}

				if (vehicleSource === 'external') {
					updateRequest.vehicle.customVehicle = {
						brand: jatoSelectedVehicle.brand!.label,
						model: jatoSelectedVehicle.model!.label,
						jatoId: jatoSelectedVehicle.model!.value
					}
				} else if (funnelData.vehicle) {
					updateRequest.vehicle.vehicleId = funnelData.vehicle.id
				}
			}

			let vehicleReq = {}
			if (vehicle?.id) {
				vehicleReq = {
					vehicleId: vehicle?.id,
					customVehicle: null
				}
			} else {
				vehicleReq = {
					vehicleId: selectedOpportunity.ID_Vehicle_HMP__c,
					customVehicle: {
						brand: selectedOpportunity.Brand__c,
						model: selectedOpportunity.Modello__c,
					}
				}
			}

			let request: OpportunityUpdateRequestDTO = {
				name: selectedOpportunity.Name,
				commissions: strToFloat(selectedOpportunity.Provvigioni__c.toString()),
				channel: selectedOpportunity.Canale__c,
				type: selectedOpportunity.Tipologia__c,
				dealerName: selectedOpportunity.Noleggiatore__c,
				note: selectedOpportunity.Note__c ?? '',
				vehicle: vehicleReq,
				emailSending: null,
				signedAttachments: null
			}

			if (canChange.includes('upload_docs') && uploadFiles.length) {
				const attachments = filesToUploadList.filter(el => el.type === 'uploadFiles')
					.map(item => {
						return {
							"filename": item.filename,
							"key": item.key
						}
					})
				request.signedAttachments = attachments
			}


			if (canChange.includes('send_docs')) {
				if (sendData.enabled) {
					request.emailSending = {
						"to": sendData.to!.value,
						"cc": sendData.cc.map(el => el.value),
						"body": sendData.message,
						"existingAttachments": sendData.dealerDocs.map(el => {
							return { key: 'public/' + el, filename: el }
						}),
						"uploadedAttachments": filesToUploadList.filter(el => el.type === 'sendFiles').map(item => {
							return {
								"filename": item.filename,
								"key": item.key
							}
						}),
					}
				}
			}

			request = { ...request, ...updateRequest }
			handleApiResponse(apiClient.opportunityController.editOpportunity(selectedOpportunity.Id, request))
				.then((res) => {
					if (afterSubmitFn) {
						afterSubmitFn(selectedOpportunity.Id)
					}
				})
				.finally(() => {
					setSending(false)
				})

		} else {
			// Create opportunity
			let request: OpportunityCreateRequestDTO = {
				accountId: funnelData.account!.Id,
				commissions: strToFloat(opportunityValues.commissions),
				channel: opportunityValues.Canale__c?.value,
				type: opportunityValues.Tipologia__c?.value,
				name: `HMP-${funnelData.account!.Name}-${opportunityValues.name}`,
				dealerName: opportunityValues.Noleggiatore__c?.value,
				emailSending: null,
				signedAttachments: null,
				note: opportunityValues.notes,
				stageName: finalState,
				vehicle: {
					vehicleId: null,
					customVehicle: null
				}
			}

			if (canChange.includes('vehicle')) {
				if (vehicleSource === 'external') {
					request.vehicle!.customVehicle = {
						brand: jatoSelectedVehicle.brand!.label,
						model: jatoSelectedVehicle.model!.label,
						jatoId: jatoSelectedVehicle.model!.value
					};
				} else if (funnelData.vehicle) {
					request.vehicle!.vehicleId = funnelData.vehicle.id?.toString() || null;
				}
			} else {
				if (funnelData.vehicle) {
					request.vehicle!.vehicleId = funnelData.vehicle.id?.toString() || null;
				}
			}


			if (canChange.includes('send_docs')) {
				if (sendData.enabled) {
					request.emailSending = {
						"to": sendData.to!.value,
						"cc": sendData.cc.map(el => el.value),
						"body": sendData.message,
						"existingAttachments": sendData.dealerDocs.map(el => {
							return { key: 'public/' + el, filename: el }
						}),
						"uploadedAttachments": filesToUploadList.filter(el => el.type === 'sendFiles').map(item => {
							return {
								"filename": item.filename,
								"key": item.key
							}
						}),
					}
				}
			}

			if (canChange.includes('upload_docs') && uploadFiles.length) {
				request.signedAttachments = filesToUploadList.filter(el => el.type === 'uploadFiles').map(item => {
					return {
						"filename": item.filename,
						"key": item.key
					}
				})
			}

			handleApiResponse(apiClient.opportunityController.createOpportunity(request))
				.then((res) => {
					if (afterSubmitFn) {
						afterSubmitFn(res.id)
					}
				})
				.finally(() => {
					setSending(false)
				})
		}
	};

	const cleanOpportunityValues = () => {
		setOpportunityValues({
			entity: null,
			Tipologia__c: null,
			Canale__c: null,
			Noleggiatore__c: null,
			commissions: '',
			notes: '',
			name: '',
		})
	}

	const opportunityTableCb = (o: OpportunityEntity) => {
		const noleggiatore = o.Noleggiatore__c ?? ''
		const tipologia = o.Tipologia__c ?? ''

		setOpportunityValues({
			Canale__c: o.Canale__c ?? '',
			Noleggiatore__c: { value: noleggiatore, label: noleggiatore },
			Tipologia__c: { value: tipologia, label: tipologia },
			notes: o.Note__c ?? '',
			name: o.Name,
			commissions: o.Provvigioni__c.toString(),
			entity: o,
		})
	}

	return (
		<div>
			<Nav fill variant="tabs" activeKey={activeKey} onSelect={(k) => {
				setActiveKey(k as FunnelStep || i18next.t('account'))
			}}>
				<NavItem
					eventKey="account"
					title={i18next.t('account')}
					isValid={validation.account}
					mustComplete={mustComplete}
					text={funnelData.account?.Name || i18next.t('select_one_account')} />
				{canChange.includes('opportunity') && (
					<NavItem
						eventKey="opportunity"
						title={i18next.t('opportunity_data')}
						isValid={validation.opportunity}
						mustComplete={mustComplete}
						text={validation.opportunity ? i18next.t('completed') : i18next.t('complete_form')} />
				)}
				<NavItem
					eventKey="vehicle"
					title={i18next.t('vehicle')}
					isValid={validation.vehicle}
					mustComplete={mustComplete}
					showIsOptional={false}
					text={funnelData.vehicle ? getVehicleLabel(funnelData.vehicle) :
						(jatoSelectedVehicle.model ? `${jatoSelectedVehicle?.brand?.label} ${jatoSelectedVehicle.model.label}` : 'Seleziona un veicolo')} />
				{canChange.includes('send_docs') && (
					<NavItem
						eventKey="send_docs"
						title={i18next.t('send_documents')}
						forceRequired={mustComplete.includes('send_or_upload_docs') && !(uploadFiles.length > 0 && validation.upload_docs)}
						mustComplete={mustComplete}
						isValid={sendData.enabled ? validation.send_docs : false}
						showIsOptional={true}
						text={getDocumentsCountLabel([...sendFiles, ...sendData.dealerDocs])}></NavItem>
				)}
				{canChange.includes('upload_docs') && (
					<NavItem
						eventKey="upload_docs"
						title={i18next.t('upload_documents')}
						forceRequired={mustComplete.includes('send_or_upload_docs') && !(sendData.enabled ? validation.send_docs : false)}
						mustComplete={mustComplete}
						showIsOptional={true}
						text={getDocumentsCountLabel(uploadFiles)}
						isValid={uploadFiles.length > 0 && validation.upload_docs}></NavItem>
				)}
			</Nav>
			{!loading ? (
				<div>
					<Card>
						<Card.Body className='pt-4'>
							{activeKey === 'account' && (
								<>
									{(
										<div>
											{canChange.includes('account') && (
												<Row>
													{accountSearchOpt && !accountSearchOpt.account.RecordType && (
														<Col md={12}>
															<MissingAccountRecordTypeAlert accountId={accountSearchOpt.account.Id} />
														</Col>
													)}
													<Col md={6}>
														<div className="mb-3">
															<Button
																onClick={() => {
																	setAccountModalOpened(true)
																	setModalType('account')
																}}
																disabled={funnelData.account !== undefined}>
																+ {t('create_new_account')}
															</Button>
														</div>
														<Form.Group>
															<AsyncSelect
																isClearable
																isDisabled={!canChange.includes('account')}
																placeholder={t('select_one_account')}
																value={accountSearchOpt}
																noOptionsMessage={() => {
																	return i18next.t('type_to_search')
																}}
																defaultOptions={accountSearchOpt ? [accountSearchOpt] : []}
																loadOptions={accountSearch}
																onChange={(opt: any) => {
																	setAccountSearchOpt(opt)

																	if (opt.account.RecordType) {
																		setFunnelData({ ...funnelData, account: opt ? opt.account : undefined })
																	}
																}}
																components={{
																	Option: (props: any) => {
																		return <SelectOptWithSubtitle {...props}></SelectOptWithSubtitle>
																	}
																}}
															/>
														</Form.Group>
													</Col>
												</Row>
											)}
											{funnelData.account && (
												<Row className='mt-3'>
													<Col md={12}>
														{<AccountSmallPreview
															account={funnelData.account}
															showAccountBtn={account === null}
														/>}
													</Col>
												</Row>
											)}
										</div>
									)}
								</>
							)}
							{activeKey === 'opportunity' && (
								<div>
									{funnelData.account ? (<Form autoComplete='off' onSubmit={() => { }}>
										<Row>
											<Col md={6}>
												<div>
													<Button
														onClick={() => {
															if (validation.opportunity) {
																cleanOpportunityValues()
																validateFn()
															} else {
																setAccountModalOpened(true)
																setModalType('opportunity')
															}
														}}
														disabled={funnelData.account === undefined}>
														{validation.opportunity ? t('change') : `+ ${t('create_new')}`}
													</Button>
												</div>
											</Col>
											<Col md={12}>
												<div>
													{!accountModalOpened && validation.opportunity && (
														<div className='mt-3'>
															<OpportunitySmallPreview opportunity={opportunityValues} />
														</div>
													)}

													{vehicle && !validation.opportunity && (
														<div>
															<div className="mt-5">
																<b>Opportunity create da HMP</b>
																{!accountModalOpened && !validation.opportunity && (
																	<div>
																		{nextState === OpportunityStageEnum.waiting_sign && (
																			<OpportunitiesTable
																				customColumnsData={opprtunityColumns}
																				dblClickCallback={opportunityTableCb}
																				query={{
																					vehicleId: vehicle.id!.toString(),
																					accountId: funnelData.account.Id,
																					stageName: [OpportunityStageEnum.proposal, OpportunityStageEnum.qualification, OpportunityStageEnum.negotiation]
																				}}
																			/>
																		)}

																		{nextState === OpportunityStageEnum.credit_score_check && (
																			<OpportunitiesTable
																				customColumnsData={opprtunityColumns}
																				dblClickCallback={opportunityTableCb}
																				query={{
																					vehicleId: vehicle.id!.toString(),
																					accountId: funnelData.account.Id,
																					stageName: [OpportunityStageEnum.proposal, OpportunityStageEnum.qualification, OpportunityStageEnum.negotiation, OpportunityStageEnum.waiting_sign]
																				}}
																			/>
																		)}

																		{nextState === OpportunityStageEnum.closed_won && (
																			<OpportunitiesTable
																				customColumnsData={opprtunityColumns}
																				dblClickCallback={opportunityTableCb}
																				query={{
																					vehicleId: vehicle.id!.toString(),
																					accountId: funnelData.account.Id,
																					stageName: [OpportunityStageEnum.proposal, OpportunityStageEnum.qualification, OpportunityStageEnum.negotiation,
																					OpportunityStageEnum.waiting_sign, OpportunityStageEnum.credit_score_check]
																				}}
																			/>
																		)}
																	</div>
																)}
															</div>

															<div className="mt-5">
																<b>Opportunity senza un veicolo associato</b>
																{!accountModalOpened && !validation.opportunity && (
																	<OpportunitiesTable
																		customColumnsData={opprtunityColumns}
																		dblClickCallback={opportunityTableCb}
																		query={{
																			withoutHmpVehicle: true,
																			accountId: funnelData.account.Id,
																			stageName: [OpportunityStageEnum.proposal, OpportunityStageEnum.qualification, OpportunityStageEnum.negotiation,
																			OpportunityStageEnum.waiting_sign, OpportunityStageEnum.credit_score_check, OpportunityStageEnum.suspended]
																		}}
																	/>
																)}
															</div>
														</div>
													)}
												</div>
											</Col>
										</Row>
									</Form>) : <Alert variant='secondary' className="mb-4">
										{t('account_selection_required_to_proceed')}
									</Alert>}
								</div>
							)}
							{activeKey === 'vehicle' && (
								<div>
									{canChange.includes('vehicle') && (
										<div>
											<Row className='mb-3'>
												<Nav variant='tabs' activeKey={vehicleSource} onSelect={(k) => {
													setFunnelData({ ...funnelData, vehicle: undefined })
													setVehicleSearchOpt(null)
													setJatoSelectedVehicle({ model: null, brand: null, images: [] })
													setVehicleSource(k || 'hmp')
												}}>
													<Nav.Item>
														<Nav.Link eventKey="hmp">	{t('vehicle_hmp')}</Nav.Link>
													</Nav.Item>
													<Nav.Item>
														<Nav.Link eventKey="external">	{t('vehicle_external')}</Nav.Link>
													</Nav.Item>
												</Nav>
											</Row>
											<Row>
												{vehicleSource === 'hmp' && (
													<Col md={6}>
														<Form.Group>
															<AsyncSelect
																placeholder={i18next.t('select')}
																value={vehicleSearchOpt}
																noOptionsMessage={() => {
																	return i18next.t('search_by_name_builder_code_order_number')
																}}
																defaultOptions={vehicleSearchOpt ? [vehicleSearchOpt] : []}
																loadOptions={vehicleSearch}
																onChange={setVehicleSearch}
																components={{
																	Option: (props: any) => {
																		return <SelectOptWithSubtitle {...props}></SelectOptWithSubtitle>
																	}
																}}
															/>
														</Form.Group>
													</Col>
												)}
												{vehicleSource === 'external' && (
													<>
														{jatoBrands.length === 0 ? <Col><Spinner animation='border' /></Col> : (
															<JatoVehicleSelector
																jatoBrands={jatoBrands}
																jatoSelectedVehicle={{
																	brand: jatoSelectedVehicle.brand,
																	model: jatoSelectedVehicle.model,
																}}
																onBrandFilterchange={(opt) => {
																	setJatoSelectedVehicle({ model: null, brand: opt, images: [] })
																}}
																onModelFilterchange={onModelFilterchange}
															/>
														)}
													</>
												)}
											</Row>
										</div>
									)}

									{funnelData.vehicle ? (
										<Card className='mt-4'>
											<VehicleCardBody data={funnelData.vehicle}></VehicleCardBody>
										</Card>
									) : (jatoSelectedVehicle.images.length > 0 ? <Card className='mt-4'>
										<VehicleCardBody data={{
											dati_veicolo: {
												marca: jatoSelectedVehicle!.brand!.label,
												modello: jatoSelectedVehicle!.model!.label
											},
											image_list: jatoSelectedVehicle.images.map(el => {
												return { url: el }
											})
										}}></VehicleCardBody>
									</Card> : null)}
								</div>
							)}
							{activeKey === 'send_docs' && (
								<div>
									{!funnelData.account ? (
										<Alert>
											{t('account_selection_required_to_proceed')}
										</Alert>
									) : (
										<div>
											<Row className='my-2'>
												<Col>
													<Form.Group>
														<Form.Check
															type="switch"
															name='send-docs-switch'
															label={i18next.t('send_documents_to_customer_for_signature')}
															checked={sendData.enabled}
															disabled={mustComplete.includes('send_docs')}
															onChange={({ target }) => {
																const enabled = target.checked
																if (!target.checked) {
																	setDealerFolderData([])
																	setSendData({ ...sendData, dealer: null, dealerDocs: [], enabled })
																} else {
																	setSendData({ ...sendData, enabled })
																}
															}}
														/>
													</Form.Group>
												</Col>
											</Row>

											{sendData.enabled && (
												<div className='mt-4'>
													<Row>
														<Col md={4}>
															<Select
																options={documentationFolder}
																placeholder={i18next.t('select_rental_company')}
																noOptionsMessage={() => 'Caricamento...'}
																value={sendData.dealer}
																onChange={(opt: any) => {
																	loadDealerDocs(opt)
																}}
															/>

															{dealerFolderData.length > 0 && (
																<div className='mt-3'>
																	{dealerFolderData.map(el => {
																		return (
																			<Form.Check
																				key={el.id}
																				label={el.title}
																				name="dealer-docs"
																				type={'checkbox'}
																				value={el.title}
																				checked={sendData.dealerDocs.includes(el.title)}
																				onChange={({ target: { checked, value } }) => {
																					const docs = new Set(sendData.dealerDocs);
																					checked ? docs.add(value) : docs.delete(value)

																					setSendData({ ...sendData, dealerDocs: Array.from(docs) })
																				}}
																			/>
																		)
																	})}
																</div>
															)}
														</Col>
													</Row>

													<div className="my-4"><hr /></div>

													<Row >
														<Col md={6}>
															<CreatableSelect
																isClearable
																components={{ DropdownIndicator: null }}
																placeholder="A"
																options={sendData.to ? [sendData.to] : []}
																noOptionsMessage={() => i18next.t('add_customer_email')}
																value={sendData.to}
																formatCreateLabel={(i) => emailIsValid(i) ? `Invia a ${i}` : `${i}`}
																onChange={(opt: any) => {
																	if (!opt) setSendData({ ...sendData, to: opt })
																	else if (emailIsValid(opt.value)) {
																		setSendData({ ...sendData, to: opt })
																	}
																}}
															></CreatableSelect>
														</Col>
														<Col md={6}>
															<CreatableSelect
																isMulti
																components={{ DropdownIndicator: null }}
																placeholder="C.c."
																noOptionsMessage={() => i18next.t('add_cc_recipients')}
																value={sendData.cc}
																formatCreateLabel={(i) => emailIsValid(i) ? `Aggiungi ${i}` : `${i}`}
																onChange={(opt: any) => {
																	opt = opt.filter(el => emailIsValid(el.value))
																	setSendData({ ...sendData, cc: removeDuplicates(opt) })
																}}
															></CreatableSelect>
														</Col>
													</Row>
													<Row className='mt-2'>
														<Col xs={12}>
															<ReactQuill theme="snow"
																placeholder={i18next.t('write_email')}
																value={sendData.message} onChange={(message) => {
																	setSendData({ ...sendData, message: message })
																}} />
														</Col>
													</Row>
													<div className="mt-4">
														<MultiFileUploader
															maxSize={10 * 1024 * 1024}
															files={sendFiles}
															setFiles={setsendFiles}
															types={OPPORTUNITY_SUPPORTED_FILE_TYPES}
														></MultiFileUploader>
													</div>
												</div>
											)}
										</div>
									)}
								</div>
							)}
							{activeKey === 'upload_docs' && (
								<div>
									<div className="">
										<p className='text-muted text-center'>
											<b>
												{t('upload_rental_proposal_or_signed_documents')}
												<br />
												{/* L'opportunity specificata verrà salvata in stato "{nextState === OpportunityState.waiting_sign ? OPPORTUNITY_STATES_DATA[OpportunityState.credit_score_check].label : OPPORTUNITY_STATES_DATA[finalState].label}" */}
											</b>
										</p>
										<MultiFileUploader
											maxSize={10 * 1024 * 1024}
											files={uploadFiles}
											setFiles={setUploadFiles}
											types={OPPORTUNITY_SUPPORTED_FILE_TYPES}
										></MultiFileUploader>
									</div>
								</div>
							)}
						</Card.Body>
					</Card>

					<div className="mt-4 text-end">
						<div>
							<Button variant='Link' onClick={onQuit} disabled={sending}>Chiudi</Button>
							<Button variant='primary' onClick={() => submitFn()} disabled={!canSave || sending}>
								{sending ? <Spinner animation='border' size='sm' /> : sendData.enabled ? i18next.t('save_and_send') : i18next.t('save')}
							</Button>
						</div>
						<div>
							<hr />
							<small className="text-muted">
								<FaInfoCircle /> {t('opportunity_saved_as')} <b>"{OPPORTUNITY_STATES_DATA[finalState].label}"</b>
							</small>
						</div>
					</div>
				</div>
			) : (
				<CardPlaceholder />
			)}

			{/* TODO: da sistemare con context o redux */}
			<OpportunityModals
				validation={validation}
				modalType={modalType}
				setAccountSearchOpt={setAccountSearchOpt}
				setAccountModalOpened={setAccountModalOpened}
				setFunnelData={setFunnelData}
				funnelData={funnelData}
				show={accountModalOpened}
				onHide={hideModal}
				account={account}
				setOpportunityValues={setOpportunityValues}
				opportunityValues={opportunityValues}
				opportunityFields={opportunityFields}
			/>
		</div >
	);
};

export default OpportunityFunnel;