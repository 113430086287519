import { Col, Form } from "react-bootstrap"
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { ReactSelectOption } from "../../types";
import SelectOptWithSubtitle from "../SelectOptWithSubtitle/SelectOptWithSubtitle";
import { useState } from "react";
import { useApiClient } from "../../hooks/useApiClient";
import { ApiJatoVehicleItem } from "../../api/models/Fuzzy";
import _ from 'lodash';

type JatoVehicleSelectOpt = ReactSelectOption & { subtitle: string };

const JatoVehicleSelector = ({ jatoSelectedVehicle, onModelFilterchange, onBrandFilterchange, jatoBrands, hideModelVariants = false }: {
    jatoSelectedVehicle: { brand: ReactSelectOption | null, model: ReactSelectOption | null }
    onModelFilterchange: any
    onBrandFilterchange: any
    hideModelVariants?: boolean
    jatoBrands: ReactSelectOption[]
}) => {
    const { apiClient, handleApiResponse } = useApiClient();
    const [jatoBrandModels, setJatoBrandModels] = useState<ReactSelectOption[]>([])
	const { t } = useTranslation()

    const refreshModelList = (opt => {
        onBrandFilterchange(opt);

        setJatoBrandModels([])
        handleApiResponse(apiClient.vehicleController.fuzzySearchGetBrandModelList(opt.value))
            .then((data: ApiJatoVehicleItem[]) => {
                data = data.filter(opt => opt.vehicle_type === 'CURRENT')
                data.sort();
                let opts: JatoVehicleSelectOpt[] = []

                if (hideModelVariants) {
                    const modelSet: any = {};
                    data.forEach(el => {
                        const m = _.capitalize(el.id_129);
                        modelSet[m] = el;
                    })

                    opts = Object.keys(modelSet)
                        .map((modelName) => {
                            const data: ApiJatoVehicleItem = modelSet[modelName];
                            return {
                                label: modelName,
                                value: data.vehicle_id.toString(),
                                subtitle: data.database === 'NSCI' ? 'Commercial' : 'Fleet',
                                data: data
                            };
                        });
                } else {
                    let strs: string[] = []
                    opts = data.map((model) => {
                        const modeldb = model.database === 'NSCI' ? 'Commercial' : 'Fleet'
                        const label = _.capitalize(model.id_129);
                        const subtitle = `${model.id_131} - ${modeldb}`;
                        strs.push(label + ':' + subtitle)
                        
                        return {
                            label: label,
                            value: model.vehicle_id.toString(),
                            subtitle: subtitle,
                            data: model
                        };
                    });

                    opts = opts.filter(opt => {
                        const s = `${opt.label}:${opt.subtitle}`
                        const i = strs.indexOf(s)
                        
                        if (i !== -1) {
                            strs.splice(i, 1)
                            return true
                        }
                        
                        return false
                    })
                }

                setJatoBrandModels(opts);
            })
    })

    return (
        <>
            <Col md={6}>
                <Form.Group>
                    <Select
                        isClearable={false}
                        isDisabled={jatoBrands.length === 0}
                        placeholder={t('select_brand')}
                        name="car_brand"
                        value={jatoSelectedVehicle.brand}
                        options={jatoBrands}
                        onChange={refreshModelList}
                    />
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group>
                    <Select
                        isClearable={false}
                        isDisabled={!jatoBrandModels.length}
                        placeholder={t('select_model')}
                        name="car_model"
                        value={jatoSelectedVehicle.model}
                        options={jatoBrandModels}
                        onChange={onModelFilterchange}
                        components={{
                            Option: ((props: any) => {
                                return <SelectOptWithSubtitle {...props}></SelectOptWithSubtitle>
                            })
                        }}
                    />
                </Form.Group>
            </Col>
        </>
    )
}

export default JatoVehicleSelector;