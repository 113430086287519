import ButtonSelect from '../ButtonSelect';
import i18next from 'i18next';  

export const columnsData = [
	{
		// this column is hidden (check VehicleTableContainer initialState)
		accessor: 'id',
		id: 'id',
		Header: '',
		disableSortBy: true,
	},
	{
		accessor: 'marca',
		id: 'marca',
		Header: i18next.t('brand'),
		width: 110,
	},
	{
		accessor: 'modello',
		id: 'modello',
		Header: i18next.t('model'),
		width: 110,
	},
	{
		accessor: 'versione',
		id: 'versione',
		Header: i18next.t('version'),
		width: 110,
	},
	{
		accessor: 'prezzo',
		id: 'prezzo',
		Header: i18next.t('price'),
		width: 110,
	},
	{
		accessor: 'codice_costruttore',
		id: 'codice_costruttore',
		Header: i18next.t('builder_code'),
		width: 110,
	},
	{
		accessor: 'alimentazione',
		id: 'alimentazione',
		Header:  i18next.t('fuel_type'), // alimentazione
		width: 110,
	},
	{
		accessor: 'select',
		id: 'select',
		Header: '',
		width: 100,
		Cell: (data) => {
			return  <ButtonSelect />
		}
	},
];
