import { useState, useEffect, useMemo } from 'react';
import { Column } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { Col, Container, Row } from 'react-bootstrap';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { useApiClient } from '../../hooks/useApiClient';
import toaster from '../../utils/toaster';
import TableContainer from '../../components/genericTable/TableContainer';
import Btn from '../../components/Btn/Btn';
import Restricted from '../../components/Restricted/Restricted';
import { columnsData, columnsDataOffer } from './columnsData';
import { Roles } from '../../types';
import { useRefresh } from '../../hooks/useRefresh';
import { GTMsendEvent } from '../../utils/trackingGA4'
import './OfferListPage.scss';

import type { DocumentDeleteRequestDTO } from '../../api/models/DocumentDeleteRequestDTO';

const OffersPage = () => {
	const { apiClient, handleApi } = useApiClient();
	const [response, setResponse]: [any, any] = useState(null);
	const [responseVehicles, setResponseVehicles]: [any, any] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [offerToApproveCount, setOfferToApproveCount] = useState(null);
	const refresh = useRefresh();
	const { t } = useTranslation()
	const navigate = useNavigate();

	const deleteDocument = (values) => {
		let body: DocumentDeleteRequestDTO = {} as DocumentDeleteRequestDTO;

		if (values.flag_doc === 'dettagli_veicolo') {
			body.document = values.document?.map((elem) => {
				// if document.name is equal to values.document_name then set is_active to false
				(elem.name === values.document_name) && (elem.is_active = false)
				return elem
			});
			body.stato_corrente = { ...values?.stato_corrente };
		}

		if (values.flag_doc === 'stato_corrente') {
			body.document = values.stato_corrente.document;
			body.stato_corrente = { ...values?.stato_corrente };

			if (body.stato_corrente) {
				if (body.stato_corrente.document && body.stato_corrente.document.length > 0) {
					body.stato_corrente.document.map(elem => {
						// if document.name is equal to values.document_name then set is_active to false
						(elem.name === values.document_name) && (elem.is_active = false)
						return elem
					})
				}
			}
		}

		handleApi(apiClient.vehicleController.deleteDocument(values.vehicleId as string, body))
			.then(() => {
				GTMsendEvent({
					event: 'delete_offer',
					category: 'offer',
					action: 'delete',
					label: 'delete_offer',
					});

				refresh();
			})
			.catch((err) => {
				setError(err);
				setLoading(false);
				toaster.error(
					err.message || i18next.t('error_updating_data')
				);
			});
	};

	useEffect(() => {
		handleApi(apiClient.leasysController.getLeasys())
			.then((res) => {
				res.forEach(item => {
					if (!item?.offerta?.data_valid) {
						item.offerta.data_valid = ''
						return item
					}
				})
				setResponse(res);
				setLoading(false);
			})
			.catch((err) => {
				setError(err);
				setLoading(false);
				toaster.error(
					err.message || i18next.t('error_loading_data')
				);
			});
	}, []);


	useEffect(() => {
		// Get all the offers with the corresponding data
		handleApi(apiClient.vehicleController.getVehiclesFilter())
			.then((res) => {
				const rowData: any = []
				const filterVehicle = res.filter((item: any) => { return item.stato_corrente.document?.length > 0 || item.document?.length > 0 });
				filterVehicle?.forEach((item: any) => {
					// get documents from the dettaglio-veicolo document
					item?.document
						// filter out the inactive documents
						.filter((doc: any) => doc.is_active)
						.forEach((doc: any) => {
							const row: any = {
								marca: item.marca,
								modello: item.modello,
								nr_ordine: item.nr_ordine,
								codice_costruttore: item.codice_costruttore,
								del_function: deleteDocument,
								document: item.document,
								dealer: item.dealer,
								vehicleId: item.id,
								document_name: doc.name,
								city: item.city,
								targa: item.targa,
								provvigioni: doc.provvigioni,
								stato_corrente: item.stato_corrente,
								flag_doc: 'dettagli_veicolo'
							}
							rowData.push(row);
						});

					// get documents from the stato-corrente document
					item?.stato_corrente?.document
						// filter out the inactive documents
						.filter((doc: any) => {
							return doc.is_active
						})
						.forEach((doc: any) => {
							const row: any = {
								marca: item.marca,
								modello: item.modello,
								nr_ordine: item.nr_ordine,
								codice_costruttore: item.codice_costruttore,
								del_function: deleteDocument,
								document: item.document,
								dealer: item.dealer,
								vehicleId: item.id,
								document_name: doc.name,
								city: item.city,
								targa: item.targa,
								provvigioni: doc.provvigioni,
								stato_corrente: item.stato_corrente,
								flag_doc: 'stato_corrente'
							}
							rowData.push(row);
						});
				});
				setResponseVehicles(rowData);
				setOfferToApproveCount(filterVehicle?.length);
				setLoading(false);
			})
			.catch((err) => {
				setError(err);
				setLoading(false);
				toaster.error(
					err.message || i18next.t('error_loading_data')
				);
			});
	}, []);

	const columns = useMemo(() => columnsData as Column<Object>[], []);
	const columnsOffer = useMemo(() => columnsDataOffer as Column<Object>[], []);
	const data = useMemo(() => response ?? [], [response]);
	const dataVehicles = useMemo(() => responseVehicles ?? [], [responseVehicles]);

	return (
		<Container fluid>
			<Row>
				<Col md={6} className="display-6">
				{t('list_offers')}
				</Col>
				<Restricted to={[Roles.Master, Roles.Admin]}>
					<Col md={6} className="mt-4 mt-md-0 text-md-end">
						<Btn
						 to="/offers/new"
						 onClick={() => {
							navigate("/offers/new");

							GTMsendEvent({
								event: 'CTA_create_new_offer',
								evtCat: 'offer',
								evtAct: 'create',
								evtLab: 'new_offer'
							})
						 }}

						 >
							<FaPlus /> {t('create_new_offer')}
						</Btn>
					</Col>
				</Restricted>
			</Row> 

			<TableContainer
				columns={columns}
				data={data}
				loading={loading}
				error={error}
			></TableContainer>

			{/* // Offerte da approvare */}
			<Restricted to={[Roles.Master, Roles.Admin]}>
				<Col md={6} className="display-6">
				{t('offers_to_approve')}
				</Col>

				{!!offerToApproveCount && (
					<TableContainer
						columns={columnsOffer}
						data={dataVehicles}
						loading={loading}
						error={error}
					></TableContainer>
				)}
				{!offerToApproveCount && (
					<p className="no-offer-to-approve-msg">
						{t('no_offers_to_approve')}
					</p>
				)}
			</Restricted>
		</Container>
	);
};

export default OffersPage;
