import { Row, Col, Card, Button } from "react-bootstrap"
import ImageGallery from 'react-image-gallery';
import { useTranslation } from 'react-i18next';

import { VehicleEntity } from "../../api";
import { useAuth } from "../../hooks/useAuth";


const VehicleCardBody = ({ showLinkBtn = false, showState = false, data, children }: {
    showLinkBtn?: boolean,
    showState?: boolean,
    data: VehicleEntity,
    children?: JSX.Element
}) => {
    const { userRole } = useAuth();
    const { t } = useTranslation()

    return (
        <Card.Body>
            <Row>
                <Col lg={6}>
                    <ImageGallery
                        lazyLoad={true}
                        showNav={false}
                        onErrorImageURL={
                            process.env.PUBLIC_URL + '/images/placeholder.png'
                        }
                        showPlayButton={false}
                        items={
                            data?.image_list?.length
                                ? data?.image_list?.map((item: any) => ({
                                    original: item?.url,
                                    thumbnail: item?.url,
                                }))
                                : [{ original: '', thumbnail: '' }]
                        }
                    />
                </Col>
                <Col lg={6}>
                    <Row>
                        <Col xs={12}>
                            <div className="h2">
                                {data?.dati_veicolo?.marca}{' '}
                                {data?.dati_veicolo?.modello}
                            </div>
                            <p className="fs-4">
                                {data?.dati_veicolo?.allestimento}
                            </p>

                            {showState && (
                                <div>
                                   {t('state')}: <b>{data.stato_corrente?.type}</b>
                                </div>
                            )}

                            {showLinkBtn && data.id && (
                                <div className="mt-3">
                                    <Button href={`/details/${data.id}`}>{t('visualize_veicolo')}</Button>
                                </div>
                            )}
                        </Col>
                    </Row>

                    <hr />

                    <Row>
                        <Col sm={5}>
                            {data?.dettagli_veicolo && (
                                <>
                                    <ul className="primary-list">
                                        <li>{data?.dati_veicolo?.cambio}</li>
                                        <li>{data?.dati_veicolo?.motore}</li>
                                        <li>{data?.dati_veicolo?.alimentazione}</li>
                                    </ul>
                                    <div className="renter-name">
                                        {userRole !== 'AZIENDA' ?
                                            <div>
                                                <div className="label">{t('auto_dealership')}:</div>
                                                <div className="value">
                                                    {data?.dettagli_veicolo?.dealer}
                                                </div>
                                            </div>
                                            : ""}
                                        {/* Il nome noleggiatore da mettere qui è quello che offre l'offerta corrispondente al canone minimo */}
                                    </div>
                                </>
                            )}
                        </Col>

                        {children}
                    </Row>

                    {userRole !== 'AZIENDA' && data?.dettagli_veicolo?.promo_note ? (
                        <Row>
                            <Col>
                                <hr />
                                <small className="mt-2 d-block fw-bold" style={{fontSize: 20}}>
                                    {data?.dettagli_veicolo.promo_note}
                                </small>
                            </Col>
                        </Row>
                    ) : ('')}
                </Col>
            </Row>
        </Card.Body>
    )
}

export default VehicleCardBody;