import { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import i18next from 'i18next';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
// import Container from 'react-bootstrap/Container';
import { Card, Col, Form, Row, Container } from 'react-bootstrap';

import { useApiClient } from '../../hooks/useApiClient';
import toaster from '../../utils/toaster';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import CreatableSelectExtended from '../../components/CreatableSelectExtended/CreatableSelectExtended';
import Btn from '../../components/Btn/Btn';
import {
  ApiError,
  CancelablePromise,
  VehicleUpdateRequestDTO,
  VehicleCreateRequestDTO,
  DettaglioVeicoloDTO,
  OptionalDTO,
} from '../../api';
import { GTMsendEvent } from '../../utils/trackingGA4';
import OptionalsCard from '../../components/OptionalsCard/OptionalsCard';
import { cloneDeep } from 'lodash'
import { NOLEGGIATORI_QUOTATORE } from '../../utils/constants';

type PageMode = 'new' | 'edit' | 'clone';

const deducePageMode = (id: string, pathname: string): PageMode => {
  if (id === 'new') return 'new';
  else return pathname.split('/').pop() as 'edit' | 'clone';
};

const newOptional: OptionalDTO = {
  codice_costruttore: '',
  descrizione: '',
  valore: 0,
};

const listaNoleggiatoriQuotatore = [
  { noleggiatore: NOLEGGIATORI_QUOTATORE.arval.name, is_checked: true },
  { noleggiatore: NOLEGGIATORI_QUOTATORE.leaseplan.name, is_checked: true },
  { noleggiatore: NOLEGGIATORI_QUOTATORE.ald.name, is_checked: true },
  { noleggiatore: NOLEGGIATORI_QUOTATORE.leasys.name, is_checked: true },
  { noleggiatore: NOLEGGIATORI_QUOTATORE.athlon.name, is_checked: true },
  { noleggiatore: NOLEGGIATORI_QUOTATORE.alphabet.name, is_checked: true },
  { noleggiatore: NOLEGGIATORI_QUOTATORE.drivalia.name, is_checked: true },
  { noleggiatore: NOLEGGIATORI_QUOTATORE.rent2go.name, is_checked: true },
  { noleggiatore: NOLEGGIATORI_QUOTATORE.vw_leasing.name, is_checked: true },
  { noleggiatore: NOLEGGIATORI_QUOTATORE.kinto.name, is_checked: false },
  { noleggiatore: NOLEGGIATORI_QUOTATORE.unipol_rental.name, is_checked: true },
  { noleggiatore: NOLEGGIATORI_QUOTATORE.hyundai_rent.name, is_checked: true }
];


const DetailsFormPage = () => {
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation();
  const pageMode = deducePageMode(id!, pathname);
  const { apiClient, handleApi } = useApiClient();

  const [addOptionDisabled, setAddOptionDisabled] = useState(false);
  const [initialValues, setInitialValues] = useState(null as any);
  const [checked, setChecked] = useState(true);
  const [loading, setLoading] = useState(true);
  const [externalWarnings, setExternalWarnings] = useState<any>(null);
  const [externalErrors, setExternalErrors] = useState<any>(null);
  const [pageTitle, setPageTitle] = useState('');
  const [vehicleName, setVehicleName] = useState('');
  const [dealerOptions, setDealerOptions] = useState([] as any[]);
  const navigate = useNavigate();
  const { t } = useTranslation()

  const stringToOption = (str: string) => {
    return {
      value: str,
      label: str || '',
    };
  };

  useEffect(() => {
    handleApi(apiClient.dealerController.getRegisterDealers())
      .then((res) =>
        setDealerOptions(res.map((r) => stringToOption(r.ragione_sociale)))
      )
      .catch((err) => { });
  }, []);

  useEffect(() => {
    if (pageMode === "new") {
      setPageTitle(i18next.t('add_vehicle'));

      setInitialValues({
        dealer: '',
        codice_costruttore: '',
        settimana_prod: '',
        data_disp: '',
        city: '',
        data_telaio: '',
        telaio: '',
        data_immatricolazione: '',
        targa: '',
        id_bo: '',
        giorno_disp_horizon: '',
        promo: false,
        promo_note: '',
        note_generiche: '',
        codice_colore_esterno: '',
        codice_colore_interno: '',
        colore_esterno: '',
        colore_interno: '',
        optionals: [newOptional],
        nr_ordine_list: [],
        document: [],
        lista_noleggiatori_quotatore: listaNoleggiatoriQuotatore,
        extra_sconto_perc: 0
      });

      setAddOptionDisabled(true);
      setLoading(false);
    }
    // EDIT or CLONE
    else {
      if (pageMode === 'edit') setPageTitle(i18next.t('modify_vehicle'));
      if (pageMode === 'clone') setPageTitle(i18next.t('duplicate_vehicle'));

      handleApi(apiClient.vehicleController.getVehicles1(id!))
        .then((res) => {
          setVehicleName(
            `${res.dati_veicolo?.marca} ${res.dati_veicolo?.modello}`
          );

          let listaNoleggiatoriToUpdate = cloneDeep(listaNoleggiatoriQuotatore)
          listaNoleggiatoriToUpdate.forEach(el => {
            const noleggiatore = el.noleggiatore;
            const nres = res.lista_noleggiatori_quotatore.find(n => n.noleggiatore === noleggiatore)

            if (nres) {
              el.is_checked = nres.is_checked
            }
          })

          const initialVals: any = {
            dealer: res.dettagli_veicolo?.dealer ?? '',
            codice_costruttore: res.dettagli_veicolo?.codice_costruttore,
            settimana_prod: res.dettagli_veicolo?.settimana_prod ?? '',
            data_disp: res.dettagli_veicolo?.data_disp ?? '',
            city: res.dettagli_veicolo?.city ?? '',
            data_telaio: res.dettagli_veicolo?.data_telaio ?? '',
            telaio: res.dettagli_veicolo?.telaio ?? '',
            data_immatricolazione:
              res.dettagli_veicolo?.data_immatricolazione ?? '',
            targa: res.dettagli_veicolo?.targa ?? '',
            id_bo: res.dettagli_veicolo?.id_bo ?? '',
            giorno_disp_horizon: res.dettagli_veicolo?.giorno_disp ?? '',
            promo: res.dettagli_veicolo?.promo ?? 'false',
            promo_note: res.dettagli_veicolo?.promo_note ?? '',
            note_generiche: res.dettagli_veicolo?.note ?? '',
            codice_colore_esterno:
              res.dettagli_veicolo?.codice_colore_esterno ?? '',
            codice_colore_interno:
              res.dettagli_veicolo?.codice_colore_interno ?? '',
            colore_esterno: res.dettagli_veicolo?.colore_esterno ?? '',
            colore_interno: res.dettagli_veicolo?.colore_interno ?? '',
            optionals: res.optionals?.length ? res.optionals : [newOptional],
            document: res.dettagli_veicolo?.document?.length ? res.dettagli_veicolo?.document : [],
            stato_corrente: res.stato_corrente,
            prezzo_listino: res.dati_veicolo.prezzo_listino,
            lista_noleggiatori_quotatore: listaNoleggiatoriToUpdate,
            extra_sconto_perc: res.dati_veicolo.extra_sconto_perc
            // in_evidenza: res.dettagli_veicolo?.in_evidenza ?? 'false',
          };

          setAddOptionDisabled(!res.optionals?.length);

          // handle nr_ordine VS nr_ordine_list
          if (pageMode === 'edit') {
            // edit mode has a single nr_ordine
            initialVals.nr_ordine = res.dettagli_veicolo?.nr_ordine ?? '';
          }
          if (pageMode === 'clone') {
            // when cloning, user must provide new order numbers
            initialVals.nr_ordine_list = [];
          }

          setInitialValues(initialVals);
          setLoading(false);
        })
        .catch((error) => { });
    }
  }, []);

  const validateFn = (values) => {
    const errors: any = {};
    const REQUIRED_MESSAGE = i18next.t('mandatory');

    setAddOptionDisabled(false);

    for (const optional of values.optionals) {
      if (!optional.codice_costruttore) {
        setAddOptionDisabled(true);
        break;
      }
    }

    if (!values.dealer) {
      errors.dealer = REQUIRED_MESSAGE;
    }
    if (!values.codice_costruttore) {
      errors.codice_costruttore = REQUIRED_MESSAGE;
    }
    if (!values.codice_colore_esterno) {
      errors.codice_colore_esterno = REQUIRED_MESSAGE;
    }
    if (!values.codice_colore_interno) {
      errors.codice_colore_interno = REQUIRED_MESSAGE;
    }
    if (pageMode === 'edit') {
      if (!values.nr_ordine) {
        errors.nr_ordine = REQUIRED_MESSAGE;
      }
    }
    if (pageMode === 'new' || pageMode === 'clone') {
      if (!values.nr_ordine_list.length) {
        errors.nr_ordine_list = REQUIRED_MESSAGE;
      }
    }

    return errors;
  };

  const handleValuesToBody = (values: any, mode: PageMode) => {
    const clonedValues = JSON.parse(JSON.stringify(values));

    clonedValues.optionals = clonedValues.optionals?.filter(
      (o: OptionalDTO) => o.codice_costruttore
    );

    let body: VehicleUpdateRequestDTO | VehicleCreateRequestDTO;

    if (mode === 'edit') {
      body = clonedValues as VehicleUpdateRequestDTO;
    }

    if (['clone', 'new'].includes(mode)) {
      const nr_ordine_list: Array<string> = clonedValues.nr_ordine_list;
      delete clonedValues.nr_ordine_list;

      body = {
        dettaglio_veicolo: clonedValues as DettaglioVeicoloDTO,
        nr_ordine_list,
        is_checked: checked,
      } as VehicleCreateRequestDTO;
    }

    return body!;
  };

  const onSubmitFn = (values, { setSubmitting, setFieldValue }) => {
    setSubmitting(false);
    // return
    let fn: CancelablePromise<any>;
    const body = handleValuesToBody(values, pageMode);
    if (pageMode === 'edit') {
      fn = apiClient.vehicleController.putVehicles(
        id!,
        body as VehicleUpdateRequestDTO
      );
    } else {
      fn = apiClient.vehicleController.postVehicles(
        body as VehicleCreateRequestDTO
      );
    }

    const ordineListLength = (body as VehicleCreateRequestDTO)?.nr_ordine_list
      ?.length;
    const successMessages = {
      new:
        ordineListLength > 1
          ? `${ordineListLength} ${i18next.t('created_vehicles_success')}`
          : i18next.t('created_vehicle_success'),
      clone:
        ordineListLength > 1
          ? `Veicolo duplicato ${ordineListLength} volte con successo`
          : i18next.t('duplicated_vehicle_success'),
      edit: i18next.t('updated_vehicle_success'),
    };

    handleApi(fn)
      .then((res) => {
        setSubmitting(false);

        if (res.warnings) {
          setExternalWarnings(res.warnings);

          Object.values(res.warnings).some((v: any) => !!v) &&
            toaster.warning(
              res.warnings?.image_list || i18next.t('there_are_warnings')
            );
        }

        if (checked) {
          if (res.vehicle_entities?.length && res.vehicle_entities[0]?.id) {
            navigate(`/details/${res.vehicle_entities[0]?.id}/edit`);
            navigate(0);
          }

          toaster.success(successMessages[pageMode]);
          if (pageMode === 'new') {
            GTMsendEvent({
              event: 'complete_add_vehicle',
              item_id: values.nr_ordine_list[0],
              affiliation: values.dealer,
              item_category3: values.codice_costruttore,
            });
          } else if (pageMode === 'edit') {
            GTMsendEvent({
              event: 'complete_actions_sale',
              actionType: 'modifica_dati_veicolo',
            });
          } else if (pageMode === 'clone') {
            GTMsendEvent({
              event: 'complete_actions_sale',
              actionType: 'duplica_veicolo',
            });
          }
        } else {
          if (res.vehicle_entities?.length) {
            !values.colore_esterno &&
              setFieldValue(
                'colore_esterno',
                res.vehicle_entities[0].dettagli_veicolo?.colore_esterno
              );

            !values.colore_interno &&
              setFieldValue(
                'colore_interno',
                res.vehicle_entities[0].dettagli_veicolo?.colore_interno
              );

            values.optionals?.forEach(
              (optional: OptionalDTO, index: number) => {
                const o: OptionalDTO = res.vehicle_entities[0].optionals?.find(
                  (item: OptionalDTO) =>
                    item.codice_costruttore === optional.codice_costruttore
                );

                if (!o) {
                  return;
                }

                if (!optional.descrizione) {
                  setFieldValue(
                    `optionals.${index}.descrizione`,
                    o.descrizione
                  );
                }

                if (!optional.valore) {
                  setFieldValue(`optionals.${index}.valore`, o.valore);
                }
              }
            );
          }

          toaster.success(i18next.t('verification_successful'));
        }
      })
      .catch((err: ApiError) => {
        setSubmitting(false);

        err.body.errors && setExternalErrors(err.body.errors);

        if (err.body.warnings) {
          setExternalWarnings(err.body.warnings);

          Object.values(err.body.warnings).some((v: any) => !!v) &&
            toaster.warning(
              err.body.warnings?.image_list || i18next.t('there_are_warnings')
            );
        }

        toaster.error(
          err.body.errors?.dup_key || i18next.t('data_entry_error')
        );
      });
  };

  return (
    <Container fluid>
      <Breadcrumb
        item1={{ name: i18next.t('vehicles_list'), link: '/available-vehicles' }}
        item2={{ name: i18next.t('vehicle_details'), link: '/details/' + id }}
        activeItem={{
          name: pageMode === 'new' ? i18next.t('add') : vehicleName,
        }}
      ></Breadcrumb>

      <div className="display-6 my-4">{pageTitle}</div>

      {!loading && initialValues && (
        <Formik
          initialValues={initialValues}
          validate={validateFn}
          onSubmit={onSubmitFn}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form>
              <Card className="mt-2">
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          {t('dealer')}
                          <span className="red-ast">*</span>
                        </Form.Label>

                        <Select
                          value={stringToOption(values.dealer)}
                          onBlur={handleBlur}
                          onChange={(opt) => {
                            setFieldValue('dealer', opt?.value || '');
                          }}
                          name="dealer"
                          options={dealerOptions}
                          className={
                            'hor-singleselect group-input ' +
                            (errors.dealer && touched.dealer
                              ? 'error-border'
                              : '')
                          }
                        />
                        {errors.dealer && touched.dealer && (
                          <small className="text-danger">
                            {errors.dealer as string}
                          </small>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          {t('builder_code')}
                          <span className="red-ast">*</span>
                        </Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          //disabled={!!id}
                          value={values.codice_costruttore}
                          name="codice_costruttore"
                          type="text"
                          className={
                            externalErrors?.codice_costruttore ||
                              (errors.codice_costruttore &&
                                touched.codice_costruttore)
                              ? 'error-border'
                              : ''
                          }
                        />
                        {errors.codice_costruttore &&
                          touched.codice_costruttore && (
                            <small className="text-danger">
                              {errors.codice_costruttore as string}
                            </small>
                          )}

                        {externalErrors?.codice_costruttore && (
                          <small className="text-danger">
                            {externalErrors.codice_costruttore as string}
                          </small>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          {t('external_color_code')}
                          <span className="red-ast">*</span>
                        </Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.codice_colore_esterno}
                          name="codice_colore_esterno"
                          type="text"
                          className={
                            errors.codice_colore_esterno &&
                              touched.codice_colore_esterno
                              ? 'error-border'
                              : externalWarnings?.codice_colore_esterno
                                ? 'warning-border'
                                : ''
                          }
                        />
                        {errors.codice_colore_esterno &&
                          touched.codice_colore_esterno && (
                            <small className="text-danger">
                              {errors.codice_colore_esterno as string}
                            </small>
                          )}

                        {externalWarnings?.codice_colore_esterno && (
                          <small className="text-warning">
                            {externalWarnings.codice_colore_esterno}
                          </small>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('external_color_description')}</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.colore_esterno}
                          name="colore_esterno"
                          type="text"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          {t('internal_color_code')}
                          <span className="red-ast">*</span>
                        </Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.codice_colore_interno}
                          name="codice_colore_interno"
                          type="text"
                          className={
                            externalErrors?.codice_colore_interno ||
                              (errors.codice_colore_interno &&
                                touched.codice_colore_interno)
                              ? 'error-border'
                              : externalWarnings?.codice_colore_interno
                                ? 'warning-border'
                                : ''
                          }
                        />
                        {errors.codice_colore_interno &&
                          touched.codice_colore_interno && (
                            <small className="text-danger">
                              {errors.codice_colore_interno as string}
                            </small>
                          )}

                        {externalWarnings?.codice_colore_interno && (
                          <small className="text-warning">
                            {externalWarnings.codice_colore_interno}
                          </small>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('internal_color_description')}</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.colore_interno}
                          name="colore_interno"
                          type="text"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        {pageMode === 'edit' ? (
                          <>
                            <Form.Label>
                              {t('n_order')}
                              <span className="red-ast">*</span>
                            </Form.Label>
                            <Form.Control
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.nr_ordine}
                              name="nr_ordine"
                              type="text"
                              className={
                                errors.nr_ordine && touched.nr_ordine
                                  ? 'error-border'
                                  : ''
                              }
                            />
                            {errors.nr_ordine && touched.nr_ordine && (
                              <small className="text-danger">
                                {errors.nr_ordine as string}
                              </small>
                            )}
                          </>
                        ) : (
                          <>
                            <Form.Label>
                              {t('n_orders')}
                              <span className="red-ast">*</span>
                            </Form.Label>
                            <CreatableSelectExtended
                              onBlur={handleBlur}
                              setFieldTouched={setFieldTouched}
                              setFieldValue={setFieldValue}
                              placeholder={`${i18next.t('press_enter_or_space_to_create')}...`}
                              value={values.nr_ordine_list}
                              name="nr_ordine_list"
                              className={
                                errors.nr_ordine_list && touched.nr_ordine_list
                                  ? 'error-border'
                                  : ''
                              }
                            ></CreatableSelectExtended>
                            {errors.nr_ordine_list &&
                              touched.nr_ordine_list && (
                                <small className="text-danger">
                                  {errors.nr_ordine_list as string}
                                </small>
                              )}
                          </>
                        )}
                      </Form.Group>
                    </Col>

                    {/* {pageMode !== 'edit' && (
												<Col md={6}>
													<Form.Group className="mb-3">
														<Form.Label>Codice optional</Form.Label>
														<CreatableSelectExtended
															onBlur={handleBlur}
															setFieldTouched={setFieldTouched}
															setFieldValue={setFieldValue}
															placeholder="Premi Enter o la barra spaziatrice per creare..."
															value={values.optional_list}
															name="optional_list"
															className={
																externalWarnings?.optionals
																	? 'warning-border'
																	: ''
															}
														></CreatableSelectExtended>

														{externalWarnings?.optionals && (
															<small className="text-warning">
																{externalWarnings.optionals}
															</small>
														)}
													</Form.Group>
												</Col>
											)} */}

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Città</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.city}
                          name="city"
                          type="text"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('week_of_production')}</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="settimana_prod"
                          value={values.settimana_prod}
                          type="text"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('vehicle_registration_date')}</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.data_immatricolazione}
                          name="data_immatricolazione"
                          type="date"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('expected_availability_date')}</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="data_disp"
                          value={values.data_disp}
                          type="date"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('vehicle_frame')}</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.telaio}
                          name="telaio"
                          type="text"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('vehicle_frame_date')}</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.data_telaio}
                          name="data_telaio"
                          type="date"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('vehicle_plate')}</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.targa}
                          name="targa"
                          type="text"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('id_bo')}</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.id_bo}
                          name="id_bo"
                          type="text"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('horizon_availability_day')}</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.giorno_disp_horizon}
                          name="giorno_disp_horizon"
                          type="date"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        {/* <Form.Label>Promo</Form.Label> */}
                        <Form.Check // prettier-ignore
                          // disabled
                          type="switch"
                          id="promo"
                          label={i18next.t('promo')}
                          name="promo"
                          className="switch"
                          checked={values.promo}
                          onChange={(opt) => {
                            setFieldValue(
                              'promo',
                              opt?.target.checked || false
                            );
                          }}
                        />
                      </Form.Group>
                    </Col>

                    <Row>
                      <Form.Label>{t('rental_companies')}</Form.Label>

                      {values.lista_noleggiatori_quotatore && values.lista_noleggiatori_quotatore.map((noleggiatore, index) => (
                        <Col md={3} key={index} className='pb-3'>
                          <Form.Group className="mb-3">
                            <Form.Check
                              type="checkbox"
                              id={`checkbox-${noleggiatore.noleggiatore}`}
                              label={noleggiatore.noleggiatore}
                              checked={noleggiatore.is_checked}
                              onChange={(e) => setFieldValue(`lista_noleggiatori_quotatore.${index}.is_checked`, e.target.checked)}
                            />
                          </Form.Group>
                        </Col>
                      ))}
                    </Row>


                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('list_price')}</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.prezzo_listino}
                          name="prezzo_listino"
                          type="number"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('extra_list_price_discount_percentage')}</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.extra_sconto_perc}
                          name="extra_sconto_perc"
                          type="number"
                        />
                      </Form.Group>
                    </Col>

                    {/* <Col md={6}>
											<Form.Group className="mb-3">
												<Form.Label>Promo</Form.Label>
												<Form.Control
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.promo}
													name="promo"
													type="text"
												/>
											</Form.Group>
										</Col> */}

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('promo_note')}</Form.Label>
                        <Form.Control
                          as="textarea"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.promo_note}
                          name="promo_note"
                          type="text"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('generic_notes')}</Form.Label>
                        <Form.Control
                          as="textarea"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.note_generiche}
                          name="note_generiche"
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <div className="display-7 my-4">{t('optionals')}</div>

              <OptionalsCard
                handleChange={handleChange}
                values={values}
                addOptionDisabled={addOptionDisabled}
                onOptionAdded={() => {
                  setAddOptionDisabled(true);
                }}
              />

              <Row>
                <Col className="mt-4 text-end">
                  {pageMode !== 'edit' && (
                    <Btn
                      className="me-4"
                      disabled={isSubmitting}
                      onClick={() => {
                        setChecked(false);
                        setExternalErrors(null);
                        setExternalWarnings(null);
                        handleSubmit();

                        Object.keys(errors).length &&
                          toaster.error(
                            i18next.t('errors_in_submitted_fields')
                          );
                      }}
                    >
                      {t('verify')}
                    </Btn>
                  )}

                  <Btn
                    icon="arrow-right"
                    disabled={isSubmitting}
                    onClick={() => {
                      setChecked(true);
                      setExternalErrors(null);
                      setExternalWarnings(null);
                      handleSubmit();

                      Object.keys(errors).length &&
                        toaster.error(
                          i18next.t('errors_in_submitted_fields')
                        );
                    }}
                  >
                    {t('save')}
                  </Btn>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </Container>
  );
};

export default DetailsFormPage;
