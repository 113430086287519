import { Form, InputGroup } from 'react-bootstrap';
import i18next from 'i18next';

const ColumnFilterDoubleInput = ({
	props,
	column: { id },
}) => {
	const { filterState, setFilterState, type } = props;

	const handleChange = (e) => {

		setFilterState((prevState) => {
			const newState = {
				...prevState,
				[id]: {
					...prevState[id],
					[e.target.name]: e.target.value,
				},
			};

			return newState;
		});
	};

	return (
		// https://stackoverflow.com/questions/20321202/not-showing-placeholder-for-input-type-date-field
		<InputGroup>
			<Form.Control
				name="from"
				size="lg"
				type={type}
				placeholder={`${i18next.t('from')}...`}
				value={filterState[id].from}
				onChange={handleChange}
			/>
			<Form.Control
				name="to"
				size="lg"
				type={type}
				placeholder={`${i18next.t('to')}...`}
				value={filterState[id].to}
				onChange={handleChange}
			/>
		</InputGroup>
	);
};

export default ColumnFilterDoubleInput;
