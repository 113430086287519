import { VehicleStateEnum } from '../../api';
import { Roles } from '../../types';

// uguali a quelle usate in MyStockPage, a parte le props 'user' e 'stateList'

const filterForBackoffice = (data, user, stateList = null) => {
	let currentData;
	let states;

	if (!stateList) {
		states = [
			VehicleStateEnum.OPZIONATO,
			VehicleStateEnum.OSCURATO,
			VehicleStateEnum.VENDUTO,
		];
	} else {
		states = stateList;
	}
	currentData = data.filter((d) => {
		return (
			states.includes(d.stato_corrente?.type) &&
			d.stato_corrente.utente === user.username
		);
	});

	return currentData;
};

const filterForSales = (data, user, stateList = null) => {
	let currentData;
	let states;

	if (!stateList) {
		states = [VehicleStateEnum.OPZIONATO, VehicleStateEnum.VENDUTO];
	} else {
		states = stateList;
	}

	currentData = data.filter((d) => {
		return (
			states.includes(d.stato_corrente?.type) &&
			d.stato_corrente.utente === user.username
		);
	});

	return currentData;
};

const filterForDealer = (data, user, stateList = null) => {
	let currentData;
	let states;

	if (!stateList) {
		states = [VehicleStateEnum.OPZIONATO, VehicleStateEnum.VENDUTO];
	} else {
		states = stateList;
	}
	const myDealer = user.attributes['custom:dealer'];

	currentData = data.filter((d) => {
		return (
			(states.includes(d.stato_corrente?.type) &&
				(d.stato_corrente.utente === user.username || d.dealer === myDealer)) ||
			(!stateList &&
				d.stato_corrente?.type === VehicleStateEnum.DISPONIBILE &&
				d.dealer === myDealer)
		);
	});

	return currentData;
};

export const filterFns = {
	[Roles.Backoffice]: filterForBackoffice,
	[Roles.Dealer]: filterForDealer,
	[Roles.Sales]: filterForSales,
	[Roles.Master]: filterForBackoffice,
	[Roles.Admin]: filterForBackoffice,
};
