import Pagination from 'react-bootstrap/Pagination';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

const GenericPagination = ({
	gotoPage,
	canPreviousPage,
	previousPage,
	nextPage,
	canNextPage,
	pageCount,
	state,
	pageOptions,
	setPageSize,
}) => {
	const { t } = useTranslation()
	return (
		<Pagination className="float-end mt-4">
			<Form.Select
				className="w-auto"
				value={state.pageSize}
				onChange={(e) => {
					setPageSize(Number(e.target.value));
				}}
			>
				{[5, 10, 20, 30, 50].map((pageSize) => (
					<option key={pageSize} value={pageSize}>
						 {t('show')} {pageSize}
					</option>
				))}
			</Form.Select>

			<Form.Label className="text-nowrap mx-3 mt-2">
				Pagina
			{/* {t('page')} */}
				<strong className="ms-1">
					{state.pageIndex + 1} of {pageOptions.length}
				</strong>
			</Form.Label>

			<Pagination.First
				onClick={() => gotoPage(0)}
				disabled={!canPreviousPage}
			/>
			<Pagination.Prev
				onClick={() => previousPage()}
				disabled={!canPreviousPage}
			/>
			<Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
			<Pagination.Last
				onClick={() => gotoPage(pageCount - 1)}
				disabled={!canNextPage}
			/>
			{/* <div className="mt-4">
        <pre>
          <code>{JSON.stringify(state, null, 2)}</code>
        </pre>
      </div> */}
		</Pagination>
	);
};

export default GenericPagination;
