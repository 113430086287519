import { NavLink } from 'react-router-dom';
import Icon from '../../components/Icon/Icon';
import Restricted from '../../components/Restricted/Restricted';
import { Roles } from '../../types';
import { FaRegFilePdf } from 'react-icons/fa';
import { MdDeleteOutline } from 'react-icons/md'
import { Storage } from 'aws-amplify';
import { getCleanFilename } from '../../utils/index'
import { GTMsendEvent } from '../../utils/trackingGA4'
import i18next from 'i18next';

export const columnsData = [
	{
		accessor: 'offerta.id',
		id: 'id',
		Header: '',
		disableSortBy: true,
	},
	{
		accessor: 'offerta.nome_offerta',
		id: 'nome_offerta',
		Header: i18next.t('name'),
	},
	{
		accessor: 'offerta.anticipo',
		id: 'anticipo',
		Header: i18next.t('downpayment'),
		Cell: ({ value }) => (value ? `€ ${value?.toLocaleString('it')}` : '€ ' + 0),
	},
	{
		accessor: 'offerta.importo_azienda',
		id: 'importo_azienda',
		Header: i18next.t('business_rental_fee'),
		Cell: ({ value }) =>
			value
				? `€ ${value?.toLocaleString('it', {
					minimumFractionDigits: 2,
				})}`
				: '',
	},
	{
		accessor: 'offerta.importo_privato',
		id: 'importo_privato',
		Header: i18next.t('private_rental_fee'),
		Cell: ({ value }) =>
			value
				? `€ ${value?.toLocaleString('it', {
					minimumFractionDigits: 2,
				})}`
				: '',
	},
	{
		accessor: 'offerta.durata',
		id: 'durata',
		Header: `${i18next.t('duration')} (${i18next.t('months')})`,
	},
	{
		accessor: 'offerta.km_totali',
		id: 'km_totali',
		Header: i18next.t('km_per_year'),
		Cell: ({ value }) => value?.toLocaleString('it'),
	},
	{
		id: 'actions',
		Header: '',
		headerClassName: 'column-sticky',
		className: 'column-sticky text-end',
		disableSortBy: true,
		Cell: (data: any) => (
			<Restricted to={[Roles.Master, Roles.Admin]}>
				<NavLink
					to={`/offers/${data.row?.original?.offerta?.id}`}
					onClick={() => {
						// navigate()
						GTMsendEvent({
							event: 'CTA_modify_offer',
							evtCat: 'offer',
							evtAct: 'modify',
							evtLab: 'modify_offer'
						})
					}}
					className="btn"
					state={data.row?.original}
				>
					<Icon
						style={{ width: '1.2rem', height: '1.2rem' }}
						name="arrow-right"
					/>
				</NavLink>
			</Restricted>
		),
	},
];

export const columnsDataOffer = [
	{
		accessor: 'id',
		id: 'id',
		Header: '',
		disableSortBy: true,
	},
	{
		accessor: 'document_name',
		id: 'document_name',
		Header: i18next.t('document_pdf'),
		Cell: ({ value }) => {
			return (
				<button
					onClick={async () => {
						const downloadLink: string = await Storage.get(
							value,
							{
								bucket:
									process.env
										.REACT_APP_AWS_STORAGE_BUCKET_VEHICLE,
							}
						);
						window.open(downloadLink, '_blank');
						// send event to GA4
						GTMsendEvent({
							event: "CTA_download_file",
							evtCat: 'offerta_da_approvare',
							evtAct: "download",
							evtLab: value
						})
					}}
					type="button"
					className="btn btn-sm"
				>
					<FaRegFilePdf
						style={{ width: '1.2rem', height: '1.6rem' }}
					/> {getCleanFilename(value?.replace('.pdf', ''))}
				</button>
			)
		}
	},
	{
		accessor: 'marca',
		id: 'marca',
		Header: i18next.t('brand'),
	},
	{
		accessor: 'modello',
		id: 'modello',
		Header: i18next.t('model'),
	},
	{
		accessor: 'nr_ordine',
		id: 'nr_ordine',
		Header: i18next.t('nr_commercial_order'),
		className: 'text-center',
		Cell: (data: any) => (
			<NavLink
				to={`/details/${data.row?.original?.vehicleId}`}
				className="btn text-decoration-underline"
				state={data.row?.original}
			>
				{data.row?.original?.nr_ordine}
			</NavLink>
		),
	},
	{
		accessor: 'codice_costruttore',
		id: 'codice_costruttore',
		Header: i18next.t('builder_code'),
		className: 'text-center'
	},
	{
		accessor: 'provvigioni',
		id: 'provvigioni',
		Header: i18next.t('commissions'),
		className: 'text-center'
	},
	{
		accessor: 'actions',
		id: 'actions',
		Header: '',
		headerClassName: 'column-sticky',
		className: 'column-sticky text-center',
		disableSortBy: true,
		Cell: (data: any) => (
			<button
				onClick={() => {
					// const a = await Storage.remove(
					// 	data.row?.original?.document_name,
					// 	{
					// 		bucket: process.env.REACT_APP_AWS_STORAGE_BUCKET_VEHICLE,
					// 	}
					// );
					data.row?.original?.del_function(data.row?.original);
				}}
				type="button"
				className="btn btn-sm"
			>
				<MdDeleteOutline
					style={{ width: '1.2rem', height: '1.6rem' }}
					name="delete"
				/>
			</button>
		),
	}
];

