import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';

interface BreadcrumbsProps {
	item1: { name: string; link: string };
	item2?: { name: string; link: string };
	activeItem: { name: string };
}

const appBreadcrumb = ({ item1, item2, activeItem }: BreadcrumbsProps) => {
	return (
		<Breadcrumb>
			<Breadcrumb.Item linkAs={Link} linkProps={{ to: item1.link }}>
				{item1.name}
			</Breadcrumb.Item>
			{item2 && (
				<Breadcrumb.Item linkAs={Link} linkProps={{ to: item2.link }}>
					{item2.name}
				</Breadcrumb.Item>
			)}
			<Breadcrumb.Item active>{activeItem.name}</Breadcrumb.Item>
		</Breadcrumb>
	);
};

export default appBreadcrumb;

