import { Formik } from 'formik';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { FieldArray } from 'formik';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import { Storage } from 'aws-amplify';
import Container from 'react-bootstrap/Container';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { DealerDTO } from '../../api';
import Btn from '../../components/Btn/Btn';
import { useApiClient } from '../../hooks/useApiClient';
import toaster from '../../utils/toaster';
import DealerGroupModal from '../../components/modals/dealerGroupModal';
import { arrayPrepend } from '../../utils';
import Restricted from '../../components/Restricted/Restricted';
import { Roles } from '../../types';
import { useAuth } from '../../hooks/useAuth';
import { GTMsendEvent } from '../../utils/trackingGA4'
import './DealerFormPage.scss';

const DealerFormPage = () => {
	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();
	const { apiClient, handleApi } = useApiClient();
	const { userRole } = useAuth();

	const [response, setResponse]: [any, any] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const [initialValues, setInitialValues] = useState(null as any);
	const [isModalVisibile, setModalVisibility] = useState(false);
	const [groupOptions, setGroupOptions] = useState([] as any[]);

	const [files, setFiles] = useState<File[]>([]);
	const fileTypes: string[] = ['png', 'jpeg', 'jpg'];
	const [uploadError, setUploadError] = useState<string | null>(null);
	const [deletedFiles, setDeletedFiles] = useState<string[]>([]);

	const { t } = useTranslation()

	const closeModal = () => {
		setModalVisibility(false);
	};
	const showModal = () => {
		setModalVisibility(true);
	};

	const stringToOption = (str: string) => {
		return {
			id: str,
			value: str,
			label: str || '-',
		};
	};

	useEffect(() => {
		// if edit dealer
		if (id !== 'new') {
			handleApi(apiClient.dealerController.getRegisterDealers1(id!))
				.then((res) => {
					setResponse(res);
					setLoading(false);

					setInitialValues({
						group: res.group,
						ragione_sociale: res.ragione_sociale,
						indirizzo: res.indirizzo,
						cf: res.cf,
						p_iva: res.p_iva,
						id_sales: res.id_sales,
						id_backoffice: res.id_backoffice,
						tipologia_dealer: res.tipologia_dealer,
						marchio: res.marchio,
						noleggiatori_convenzionati: res.noleggiatori_convenzionati,
						is_main_dealer: res.is_main_dealer,
						contatti_list: res.contatti_list,
						logo: res.logo
					});
				})
				.catch((err) => {
					setError(err);
					setLoading(false);
					toaster.error(
						err.message || i18next.t('error_loading_data')
					);
				});
		}
		// if new dealer
		else {
			const defaultContatto = {
				cognome: '',
				email: '',
				nome: '',
				ruolo: 'BACKOFFICE_OPERATIVO',
				telefono: '',
			};

			setInitialValues({
				group: '',
				ragione_sociale: '',
				indirizzo: '',
				cf: '',
				p_iva: '',
				id_sales: '',
				id_backoffice: '',
				tipologia_dealer: '',
				marchio: '',
				noleggiatori_convenzionati: '',
				is_main_dealer: false,
				contatti_list: [defaultContatto],
				logo: '',
			});

			setLoading(false);
		}

		handleApi(apiClient.groupController.getRegisterGroups()).then(
			(groupList) => {
				const options = arrayPrepend(groupList, '').map((groupStr) =>
					stringToOption(groupStr)
				);

				setGroupOptions(options);
			}
		);
	}, []);

	const validateFn = (values) => {
		const errors: any = {};

		const REQUIRED_MESSAGE = i18next.t('mandatory');

		if (!values.group) {
			errors.group = REQUIRED_MESSAGE;
		}

		if (!values.ragione_sociale) {
			errors.ragione_sociale = REQUIRED_MESSAGE;
		}

		if (!values.indirizzo) {
			errors.indirizzo = REQUIRED_MESSAGE;
		}

		if (!values.cf) {
			errors.cf = REQUIRED_MESSAGE;
		}

		if (!values.p_iva) {
			errors.p_iva = REQUIRED_MESSAGE;
		}

		if (!values.contatti_list[0].nome) {
			if (!errors.contatti_list) errors.contatti_list = [{}];
			errors.contatti_list[0].nome = REQUIRED_MESSAGE;
		}

		if (!values.contatti_list[0].cognome) {
			if (!errors.contatti_list) errors.contatti_list = [{}];
			errors.contatti_list[0].cognome = REQUIRED_MESSAGE;
		}

		if (!values.contatti_list[0].ruolo) {
			if (!errors.contatti_list) errors.contatti_list = [{}];
			errors.contatti_list[0].ruolo = REQUIRED_MESSAGE;
		}

		if (
			!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
				values.contatti_list[0].email
			)
		) {
			if (!errors.contatti_list) errors.contatti_list = [{}];
			errors.contatti_list[0].email = i18next.t('invalid_email');
		}
		if (!values.contatti_list[0].email) {
			if (!errors.contatti_list) errors.contatti_list = [{}];
			errors.contatti_list[0].email = REQUIRED_MESSAGE;
		}

		if (!values.contatti_list[0].telefono) {
			if (!errors.contatti_list) errors.contatti_list = [{}];
			errors.contatti_list[0].telefono = REQUIRED_MESSAGE;
		}

		return errors;
	};

	async function uploadFiles(values): Promise<void> {
		try {
			setUploadError(null);

			values.logo = [...(initialValues.logo || '')];

			for (const name of deletedFiles) {
				const a = await Storage.remove(name, {
					bucket: process.env.REACT_APP_AWS_STORAGE_BUCKET_DEALER_LOGO,
				});

				values.logo = '';
			}

			setDeletedFiles([]);

			for (const file of files) {
				const uploadRes: any = await Storage.put(file.name, file, {
					contentType: file.type,
					bucket: process.env.REACT_APP_AWS_STORAGE_BUCKET_DEALER_LOGO,
				});

				values.logo = uploadRes.key;
			}

			setInitialValues({
				...initialValues,
				logo: values.logo,
			});
			setFiles([]);
		} catch (error) {
			setUploadError(i18next.t('error_loading_data'));
		}
	}

	const submitFnFactory = (id) => {
		// if edit dealer
		if (id !== 'new') {
			return async (values, { setSubmitting }) => {
				//await uploadFiles(values);
				values.logo = ''
				const body: DealerDTO = values;

				handleApi(apiClient.dealerController.putRegisterDealers(id!, body))
					.then((res) => {
						setSubmitting(false);
						GTMsendEvent({
							event: 'update_dealer',
							category: 'dealer',
							action: 'update',
							label: 'update_dealer'
							,
						});
						toaster.success(i18next.t('dealer_updated_success'));
					})
					.catch((err) => {
						setSubmitting(false);
						toaster.error(
							err.message || i18next.t('error_updating_data')
						)
					});
			};
		}
		// if new dealer
		else {
			return async (values, { setSubmitting }) => {
				//await uploadFiles(values);
				const body: DealerDTO = values;

				handleApi(apiClient.dealerController.postRegisterDealers(body))
					.then((res) => {
						setSubmitting(false);
						GTMsendEvent({
							event: 'complete_new_dealer',
							evtCat: 'dealer',
							evtAct: 'complete',
							evtLab: 'new_dealer'
							,
						});
						toaster.success(i18next.t('dealer_create_success'));
						navigate(`/manage-dealers`);
					})
					.catch((err) => {
						setSubmitting(false);
						toaster.error(
							err.message ||
							i18next.t('unspecified_error_creating_new_dealer')
						);
					});
			};
		}
	};

	const deleteDealer = (id) => {
		handleApi(apiClient.dealerController.deleteRegisterDealers(id))
			.then((res) => {
				toaster.success(i18next.t('dealer_deleted_successfully'));
				GTMsendEvent({
					event: 'delete_dealer',
					category: 'dealer',
					action: 'delete',
					label: `delete_dealer`
				})
				navigate(`/manage-dealers`);
			})
			.catch((err) => {
				toaster.error(
					err.message || i18next.t('unspecified_error_deleting_dealer')
				);
			});
	};

	return (
		<Container className="dealer-form-page-container" fluid>
			<Breadcrumb
				item1={{ name: 'Gestione dealer', link: '/manage-dealers' }}
				activeItem={{ name: 'Dealer' }}
			></Breadcrumb>

			{!loading && (
				<div className="mb-3">
					<span className="display-6">
						{response ? response?.ragione_sociale.replace('_', ' ') : i18next.t('create_new_dealer')}
					</span>
					{id !== 'new' && (
						<Restricted to={[Roles.Backoffice, Roles.Master, Roles.Admin]}>
							<span className="float-end mt-2">
								<Button onClick={() => deleteDealer(id)}>
									<FaMinusCircle style={{ marginRight: '8px' }} />
									{t('delete_dealer')}
								</Button>
							</span>
						</Restricted>
					)}
				</div>
			)}

			{!loading && initialValues && (
				<Card className="mt-2">
					<Card.Body>
						<Formik
							initialValues={initialValues}
							validate={validateFn}
							onSubmit={submitFnFactory(id)}
						>
							{({
								values,
								errors,
								touched,
								handleChange,
								handleBlur,
								handleSubmit,
								isSubmitting,
								setFieldValue,
							}) => {
								return (
									<>
										<DealerGroupModal
											isVisible={isModalVisibile}
											closeFn={closeModal}
											addGroupFn={(newGroupName) => {
												setGroupOptions([
													...groupOptions,
													stringToOption(newGroupName),
												]);
												setFieldValue('group', newGroupName);
											}}
										/>

										<Form onSubmit={handleSubmit}>
											<Row>
												<Col md={6}>
													<Form.Group className="mb-3">
														<Form.Label>
														{t('membership_group')}
															<span className="red-ast">*</span>
														</Form.Label>
														<div className="group-input-btn-wrapper">
															<Select
																name="group"
																value={stringToOption(values.group)}
																options={groupOptions}
																onBlur={handleBlur}
																isDisabled={userRole !== Roles.Backoffice && userRole !== Roles.Master && userRole !== Roles.Admin}
																onChange={(opt) => {
																	// handleChange(opt?.value || '');
																	setFieldValue('group', opt?.value || '');
																}}
																className={`hor-singleselect group-input ${errors.group && touched.group
																	? 'error-border'
																	: ''
																	}`}
															/>
															<Restricted to={[Roles.Backoffice, Roles.Master, Roles.Admin]}>
																<Button
																	className="add-group-btn"
																	onClick={showModal}
																>
																	<FaPlusCircle />
																</Button>
															</Restricted>
														</div>
														{errors.group && touched.group && (
															<small className="text-danger">
																{errors.group as string}
															</small>
														)}
													</Form.Group>
												</Col>
												<Col md={6}>
													<Form.Group className="mb-3">
														<Form.Label>
														{t('business_name')}
															<span className="red-ast">*</span>
														</Form.Label>
														<Form.Control
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.ragione_sociale}
															name="ragione_sociale"
															type="text"
															disabled={userRole !== Roles.Backoffice && userRole !== Roles.Master && userRole !== Roles.Admin}
															// readOnly={userRole !== Roles.Backoffice}
															className={`${errors.ragione_sociale &&
																touched.ragione_sociale
																? 'error-border'
																: ''
																}`}
														/>
														{errors.ragione_sociale &&
															touched.ragione_sociale && (
																<small className="text-danger">
																	{errors.ragione_sociale as string}
																</small>
															)}
													</Form.Group>
												</Col>
											</Row>

											<Row>
												<Col md={6}>
													<Form.Group className="mb-3">
														<Form.Label>
														{t('address')}
															<span className="red-ast">*</span>
														</Form.Label>
														<Form.Control
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.indirizzo}
															name="indirizzo"
															type="text"
															disabled={userRole !== Roles.Backoffice && userRole !== Roles.Master && userRole !== Roles.Admin}
															className={`${errors.indirizzo && touched.indirizzo
																? 'error-border'
																: ''
																}`}
														/>
														{errors.indirizzo && touched.indirizzo && (
															<small className="text-danger">
																{errors.indirizzo as string}
															</small>
														)}
													</Form.Group>
												</Col>
												<Col md={6}>
													<Form.Group className="mb-3">
														<Form.Label>
														{t('fiscal_code')}
															<span className="red-ast">*</span>
														</Form.Label>
														<Form.Control
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.cf}
															name="cf"
															type="text"
															disabled={userRole !== Roles.Backoffice && userRole !== Roles.Master && userRole !== Roles.Admin}
															className={`${errors.cf && touched.cf ? 'error-border' : ''
																}`}
														/>
														{errors.cf && touched.cf && (
															<small className="text-danger">
																{errors.cf as string}
															</small>
														)}
													</Form.Group>
												</Col>
											</Row>

											<Row>
												<Col md={6}>
													<Form.Group className="mb-3">
														<Form.Label>
														{t('p_iva')}
															<span className="red-ast">*</span>
														</Form.Label>
														<Form.Control
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.p_iva}
															name="p_iva"
															type="text"
															disabled={userRole !== Roles.Backoffice && userRole !== Roles.Master && userRole !== Roles.Admin}
															className={`${errors.p_iva && touched.p_iva
																? 'error-border'
																: ''
																}`}
														/>
														{errors.p_iva && touched.p_iva && (
															<small className="text-danger">
																{errors.p_iva as string}
															</small>
														)}
													</Form.Group>
												</Col>
												<Col md={6}>
													<Form.Label>{t('head_group')}</Form.Label>
													<Form.Check
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.is_main_dealer}
														checked={values.is_main_dealer}
														disabled={userRole !== Roles.Backoffice && userRole !== Roles.Master && userRole !== Roles.Admin}
														name="is_main_dealer"
														type="checkbox"
														className="hor-check-box-big"
													/>
												</Col>
											</Row>

											<Row>
												<Col md={6}>
													<Form.Group className="mb-3">
														<Form.Label>{t('sales_horizon')}</Form.Label>
														<Form.Control
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.id_sales}
															disabled={userRole !== Roles.Backoffice && userRole !== Roles.Master && userRole !== Roles.Admin}
															name="id_sales"
															type="text"
														/>
													</Form.Group>
												</Col>
												<Col md={6}>
													<Form.Group className="mb-3">
														<Form.Label>{t('backoffice_horizon')}</Form.Label>
														<Form.Control
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.id_backoffice}
															disabled={userRole !== Roles.Backoffice && userRole !== Roles.Master && userRole !== Roles.Admin}
															name="id_backoffice"
															type="text"
														/>
													</Form.Group>
												</Col>
											</Row>

											<Row>
												<Col md={6}>
													<Form.Group className="mb-3">
														<Form.Label>{t('dealer_typology')}</Form.Label>
														<Form.Control
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.tipologia_dealer}
															disabled={userRole !== Roles.Backoffice && userRole !== Roles.Master && userRole !== Roles.Admin}
															name="tipologia_dealer"
															type="text"
														/>
													</Form.Group>
												</Col>
												<Col md={6}>
													<Form.Group className="mb-3">
														<Form.Label>{t('brand')}</Form.Label>
														<Form.Control
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.marchio}
															disabled={userRole !== Roles.Backoffice && userRole !== Roles.Master && userRole !== Roles.Admin}
															name="marchio"
															type="text"
														/>
													</Form.Group>
												</Col>
											</Row>

											<Row>
												<Col md={6}>
													<Form.Group className="mb-3">
														<Form.Label>{t('affiliated_rental_companies')}</Form.Label>
														<Form.Control
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.noleggiatori_convenzionati}
															disabled={userRole !== Roles.Backoffice && userRole !== Roles.Master && userRole !== Roles.Admin}
															name="noleggiatori_convenzionati"
															type="text"
														/>
													</Form.Group>
												</Col>
											</Row>

											{/*<Row>
												<Col md={12}>
													<Form.Group>
														<Form.Label>Logo</Form.Label>
															{
																<ul>
																	{
																		initialValues.logo ? (
																			deletedFiles.includes(initialValues.logo) ? (
																				<del>{initialValues.logo}</del>
																			) : (
																				<>
																				<li>
																					{initialValues.logo}
																						<Link
																							to="#"
																							onClick={(e) => {
																								e.preventDefault();
																								setDeletedFiles([...deletedFiles, initialValues.logo]);
																							}}
																							className="mx-2"
																						>
																							<FaTimes />
																						</Link>
																				</li>
																				</>
																			)
																		) : ''
																	}
																</ul>
															}

														<div className="file-uploader-container text-center">
															{files.length ? (
																<Button
																	variant="secondary"
																	onClick={() => {
																		setFiles([]);
																	}}
																	className="btn btn-sm btn-outline file-uploader-clear-btn"
																	type="button"
																>
																	Elimina
																</Button>
															) : (
																''
															)}
															<FileUploader
																multiple={false}
																classes="dropzone"
																types={fileTypes}
																handleChange={(f: File) => {
																	setUploadError(null);

																	const fileData: File[] = [];
																	fileData.push(f);


																	setFiles(fileData);
																}}
																onTypeError={() => {
																	setFiles([]);
																	setUploadError('Il tipo di file non è supportato');
																}}
																name="file"
															>
																<div className="fs-4">
																	<div>
																		Trascina il file qui o clicca per caricare il
																		file, poi salva per completare l'associazione
																	</div>
																	<div className="fs-6 text-muted">
																		Tipo file: {fileTypes.join(', ')}
																	</div>
																</div>

																{files?.length ? (
																	<ul className="my-5">
																		{files.map((file: File, k: number) => (
																			<li key={k}> {file.name} </li>
																		))}
																	</ul>
																) : (
																	''
																)}
																{uploadError ? (
																	<p className="mt-4 text-danger">{uploadError}</p>
																) : (
																	''
																)}
															</FileUploader>
														</div>
													</Form.Group>
												</Col>
											</Row>*/}

											<div className="contatti-sub-title mt-3">
											{t('dealer_contacts')}
											</div>

											<FieldArray name="contatti_list">
												{({ insert, remove, push }) => (
													<div>
														{values.contatti_list.map((contatto, index) => (
															<Card className="mt-2" key={index}>
																<Card.Body>
																	<Row>
																		<Col md={4}>
																			<Form.Group className="mb-3">
																				<Form.Label
																					htmlFor={`contatti_list.${index}.nome`}
																				>
																					{t('name')}
																					{index === 0 && (
																						<span className="red-ast">*</span>
																					)}
																				</Form.Label>
																				<Form.Control
																					onChange={handleChange}
																					onBlur={handleBlur}
																					value={
																						values.contatti_list[index].nome
																					}
																					name={`contatti_list.${index}.nome`}
																					disabled={
																						userRole !== Roles.Backoffice && userRole !== Roles.Master && userRole !== Roles.Admin
																					}
																					type="text"
																					className={`${index === 0 &&
																						errors.contatti_list &&
																						touched.contatti_list &&
																						errors.contatti_list[0].nome &&
																						touched.contatti_list[0].nome
																						? 'error-border'
																						: ''
																						}`}
																				/>
																				{index === 0 &&
																					errors.contatti_list &&
																					touched.contatti_list &&
																					errors.contatti_list[0].nome &&
																					touched.contatti_list[0].nome && (
																						<small className="text-danger">
																							{errors.contatti_list[0].nome}
																						</small>
																					)}
																			</Form.Group>
																		</Col>
																		<Col md={4}>
																			<Form.Group className="mb-3">
																				<Form.Label>
																				{t('surname')}
																					{index === 0 && (
																						<span className="red-ast">*</span>
																					)}
																				</Form.Label>
																				<Form.Control
																					onChange={handleChange}
																					onBlur={handleBlur}
																					disabled={
																						userRole !== Roles.Backoffice && userRole !== Roles.Master && userRole !== Roles.Admin
																					}
																					value={
																						values.contatti_list[index].cognome
																					}
																					name={`contatti_list.${index}.cognome`}
																					type="text"
																					className={`${index === 0 &&
																						errors.contatti_list &&
																						touched.contatti_list &&
																						errors.contatti_list[0].cognome &&
																						touched.contatti_list[0].cognome
																						? 'error-border'
																						: ''
																						}`}
																				/>
																				{index === 0 &&
																					errors.contatti_list &&
																					touched.contatti_list &&
																					errors.contatti_list[0].cognome &&
																					touched.contatti_list[0].cognome && (
																						<small className="text-danger">
																							{errors.contatti_list[0].cognome}
																						</small>
																					)}
																			</Form.Group>
																		</Col>
																		<Col md={4}>
																			<Form.Group className="mb-3">
																				<Form.Label>
																				{t('role')}
																					{index === 0 && (
																						<span className="red-ast">*</span>
																					)}
																				</Form.Label>
																				<Form.Control
																					onChange={handleChange}
																					onBlur={handleBlur}
																					disabled={
																						userRole !== Roles.Backoffice && userRole !== Roles.Master && userRole !== Roles.Admin
																					}
																					value={
																						values.contatti_list[index].ruolo
																					}
																					name={`contatti_list.${index}.ruolo`}
																					type="text"
																					className={`${index === 0 &&
																						errors.contatti_list &&
																						touched.contatti_list &&
																						errors.contatti_list[0].ruolo &&
																						touched.contatti_list[0].ruolo
																						? 'error-border'
																						: ''
																						}`}
																				/>
																				{index === 0 &&
																					errors.contatti_list &&
																					touched.contatti_list &&
																					errors.contatti_list[0].ruolo &&
																					touched.contatti_list[0].ruolo && (
																						<small className="text-danger">
																							{errors.contatti_list[0].ruolo}
																						</small>
																					)}
																			</Form.Group>
																		</Col>
																	</Row>
																	<Row>
																		<Col md={4}>
																			<Form.Group className="mb-3">
																				<Form.Label>
																				{t('phone')}
																					{index === 0 && (
																						<span className="red-ast">*</span>
																					)}
																				</Form.Label>
																				<Form.Control
																					onChange={handleChange}
																					onBlur={handleBlur}
																					disabled={
																						userRole !== Roles.Backoffice && userRole !== Roles.Master && userRole !== Roles.Admin
																					}
																					value={
																						values.contatti_list[index].telefono
																					}
																					name={`contatti_list.${index}.telefono`}
																					type="text"
																					className={`${index === 0 &&
																						errors.contatti_list &&
																						touched.contatti_list &&
																						errors.contatti_list[0].telefono &&
																						touched.contatti_list[0].telefono
																						? 'error-border'
																						: ''
																						}`}
																				/>
																				{index === 0 &&
																					errors.contatti_list &&
																					touched.contatti_list &&
																					errors.contatti_list[0].telefono &&
																					touched.contatti_list[0].telefono && (
																						<small className="text-danger">
																							{errors.contatti_list[0].telefono}
																						</small>
																					)}
																			</Form.Group>
																		</Col>
																		<Col md={4}>
																			<Form.Group className="mb-3">
																				<Form.Label>
																				{t('email')}
																					{index === 0 && (
																						<span className="red-ast">*</span>
																					)}
																				</Form.Label>
																				<Form.Control
																					onChange={handleChange}
																					onBlur={handleBlur}
																					disabled={
																						userRole !== Roles.Backoffice && userRole !== Roles.Master && userRole !== Roles.Admin
																					}
																					value={
																						values.contatti_list[index].email
																					}
																					name={`contatti_list.${index}.email`}
																					type="text"
																					className={`${index === 0 &&
																						errors.contatti_list &&
																						touched.contatti_list &&
																						errors.contatti_list[0].email &&
																						touched.contatti_list[0].email
																						? 'error-border'
																						: ''
																						}`}
																				/>
																				{index === 0 &&
																					errors.contatti_list &&
																					touched.contatti_list &&
																					errors.contatti_list[0].email &&
																					touched.contatti_list[0].email && (
																						<small className="text-danger">
																							{errors.contatti_list[0].email}
																						</small>
																					)}
																			</Form.Group>
																		</Col>
																	</Row>
																</Card.Body>
															</Card>
														))}

														<Restricted to={[Roles.Backoffice, Roles.Master, Roles.Admin]}>
															<Button
																className="add-contact-btn"
																onClick={() =>
																	push({
																		nome: '',
																		cognome: '',
																		email: '',
																		telefono: '',
																		ruolo: '',
																	})
																}
															>
																<FaPlusCircle />
															</Button>
														</Restricted>
													</div>
												)}
											</FieldArray>

											<Restricted to={[Roles.Backoffice, Roles.Master, Roles.Admin]}>
												<Btn
													type="submit"
													icon="arrow-right"
													className="float-end"
													disabled={isSubmitting}
												>
													{id !== 'new' ? 'Aggiorna' : 'Crea'}
												</Btn>
											</Restricted>
										</Form>
									</>
								);
							}}
						</Formik>
					</Card.Body>
				</Card>
			)}
		</Container>
	);
};

export default DealerFormPage;
