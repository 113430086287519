import { useEffect, useState } from "react";
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';

const CountdownDisplay = ({ timestamp }) => {
  const [countdown, setCountdown] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const [loading, setLoading] = useState(true)
	const { t } = useTranslation()

  useEffect(() => {
    // if (!isOlderThanThreeDays) {
    //   setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    //   return;
    // }
    const countdownInterval = setInterval(() => {
      const differenceInMillis = timestamp - Date.now();
      if (differenceInMillis <= 0) {
        clearInterval(countdownInterval);
      } else {
        const days = Math.floor(differenceInMillis / (24 * 60 * 60 * 1000));
        const hours = Math.floor((differenceInMillis % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
        const minutes = Math.floor((differenceInMillis % (60 * 60 * 1000)) / (60 * 1000));
        const seconds = Math.floor((differenceInMillis % (60 * 1000)) / 1000);
        setCountdown({ days, hours, minutes, seconds });
        setLoading(false)
      }
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, [timestamp]);

  return (
    <div className="container-fluid">
      <div className="countdown">

        <span className="countdown-val">
          {loading ?
            <Spinner animation="border" role="status" size="sm">
              <span className="visually-hidden">{t('loading')}...</span>
            </Spinner> :
            countdown.days
          }
        </span>
        {' '} {t('days')} - {' '}

        <span className="countdown-val">
          {loading ?
            <Spinner animation="border" role="status" size="sm">
              <span className="visually-hidden">{t('loading')}...</span>
            </Spinner> :
            countdown.hours + 2 // server saves dates UMT time, and Italy is UMT + 2
          }
        </span>
        {' '} {t('hours')} -  {' '}

        <span className="countdown-val">
          {loading ?
            <Spinner animation="border" role="status" size="sm">
              <span className="visually-hidden">{t('loading')}...</span>
            </Spinner> :
            countdown.minutes
          }
        </span>
        {' '} {t('minutes')} - {' '}

        <span className="countdown-val">
          {loading ?
            <Spinner animation="border" role="status" size="sm">
              <span className="visually-hidden">{t('loading')}...</span>
            </Spinner> :
            countdown.seconds
          }
        </span>
        {' '} {t('seconds')}
      </div>
    </div>
  );
}

export default CountdownDisplay;