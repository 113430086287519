import dayjs from 'dayjs';
import Countdown from 'react-countdown';
import _ from 'lodash'
import { useState, useEffect, useMemo, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Dropdown, Card, Breadcrumb, Accordion, Container, Row, Col, Button, } from 'react-bootstrap';
import { FaFilePdf, FaHeart, FaRegHeart, FaFileImport, FaPlus } from 'react-icons/fa';
import { Storage } from 'aws-amplify';
import { TbFileEuro } from 'react-icons/tb';
import { Column } from 'react-table';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import toaster from '../../utils/toaster';
import Restricted from '../../components/Restricted/Restricted';
import { useApiClient } from '../../hooks/useApiClient';
import { Roles } from '../../types';
import { useAuth } from '../../hooks/useAuth';
import {
	HideVehicleRequestDTO,
	ReleaseVehicleOptionsRequestDTO,
	RenewVehicleOptionsRequestDTO, OffertaEntity,
	VehicleStateEnum,
	VehicleUpdateRequestDTO,
	RoleEnum,
} from '../../api';
import Icon from '../../components/Icon/Icon';
import OptionActionModal from '../../components/modals/optionActionModal';
import VehicleDetails from '../../components/VehicleDetails/VehicleDetails';
import { calcRemaingOptionTime, getUrl } from '../../utils';
import CountdownDisplay from '../../components/CountdownDisplay/CountdownDisplay';
import { MakeAvailableVehicleRequestDTO } from '../../api/models/MakeAvailableVehicleRequestDTO';
import { useRefresh } from '../../hooks/useRefresh';
import { VehicleInEvidenzaToggleDTO } from '../../api/models/VehicleInEvidenzaToggleDTO';
import { InformationRequestDTO } from '../../api/models/InformationRequestDTO';
import TableContainer from '../../components/genericTable/TableContainer';
import { columnsData } from './columnsData';
import { GTMsendEvent } from '../../utils/trackingGA4';
import AiQuotation from '../../components/AiQuotation/AiQuotation';
import { useAiContext } from '../../context/AiContext';
import { FORMAT_DATE_TIME } from '../../utils/constants';
import { OpportunityStageEnum } from '../../api/';
import VehicleCardBody from '../../components/VehicleCardBody/VehicleCardBody';
import { generatePdf } from '../../utils/pdfUtils';
import { OpportunityEntity } from '../../api/models/AccountEntity';
import QuickoPdfTemplate from '../../components/QuickoPdfTemplate/QuickoPdfTemplate';
import './DetailsPage.scss';

interface Servizi {
	value: string;
	label: string;
}

const servizi: Servizi[] = [
	{ value: 'gomme', label: i18next.t('tyres') },
	{ value: 'rca', label: i18next.t('rca') },
	{ value: 'incendio_furto', label: i18next.t('i_f') },
	{ value: 'kasco', label: i18next.t('kasko') },
	{ value: 'vettura_sostitutiva', label: i18next.t('replacement_vehicle') },
];

const DetailsPage = () => {
	const navigate = useNavigate();
	const { id } = useParams<{ id: string }>();
	const { apiClient, handleApi, handleApiResponse } = useApiClient();
	const { user, userRole } = useAuth();
	const [statoVeicolo, setStatoVeicolo] = useState(
		null as VehicleStateEnum | null
	);
	const [downlodPdfValues, setDownlodPdfValues] = useState(null);
	const [opportunity, setOpportunity] = useState<OpportunityEntity | null>(null);
	const [preferiti, setPreferiti]: [any, any] = useState(null);
	const [isModalVisibile, setModalVisibility] = useState<boolean>(false);
	const [modalType, setModalType] = useState('');
	const [extensionNum, setExtensionNum] = useState(0);
	const [modalParams, setModalParams] = useState(null);
	const [quickoPdfData, setQuickoPdfData] = useState(null);
	const [response, setResponse]: [any, any] = useState(null);
	const [pdfImageList, setpdfImageList] = useState<any[]>([])
	const [loading, setLoading] = useState(true);
	const [isReoptionable, setIsReoptionable] = useState(true);
	const [isActionForbidden, setIsActionForbidden] = useState<boolean>(false);
	const [timestamp, setTimestamp] = useState(0);
	const [inEvidenza, setInEvidenza] = useState(false);
	const refresh = useRefresh();
	const { setQuotReqState, cleanAiContext } = useAiContext();
	const aiQuotationRef = useRef<HTMLDivElement>(null);
	const { t } = useTranslation()


	const closeModal = () => {
		setModalType('');
		setModalVisibility(false);
	};

	const showModal = (type, params?: any) => {
		setModalType(type);
		setModalParams(params || response);
		setModalVisibility(true);
	};

	const wasActionDoneByCurrentUser = (stateType, currentState) => {
		return (
			currentState?.type === stateType && currentState?.utente === user.username
		);
	};

	const wasActionDoneByMaster = () => {
		// take the user of the current state
		if (
			(response.stato_corrente?.type === VehicleStateEnum.OPZIONATO ||
				response.stato_corrente?.type === VehicleStateEnum.OSCURATO) &&
			userRole === RoleEnum.BACKOFFICE
		) {
			const userCurrentState = response.stato_corrente.utente;
			handleApi(apiClient.userController.getRegisterUsers1(userCurrentState))
				.then((userUpdatedLatestState) => {
					// check if the user is a master and it is not the current role
					if (userUpdatedLatestState.role === RoleEnum.MASTER) {
						setIsActionForbidden(true);
					}
				})
				.catch((err) => {
					console.error('error', err);
				});
		}
	};

	const displayCanoneMinimo = (importoPrivato, importoAzienda) => {
		if (!importoPrivato && !importoAzienda) return '-';

		if (importoPrivato === null) {
			return importoAzienda.toLocaleString('it', {
				minimumFractionDigits: 2,
			});
		}

		if (importoAzienda === null) {
			return importoPrivato.toLocaleString('it', {
				minimumFractionDigits: 2,
			});
		}

		// if both importoAzienda and importoPrivato are NOT null, compare the 2 and return the min
		return Math.min(importoPrivato, importoAzienda).toLocaleString('it', {
			minimumFractionDigits: 2,
		});
	};

	const displayIvaStr = (importoPrivato, importoAzienda) => {
		if (!importoPrivato && !importoAzienda) return '';
		if (!importoPrivato) return i18next.t('vat_excluded');
		if (!importoAzienda) return i18next.t('vat_included');

		const min = Math.min(importoPrivato, importoAzienda);

		return min === importoPrivato ? i18next.t('vat_included') : i18next.t('vat_excluded');
	};

	const coundownRenderer = ({
		days,
		formatted: { hours, minutes, seconds },
		completed,
	}) => {
		if (completed) {
			return <span className="topbar-scaduto">{t('expired')}</span>;
		} else {
			return (
				<span className="countdown">
					<div>
						<span className="countdown-val">{days}</span>
						<span className="countdown-val-m">{t('days_shorted_string')}</span>
					</div>
					<div>
						<span className="countdown-val">{hours}</span>
						<span className="countdown-val-m">{t('hours_shorted_string')}</span>
					</div>
				</span>
			);
		}
	};

	const sendSelectActionToGA4 = (actiontype) => {
		GTMsendEvent({
			event: 'select_actions_sale',
			actionType: actiontype,
		});
	};

	const checkIsReOptionable = () => {
		if (response.stato_corrente?.type === VehicleStateEnum.DISPONIBILE) {
			// Il ruolo master può opzionare senza attendere
			if (userRole === Roles.Master || userRole === Roles.Admin) {
				setIsReoptionable(true);
				return true;
			}
			const storico = response.storico_azioni;
			const { canBeOptioned, timestamp } = calcRemaingOptionTime(user, storico);
			if (canBeOptioned) {
				setIsReoptionable(true);
			} else {
				setIsReoptionable(false);
				setTimestamp(timestamp);
			}
		}
	};

	const makeAvailable = () => {
		const body: MakeAvailableVehicleRequestDTO = {
			vehicleId: response.id,
		};

		return handleApi(
			apiClient.vehicleActionsController.postVehiclesActionsMakeAvailable(body)
		)
			.then((res) => {
				toaster.success(i18next.t('vehicle_made_available_successfully'));
				GTMsendEvent({
					event: 'complete_actions_sale',
					actionType: 'rendi_veicolo_disponibile',
				});
				refresh();
			})
			.catch((err) => {
				toaster.error(
					err.message || i18next.t('error_sending_data')
				);
			});
	};

	const collectPdfData = async (values: any) => {
		let res;

		try {
			if ([Roles.Admin, Roles.Master, Roles.Backoffice, Roles.Sales].includes(userRole)) {
				res = await handleApi(
					apiClient.dealerController.getRegisterDealers2(
						response.dettagli_veicolo.dealer
					)
				)
			}
		} catch (err: any) {
			toaster.error(
				err.message || i18next.t('error_sending_data')
			);
		} finally {
			if (res && res.logo) {
				const logoDealerLink: string = await Storage.get(res.logo, {
					bucket: process.env.REACT_APP_AWS_STORAGE_BUCKET_DEALER_LOGO,
				});
				res.logo = logoDealerLink;
			}

			values = { dealerDetail: res || null, ...values };
			return values
		}
	};

	const downloadPdf = (values) => {
		collectPdfData(values)
			.then(result => {
				window.dispatchEvent(new CustomEvent('download-pdf', {
					detail: {
						data: {
							pdf: _.clone(result),
							...response,
							image_list: pdfImageList
						}
					}
				}))

				closeModal();
			})
	}


	// maybe we will need this in the future ?
	// const renderTooltip = (props) => (
	// 	<Tooltip id="button-tooltip" {...props}>
	// 		{isReoptionable ? '' : <> Questo veicolo sarà opzionabile tra <CountdownDisplay timestamp={timestamp} /> </>}
	// 	</Tooltip>
	// );

	// ----------------------------------------------------------------
	// API calls from onSubmitFn in optionActionsModal START

	const hideOption = (values) => {
		const body: HideVehicleRequestDTO = values;
		body.vehicleId = id!;

		return handleApi(
			apiClient.vehicleActionsController.postVehiclesActionsHide(body)
		);
	};

	const uploadPdfActions = (values) => {
		const body: VehicleUpdateRequestDTO = values;

		return handleApi(
			apiClient.vehicleController.putVehicles(id as string, body)
		);
	};

	const releaseOption = (values) => {
		const body: ReleaseVehicleOptionsRequestDTO = {
			vehicleId: id!,
		};

		return handleApi(
			apiClient.vehicleActionsController.postVehiclesActionsRelease(body)
		);
	};

	const renewOption = (values) => {
		const body: RenewVehicleOptionsRequestDTO = values;
		body.vehicleId = id!;

		return handleApi(
			apiClient.vehicleActionsController.postVehiclesActionsRenewal(body)
		);
	};

	const deleteOptions = async (values) => {
		const a = await Storage.remove(values?.document_name, {
			bucket: process.env.REACT_APP_AWS_STORAGE_BUCKET_VEHICLE,
		});

		if (response.stato_corrente?.opportunityId) {
			if (!opportunity) {
				toaster.error(`${i18next.t('wait_for_data_loading')}...`)
				return
			}

			const request = {
				name: opportunity.Name,
				commissions: opportunity.Provvigioni__c,
				channel: opportunity.Canale__c,
				type: opportunity.Tipologia__c,
				dealerName: opportunity.Noleggiatore__c,
				note: opportunity.Note__c || '',
				stageName: OpportunityStageEnum.suspended,
				vehicle: {
					vehicleId: opportunity?.ID_Vehicle_HMP__c || null,
					customVehicle: opportunity?.ID_Vehicle_HMP__c ? null : {
						brand: opportunity?.Brand__c ?? '',
						model: opportunity?.Modello__c ?? '',
					}
				},
			}

			await handleApiResponse(apiClient.opportunityController.editOpportunity(opportunity.Id, request))
		}

		return handleApiResponse(
			apiClient.vehicleActionsController.deleteVehiclesActions(id!)
		);
	};

	const deleteVehicle = (values) => {
		return handleApi(apiClient.vehicleController.deleteVehicles(id!));
	};

	const hideRenew = (values) => {
		const body: HideVehicleRequestDTO = values;
		body.vehicleId = id!;

		return handleApi(
			apiClient.vehicleActionsController.postVehiclesActionsHideRenew(body)
		);
	};

	const toggleInEvidenza = async (values) => {
		const inEvidenza = !values.inEvidenza;
		const body: VehicleInEvidenzaToggleDTO = {
			in_evidenza: inEvidenza,
			vehicleId: id!,
		};

		if (response.lista_offerte.length === 0 && !values.inEvidenza) {
			toaster.info(i18next.t('warning_vehicle_has_no_linked_offers'));
		}

		return handleApi(apiClient.vehicleActionsController.toggleInEvidenza(body));
	};

	const deleteWishlistFunc = (vehicleId) => {
		handleApi(apiClient.vehicleController.deleteWishlist(vehicleId))
			.then((res) => {
				toaster.success(
					i18next.t('vehicle_successfully_removed_from_favorites')
				);
				GTMsendEvent({
					event: 'wishlist',
					wishlist_action: 'remove',
					item_name: `${response.dati_veicolo.marca} ${response.dati_veicolo.allestimento}`,
					item_category: response.dati_veicolo.marca,
					item_category2: `${response.dati_veicolo.modello} ${response.dati_veicolo.allestimento}`,
					item_category3: `${response.dettagli_veicolo.telaio} - ${response.dettagli_veicolo.targa}`,
					item_category4: `${response.dati_veicolo.alimentazione} ${response.dati_veicolo.carrozzeria}`,
					item_variant: `${response.dati_veicolo.cambio} - ${response.dati_veicolo.trazione}`,
					colour: `${response.dettagli_veicolo.colore_esterno} - ${response.dettagli_veicolo.colore_interno}`,
				});
				setLoading(false);
				refresh();
			})
			.catch((err) => {
				setLoading(false);
				toaster.error(
					err.message || i18next.t('error_deleting_data')
				);
			});
	};

	const addWishlistFunc = (vehicleId) => {
		handleApi(apiClient.vehicleController.postWishlist(vehicleId))
			.then((res) => {
				toaster.success(
					i18next.t('vehicle_added_to_favorites_consultant_will_contact')
				);
				GTMsendEvent({
					event: 'wishlist',
					wishlist_action: 'add',
					item_name: `${response.dati_veicolo.marca} ${response.dati_veicolo.allestimento}`,
					item_category: response.dati_veicolo.marca,
					item_category2: `${response.dati_veicolo.modello} ${response.dati_veicolo.allestimento}`,
					item_category3: `${response.dettagli_veicolo.telaio} - ${response.dettagli_veicolo.targa}`,
					item_category4: `${response.dati_veicolo.alimentazione} ${response.dati_veicolo.carrozzeria}`,
					item_variant: `${response.dati_veicolo.cambio} - ${response.dati_veicolo.trazione}`,
					colour: `${response.dettagli_veicolo.colore_esterno} - ${response.dettagli_veicolo.colore_interno}`,
				});
				setLoading(false);
				refresh();
			})
			.catch((err) => {
				setLoading(false);
				toaster.error(
					err.message || i18next.t('error_saving_data')
				);
			});
	};

	const requestInformations = (informations) => {
		const services = Object.keys(informations.servizi)
			.filter(
				(key) =>
					informations.servizi[key].value !== '' &&
					informations.servizi[key].value !== 0
			) // Filter out items with empty or zero values
			.map((key) => {
				const { label, value } = informations.servizi[key];
				return `${key}: ${label} ${value}`;
			});

		const body: InformationRequestDTO = {
			...informations,
			elenco_servizi: services,
			marca: response.dati_veicolo.marca,
			modello: response.dati_veicolo.modello,
		};

		return handleApi(apiClient.vehicleController.sendQuationRequest(id!, body));
	};

	const downloadQuotationPdf = (values) => {
		const userName = user?.attributes.given_name ?? '';
		const userSurname = user?.attributes.family_name ?? '';
		const userMail = user?.attributes.email ?? '';
		const userPhone = user?.attributes['custom:phone'] ?? '';
		const typeTemplate = "quickoQuotation";
		// const offerType = values.clientType

		setQuickoPdfData({ ...values, userName, userSurname, userMail, userPhone, typeTemplate });
	}

	useEffect(() => {
		if (quickoPdfData) {
			const typePdfTemplate = "#quickoPdf";
			const filename = "Preventivo_con_Quicko.pdf"
			const cb = (filename) => {
				closeModal();
				setQuickoPdfData(null);
			}
			generatePdf(cb, typePdfTemplate, filename);
			// GTMsendEvent({
			// 	event: 'CTA_download_file',
			// 	evtCat: 'quicko_preventivo_veicolo',
			// 	evtAct: 'download',
			// 	evtLab: `${filename}`,
			// });
		}
	}, [quickoPdfData]);

	const loadVehicleOpportunity = (id) => {
		handleApi(apiClient.opportunityController.getOpportunity(id))
			.then(
				(res) => {
					if (res.data.length) {
						setOpportunity(res.data[0]);
					}
				}
			);
	};

	// API calls END
	// ----------------------------------------------------------------

	useEffect(() => {
		handleApi(apiClient.vehicleController.getVehicles1(id!))
			.then((res) => {
				res.lista_offerte = res.lista_offerte
					?.filter((offer) => {
						return (
							(offer.importo_azienda !== null && offer.importo_azienda !== 0) ||
							(offer.importo_privato !== null && offer.importo_privato !== 0)
						);
					})
					// need to handle the case where data is zero and get ignored but do not ignore the entire offer for comparison in displayCanoneMinimo
					?.map((offer) => {
						if (offer.importo_privato === 0) offer.importo_privato = null;
						if (offer.importo_azienda === 0) offer.importo_azienda = null;
						return offer;
					})
					?.sort((a: OffertaEntity, b: OffertaEntity) => {
						let min1 = a.importo_azienda ?? a.importo_privato;
						let min2 = b.importo_azienda ?? b.importo_privato;

						if (min1 === undefined && min2 === undefined) {
							return 0;
						}

						return min1! > min2! ? 1 : min2! > min1! ? -1 : 0;
					});

				setInEvidenza(res.dettagli_veicolo.in_evidenza);

				if (res.image_list && res.image_list.length > 0 && res.lista_offerte?.length) {
					if (res.image_list[0].url.includes('sslphotos.jato.com')) {
						handleApi(apiClient.vehicleController.getJatoImageFile(res.image_list[0].url))
							.then(vehicleImg => {
								vehicleImg = 'data:application/octet-stream;base64,' + vehicleImg;
								setpdfImageList([{ base64: vehicleImg }])
							})
					} else {
						setpdfImageList(res.image_list)
					}
				}

				// OPTIONALS deducat VAT and SUM
				setQuotReqState(prevState => {

					const updatedOptionals = res.optionals.map(optional => {
						return {
							// deduct VAT
							...optional,
							valore: parseFloat((optional.valore / 1.22).toFixed(2)),
							isChecked: true
						}
					});

					const totalOptionalsValue = updatedOptionals.reduce((acc, optional) => {
						return acc + optional.valore;
					}, 0);
					return {
						...prevState,
						vehicleState: {
							...res,
							optionals: updatedOptionals,
						},
						optionalTotals: totalOptionalsValue
					}
				});

				setResponse(res);

				GTMsendEvent({
					event: 'view_item',
					item_id: res.dettagli_veicolo.nr_ordine,
					item_name: `${res.dati_veicolo.marca} - ${res.dati_veicolo.modello} ${res.dati_veicolo.allestimento}`,
					affiliation: res.dettagli_veicolo.dealer,
					item_category: res.dati_veicolo.marca,
					item_category2: `${res.dati_veicolo.modello} ${res.dati_veicolo.allestimento}`,
					item_category3: res.dettagli_veicolo.codice_costruttore,
					item_category4: res.dettagli_veicolo.stato_disp,
					price:
						parseFloat(
							displayCanoneMinimo(
								res?.lista_offerte[0]?.importo_privato,
								res?.lista_offerte[0]?.importo_azienda
							)
						) || 0,
					advance: res.lista_offerte[0]?.anticipo,
				});

				if (
					[VehicleStateEnum.VENDUTO, VehicleStateEnum.OPZIONATO].includes(
						res.stato_corrente?.type
					) &&
					res.stato_corrente?.opportunityId
				) {
					loadVehicleOpportunity(res.stato_corrente.opportunityId);
				}

				setLoading(false);
			})
			.finally(() => {
				setLoading(false);

			});

		return () => {
			cleanAiContext()
		}
	}, []);

	useEffect(() => {
		if (response) {
			setStatoVeicolo(response.stato_corrente?.type);
			setPreferiti(response.preferiti);
			setExtensionNum(response.stato_corrente?.renewalsCounter || 0);
			checkIsReOptionable();
			wasActionDoneByMaster();
		}
	}, [response]);

	const columns = useMemo(() => columnsData as Column<Object>[], []);
	const data = useMemo(() => response ?? [], [response]);

	return (
		<Container fluid>
			{!loading && response && (
				<>
					<OptionActionModal
						show={isModalVisibile}
						onHide={closeModal}
						modalType={modalType}
						details={modalParams}
						data={{ extensionNum, today: dayjs(), inEvidenza }}
						servizi={servizi}
						actionMethods={{
							downloadPdf,
							renewOption,
							deleteOptions,
							deleteVehicle,
							hideOption,
							releaseOption,
							hideRenew,
							toggleInEvidenza,
							uploadPdfActions,
							requestInformations,
							downloadQuotationPdf
						}}
					/>

					<Breadcrumb>
						<Breadcrumb.Item
							linkAs={Link}
							linkProps={{ to: '/available-vehicles' }}
						>
							{t('vehicles_list')}
						</Breadcrumb.Item>
						<Breadcrumb.Item active>
							{`${response?.dati_veicolo.marca} ${response?.dati_veicolo.modello}`}
						</Breadcrumb.Item>
					</Breadcrumb>

					<Card className="details-topbar mt-2">
						<Card.Body className="p-0 pb-2 pb-md-0">
							<Row>
								<Col className="text-sm-start">
									{statoVeicolo === VehicleStateEnum.OPZIONATO && (
										<Row className="mx-0 my-1">
											<Col sm={6}>
												<div className="topbar-scadenza-label">
													{t('option_expiration')}
												</div>
												<div className="topbar-scadenza-value">
													<span className="topbar-date">
														{dayjs(
															response?.stato_corrente?.data_scadenza
														).format(FORMAT_DATE_TIME)}
													</span>
													<span className="ms-2 topbar-countdown">
														<Countdown
															date={
																response?.stato_corrente?.data_scadenza
																	? new Date(
																		response?.stato_corrente?.data_scadenza
																	)
																	: new Date()
															}
															renderer={coundownRenderer}
														/>
													</span>
												</div>
											</Col>
											<Col sm={7}>
												<div className="topbar-renewalsCounter">
													{t('remaining_extensions')}:
													<span className="renewalsCounter-value">
														{1 - extensionNum}
													</span>
												</div>
											</Col>
										</Row>
									)}
									{statoVeicolo === VehicleStateEnum.OSCURATO && (
										<Row className="mx-0 my-1">
											<Col sm={6}>
												<div className="topbar-scadenza-label">
													{t('hide_expiration')}:
												</div>
												<div className="topbar-scadenza-value">
													<span className="topbar-date">
														{dayjs(
															response?.stato_corrente?.data_scadenza
														).format(FORMAT_DATE_TIME)}
													</span>
													<span className="ms-2 topbar-countdown">
														<Countdown
															date={
																response?.stato_corrente?.data_scadenza
																	? new Date(
																		response?.stato_corrente?.data_scadenza
																	)
																	: new Date()
															}
															renderer={coundownRenderer}
														/>
													</span>
												</div>
											</Col>
											<Col sm={4}>
												<div className="topbar-renewalsCounter">
													{t('number_extensions')}:
													<span className="renewalsCounter-value">
														{response?.stato_corrente?.renewalsCounter}
													</span>
												</div>
											</Col>
										</Row>
									)}
									{statoVeicolo === VehicleStateEnum.DISPONIBILE && (
										<div style={{ display: 'flex' }}>
											<div
												className={
													'd-block text-center d-md-inline-block topbar-stato-veicolo ' +
													statoVeicolo
												}
											>
												{t('vehicle_available')}
											</div>

											{!isReoptionable ? (
												<div className="topbar-scadenza-value optionable-countdown-message">
													<div className="topbar-date">
														{t('you_can_option_this_vehicle_in')}
													</div>

													<div className="topbar-renewalsCounter">
														<CountdownDisplay timestamp={timestamp} />
													</div>
												</div>
											) : (
												''
											)}
										</div>
									)}
									{/* {statoVeicolo === VehicleStateEnum.OSCURATO && (
										<div
											className={
												'd-block text-center d-md-inline-block topbar-stato-veicolo ' +
												statoVeicolo
											}
										>
											Veicolo oscurato
										</div>
									)} */}

									{statoVeicolo === VehicleStateEnum.VENDUTO && (
										<div
											className={
												'd-block text-center d-md-inline-block topbar-stato-veicolo ' +
												statoVeicolo
											}
										>
											{t('vehicle_sold')}
										</div>
									)}
								</Col>
								<Col md={7} className="text-sm-end gx-2">
									<Restricted
										to={[Roles.Backoffice, Roles.Sales, Roles.Master, Roles.Admin]}
									>
										<Button
											disabled={
												statoVeicolo !== VehicleStateEnum.DISPONIBILE &&
												statoVeicolo !== VehicleStateEnum.OSCURATO
											}
											className="mx-1 align-text-bottom btn"
											onClick={() => {
												navigate(
													getUrl('/opportunities/new', {
														from: 'vehicle',
														vehicle_id: response.id,
														action: 'send_docs'
													})
												);
											}}
										>
											<FaFileImport className="fs-5" /> {t('send_docs')}
										</Button>
									</Restricted>

									{process.env.REACT_APP_QUICKO_ENABLED === '1' && (
										<Button
											className="mx-1 align-text-bottom btn"
											onClick={() => {
												if (response.dati_veicolo.jato_id) {
													if (aiQuotationRef.current) {
														aiQuotationRef.current.scrollIntoView({ behavior: 'smooth' });
													}
												} else {
													toaster.error(i18next.t('vehicle_temporarily_not_enabled_for_ai_quotator'));
												}
											}}
										>
											<img src="/svg-icons/quicko-logo-q.png" alt="Quicko Beta" style={{ width: '24px', height: '20px' }} />
											{/* <FaPlus className="fs-5" /> */}
											{/* {t('quicko_quotation')} */}
											&nbsp;QUICKO <small>(beta)</small>
										</Button>
									)}

									<Restricted to={[Roles.Backoffice, Roles.Master, Roles.Admin]}>
										<Button
											className="mx-1 align-text-bottom btn"
											onClick={() => {
												showModal('uploadPdfActions')
												GTMsendEvent({
													event: 'CTA_upload_file',
													evtCat: 'offerta',
													evtAct: 'upload',
												});
											}}
										>
											<FaFilePdf className="fs-5" /> {t('upload')}
										</Button>
									</Restricted>

									{/* Bottone dello storico spostato nel menu azioni per guadagnare spazio */}
									{/* <Restricted to={[Roles.Backoffice, Roles.Master, Roles.Admin]}>
								<Button
									className="mx-1 align-text-bottom btn"
									onClick={() => {
										showModal('historyActions');
										GTMsendEvent({
											event: 'CTA_storico',
											evtCat: 'storico',
											evtAct: 'click',
											evtLab: response.dati_veicolo.allestimento,
										});
									}}
								>
									<FaHistory className="fs-5" /> Storico
								</Button>
							</Restricted> */}

									<Restricted to={[Roles.Azienda]}>
										{preferiti?.length > 0 ? (
											<button
												className="align-text-bottom btn btn-danger mt-1"
												onClick={() => deleteWishlistFunc(id)}
											>
												{t('remove_from_favourites')} <FaHeart className="fs-5" />
											</button>
										) : (
											<button
												className="align-text-bottom btn btn-primary mt-1"
												onClick={() => addWishlistFunc(id)}
											>
												{t('add_to_favourties')} <FaRegHeart className="fs-5" />
											</button>
										)}
										<Button
											className="mx-2 align-text-bottom btn"
											onClick={() => {
												showModal('requestInformations');
												GTMsendEvent({
													event: 'CTA_ask_quotes',
													evtCat: 'ask_quotes',
													evtAct: 'click',
													evtLab: `${response.dati_veicolo.marca} ${response.dati_veicolo.allestimento}`,
												});
											}}
										>
											<TbFileEuro className="fs-5" />{t('quotation_request')}
										</Button>
									</Restricted>

									<Restricted
										to={[
											Roles.Master,
											Roles.Backoffice,
											Roles.Sales,
											Roles.Dealer,
											Roles.Admin,
										]}
									>
										<Dropdown className="me-3 ms-1 align-text-bottom d-inline-block action-dropdown">
											{/* <Dropdown.Toggle
												as={forwardRef<any, DOMAttributes<any>>((props, ref) => (
													<Btn ref={ref} {...props}>
														Menu Azioni
													</Btn>
												))}
											></Dropdown.Toggle> */}
											<Dropdown.Toggle className="btn">{t('actions')}</Dropdown.Toggle>

											<Dropdown.Menu>
												<Restricted
													to={[Roles.Backoffice, Roles.Master, Roles.Admin]}
												>
													<Dropdown.Item
														onClick={() => {
															showModal('historyActions');
															GTMsendEvent({
																event: 'CTA_storico',
																evtCat: 'storico',
																evtAct: 'click',
																evtLab: response.dati_veicolo.allestimento,
															});
														}}
													>
														{t('view_history')}
													</Dropdown.Item>
												</Restricted>

												<Restricted to={[Roles.Master, Roles.Admin]}>
													<Dropdown.Item
														as={Link}
														to={`/details/${id}/edit`}
														disabled={statoVeicolo === VehicleStateEnum.VENDUTO}
														onClick={() =>
															sendSelectActionToGA4('modifica_dati_veicolo')
														}
													>
														{t('modify_vehicle_data')}
													</Dropdown.Item>
												</Restricted>

												<Restricted to={[Roles.Master, Roles.Admin]}>
													<Dropdown.Item
														as="button"
														onClick={() => {
															makeAvailable();
															sendSelectActionToGA4('rendi_veicolo_disponibile');
														}}
														disabled={statoVeicolo !== VehicleStateEnum.VENDUTO}
													>
														{t('make_it_available')}
													</Dropdown.Item>
												</Restricted>

												<Restricted to={[Roles.Master, Roles.Admin]}>
													<Dropdown.Item
														as={Link}
														to={`/details/${id}/clone`}
														onClick={() => sendSelectActionToGA4('duplica_veicolo')}
													>
														{t('duplicate_vehicle')}
													</Dropdown.Item>
												</Restricted>

												<Restricted
													to={[
														Roles.Master,
														Roles.Admin,
														Roles.Backoffice,
														Roles.Sales,
													]}
												>
													<Dropdown.Item
														as="button"
														onClick={() => {
															sendSelectActionToGA4('inserisci_opzione');
															navigate(
																getUrl('/opportunities/new', {
																	from: 'vehicle',
																	vehicle_id: response.id,
																	action: 'create_option'
																})
															);
														}}
														disabled={
															statoVeicolo !== VehicleStateEnum.DISPONIBILE ||
															!isReoptionable
														}
													>
														{t('insert_option')}
													</Dropdown.Item>
													<Dropdown.Item
														as="button"
														onClick={() => {
															showModal('renewOption');
															sendSelectActionToGA4('rinnova_opzione');
														}}
														disabled={
															statoVeicolo !== VehicleStateEnum.OPZIONATO ||
															extensionNum > 0 ||
															(userRole !== Roles.Master &&
																!wasActionDoneByCurrentUser(
																	VehicleStateEnum.OPZIONATO,
																	response.stato_corrente
																))
														}
													>
														{t('renew_option')}
													</Dropdown.Item>
													<Dropdown.Item
														as="button"
														onClick={() => {
															showModal('deleteOptions');
															sendSelectActionToGA4('cancella_opzione');
														}}
														disabled={
															statoVeicolo !== VehicleStateEnum.OPZIONATO ||
															(userRole !== Roles.Master &&
																!wasActionDoneByCurrentUser(
																	VehicleStateEnum.OPZIONATO,
																	response.stato_corrente
																))
														}
													>
														{t('delete_option')}
													</Dropdown.Item>
												</Restricted>

												<Restricted
													to={[Roles.Backoffice, Roles.Master, Roles.Admin]}
												>
													<Dropdown.Item
														as="button"
														onClick={() => {
															showModal('hideOption');
															sendSelectActionToGA4('oscura_veicolo');
														}}
														disabled={statoVeicolo !== VehicleStateEnum.DISPONIBILE}
													>
														{t('hide_vehicle')}
													</Dropdown.Item>
												</Restricted>

												<Restricted
													to={[Roles.Backoffice, Roles.Master, Roles.Admin]}
												>
													<Dropdown.Item
														as="button"
														onClick={() => {
															showModal('hideRenew');
															sendSelectActionToGA4('rinnova_oscuramento');
														}}
														disabled={
															statoVeicolo !== VehicleStateEnum.OSCURATO ||
															!wasActionDoneByCurrentUser(
																VehicleStateEnum.OSCURATO,
																response.stato_corrente
															)
														}
													>
														{t('renew_hide')}
													</Dropdown.Item>
												</Restricted>

												<Restricted
													to={[Roles.Backoffice, Roles.Master, Roles.Admin]}
												>
													<Dropdown.Item
														as="button"
														onClick={() => {
															showModal('releaseOption');
															sendSelectActionToGA4('rimuovi_oscuramento');
														}}
														disabled={
															statoVeicolo !== VehicleStateEnum.OSCURATO ||
															((userRole !== Roles.Master ||
																userRole !== Roles.Admin) &&
																!wasActionDoneByCurrentUser(
																	VehicleStateEnum.OSCURATO,
																	response.stato_corrente
																))
														}
													>
														{t('delete_hide')}
													</Dropdown.Item>
												</Restricted>

												<Restricted
													to={[
														Roles.Backoffice,
														Roles.Master,
														Roles.Admin,
													]}
												>
													<Dropdown.Item
														as="button"
														onClick={() => {
															sendSelectActionToGA4('conferma_vendita');
															navigate(
																getUrl('/opportunities/new', {
																	from: 'vehicle',
																	vehicle_id: response.id,
																	action: 'confirm_sell'
																})
															);
														}}
														disabled={
															statoVeicolo === VehicleStateEnum.VENDUTO ||
															isActionForbidden!
														}
													>
														{t('confirm_sale')}
													</Dropdown.Item>
												</Restricted>

												<Dropdown.Item
													as="button"
													onClick={() => showModal('downloadPdf')}
													disabled={!response.lista_offerte?.length}
												>
													{t('download_pdf')}
												</Dropdown.Item>

												<Restricted to={[Roles.Master, Roles.Admin]}>
													<Dropdown.Item
														as="button"
														onClick={() => {
															showModal('deleteVehicle');
															sendSelectActionToGA4('elimina_veicolo');
														}}
														disabled={statoVeicolo !== VehicleStateEnum.DISPONIBILE}
													>
														{t('delete')}
													</Dropdown.Item>
												</Restricted>

												{/* toggle in evidenza */}
												<Restricted to={[Roles.Master, Roles.Admin]}>
													{![
														VehicleStateEnum.OSCURATO,
														VehicleStateEnum.VENDUTO,
													].includes(statoVeicolo!) ? (
														<Dropdown.Item
															as="button"
															onClick={() => {
																showModal('toggleInEvidenza');
																sendSelectActionToGA4(
																	inEvidenza
																		? 'rimuovi_in_evidenza'
																		: 'metti_in_evidenza'
																);
															}}
														>
															{inEvidenza
																? i18next.t('remove_highlight')
																: i18next.t('give_highlight')}
														</Dropdown.Item>
													) : (
														''
													)}
												</Restricted>
											</Dropdown.Menu>
										</Dropdown>
									</Restricted>
								</Col>
							</Row>
							{/* <Col md={7} className="text-sm-end pe-4 pt-1">

								<Restricted to={[Roles.Backoffice, Roles.Sales, Roles.Master, Roles.Admin]}>
									<Button
										className="mx-2 align-text-bottom btn"
										onClick={() => {
											showModal('uploadPdfActions')
											GTMsendEvent({
												event: 'CTA_upload_file',
												evtCat: 'offerta',
												evtAct: 'upload'
											})
										}}
									>
										<FaFilePdf className="fs-5" /> Upload
									</Button>
								</Restricted>
								<Restricted to={[Roles.Backoffice, Roles.Master, Roles.Admin]}>
									<Button
										className="align-text-bottom btn"
										onClick={() => {
											showModal('historyActions')
											GTMsendEvent({
												event: 'CTA_storico',
												evtCat: 'storico',
												evtAct: 'click',
												evtLab: response.dati_veicolo.allestimento
											})
										}}
									>
										<FaHistory className="fs-5" /> Storico
									</Button>
								</Restricted>
								<Restricted to={[Roles.Azienda]}>
									{preferiti?.length > 0 ? ( */}
						</Card.Body>
					</Card>


					{/* MAIN *************************************************/}
					<Card className="mt-4 main-container">
						<div className="ribbon-container">
							{response.dettagli_veicolo.in_evidenza ? (
								<div className="vehicle-evidenza-ribbon">{t('in_highlight').toUpperCase()}</div>
							) : (
								''
							)}
						</div>
						<VehicleCardBody data={response}>
							<Col sm={7}>
								{response?.lista_offerte?.length ? (
									<div className="price-container">
										{userRole !== 'AZIENDA' ? (
											<>
												<div className="small">{t('min_rental_fee')}</div>
												<span className="h1" style={{ fontSize: '2.4rem' }}>
													{displayCanoneMinimo(
														response?.lista_offerte[0].importo_privato,
														response?.lista_offerte[0].importo_azienda
													)}
												</span>
												<span className="text-start ms-2 lh-1 d-inline-block">
													<span className="fs-5">€</span>
													<span>/{t('month')}</span>
													<strong className="d-block">
														{displayIvaStr(
															response?.lista_offerte[0].importo_privato,
															response?.lista_offerte[0].importo_azienda
														)}
													</strong>
												</span>
												<div className="h5">
													{t('downpayment')} €
													{response?.lista_offerte[0].anticipo?.toLocaleString(
														'it'
													)}
												</div>
											</>
										) : (
											''
										)}

										{userRole !== 'AZIENDA' ? (
											<div>
												<div className="small mt-3">
													{response?.lista_offerte[0].durata} {t('months')}
												</div>
												<div className="small">
													{response?.lista_offerte[0].km_totali?.toLocaleString(
														'it'
													)}{' '}
													{t('km_per_year')}
												</div>
											</div>
										) : (
											''
										)}
									</div>
								) : (
									<div className="text-muted">
										{t('no_offers_linked_to_this_vehicle')}
									</div>
								)}
							</Col>
						</VehicleCardBody>
					</Card>

					<div className="display-6 mt-5">	{t('details')}</div>

					{/* DETTAGLI *************************************************/}

					<Card className="mt-3">
						<Card.Body>
							<Row className="details-list m-0">
								<Col sm={6}>
									<small>	{t('vehicle_state').toUpperCase()}</small>
									<div>{statoVeicolo}</div>
								</Col>
								<Col sm={6}>
									<small>{t('builder_code').toUpperCase()}</small>
									<div>
										{response?.dettagli_veicolo.codice_costruttore || '-'}
									</div>
								</Col>
								<Col sm={6}>
									<small>{t('availability').toUpperCase()}</small>
									<div>
										{response?.dettagli_veicolo.stato_disp?.replace('_', ' ') ||
											'-'}
									</div>
								</Col>
								<Col sm={6}>
									<small>{t('city').toUpperCase()}</small>
									<div>{response?.dettagli_veicolo.city || '-'}</div>
								</Col>
								<Col sm={6}>
									<small>{t('expected_availability_date').toUpperCase()}</small>
									<div>
										{response?.dettagli_veicolo?.data_disp
											? new Date(
												response?.dettagli_veicolo?.data_disp
											)?.toLocaleDateString('it')
											: '-'}
									</div>
								</Col>
								<Col sm={6}>
									<small>{t('factory_order_number').toUpperCase()}</small>
									<div>{response?.dettagli_veicolo.nr_ordine || '-'}</div>
								</Col>
								<Col sm={6}>
									<small>{t('external_color').toUpperCase()} </small>
									<div>{response?.dettagli_veicolo.colore_esterno || '-'}</div>
								</Col>
								<Col sm={6}>
									<small>{t('list_price').toUpperCase()}</small>
									<div className="text-nowrap">
										{response?.dati_veicolo.prezzo_listino
											? '€ ' +
											response?.dati_veicolo.prezzo_listino?.toLocaleString(
												'it',
												{
													minimumFractionDigits: 2,
												}
											)
											: '-'}
									</div>
								</Col>
								<Col sm={6}>
									<small>{t('extra_list_price_discount_percentage').toUpperCase()}</small>
									<div>
										{response?.dati_veicolo.extra_sconto_perc} %
									</div>
								</Col>
								<Col sm={6}>
									<small>{t('internal_color').toUpperCase()}</small>
									<div>{response?.dettagli_veicolo.colore_interno || '-'}</div>
								</Col>
								<Col sm={6}>
									<small>{t('vehicle_details').toUpperCase()}</small>
									<div>{response?.dettagli_veicolo.telaio || '-'}</div>
								</Col>
								<Col sm={6}>
									<small>{t('vehicle_body').toUpperCase()}</small>
									<div>{response?.dettagli_veicolo.carrozzeria || '-'}</div>
								</Col>
								<Col sm={6}>
									<small>{t('vehicle_registration_date').toUpperCase()}</small>
									<div>
										{response?.dettagli_veicolo?.data_immatricolazione
											? new Date(
												response?.dettagli_veicolo?.data_immatricolazione
											)?.toLocaleDateString('it')
											: '-'}
									</div>
								</Col>
								<Col sm={6}>
									<small>{t('vehicle_plate').toUpperCase()}</small>
									<div>{response?.dettagli_veicolo.targa || '-'}</div>
								</Col>
								<Col sm={6}>
									<small>{t('id_bo').toUpperCase()}</small>
									<div>{response?.dettagli_veicolo?.id_bo || '-'}</div>
								</Col>
								<Col sm={6}>
									<small>{t('notes').toUpperCase()}</small>
									<div>{response?.dettagli_veicolo?.note || '-'}</div>
								</Col>
							</Row>

							{/* DETT OPZIONE *************************************************/}

							<Accordion className="mt-4 technical-data" flush>
								{[VehicleStateEnum.OPZIONATO, VehicleStateEnum.OSCURATO].includes(
									response?.stato_corrente?.type
								) ? (
									<Accordion.Item eventKey="2">
										<Accordion.Header>{t('option_details')}</Accordion.Header>
										<Accordion.Body>
											<Row className="details-list">
												<Col sm={6}>
													<small>{t('option_user')}</small>
													<div>{response?.stato_corrente?.utente || '-'}</div>
												</Col>
												<Col sm={6}>
													<small>{t('option_dealer')}</small>
													<div>
														{response?.stato_corrente?.dealer_utente || '-'}
													</div>
												</Col>
												<Col sm={6}>
													<small>{opportunity ? i18next.t('account') : i18next.t('customer')}</small>
													<div>
														{opportunity ? (
															<span>
																<a
																	href={`/my-accounts/${opportunity.AccountId}`}
																>
																	{response?.stato_corrente?.cliente || '-'}
																</a>
															</span>
														) : (
															<span>
																{response?.stato_corrente?.cliente || '-'}
															</span>
														)}
													</div>
												</Col>
												<Col sm={6}>
													<small>{t('business_name')}</small>
													<div>
														{response?.stato_corrente?.ragione_sociale || '-'}
													</div>
												</Col>
												<Col sm={6}>
													<small>{t('credit_application')}</small>
													<div>
														{response?.stato_corrente?.pratica_credito || '-'}
													</div>
												</Col>
												<Col sm={6}>
													<small>{t('number_extensions')}</small>
													<div>
														{response?.stato_corrente?.n_estensione || '0'}
													</div>
												</Col>

												{response?.stato_corrente?.type ===
													VehicleStateEnum.OPZIONATO && (
														<>
															<Col sm={6}>
																<small>{t('option_start_date').toUpperCase()}</small>
																<div>
																	{response?.stato_corrente?.data_inizio
																		? new Date(
																			response?.stato_corrente?.data_inizio
																		)?.toLocaleDateString('it')
																		: '-'}
																</div>
															</Col>
															<Col sm={6}>
																<small>{t('option_expiration_date').toUpperCase()}</small>
																<div>
																	{response?.stato_corrente?.data_scadenza
																		? new Date(
																			response?.stato_corrente?.data_scadenza
																		)?.toLocaleDateString('it')
																		: '-'}
																</div>
															</Col>
														</>
													)}

												{opportunity && (
													<>
														<Col sm={6}>
															<small>{t('opportunity_name').toUpperCase()}</small>
															<div>
																<a href={`/opportunities/${opportunity.Id}`}>
																	{opportunity.Name}
																</a>
															</div>
														</Col>
														<Col sm={6}>
															<small>{t('rental_company').toUpperCase()}</small>
															<div>{opportunity.Noleggiatore__c}</div>
														</Col>
														<Col sm={6}>
															<small>{t('opportunity_creation_date').toUpperCase()}</small>
															<div>
																{dayjs(opportunity.CreatedDate).format(
																	FORMAT_DATE_TIME
																)}
															</div>
														</Col>
													</>
												)}
											</Row>
										</Accordion.Body>
									</Accordion.Item>
								) : (
									''
								)}

								{/* DETTAGLI VENDITA */}
								{response?.stato_corrente?.type === VehicleStateEnum.VENDUTO ? (
									<Accordion.Item eventKey="3">
										<Accordion.Header>{t('sale_details')}</Accordion.Header>
										<Accordion.Body>
											<Row className="details-list">
												<Col sm={6}>
													<small>{t('sale_user')}</small>
													<div>{response?.stato_corrente?.utente || '-'}</div>
												</Col>
												<Col sm={6}>
													<small>{opportunity ? i18next.t('account') : i18next.t('user')}</small>
													<div>
														{opportunity ? (
															<span>
																<a
																	href={`/my-accounts/${opportunity.AccountId}`}
																>
																	{response?.stato_corrente?.cliente || '-'}
																</a>
															</span>
														) : (
															<span>
																{response?.stato_corrente?.cliente || '-'}
															</span>
														)}
													</div>
												</Col>
												<Col sm={6}>
													<small>{t('business_name')}</small>
													<div>
														{response?.stato_corrente?.ragione_sociale || '-'}
													</div>
												</Col>
												<Col sm={6}>
													<small>{t('credit_application')}</small>
													<div>
														{response?.stato_corrente?.pratica_credito || '-'}
													</div>
												</Col>
												<Col sm={6}>
													<small>{t('sale_date').toUpperCase()}</small>
													<div>
														{response?.stato_corrente?.data_inizio
															? new Date(
																response?.stato_corrente?.data_inizio
															)?.toLocaleDateString('it')
															: '-'}
													</div>
												</Col>
												{opportunity && (
													<>
														<Col sm={6}>
															<small>{t('opportunity_name').toUpperCase()}</small>
															<div>
																<a href={`/opportunities/${opportunity.Id}`}>
																	{opportunity.Name}
																</a>
															</div>
														</Col>
														<Col sm={6}>
															<small>{t('rental_company').toUpperCase()}</small>
															<div>{opportunity.Noleggiatore__c}</div>
														</Col>
														<Col sm={6}>
															<small>{t('opportunity_creation_date').toUpperCase()}</small>
															<div>
																{dayjs(opportunity.CreatedDate).format(
																	FORMAT_DATE_TIME
																)}
															</div>
														</Col>
													</>
												)}
											</Row>
										</Accordion.Body>
									</Accordion.Item>
								) : (
									''
								)}
							</Accordion>
						</Card.Body>
					</Card>

					<Restricted
						to={[
							Roles.Master,
							Roles.Backoffice,
							Roles.Sales,
							Roles.Dealer,
							Roles.Admin,
						]}
					>
						<div>
							<div className="display-6 mt-5">
							{t('available_price_combinations').toUpperCase()}
							</div>

							<Card className="mt-3">
								<Card.Body>
									{response?.lista_offerte?.length ? (
										<TableContainer
											columns={columns}
											data={data.lista_offerte}
											loading={loading}
											error={null}
											showModalFunc={showModal}
										></TableContainer>
									) : (
										<div className="m-4 text-muted">
										{t('no_offers_linked_to_this_vehicle')}
										</div>
									)}
								</Card.Body>
							</Card>
						</div>
					</Restricted>

					<div className="display-6 mt-5">{t('discover_car_data')}</div>

					{/* DATI AUTO *************************************************/}

					<Card className="mt-3">
						<Card.Body>
							<Accordion className="technical-data" flush>
								<Accordion.Item eventKey="0">
									<Accordion.Header
										onClick={() => {
											GTMsendEvent({
												event: 'cta_accordion',
												category: 'accordion',
												action: 'click',
												label: 'Motore',
												item_name: `${response.dati_veicolo.marca} ${response.dati_veicolo.allestimento}`,
											});
										}}
									>
										<Icon name="engine" /> {t('engine')}
									</Accordion.Header>
									<Accordion.Body>
										<Row className="details-list">
											<Col sm={6}>
												<small>{t('cylinder_capacity')}</small>
												<div>{response?.dati_veicolo.cilindrata || '-'} {t('cylinder_capacity_short').toUpperCase()}</div>
											</Col>
											<Col sm={6}>
												<small>{t('horsepower')}</small>
												<div>{response?.dati_veicolo.cavalli || '-'} {t('horsepower_short').toUpperCase()}</div>
											</Col>
											<Col sm={6}>
												<small>{t('fuel_type')}</small>
												<div>{response?.dati_veicolo.alimentazione || '-'}</div>
											</Col>
											<Col sm={6}>
												<small>{t('kw')}</small>
												<div>{response?.dati_veicolo.kw || '-'} {t('kw_short').toUpperCase()}</div>
											</Col>
											<Col sm={6}>
												<small>{t('vehicle_gear')}</small>
												<div>{response?.dati_veicolo.cambio || '-'}</div>
											</Col>
											<Col sm={6}>
												<small>{t('vehicle_drive_system')}</small>
												<div>{response?.dati_veicolo.trazione || '-'}</div>
											</Col>
											<Col sm={6}>
												<small>{t('engine_torque')}</small>
												<div>{response?.dati_veicolo.coppia || '-'}</div>
											</Col>
										</Row>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="1">
									<Accordion.Header
										onClick={() => {
											GTMsendEvent({
												event: 'cta_accordion’',
												category: 'accordion',
												action: 'click',
												label: 'Dimensioni',
												item_name: `${response.dati_veicolo.marca} ${response.dati_veicolo.allestimento}`,
											});
										}}
									>
										<Icon name="car-size" />{t('dimensions')}
									</Accordion.Header>
									<Accordion.Body>
										<Row className="details-list">
											<Col sm={6}>
												<small>{t('doors').toUpperCase()}</small>
												<div>{response?.dati_veicolo.porte || '-'}</div>
											</Col>
											<Col sm={6}>
												<small>{t('seats').toUpperCase()}</small>
												<div>{response?.dati_veicolo.posti || '-'}</div>
											</Col>
											<Col sm={6}>
												<small>{t('length').toUpperCase()}</small>
												<div>{response?.dati_veicolo.lunghezza || '-'} {t('centimeters_short').toUpperCase()}</div>
											</Col>
											<Col sm={6}>
												<small>{t('width').toUpperCase()}</small>
												<div>{response?.dati_veicolo.larghezza || '-'} {t('centimeters_short').toUpperCase()}</div>
											</Col>
											<Col sm={6}>
												<small>{t('height').toUpperCase()}</small>
												<div>{response?.dati_veicolo.altezza || '-'} {t('centimeters_short').toUpperCase()}</div>
											</Col>
											<Col sm={6}>
												<small>{t('luggage_capacity').toUpperCase()}</small>
												<div>
													{response?.dati_veicolo.capacita_vano_carico || '-'} L
												</div>
											</Col>
										</Row>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="2">
									<Accordion.Header
										onClick={() => {
											GTMsendEvent({
												event: 'cta_accordion’',
												category: 'accordion',
												action: 'click',
												label: 'Prestazioni',
												item_name: `${response.dati_veicolo.marca} ${response.dati_veicolo.allestimento}`,
											});
										}}
									>
										<Icon name="speedometer" /> {t('performance')}
									</Accordion.Header>
									<Accordion.Body>
										<Row className="details-list">
											<Col sm={6}>
												<small>{t('speed').toUpperCase()}</small>
												<div>{response?.dati_veicolo.velocita || '-'}{t('km_h_short').toUpperCase()}</div>
											</Col>
											<Col sm={6}>
												<small>{t('acceleration').toUpperCase()}</small>
												<div>
													{response?.dati_veicolo.accelerazione || '-'} {t('seconds_short')}
												</div>
											</Col>
										</Row>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="3">
									<Accordion.Header
										onClick={() => {
											GTMsendEvent({
												event: 'cta_accordion’',
												category: 'accordion',
												action: 'click',
												label: 'Consumi ed emissioni',
												item_name: `${response.dati_veicolo.marca} ${response.dati_veicolo.allestimento}`,
											});
										}}
									>
										<Icon name="fuel" />
										{t('fuel_consumption_and_emissions')}
									</Accordion.Header>
									<Accordion.Body>
										<Row className="details-list">
											<Col sm={6}>
												<small>{t('average_consumption').toUpperCase()}</small>
												<div>
													{response?.dati_veicolo.consumo_medio || '-'} {t('liters_100_km').toUpperCase()}
												</div>
											</Col>
											<Col sm={6}>
												<small>{t('urban_consumption').toUpperCase()}</small>
												<div>
													{response?.dati_veicolo.consumo_urbano || '-'} {t('liters_100_km').toUpperCase()}
												</div>
											</Col>

											<Col sm={6}>
												<small>{t('urban_extra_consumption').toUpperCase()}</small>
												<div>
													{response?.dati_veicolo.consumo_extra_urbano || '-'}{' '}
													{t('liters_100_km').toUpperCase()}
												</div>
											</Col>
											<Col sm={6}>
												<small>{t('co2_emissions').toUpperCase()}</small>
												<div>
													{response?.dati_veicolo.emissioni_co2 || '-'} {t('g_km').toUpperCase()}
												</div>
											</Col>
										</Row>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="4">
									<Accordion.Header
										onClick={() => {
											GTMsendEvent({
												event: 'cta_accordion’',
												category: 'accordion',
												action: 'click',
												label: 'Optional',
												item_name: `${response.dati_veicolo.marca} ${response.dati_veicolo.allestimento}`,
											});
										}}
									>
										<Icon name="list" /> {t('optional')}
									</Accordion.Header>
									<Accordion.Body>
										<Row className="details-list">
											{response?.optionals?.map((o, i) => (
												<Col key={i} xs={12}>
													{o.descrizione} ({o.codice_costruttore})
													<span className="float-end">
														€
														{(o.valore || 0)?.toLocaleString('it', {
															minimumFractionDigits: 2,
														})}
													</span>
												</Col>
											))}
										</Row>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</Card.Body>
					</Card>

					<VehicleDetails></VehicleDetails>

					<div style={{ display: 'none' }}>
						<QuickoPdfTemplate
							data={{ pdf: quickoPdfData, ...response, image_list: pdfImageList }}
						></QuickoPdfTemplate>
					</div>

					{process.env.REACT_APP_QUICKO_ENABLED === '1' && response.dati_veicolo.jato_id &&
						<div ref={aiQuotationRef}>
							<AiQuotation data={response} showModal={showModal} setDownlodPdfValues={setDownlodPdfValues} />
						</div>
					}
				</>
			)}
		</Container>
	);
};

export default DetailsPage;