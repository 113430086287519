import React, { useState, useEffect } from 'react';
import OptionActionModal from '../modals/optionActionModal';
import { useTranslation } from 'react-i18next';
import { useApiClient } from '../../hooks/useApiClient';
import toaster from '../../utils/toaster';

interface ManageModalInterface {
  vehicle_id: string,
  db: string,
  isModalVisible: boolean,
  setModalVisibility: (v: React.SetStateAction<boolean>) => void
}

const ManageModal = ({ vehicle_id, db, isModalVisible, setModalVisibility }: ManageModalInterface) => {
  const [modalType, setModalType] = useState('');
  const [modalParams, setModalParams] = useState(null);
  const [loading, setLoading] = useState(false);
  const { apiClient, handleApi } = useApiClient();
  const { t } = useTranslation()

  const fetchOptions = async () => {
    setLoading(true);

    handleApi(apiClient.vehicleController.fuzzySearchOptionals(db, vehicle_id))
      .then((data) => {

        data = data.filter(el => {
          el = el.trim();
          return el !== 'O' || el !== 'E'
        });

        setLoading(false)
        showModal('viewVehicleOptionals', { optionals: data, vehicle_id });
      })
      .catch((err) => {
        setLoading(false)
        // setError(error)
        toaster.error(
          err.message || t('error_updating_data')
        );
      });
  }
  const closeModal = () => {
    setModalType('');
    setModalVisibility(false);
  };

  const showModal = (type, params?: any) => {
    setModalType(type);
    setModalParams(params);
    setModalVisibility(true);
  };

  useEffect(() => {
    if (vehicle_id) {
      fetchOptions()
    }
  }, [])

  return (
    <div>
      <OptionActionModal
        show={isModalVisible}
        onHide={closeModal}
        modalType={modalType}
        details={modalParams}
        loading={loading}
      />
    </div>
  );
};

export default ManageModal;
