import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import { useRef } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { useTranslation } from 'react-i18next';

import './AutobizPage.scss';


const AutobizPage = () => {
	const loadingBarRef: any = useRef(null);
  const { t } = useTranslation()

	return (
		<>
			<LoadingBar color="#1fb0e5" height={4} ref={loadingBarRef} />
			<Container fluid>
				<div className="display-6">{t('used_vehicle_evaluation')}</div>
				<Card className="mt-2">
					<Card.Body>
						<iframe title='Autobiz Frame' src="https://iframe.autobiz.com/iframe/330ce7bb-ecd6-4dbd-8102-9c7b78f29fdd"></iframe>
					</Card.Body>
				</Card>
			</Container>
		</>
	);
};

export default AutobizPage;
