import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { useState, useEffect} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FaAddressBook, FaBuilding } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { GTMsendEvent } from '../../utils/trackingGA4';
import { useAuth } from '../../hooks/useAuth';
import { useApiClient } from '../../hooks/useApiClient';
import toaster from '../../utils/toaster';
import TableContainer from '../../components/genericTable/TableContainer';
import { columnsData } from './columnsData';
import { VehicleStateEnum } from '../../api';
import { filterFns } from './filterFns';
import Icon from '../../components/Icon/Icon';
import { Roles } from '../../types';
import CardPlaceholder from '../../components/CardPlaceholder/CardPlaceholder';
import HomePageCarouselPlaceholder from '../../components/HomePageCarouselPlaceholder/HomePageCarouselPlaceholder';
import './Homepage.scss';

const HomePage = () => {
	const { t } = useTranslation()
	const { user, userRole } = useAuth();
	const { apiClient, handleApi } = useApiClient();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [availableCount, setAvailableCount] = useState(null);
	const [promoCount, setPromoCount] = useState(null);
	const [optionedCount, setOptionedCount] = useState(null);
	const [soldCount, setSoldCount] = useState(null);
	const [mystockCount, setMystockCount] = useState(null);
	const [obscuredCount, setObscuredCount] = useState(null);
	const [backAvailableCount, setbackAvailableCount] = useState(null);
	const [latestVehiclesInsCount, setLatestInsertedVehiclesCount] = useState(null);
	const [soldData, setSoldData] = useState([]);
	const [optionedData, setOptionedData] = useState([]);
	const [obscuredData, setObscuredData] = useState([]);
	const [backAvailableData, setbackAvailableData] = useState([]);
	const [latestVehiclesInsData, setLatestInsertedVehiclesData] = useState([]);
	const [vehiclesInEvidenza, setVehiclesInEvidenza] = useState<any>([])

	useEffect(() => {

		Promise.all([
			handleApi(apiClient.vehicleController.getVehiclesBackAvailable()),
			handleApi(apiClient.vehicleController.getVehiclesInEvidenza()),
			handleApi(apiClient.vehicleController.getLastestInsertedVehicle()),
			handleApi(apiClient.vehicleController.getVehiclesFilter())
		]).then(res => {
			const [backAvailableRes, inEvidenzaRes, lastestInsertedVehicleRes, vehiclesFilterRes] = res;

			setbackAvailableCount(backAvailableRes.length);
			setbackAvailableData(backAvailableRes);

			setLatestInsertedVehiclesCount(lastestInsertedVehicleRes.length);
			setLatestInsertedVehiclesData(lastestInsertedVehicleRes);

			// get the cheapest offer
			inEvidenzaRes.map((item) => {
				if (item.lista_offerte.length > 0) {
					// Filter out null or undefined values from importo_azienda
					const validOffer = item.lista_offerte.filter(
						(offerta) => offerta.is_active === true && (
							(offerta.importo_azienda !== null && offerta.importo_azienda !== undefined) ||
							(offerta.importo_privato !== null && offerta.importo_privato !== undefined)
						)
					);

					// Check if there are valid offers
					if (validOffer.length > 0) {
						for (let i = 0; i < validOffer.length; i++) {
							const element = validOffer[i];

							if (typeof element.importo_azienda === 'number') {
								element.smallest_importo = element.importo_azienda;
							}

							if (typeof element.importo_privato === 'number') {
								if (typeof element.smallest_importo === 'undefined' || element.importo_privato < element.smallest_importo) {
									element.smallest_importo = element.importo_privato;
								}
							}
						}

						const smallestImporto = validOffer.reduce((prev, current) =>
							current.smallest_importo < prev.smallest_importo ? current : prev
						);

						item.lista_offerte = smallestImporto;
					} else {
						// If all importo_azienda values are null or undefined, set lista_offerte to null
						item.lista_offerte = [];
					}
				}

				return item;
			});

			setVehiclesInEvidenza(inEvidenzaRes);

			setAvailableCount(vehiclesFilterRes.filter(vehicle =>
				vehicle.stato_corrente.type === VehicleStateEnum.DISPONIBILE
			).length
			);

			setPromoCount(vehiclesFilterRes.filter((vehicle) => vehicle.promo === true).length);

			// il mio cruscotto counts
			setMystockCount(filterFns[userRole](vehiclesFilterRes, user).length);

			setOptionedCount(
				filterFns[userRole](vehiclesFilterRes, user, [VehicleStateEnum.OPZIONATO])
					.length
			);

			setSoldCount(
				filterFns[userRole](vehiclesFilterRes, user, [VehicleStateEnum.VENDUTO])
					.length
			);

			setObscuredCount(
				filterFns[userRole](vehiclesFilterRes, user, [VehicleStateEnum.OSCURATO])
					.length
			);

			// tables
			setSoldData(
				filterFns[userRole](vehiclesFilterRes, user, [VehicleStateEnum.VENDUTO])
			);

			setOptionedData(
				filterFns[userRole](vehiclesFilterRes, user, [VehicleStateEnum.OPZIONATO])
			);

			setObscuredData(
				filterFns[userRole](vehiclesFilterRes, user, [VehicleStateEnum.OSCURATO])
			);
		})
			.catch((err) => {
				toaster.error(
					err.message || i18next.t('error_loading_data')
				);
			})
			.finally(() => {
				setLoading(false)
			})
	}, []);

	const handleClickVehicle = (vehicle, index) => {
		GTMsendEvent({
			event: 'select_item',
			item_id: vehicle.nr_ordine,
			item_name: `${vehicle.marca} ${vehicle.modello} - ${vehicle.allestimento}`,
			affiliation: vehicle.dealer,
			item_category: vehicle?.marca,
			item_category2: vehicle.allestimento,
			item_category3: vehicle.codice_costruttore,
			item_category4: vehicle.stato_disp,
			price: Number(vehicle.lista_offerte.importo_azienda || 0),
		})
	}

	return (
		<Container fluid>
			<div className="display-4">{t('hello')} {userRole}</div>

			{!loading ? (
				<>
					<div className="mt-5" style={{ height: "auto", borderRadius: '10px' }} >
						<Card.Title className='mt-3'>{t('the_vehicles')}
							<span className='vehicle-in-evidenza-title'>
								{' '} {t('in_highlight')}
							</span>
						</Card.Title>
						<div className="homepage-tile">
							<div className="vehicle-scroll-container">
								{vehiclesInEvidenza.map((vehicle: any, index: number) => (
									<Link
										to={`/details/${vehicle.id}`}
										className='vehicle-link-to'
										key={index}
										onClick={() => handleClickVehicle(vehicle, index)}
									>
										<div className="vehicle-container">

											{/* PROMO LABEL TOP */}
											{vehicle.promo && vehicle.promo_note ?
												<div className="vehicle-promo-top">
													{vehicle.promo_note}
													{/* <span className="tooltiptext">Tooltip text</span> */}

												</div>
												: ""}
											<div className="vehicle-image-container">
												<img src={vehicle?.image_list[0]?.url || process.env.PUBLIC_URL + '/images/placeholder.png'} alt={`${vehicle.marca} thumbnail`} className='vehicle-thumbnail' style={!vehicle?.image_list[0]?.url ? { height: '186px' } : {}} />
											</div>
											<div className="vehicle-promo">
												{vehicle.promo_note}
											</div>

											<div className="vehicle-info">
												<div className="vehicle-marca">{vehicle.marca.toUpperCase()}</div>
												<div className="vehicle-modello">{vehicle.modello}</div>
												<div className="vehicle-allestimento">{vehicle.allestimento}</div>
												{Object.keys(vehicle.lista_offerte).length > 0 ?
													<div className="vehicle-offerta-container">
														<div className="vehicle-importo">
															<div className="left-section">
																<span className="full-height-text">{vehicle.lista_offerte.smallest_importo}</span>
															</div>
															<div className="right-section">
																<div className="half-height-text"> <span>€</span> /{t('month')}</div>
																<div className="half-height-text">
																{t('vat').toUpperCase()} {vehicle.lista_offerte.smallest_importo === vehicle.lista_offerte.importo_azienda ? i18next.t('excluded') : i18next.t('included')}
																</div>
															</div>
														</div>
														<div className="vehicle-anticipo">
															{t('downpayment')} € {vehicle.lista_offerte.anticipo}
														</div>
														<div className="vehicle-months-km">{vehicle.lista_offerte.durata} mesi / {vehicle.lista_offerte.km_totali} km all'anno</div>
													</div>
													: <div className='vehicle-no-offerte'>
														{t('no_offers_for_this_vehicle')}
													</div>
												}
											</div>
										</div>
									</Link>
								))}
							</div>
						</div>
					</div>


					<div className="py-4">
						<hr />
					</div>

					<Row>
						<Col lg={3} md={4} sm={6}>
							<Card >
								<Card.Body
									className="homepage-tile"
									onClick={() => navigate(`/available-vehicles`, {
										state: {
											filter: {
												stato_corrente: [VehicleStateEnum.DISPONIBILE]
											},
										}
									})}
								>
									<Card.Title className="text-center">
										{t('vehicles_available')}
									</Card.Title>

									<div className="h4 text-center">
										<Card.Text>{availableCount}</Card.Text>
									</div>
								</Card.Body>
							</Card>
						</Col>

						<Col lg={3} md={6} sm={6}>
							<Card className="promo-discover">
								<Card.Body
									className="homepage-tile"
									onClick={() => navigate(`/promo-vehicles`)}
								>
									<Card.Title className="text-center">
									{t('discover_all_promo_vehicles')}
									</Card.Title>

									{loading ?
										<Spinner animation="border" role="status" size="sm">
											<span className="visually-hidden">{t('loading')}...</span>
										</Spinner> :
										<div className="h4 text-center">
											<Card.Text>{promoCount}</Card.Text>
										</div>
									}
								</Card.Body>
							</Card>

						</Col>
					</Row>

					<Row className="mt-0">
						<Col lg={6}>
							<Card className="mt-4 homepage-table-card">
								<Card.Body className="table-card-body">
									<Card.Title
										className="table-card-title"
										onClick={() => navigate(`/available-vehicles`)}
									>
									{t('vehicles_back_in_stock_last_24h')}{' '}
										<div className="cruscotto-table-count DISPONIBILE">
											<span>{backAvailableCount}</span>
										</div>
										<div className="arrow-wrapper">
											<Icon className="table-card-arrow" name="arrow-right" />
										</div>
									</Card.Title>

									{!!backAvailableCount && (
										<TableContainer
											columns={columnsData}
											data={backAvailableData}
											loading={loading}
											error={null}
											useFixedColumn={true}
											hasFilter={false}
											initPageSize={5}
											noBorder={true}
											isMinorPagination={true}
											dblClickCallback={(row: any) =>
												navigate(`/details/${row.id}`)
											}
										></TableContainer>
									)}
									{!backAvailableCount && (
										<p className="no-veicoli-msg">
											{t('no_vehicles_back_in_stock_last_24h')}
										</p>
									)}
								</Card.Body>
							</Card>
						</Col>
						<Col lg={6}>
							<Card className="mt-4 homepage-table-card">
								<Card.Body className="table-card-body">
									<Card.Title
										className="table-card-title"
										onClick={() => navigate(`/available-vehicles`, { state: { sortByInsDate: true } })}
									>
										{t('latest_vehicles_uploaded')}
										<div className="arrow-wrapper">
											<Icon className="table-card-arrow" name="arrow-right" />
										</div>
									</Card.Title>

									{!!latestVehiclesInsCount && (
										<TableContainer
											columns={columnsData}
											data={latestVehiclesInsData}
											loading={loading}
											error={null}
											useFixedColumn={true}
											hasFilter={false}
											initPageSize={5}
											noBorder={true}
											isMinorPagination={true}
											dblClickCallback={(row: any) =>
												navigate(`/details/${row.id}`)
											}
										></TableContainer>
									)}
									{!latestVehiclesInsCount && (
										<p className="no-veicoli-msg">
											{t('no_vehicle_uploaded')}
										</p>
									)}
								</Card.Body>
							</Card>
						</Col>
					</Row>

					<div className="py-4">
						<hr />
					</div>

					<div className="h5">{t('my_dashboard')}</div>
					<Row>
						<Col lg={3} md={4} sm={6}>
							<Card className="mt-4">
								<Card.Body
									className="homepage-tile"
									onClick={() => navigate(`/my-stock`)}
								>
									<Card.Title className="text-center">{t('my_stock')}</Card.Title>
									<div className="h4 text-center">
										<Card.Text>{mystockCount}</Card.Text>
									</div>
								</Card.Body>
							</Card>
						</Col>
						<Col lg={3} md={4} sm={6}>
							<Card className="mt-4">
								<Card.Body
									className="homepage-tile"
									onClick={() => navigate(`/my-companies`)}
								>
									<div className="h5 text-center">
										<FaBuilding />
										<Card.Text>{t('my_companies')}</Card.Text>
									</div>
								</Card.Body>
							</Card>
						</Col>
						<Col lg={3} md={4} sm={6}>
							<Card className="mt-4">
								<Card.Body
									className="homepage-tile"
									onClick={() => navigate(`/my-accounts`)}
								>
									<div className="h5 text-center">
										<FaAddressBook />
										<Card.Text>
										{t('my_accounts')}
										</Card.Text>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>

					<Row className="mt-2">
						{[Roles.Dealer, Roles.Sales].includes(userRole) ? (
							''
						) : (
							<Col lg={6}>
								<Card className="mt-4 homepage-table-card">
									<Card.Body className="table-card-body">
										<Card.Title
											className="table-card-title"
											onClick={() =>
												navigate(`/my-stock?prefilter=${VehicleStateEnum.VENDUTO}`)
											}
										>
											{t('vehicle_sold')}{' '}
											<div className="cruscotto-table-count VENDUTO">
												<span>{soldCount}</span>
											</div>
											<div className="arrow-wrapper">
												<Icon className="table-card-arrow" name="arrow-right" />
											</div>
										</Card.Title>

										{!!soldCount && (
											<TableContainer
												columns={columnsData}
												data={soldData}
												loading={loading}
												error={null}
												useFixedColumn={true}
												hasFilter={false}
												initPageSize={5}
												noBorder={true}
												isMinorPagination={true}
												dblClickCallback={(row: any) =>
													navigate(`/details/${row.id}`)
												}
											></TableContainer>
										)}
										{!soldCount && (
											<p className="no-veicoli-msg">
												{t('no_sold_vehicles_in_your_stock')}
											</p>
										)}
									</Card.Body>
								</Card>
							</Col>
						)}
						<Col lg={6}>
							<Card className="mt-4 homepage-table-card">
								<Card.Body className="table-card-body">
									<Card.Title
										className="table-card-title"
										onClick={() =>
											navigate(`/my-stock?prefilter=${VehicleStateEnum.OPZIONATO}`)
										}
									>
										{t('optioned_vehicles')}{' '}
										<div className="cruscotto-table-count OPZIONATO">
											<span>{optionedCount}</span>
										</div>
										<div className="arrow-wrapper">
											<Icon className="table-card-arrow" name="arrow-right" />
										</div>
									</Card.Title>

									{!!optionedCount && (
										<TableContainer
											columns={columnsData}
											data={optionedData}
											loading={loading}
											error={null}
											hasFilter={false}
											useFixedColumn={true}
											initPageSize={5}
											noBorder={true}
											isMinorPagination={true}
											dblClickCallback={(row: any) =>
												navigate(`/details/${row.id}`)
											}
										></TableContainer>
									)}
									{!optionedCount && (
										<p className="no-veicoli-msg">
										{t('no_optioned_vehicles_in_your_stock')}
										</p>
									)}
								</Card.Body>
							</Card>
						</Col>
					</Row>

					{[Roles.Dealer, Roles.Sales].includes(userRole) ? (
						''
					) : (
						<Row>
							<Col lg={6}>
								<Card className="mt-4 homepage-table-card">
									<Card.Body className="table-card-body">
										<Card.Title
											className="table-card-title"
											onClick={() =>
												navigate(
													`/my-stock?prefilter=${VehicleStateEnum.OSCURATO}`
												)
											}
										>
											{t('hidden_vehicles')}{' '}
											<div className="cruscotto-table-count OSCURATO">
												<span>{obscuredCount}</span>
											</div>
											<div className="arrow-wrapper">
												<Icon className="table-card-arrow" name="arrow-right" />
											</div>
										</Card.Title>

										{!!obscuredCount && (
											<TableContainer
												columns={columnsData}
												data={obscuredData}
												loading={loading}
												error={null}
												hasFilter={false}
												useFixedColumn={true}
												initPageSize={5}
												noBorder={true}
												isMinorPagination={true}
												dblClickCallback={(row: any) =>
													navigate(`/details/${row.id}`)
												}
											></TableContainer>
										)}
										{!obscuredCount && (
											<p className="no-veicoli-msg">
												{t('no_hidden_vehicles_in_your_stock')}
											</p>
										)}
									</Card.Body>
								</Card>
							</Col>

							{/* <Col lg={6}>
							<Card className="mt-4">
								<Card.Body>
									<Card.Title>Il mio stock</Card.Title>

									<Table striped>
										<thead>
											<tr>
												<th>Marca</th>
												<th>Modello</th>
												<th>Data</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Fiat</td>
												<td>Panda</td>
												<td>11/02/2022</td>
											</tr>
											<tr>
												<td>Audi</td>
												<td>A6</td>
												<td>12/02/2022</td>
											</tr>
											<tr>
												<td>Renault</td>
												<td>Clio</td>
												<td>13/02/2022</td>
											</tr>
										</tbody>
									</Table>
								</Card.Body>
							</Card>
						</Col> */}
						</Row>
					)}
				</>
			) : (
				<div>
					<div className='mt-4'><HomePageCarouselPlaceholder /></div>
					<div className='py-4'><hr /></div>
					<div className="mt-4">
						<Row>
							<Col><CardPlaceholder /></Col>
							<Col><CardPlaceholder /></Col>
						</Row>
					</div>
					<div className='py-4'><hr /></div>
					<div className="mt-4">
						<Row>
							<Col><CardPlaceholder /></Col>
							<Col><CardPlaceholder /></Col>
						</Row>
					</div>
				</div>
			)}
		</Container>
	);
};

export default HomePage;
