import React, { useState } from 'react';
import Stepper from 'react-stepper-horizontal';

const CustomStepper: React.FC<any> = ({ steps, getSectionComponent, activeStep }) => {
  return (
    <div>
      <Stepper
        steps={steps}
        activeStep={activeStep} />
      <div style={{ padding: '20px' }}>
      </div>
    </div>
  );
}

export default CustomStepper;