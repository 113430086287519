// https://www.google.com/search?q=react-table+filter+in+multi+select&rlz=1C1GCEU_itIT915IT915&oq=react-table+filter+in+multi+select&aqs=chrome..69i57j0i22i30.14204j0j7&sourceid=chrome&ie=UTF-8
// https://codesandbox.io/s/react-table-multi-select-filtering-mr18l?file=/index.js

import {  useMemo } from 'react';
import Select from 'react-select';
import i18next from 'i18next';  // Import the i18next instance

const LABELS = {
	stato_disp: {
		TARGATO: i18next.t('registered').toUpperCase(),
		IN_ARRIVO:i18next.t('incoming').toUpperCase(),
		TELAIATO: i18next.t('framed').toUpperCase(),
	},
};
// si potrebbe dare consistenza alle labels. lowercase e capitalize.
const getLabel = (columnId, value: string) => {
	const label = LABELS[columnId]?.[value].replace("_", " ").trim();

	if (label) return label;
	else return value;
};

const ColumnFilterMultiSelect = ({
	props,
	column: { filterValue, setFilter, preFilteredRows, id },
}) => {
	const { filterState, setFilterState } = props;

	const options = useMemo(() => {
		const options: any = [];

		preFilteredRows.forEach((row) => {
			if (!row.values[id]) return;

			// remove underscore
			// row.values[id] = row.values[id].replace("_", " ").trim()

			if (!options.filter((opt) => opt.value === row.values[id]).length) {
				options.push({
					id: id, //type
					value: row.values[id], // OPZIONAMENTO
					label: getLabel(id, row.values[id]), //
				});
			}
		});

		return [...options];
	}, [id, preFilteredRows]);


	const selectedOptions = filterState[id].map((value) => {
		return { id, value, label: getLabel(id, value) };
	});

	const handleChange = (optionArr) => {

		setFilterState((prevState) => {
			return {
				...prevState,
				[id]: optionArr.map((item) => item.value),
			};
		});
	};

	return (
		<Select
			isMulti
			className="multiselect"
			name={id}
			value={selectedOptions}
			options={options}
			onChange={handleChange}
			placeholder={i18next.t('select')}
		/>
	);
};

export default ColumnFilterMultiSelect;
