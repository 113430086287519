import { useRef, useEffect } from 'react';
import LoadingBar from 'react-top-loading-bar';
import fetchIntercept from 'fetch-intercept';

const TopLoadingBar = () => {
	const loadingBarRef: any = useRef(null);

	useEffect(() => {
		const unregister = fetchIntercept.register({
			request: (url, config) => {
				loadingBarRef?.current?.continuousStart();
				return [url, config];
			},

			requestError: (error) => {
				loadingBarRef?.current?.complete();
				return Promise.reject(error);
			},

			response: (response) => {
				loadingBarRef?.current?.complete();
				return response;
			},

			responseError: (error) => {
				loadingBarRef?.current?.complete();
				return Promise.reject(error);
			},
		});

		return () => {
			unregister();
		};
	}, []);

	return <LoadingBar color="#1fb0e5" height={4} ref={loadingBarRef} />;
};

export default TopLoadingBar;
