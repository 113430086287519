import { Card, ListGroup, Row, Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const CardPlaceholder = () => {
    return (
        <Card>
            <Card.Img />
            <Card.Body>
                <Card.Title>
                    <Row>
                        <Col><Skeleton /></Col>
                        <Col></Col>
                    </Row>
                </Card.Title>
                <Card.Text>
                    <Skeleton count={3}/>
                </Card.Text>
            </Card.Body>
            <ListGroup className="list-group-flush">
                <ListGroup.Item><Skeleton /></ListGroup.Item>
                <ListGroup.Item><Skeleton /></ListGroup.Item>
                <ListGroup.Item><Skeleton /></ListGroup.Item>
            </ListGroup>
        </Card>
    );
}

export default CardPlaceholder;