/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { DealerControllerService } from './services/DealerControllerService';
import { GroupControllerService } from './services/GroupControllerService';
import { LeasysControllerService } from './services/LeasysControllerService';
import { UserControllerService } from './services/UserControllerService';
import { VehicleActionsControllerService } from './services/VehicleActionsControllerService';
import { VehicleControllerService } from './services/VehicleControllerService';
import { AreaAcademyControllerService } from './services/AreaAcademyControllerService';
import { AreaMarketingControllerService } from './services/AreaMarketingControllerService';
import { AccountControllerService } from './services/AccountControllerService';
import { OpportunityControllerService } from './services/OpportunityControllerService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class Api {
 
    public readonly dealerController: DealerControllerService;
    public readonly groupController: GroupControllerService;
    public readonly leasysController: LeasysControllerService;
    public readonly userController: UserControllerService;
    public readonly vehicleActionsController: VehicleActionsControllerService;
    public readonly vehicleController: VehicleControllerService;
    public readonly areaAcademyController: AreaAcademyControllerService;
    public readonly areaMarketingController: AreaMarketingControllerService;
    public readonly accountController: AccountControllerService;
    public readonly opportunityController: OpportunityControllerService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1.0-SNAPSHOT',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        
        this.dealerController = new DealerControllerService(this.request);
        this.groupController = new GroupControllerService(this.request);
        this.leasysController = new LeasysControllerService(this.request);
        this.userController = new UserControllerService(this.request);
        this.vehicleActionsController = new VehicleActionsControllerService(this.request);
        this.vehicleController = new VehicleControllerService(this.request);
        this.areaAcademyController = new AreaAcademyControllerService(this.request);
        this.areaMarketingController = new AreaMarketingControllerService(this.request);
        this.accountController = new AccountControllerService(this.request);
        this.opportunityController = new OpportunityControllerService(this.request);
    }
}
