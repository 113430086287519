import { OpportunityStageEnum } from "../api"

export const FORMAT_DATE = 'DD/MM/YYYY'
export const FORMAT_DATE_TIME = 'DD/MM/YYYY HH:mm'

export const OPPORTUNITY_SUPPORTED_FILE_TYPES = ['pdf', 'doc', 'docx', 'ppt', 'pptx']

export const OPPORTUNITY_STATES_DATA: { [key: string]: { label: string, cssClass: string }, } = {
    [OpportunityStageEnum.waiting_sign]: {
        label: "Attesa firma",
        cssClass: "primary"
    },
    [OpportunityStageEnum.closed_won]: {
        label: "Chiusa vinta",
        cssClass: 'success'
    },
    [OpportunityStageEnum.closed_by_customer]: {
        label: "Chiusa persa per ripensamento cliente",
        cssClass: 'danger'
    },
    [OpportunityStageEnum.closed_by_credit_score_check]: {
        label: "Chiusa persa per credito",
        cssClass: 'danger'
    },
    [OpportunityStageEnum.credit_score_check]: {
        label: "Al credito",
        cssClass: "primary"
    },
    [OpportunityStageEnum.suspended]: {
        label: "Sospesa",
        cssClass: "secondary"
    },
    [OpportunityStageEnum.negotiation]: {
        label: "Negoziazione",
        cssClass: "secondary"
    },
    [OpportunityStageEnum.proposal]: {
        label: "Proposta",
        cssClass: "secondary"
    },
    [OpportunityStageEnum.qualification]: {
        label: "Qualificazione",
        cssClass: "secondary"
    }
}

export const NOLEGGIATORI = {
    ARVAL_PRIVATI: "ARVAL PRIVATI",
    ARVAL_P_IVA: "ARVAL P.IVA",
    LEASEPLAN: "LEASEPLAN",
    ALD: "ALD",
    LEASYS_I_CARE: "LEASYS I-CARE",
    LEASYS_NOLEGGIO_CHIARO: "LEASYS"
}

export const NOLEGGIATORI_QUOTATORE = {
    "arval": { name: "Arval", noleggiatori_quicko: [NOLEGGIATORI.ARVAL_PRIVATI, NOLEGGIATORI.ARVAL_P_IVA] },
    "leaseplan": { name: "Leaseplan", noleggiatori_quicko: [NOLEGGIATORI.LEASEPLAN] },
    "ald": { name: "ALD", noleggiatori_quicko: [NOLEGGIATORI.ALD] },
    "leasys": { name: "Leasys", noleggiatori_quicko: [NOLEGGIATORI.LEASYS_I_CARE, NOLEGGIATORI.LEASYS_NOLEGGIO_CHIARO] },
    "athlon": { name: "Athlon", noleggiatori_quicko: [] },
    "alphabet": { name: "Alphabet", noleggiatori_quicko: [] },
    "drivalia": { name: "Drivalia", noleggiatori_quicko: [] },
    "rent2go": { name: "Rent2Go", noleggiatori_quicko: [] },
    "vw_leasing": { name: "VW Leasing", noleggiatori_quicko: [] },
    "kinto": { name: "Kinto", noleggiatori_quicko: [] },
    "unipol_rental": { name: "Unipol Rental", noleggiatori_quicko: [] },
    "hyundai_rent": { name: "Hyundai rent", noleggiatori_quicko: [] }
};