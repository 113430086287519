import i18next from 'i18next';

import UserActionsTableContainer from './UserActionsTableContainer';
import CardPlaceholder from '../CardPlaceholder/CardPlaceholder';


function UserActions({ itsMe, columns, data, loading, error }) {
  return (
    <>
      <div className="pb-2 pt-4">
        <span className="display-6">
          {data ? (itsMe ? i18next.t('my_actions') : i18next.t('user_actions')) : i18next.t('create_new_user')}
        </span>
      </div>

      {!loading ? (
        <UserActionsTableContainer
          columns={columns}
          data={data}
          loading={loading}
          error={error}
        ></UserActionsTableContainer>
      ) : <CardPlaceholder />}
    </>
  )
}

export default UserActions