import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
	Accordion,
	AccordionContext,
	Col,
	Row,
	useAccordionButton,
	Form,
	Card,
	Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Icon from '../../Icon/Icon';
import { Roles } from '../../../types';
import { useAuth } from '../../../hooks/useAuth';
import { VehicleStateEnum } from '../../../api';

const VehicleFilters = ({
	headerGroups,
	setAllFilters,
	loading,
	presetFilter }) => {
	const expandMenu = useAccordionButton('0');
	const { activeEventKey } = useContext(AccordionContext);
	const { userRole } = useAuth();
	const location = useLocation();
	const currentURL = location.pathname;
	let initialState = {}
	const { t } = useTranslation()

	// if user is AZIENDA or SALES and we are in the wishlist page then it needs to return this data
	// ALSO if user is AZIENDA and we are in the available-vehicles page we need to set initial state like this
	// because same component, same url, but only AZIENDA see different colons compare to all other users
	if ((userRole === Roles.Azienda || userRole === Roles.Sales || userRole === Roles.Master || userRole === Roles.Backoffice) && currentURL.includes('/wishlist')
		|| userRole === Roles.Azienda && currentURL.includes('/available-vehicles')) {
		// if (currentURL.includes('/wishlist')) {
		initialState = {
			marca: [],
			modello: [],
			allestimento: [],
			carrozzeria: [],
			colore_esterno: [],
			colore_interno: [],
			telaio: '',
			targa: '',
			alimentazione: '',
			cambio: '',
			trazione: [],
		}
	} else {
		initialState = {
			stato_corrente: [],
			marca: [],
			stato_disp: [],
			modello: [],
			allestimento: [],
			city: [],
			data_disp: { from: '', to: '' },
			offerta_minima: { from: '', to: '' },
			carrozzeria: [],
			colore_esterno: [],
			colore_interno: [],
			telaio: '',
			data_immatricolazione: { from: '', to: '' },
			targa: '',
			alimentazione: '',
			cambio: '',
			trazione: [],
			nr_ordine: '',
			codice_costruttore: '',
			utente: '',
		};
	}

	const getCurrentFilter = () => {
		switch (true) {
			case currentURL.includes('/promo-vehicles'):
				return "vehiclePromo-filters";
			case currentURL.includes('/wishlist'):
				return "vehicleWishlist-filters";
			case currentURL.includes('/available-vehicles'):
				return "vehicleAvailable-filters";
			default:
				return '';
		}
	};

	const savedFilters = sessionStorage.getItem(getCurrentFilter())
	const [filterState, setFilterState] = useState(savedFilters ? JSON.parse(savedFilters) : initialState);
	const applyFilters = (filterState) => {
		// turn the filteState object into an array
		const filtersObjectArray: { id: string; value: any }[] = [];
		for (const [key, value] of Object.entries(filterState)) {
			filtersObjectArray.push({ id: key, value });
		}
		setAllFilters(filtersObjectArray);
	};

	const handleSearch = (e) => {
		applyFilters(filterState);
	};

	const handleReset = () => {
		setFilterState(initialState);
		applyFilters(initialState);
		if (currentURL.includes('/promo-vehicles')) {
			sessionStorage.removeItem("vehiclePromo-filters");
		} else if (currentURL.includes('/wishlist')) {
			sessionStorage.removeItem("vehicleWishlist-filters");
		} else {
			sessionStorage.removeItem("vehicleAvailable-filters");
		}
	};

	useEffect(() => {
		if (!loading) {
			if (presetFilter) {
				if (Object.values(VehicleStateEnum).includes(presetFilter)) {
					presetFilter = { ...filterState, stato_corrente: [presetFilter] }
				}

				setFilterState(presetFilter);
				applyFilters(presetFilter);
			} else {
				applyFilters(filterState);
			}

		}
	}, [loading]);


	useEffect(() => {
		if (currentURL.includes('/promo-vehicles')) {
			sessionStorage.setItem("vehiclePromo-filters", JSON.stringify(filterState));
		} else if (currentURL.includes('/wishlist')) {
			sessionStorage.setItem("vehicleWishlist-filters", JSON.stringify(filterState));
		} else {
			sessionStorage.setItem("vehicleAvailable-filters", JSON.stringify(filterState));
		}
		applyFilters(filterState);
	}, [filterState]);


	return (
		<Card className="filters-card">
			<Card.Header className="border-0">
				<Row>
					{headerGroups.map((headerGroup) =>
						headerGroup.headers.map((column) => {
							return (
								column.PrimaryFilter ? (
									<Col key={column.id} lg={6}>
										<Form.Group className="mb-3">
											<div key={column.id}>
												<Form.Label htmlFor={column.id}>
													{column.render('Header')}
												</Form.Label>
												{column.render('PrimaryFilter', {
													props: { filterState, setFilterState },
												})}
											</div>
										</Form.Group>
									</Col>
								) : null)
						}
						)
					)}
				</Row>
			</Card.Header>
			<Card.Body className="py-0">
				<Accordion.Collapse eventKey="0">
					<>
						<div className="my-4 pb-1 h6 border-bottom">{t('added_filters')}</div>
						<Row>
							{headerGroups.map((headerGroup) =>
								headerGroup.headers.map((column) =>
									column.SecondaryFilter ? (
										<Col key={column.id} lg={6}>
											<Form.Group className="mb-3">
												<div key={column.id}>
													<Form.Label htmlFor={column.id}>
														{column.render('Header')}
													</Form.Label>
													{column.render('SecondaryFilter', {
														props: {
															filterState,
															setFilterState,
															type: column.inputType,
														},
													})}
												</div>
											</Form.Group>
										</Col>
									) : null
								)
							)}
						</Row>
					</>
				</Accordion.Collapse>
			</Card.Body>
			<Card.Footer className="pt-0 border-0">
				<Row>
					<Col lg={4}></Col>
					<Col lg={4} className="open-filtri-- text-center">
						<div
							className="open-filtri-container"
							onClick={(event: React.MouseEvent) => {
								event.preventDefault();
								expandMenu(event);
							}}
						>
							<div className="open-filtri-label">{t('added_filters')}</div>
							<div>
								<div>
									<Icon
										className={
											(activeEventKey === '0' ? 'is-open' : '') +
											' mt-3 expand-icon'
										}
										name="arrow-right"
									></Icon>
								</div>
							</div>
						</div>
					</Col>
					<Col lg={4} className="text-end">
						{/* <Link to="#" onClick={handleReset} className="btn me-3">
							Reset
						</Link> */}
						{/* <Btn onClick={handleSearch} icon="arrow-right">
							Cerca
						</Btn> */}
						<Button className="filter-reset-btn" onClick={handleReset}>
						{t('reset_filters')}
						</Button>
					</Col>
				</Row>
			</Card.Footer>
		</Card>
	);
};

export default VehicleFilters;
