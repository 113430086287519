import { Alert } from "react-bootstrap"
import { useTranslation } from 'react-i18next';

const MissingAccountRecordTypeAlert = ({ accountId }: { accountId: string }) => {
    const { t } = useTranslation()

    return (
        <Alert variant='danger'>
            L'account selezionato non può essere utilizzato per creare opportunity dalla piattaforma HMP. <br />
            Si prega di aggiungere il valore "Tipo di record" &nbsp;
            {process.env.REACT_APP_SALESFORCE_INSTANCE_URL ? (
                <a target={'_blank'}
                    href={process.env.REACT_APP_SALESFORCE_INSTANCE_URL + `/lightning/r/Account/${accountId}/view`}
                >su Salesforce</a>
            ): 'su Salesforce'}
        </Alert>
    )
}

export default MissingAccountRecordTypeAlert;