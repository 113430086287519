import { useState, useEffect } from 'react';
import _ from 'lodash';
import { FcList, FcGrid } from "react-icons/fc";
import {
  Dropdown,
  Col,
  Button,
  Breadcrumb,
  Container,
  Form,
} from 'react-bootstrap';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { BiSolidFolderPlus, BiLogoYoutube } from "react-icons/bi";
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import toaster from '../../../utils/toaster';
import Restricted from '../../../components/Restricted/Restricted';
import DisplayData from '../../../components/Areas/DisplayData'
import OptionActionModal from '../../../components/modals/optionActionModal';
import { Roles } from '../../../types';
import { AreaObjectTypeEnum, AreaEditObjectDTO, AreaFolderDTO, AreaObjectCreateRequestDTO } from '../../../api';
import { useApiClient } from '../../../hooks/useApiClient';
import { GTMsendEvent } from '../../../utils/trackingGA4'
import '../areas.scss'
interface FolderEdit {
  is_visible: boolean,
  id: number
}

const initialBreadcrumbs = []

function AreaAcademy() {
  const [modalType, setModalType] = useState('');
  const [modalParams, setModalParams] = useState(null);
  const [isModalVisibile, setModalVisibility] = useState(false);
  const [showAllBreadcrumbs, setShowAllBreadcrumbs] = useState(false);
  const [viewFolderEdit, setViewFolderEdit] = useState<FolderEdit>({} as FolderEdit);
  const [loading, setLoading] = useState(false)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [isSearchActive, setIsSearchActive] = useState(false)
  const [search, setSearch] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const [error, setError] = useState(null);
  const [currentFolder, setCurrentFolder] = useState<AreaFolderDTO>({} as AreaFolderDTO)
  const [breadcrumbItems, setBreadcrumbsItems] = useState<{ id: string; title: string }[]>(initialBreadcrumbs)
  const viewGridString = localStorage.getItem('viewGrid');
  const [viewGrid, setViewGrid] = useState<Boolean>(viewGridString !== null ? JSON.parse(viewGridString) : true)
	const { t } = useTranslation()

  const { apiClient, handleApi } = useApiClient();
  const navigate = useNavigate()
  let { id } = useParams();

  const showModal = (type, params?: any) => {
    setModalType(type);
    setModalParams(params);
    setModalVisibility(true);
  };

  const closeModal = () => {
    setModalType('');
    setModalVisibility(false);
  };

  // API calls from onSubmitFn in optionActionsModal
  const createAreaAcademyFolder = (values) => {
    const body = {} as AreaObjectCreateRequestDTO;
    body.title = values.folderName;
    body.parent_folder_id = currentFolder.id
    body.type = AreaObjectTypeEnum.FOLDER

    return handleApi(
      apiClient.areaAcademyController.postFolder(body)
    );
  };

  const editAreaAcademyFolder = (values) => {
    const body: AreaEditObjectDTO = {} as AreaEditObjectDTO;
    body.title = values.folderName;

    return handleApi(
      apiClient.areaAcademyController.editFolderName(values.id, body)
    );
  }

  const editAreaAcademyVideo = (values) => {
    const body = values;
    body.title = values.videoTitle;
    body.link = values.videoLink;

    return handleApi(
      apiClient.areaAcademyController.editFolderName(values.id, body)
    );
  }

  const deleteAreaAcademyObject = (values) => {
    return handleApi(
      apiClient.areaAcademyController.deleteFolder(values.id)
    );
  }

  const addAreaAcademyVideo = (values) => {
    const body: AreaObjectCreateRequestDTO = values;
    body.title = values.videoTitle;
    body.link = values.videoLink;
    body.parent_folder_id = currentFolder.id
    body.type = AreaObjectTypeEnum.VIDEO_URL

    return handleApi(
      apiClient.areaAcademyController.postFolder(body)
    );
  };

  const uploadAreaAcademyFileModal = async (values) => {
    const body: AreaObjectCreateRequestDTO = {} as AreaObjectCreateRequestDTO;
    body.title = values.file.name;
    body.parent_folder_id = currentFolder.id;
    body.type = AreaObjectTypeEnum.FILE;
    body.can_download = values.can_download;

    await handleApi(
      apiClient.areaAcademyController.postFolder(body)
    );
  }

  // handle functions
  const handleOpenFolder = (folder) => {
    navigate(`/area-academy/${folder.id}`);
  };

  const handleMouseEnter = (folder) => {
    setViewFolderEdit({
      is_visible: true,
      id: folder.id
    })
  }

  const handleMouseLeave = (folder) => {
    setViewFolderEdit({
      is_visible: false,
      id: folder.id
    })
  }

  const handleDeleteObject = (object) => {
    showModal('deleteAreaAcademyObject', { object })
  }

  const handleEditObject = (object) => {
    if (object.type === 'folder') {
      showModal('editAreaAcademyFolder', { id: object.id, folderName: object.title, type: object.type })
    } else if (object.type === 'video_url') {
      showModal('editAreaAcademyVideo', { id: object.id, videoTitle: object.title, videoLink: object.link, type: object.type })
    }
  }

  const handleBreadcrumbClick = (breadcrumbItem) => {
    if (breadcrumbItem.title === 'root') {
      navigate(`/area-academy`);
    } else {
      navigate(`/area-academy/${breadcrumbItem.id}`);
    }
  }

  const debouncedHandleSearch = _.debounce((searchValue) => {
    setLoadingSearch(true)
    handleApi(apiClient.areaAcademyController.searchQuery({ search: searchValue }))
      .then((res) => {
        setIsSearchActive(true)
        if (searchValue) {
          setSearchResult(res)
        }
        setLoadingSearch(false);
      })
      .catch((err) => {
        setError(err);
        setLoadingSearch(false);
        toaster.error(
          err.message || i18next.t('error_fetching_data')
        );
      });
  }, 700, { trailing: true });

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue)
    if (searchValue) {
      debouncedHandleSearch(searchValue);
    } else {
      setIsSearchActive(false)
    }
  };

  const handleResetSearch = () => {
    setSearch("")
    setIsSearchActive(false)
  }

  const handleViewLayout = () => {
    setViewGrid(prev => !prev)
    localStorage.setItem('viewGrid', JSON.stringify(!viewGrid))
  }

  useEffect(() => {
    if (!id) {
      handleApi(apiClient.areaAcademyController.getRootAcademyFolder())
        .then((res) => {
          setLoading(false);
          setCurrentFolder(res)
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
          toaster.error(
            err.message || i18next.t('error_loading_academy_area_data')
          );
        });
    } else {
      handleApi(apiClient.areaAcademyController.getSingleFolder(id))
        .then((res) => {
          setLoading(false);
          setCurrentFolder(res)
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
          toaster.error(
            err.message || i18next.t('error_loading_academy_area_data')
          );
        });
    }
  }, [id])

  useEffect(() => {
    // check if there are previous folders and add them to the breadcrumbItems
    // these will be mapped and rendered divided by slash (/) on top of the page
    const newBreadcrumbs: { id: string; title: string }[] = (currentFolder.prev_folders ?? []).map(folder => {
      return { id: folder.id.toString(), title: folder.title }
    })
    if (newBreadcrumbs && newBreadcrumbs.length > 0) {
      setBreadcrumbsItems(newBreadcrumbs)
    } else (
      setBreadcrumbsItems(initialBreadcrumbs)
    )
  }, [currentFolder])



  return (
    <div className='area-container'>
      <Container fluid>

        {breadcrumbItems.length > 1 ? (
          <Breadcrumb>
            <Breadcrumb.Item style={{ position: 'relative', top: '-3px' }}>
              {showAllBreadcrumbs ? (
                <Button variant="link" className='p-0' onClick={() => setShowAllBreadcrumbs(false)}>...showLess</Button>
              ) : (
                <Button variant="link" className='p-0' onClick={() => setShowAllBreadcrumbs(true)}>...showAll</Button>
              )}
            </Breadcrumb.Item>

            {showAllBreadcrumbs ? (
              <>
                {breadcrumbItems.map((breadcrumbItem) => (
                  <Breadcrumb.Item
                    key={breadcrumbItem.id}
                    linkAs={Link}
                    linkProps={{ to: `/area-academy/${breadcrumbItem.id}` }}
                    onClick={() => handleBreadcrumbClick(breadcrumbItem)}

                  >
                    {breadcrumbItem.title === 'root' ? 'area-academy' : breadcrumbItem.title}
                  </Breadcrumb.Item>

                ))}
                <Breadcrumb.Item active>
                  {currentFolder.title}
                </Breadcrumb.Item>
              </>
            ) : (
              <>
                {/* Show only the last 2 folders */}
                {breadcrumbItems.slice(-1).map((breadcrumbItem) => (
                  <Breadcrumb.Item
                    key={breadcrumbItem.id}
                    linkAs={Link}
                    linkProps={{ to: `/area-academy/${breadcrumbItem.id}` }}

                    // linkProps={{ to: `/${pathnameArray.slice(0, -1).join('/')}` }}
                    onClick={() => handleBreadcrumbClick(breadcrumbItem)}

                  >
                    {breadcrumbItem.title === 'root' ? 'area-academy' : breadcrumbItem.title}

                  </Breadcrumb.Item>
                ))}
                <Breadcrumb.Item active>
                  {currentFolder.title}
                </Breadcrumb.Item>
              </>
            )}
          </Breadcrumb>
        ) : (
          // Only render the last two segments when there are 2 or fewer elements
          <Breadcrumb>
            {breadcrumbItems.map(breadcrumbItem => (
              <Breadcrumb.Item
                key={breadcrumbItem.id}
                linkAs={Link}
                linkProps={{ to: `/area-academy/${breadcrumbItem.id}` }}
                onClick={() => handleBreadcrumbClick(breadcrumbItem)}
              >
                {breadcrumbItem.title === 'root' ? 'area-academy' : breadcrumbItem.title}
              </Breadcrumb.Item>
            ))}
            <Breadcrumb.Item active>
              {/* {currentFolder.title === 'root' ? "" : currentFolder.title} */}
              {breadcrumbItems.length > 0 ? currentFolder.title : "area-academy"}
            </Breadcrumb.Item>
          </Breadcrumb>
        )}



        <div className="d-flex justify-content-between mb-4">
          <Col md={6} xl={5} className='d-flex'>
            <Form.Control
              type="text"
              id="search"
              size="lg"
              placeholder={`${i18next.t('search')}...`}
              value={search}
              onChange={handleSearch}
              aria-describedby="search AreaAcademy"
            />
            <div className="vr mx-2" />
            <Button variant="secondary" onClick={handleResetSearch}>{t('reset')}</Button>
          </Col>

          <div className='d-flex align-items-end'>
            <div className='text-end me-3 mb-0.5'>
              {viewGrid ? (
                <div onClick={handleViewLayout}>
                  <FcGrid className="fs-1 layout-switch-button" />
                </div>)
                :
                (<div onClick={handleViewLayout}>
                  <FcList className="fs-1 layout-switch-button" />
                </div>)}
            </div>

            <Restricted to={[Roles.Master]}>
              <Dropdown className="px-2 align-text-bottom action-dropdown">
                <Dropdown.Toggle className="btn">{t('actions')}</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    as="button"
                    onClick={() => {
                      showModal('createAreaAcademyFolder')
                      GTMsendEvent({
                        event: 'select_actions_academy',
                        evtCat: 'Academy',
                        actionType: 'aggiungi cartella'
                      })
                    }}                  >
                    <BiSolidFolderPlus className="fs-5" /> {t('add_folder')}
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    onClick={() => {
                      showModal('addAreaAcademyVideo')
                      GTMsendEvent({
                        event: 'select_actions_academy',
                        evtCat: 'Academy',
                        actionType: 'aggiungi video'
                      })
                    }}
                  >
                    <BiLogoYoutube className="fs-5" /> {t('add_video')}
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    onClick={() => {
                      showModal('uploadAreaAcademyFileModal')
                      GTMsendEvent({
                        event: 'select_actions_academy',
                        evtCat: 'Academy',
                        actionType: 'aggiungi file'
                      })
                    }}
                  >
                    <BiSolidFolderPlus className="fs-5" /> {t('add_file')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Restricted>
          </div>

        </div>

        <OptionActionModal
          show={isModalVisibile}
          onHide={closeModal}
          modalType={modalType}
          details={modalParams}
          data={[]}
          actionMethods={{
            createAreaAcademyFolder,
            editAreaAcademyFolder,
            deleteAreaAcademyObject,
            addAreaAcademyVideo,
            editAreaAcademyVideo,
            uploadAreaAcademyFileModal
          }}
        />

        { }
        {/* {(currentFolder.prev_folders?.map(el => el.title).join('/') + `/${currentFolder.title}`).toLowerCase()
            .startsWith('root/noleggiatori/documentazione') && (
            <Alert variant='secondary'>
              Questa cartella contiene i documenti dei noleggiatori selezionabili nelle Opportunity.
              <br />
              È possibile utilizzare le cartelle <b>PRIVATI</b>, <b>LIBERI PROFESSIONISTI/SOCIETÀ DI PERSONE</b>, <b>AZIENDE (SOCIETÀ DI CAPITALI)</b> per caricare documenti per specifici account.
            </Alert>
          )} */}

        <DisplayData
          loadingSearch={loadingSearch}
          loading={loading}
          viewGrid={viewGrid}
          data={isSearchActive ? searchResult : currentFolder.data}
          viewFolderEdit={viewFolderEdit}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          handleDeleteObject={handleDeleteObject}
          handleEditObject={handleEditObject}
          handleOpenFolder={handleOpenFolder}
          flagArea={'AreaAcademy'}

        />
      </Container>

    </div>
  )
}

export default AreaAcademy

