import React from 'react';
import i18next from 'i18next';  // Import the i18next instance
import './ErrorBoundaty.scss';

class ErrorBoundary extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		console.error('error: ', {error, errorInfo});
		// You can also log the error to an error reporting service
	}


	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<p className="error-boundary-message">{i18next.t('something_went_wrong')}</p>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;