import { useState, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Column } from 'react-table';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useApiClient } from '../../hooks/useApiClient';
import { useAuth } from '../../hooks/useAuth';
import VehicleTableContainer from '../../components/VehicleTable/VehicleTableContainer';
import { columnsData } from '../../components/MyStock/columnsData';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import { VehicleStateEnum } from '../../api';
import { Roles } from '../../types';

const MyStockPage = () => {
	const { apiClient, handleApi } = useApiClient();
	const { user, userRole } = useAuth();
	const [searchParams] = useSearchParams();

	const [response, setResponse]: [any, any] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const { t } = useTranslation()

	const presetFilter = searchParams.get('prefilter');

	const filterForBackoffice = (data) => {
		let currentData;

		const states = [
			VehicleStateEnum.OPZIONATO,
			VehicleStateEnum.OSCURATO,
			VehicleStateEnum.VENDUTO,
		];

		currentData = data.filter((d) => {
			return (
				states.includes(d.stato_corrente?.type) &&
				d.stato_corrente.utente === user.username
			);
		});

		return currentData;
	};

	const filterForSales = (data) => {
		let currentData;

		const states = [VehicleStateEnum.OPZIONATO, VehicleStateEnum.VENDUTO];

		currentData = data.filter((d) => {
			return (
				states.includes(d.stato_corrente?.type) &&
				d.stato_corrente.utente === user.username
			);
		});

		return currentData;
	};

	const filterForDealer = (data) => {
		let currentData;

		const states = [VehicleStateEnum.OPZIONATO, VehicleStateEnum.VENDUTO];
		const myDealer = user.attributes['custom:dealer'];

		currentData = data.filter((d) => {
			return (
				(states.includes(d.stato_corrente?.type) &&
					(d.stato_corrente.utente === user.username ||
						d.dealer === myDealer)) ||
				(d.stato_corrente?.type === VehicleStateEnum.DISPONIBILE &&
					d.dealer === myDealer)
			);
		});

		return currentData;
	};

	useEffect(() => {
		handleApi(apiClient.vehicleController.getVehiclesFilter())
			.then((res) => {
				let currentData;

				if (userRole === Roles.Backoffice || userRole === Roles.Master  || userRole === Roles.Admin) {
					currentData = filterForBackoffice(res);
				}
				if (userRole === Roles.Sales) {
					currentData = filterForSales(res);
				}
				if (userRole === Roles.Dealer) {
					currentData = filterForDealer(res);
				}
				const storico = res.storico_azioni;

				setResponse(currentData || []);
				setLoading(false);
			})
			.catch((err) => {
				setError(err);
				setLoading(false);
			});
	}, []);

	const columns = useMemo(() => columnsData as Column<Object>[], []);
	const data = useMemo(() => response ?? [], [response]);

	return (
		<Container fluid>
			<Row>
				<Col md={6} className="display-6">
					{t('my_stock')}
				</Col>
			</Row>

			<ErrorBoundary>
				<VehicleTableContainer
					columns={columns}
					data={data}
					loading={loading}
					error={error}
					presetFilter={presetFilter}
				></VehicleTableContainer>
			</ErrorBoundary>
		</Container>
	);
};

export default MyStockPage;
