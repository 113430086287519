/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { GetOpportunityQuery, OpportunityEntity } from '../models/AccountEntity';

export class OpportunityControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) { }

    public getOpportunities(query: GetOpportunityQuery): CancelablePromise<{ data: OpportunityEntity[] }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/register/opportunities',
            query,
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    public getOpportunity(id: string): CancelablePromise<{ data: OpportunityEntity[] }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/register/opportunities',
            query: { opportunityId: id, skip: 0, limit: 1 },
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    public getOpportunityFields(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/register/opportunities/fields',
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            }, 
        });
    }

    public getOpportunityAttachment(id: string): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/register/opportunities/attachment/' + id,
            headers: {
                'Accept': 'application/octet-stream'
            },
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    public editOpportunity(id: string, body: any): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/register/opportunities/' + id,
            body,
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    public addOpportunityAttachments(id: string, a: { key: string; filename: string }[]): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/register/opportunities/' + id + '/attachments',
            body: a,
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    public createOpportunity(body: any): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/register/opportunities',
            body,
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    public duplicateOpportunity(id: string, copies: number): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/register/opportunities/copy_opportunity',
            body: { id, copies },
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }
}