import jsPDF from "jspdf";
import { GTMsendEvent } from "./trackingGA4";
import { translateServices } from ".";

export const generatePdf = (onDone: (filename: string) => void, typePdfTemplate = "#pdfContent", filename = "preventivo.pdf") => {

    var doc = new jsPDF();
    var elementHTML: HTMLElement | null =
        document.querySelector(typePdfTemplate);

    doc.html(elementHTML!, {
        callback: function (doc) {
            doc.save(filename);
            onDone(filename);
        },
        margin: [10, 10, 10, 10],
        autoPaging: 'text',
        x: 0,
        y: 0,
        width: 190, //target width in the PDF document
        windowWidth: 675, //window width in CSS pixels
    });

    // send event to GA4
    GTMsendEvent({
        event: "CTA_download_file",
        evtCat: 'preventivo_veicolo',
        evtAct: "download",
        evtLab: `${filename}`
    })
}

export function mapServices(services: string, dettListaOfferteServ: any) {
    Object.entries(dettListaOfferteServ).forEach(([key, value], index) => {
        let valService = (value as string);
        if (valService) {
            if (!isNaN(parseInt(valService)) || (valService).includes('€')) {
                if ((valService).split(" ").length < 2) {
                    value = parseInt(valService).toLocaleString('it') + ' €';
                } else {
                    if ((valService).includes('€')) {
                        value = valService.replace('€', '') + ' €';
                    }
                }
            }
            services += (translateServices(key) + ': ' + value);
            if (index < Object.entries(dettListaOfferteServ).length - 1) {
                services += '\n';
            }
        }
    });

    return services;
}