import { KeyboardEventHandler, useState } from 'react';
import CreatableSelect from 'react-select/creatable';

interface Option {
	readonly label: string;
	readonly value: string;
}

const createOption = (label: string) => ({
	label,
	value: label,
});

const CreatableSelectExtended = ({
	setFieldValue,
	setFieldTouched,
	onBlur,
	className = '',
	name,
	disabled = false,
	value = [],
	...props
}) => {
	if (!Array.isArray(value)) {
		value = [value];
	}

	const [inputValue, setInputValue] = useState('');
	const [vals, setVals] = useState<readonly Option[]>(
		(value || []).map((v: string) => createOption(v))
	);

	const onCreatableBlur = (event: any) => {
		onBlur(event);
		setFieldTouched(name, true);
	};

	const handleKeyDown: KeyboardEventHandler = (event) => {
		if (!inputValue) {
			return;
		}

		if (['Enter', 'Space'].includes(event.code)) {
			if (vals.find((v: Option) => v.value === inputValue)) {
				return;
			}

			setVals((prev) => [...prev, createOption(inputValue)]);

			setInputValue('');
			setFieldValue(name, [...vals.map((v: Option) => v.value), inputValue]);

			event.preventDefault();
		}
	};

	return (
		<CreatableSelect
			className={className + ' multiselect'}
			isClearable={false}
			isMulti
			components={{
				DropdownIndicator: null,
			}}
			isDisabled={disabled}
			inputValue={inputValue}
			onChange={(newValue) => {
				setFieldValue(
					name,
					newValue.map((v: Option) => v.value)
				);
				setVals(newValue as Option[]);
			}}
			onInputChange={(newValue) => setInputValue(newValue)}
			onKeyDown={handleKeyDown}
			onBlur={onCreatableBlur}
			menuIsOpen={false}
			value={vals}
			name={name}
			{...props}
		/>
	);
};

export default CreatableSelectExtended;
