const ValidationError = ({ errors, touched, name }: { errors: any, touched: any, name: string }) => {
    return (
        <>
            {errors[name] && touched[name] && (
                <small className="text-danger">
                    {errors[name] as string}
                </small>
            )}
        </>
    )
}

export default ValidationError;