import { components } from 'react-select'

const SelectOptWithSubtitle = (props: any) => {
    return <div>
        <components.Option {...props}>
            <div>{props.data.label}</div>
            <div>
                <small style={{opacity: '0.8'}}>{props.data ? props.data?.subtitle : ''}</small>
            </div>
        </components.Option>
    </div>
}

export default SelectOptWithSubtitle;