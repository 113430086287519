import { useEffect, useMemo, useState } from 'react';
import { Column, useAsyncDebounce } from 'react-table';
import { Form, Row, Col, Card, Alert , Button} from 'react-bootstrap';
import { useFormik } from 'formik';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import toaster from '../../utils/toaster';
import columnsData from '../../components/FuzzyRadar/columnsData';
import TableContainer from '../../components/genericTable/TableContainer';
import { useApiClient } from '../../hooks/useApiClient';

interface SearchFormValues {
	radioSelected: string;
}

interface CarBrandOpt {
	label: string;
	value: string;
}

const FuzzyRadar = () => {
	const [carBrand, setCarBrand] = useState<CarBrandOpt>();
	const [carBrandOpts, setCarBrandOpts] = useState<any[]>([]);

	const [searchQuery, setSearchQuery] = useState('');
	const [response, setResponse] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const { apiClient, handleApi } = useApiClient();
	const initialState = { hiddenColumns: ['year'], pageSize: 20 };

  const { t } = useTranslation()
	const initialValues: SearchFormValues = {
		radioSelected: 'NSCI',
	};

	const formik = useFormik({
		initialValues,
		onSubmit: (values) => {
			fetchData(searchQuery, (carBrand ? carBrand.value : ''));
		},
	});

	const fetchData = useAsyncDebounce(async (value: string, brand = '') => {
		setLoading(true);

		handleApi(
			apiClient.vehicleController.fuzzySearch(
				formik.values.radioSelected,
				value,
				brand
			)
		)
			.then((data) => {
				// need to pas db to the data that will be used in the columns file to open the modal because it needs to fetch the optionals with the db
				data.forEach((object) => {
					const { item } = object;
					if (item.vehicle_id) {
						item.vehicle_id = {
							vehicle_id: item.vehicle_id,
							db: formik.values.radioSelected,
						};
					}
				});
				setResponse(data);
			})
			.catch((err) => {
				console.error('Error during fetch:', error);
				toaster.error(
					err.message ||
						i18next.t('error_updating_data')
				);
			})
			.finally(() => {
				setLoading(false);
			});
	}, 200);

	useEffect(() => {
		handleApi(apiClient.vehicleController.fuzzySearchGetBrandList())
			.then((data) => {
				data.sort();
				const opts = data.map((el) => {
					return { label: el, value: el };
				});

				setCarBrandOpts(opts);
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	const onChange = (value) => {
		setSearchQuery(value);
	};

	const onBrandFilterchange = (value: any) => {
		setCarBrand(value);

		if (searchQuery.length) {
			fetchData(searchQuery, (value ? value.value : ''));
		}
	};

	const columns = useMemo(() => columnsData as Column<Object>[], []);
	const data = useMemo(() => response ?? [], [response]);

	return (
		<div>
			<h1>Fuzzy Radar</h1>
			<Card>
				<Card.Body>
					<Form onSubmit={formik.handleSubmit}>
						<Row>
							<Col md={12} className="d-flex align-items-center">
								<Form.Group className="w-100">
									<Row className="d-flex mb-3">
										<Col md={2} className="d-flex">
											<Form.Check
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={'NSCI'}
												checked={formik.values.radioSelected === 'NSCI'}
												name="radioSelected"
												type="radio"
												id="commercialRadio"
												className="hor-check-box-big"
											/>
											<Form.Check.Label
												htmlFor="commercialRadio"
												className="ps-1"
											>
												{t('commercial')}
											</Form.Check.Label>
										</Col>
										<Col md={2} className="d-flex">
											<Form.Check
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={'SSCI'}
												checked={formik.values.radioSelected === 'SSCI'}
												name="radioSelected"
												type="radio"
												// label="Fleet"
												id="fleetRadio"
												className="hor-check-box-big"
											/>
											<Form.Check.Label htmlFor="fleetRadio" className="ps-1">
											{t('flet')}
											</Form.Check.Label>
										</Col>
									</Row>
								</Form.Group>
							</Col>
						</Row>

						<Row>
							<Col md={3} className="mb-3">
								<Select
									isClearable
									isDisabled={loading}
									placeholder={i18next.t('filter_brands')}
									className="multiselect"
									name="car_brand"
									value={carBrand}
									options={carBrandOpts}
									onChange={onBrandFilterchange}
								/>
							</Col>

							<Col md={3} className="mb-3">
								<Form.Control
									size="lg"
									placeholder={`${i18next.t('search')}...`}
									value={searchQuery}
									onChange={(e) => onChange(e.target.value)}
								/>
							</Col>

							<Col md={3} className="mb-3">
								<Button variant="primary" type="submit" disabled={loading}>
									{loading ? (
										<Spinner
											animation="border"
											role="status"
											size="sm"
											className="mx-3"
										>
											<span className="visually-hidden">{t('loading')}...</span>
										</Spinner>
									) : (
										'Cerca'
									)}
								</Button>
							</Col>
						</Row>
					</Form>
				</Card.Body>
			</Card>

			{(!loading && data.length === 0) ? (
				<div className="mt-4">
					<Alert>{t('no_results_to_display')}</Alert>
				</div>
			) : (
				<TableContainer
					columns={columns}
					data={data}
					loading={loading}
					error={error}
					initPageSize={20}
					hasFilter={false}
					initialState={initialState}
				></TableContainer>
			)}

			{/* {!loading && } */}
		</div>
	);
};

export default FuzzyRadar;
