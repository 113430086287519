import {
	Menu,
	MenuItem,
	SubMenu,
	Sidebar,
} from 'react-pro-sidebar';
import {
	FaSignOutAlt,
	FaFileImport,
	FaRegHeart,
	FaUniversity,
	FaCar, FaBuilding,
	FaUserTie,
	FaAddressBook, FaFilePdf,
	FaMagic
} from 'react-icons/fa';
import { BiSolidBusiness, BiSearchAlt } from 'react-icons/bi';
import { SiOnlyoffice } from 'react-icons/si';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../hooks/useAuth';
import { Roles } from '../../types';
import Icon from '../Icon/Icon';
import './SideMenu.scss';
import 'tippy.js/dist/tippy.css';

type MenuItemType = {
	to?: string;
	label: string;
	icon: JSX.Element;
	subMenu?: MenuItemType[];
	class?: string;
};

const SideMenu = ({ toggled, handleToggleSidebar }) => {
	const { t } = useTranslation()
	const { user, signOut, userRole } = useAuth();
	const menuItems: MenuItemType[] = [];
	const navigate = useNavigate();

	const pathIsVisible = ((path: string) => {
		const pathname = window.location.pathname;
		return path === pathname;
	})

	const availableItems = {
		// '/wizard': {
		// 	to: `/wizard`,
		// 	label: t('new_wizard'),
		// 	icon: <FaMagic />,
		// },
		'/available-vehicles': {
			to: '/available-vehicles',
			label: t('vehicles_list'),
			icon: <Icon name="list" />,
		},
		'/promo-vehicles': {
			to: '/promo-vehicles',
			label: t('promo_vehicles'),
			icon: <Icon name="list" />,
		},
		'/my-stock': {
			to: '/my-stock',
			label: t('my_stock'),
			icon: <Icon name="heart" />,
		},
		'/quicko-vehicles': {
			to: '/quicko-vehicles',
			label: "Quicko Beta",
			icon: <img src="/svg-icons/quicko-logo-q.png" alt="Quicko Beta" style={{ width: '24px', height: '20px' }} />,
			class: 'highlighted-item',
		},
		'/wishlist': {
			to: `/wishlist`,
			label: t('favourites'),
			icon: <FaRegHeart />,
		},
		'/quotation-request-list': {
			to: `/quotation-request-list`,
			label: t('estimates'),
			icon: <SiOnlyoffice />,
		},
		'/my-companies': {
			to: '/my-companies',
			label: t('my_companies'),
			icon: <FaBuilding />,
		},
		'/my-accounts': {
			to: '/my-accounts',
			label: t('my_accounts'),
			icon: <FaAddressBook />,
		},
		'/offers': {
			to: '/offers',
			label: t('offers_list'),
			icon: <Icon name="price" />,
		},
		'/vehicle-offer': {
			to: '/vehicle-offer',
			label: t('create_pdf'),
			icon: <FaFilePdf />,
		},
		'/manage-dealers': {
			to: '/manage-dealers',
			label: t('manage_dealers'),
			icon: <FaUserTie />,
		},
		'/manage-users': {
			to: '/manage-users',
			label: t('manage_users'),
			icon: <Icon name="user" />,
		},
		'/import': {
			to: '/import',
			label: t('import'),
			icon: <FaFileImport />,
		},
		'/autobiz': {
			to: '/autobiz',
			label: t('used_car_valuation'),
			icon: <Icon name="car-size" />,
		},
		'/area-academy': {
			to: `/area-academy`,
			label: t('area_academy'),
			class: 'highlighted',
			icon: <FaUniversity />,
		},
		'/area-marketing': {
			to: `/area-marketing`,
			label: t('area_marketing'),
			icon: <BiSolidBusiness />,
		},
		'/search': {
			to: '/search',
			label: t('fuzzy_radar'),
			icon: <BiSearchAlt />,
		},
	}

	if (userRole !== Roles.Azienda) {
		menuItems.push(
			{
				label: t('vehicles'),
				icon: <FaCar />,
				subMenu: [
					availableItems['/available-vehicles'],
					availableItems['/promo-vehicles'],
					availableItems['/my-stock'],
				],
			},
		);
	} else {
		menuItems.push(
			availableItems['/available-vehicles'],
			availableItems['/wishlist'],
			availableItems['/quotation-request-list'],
		);
	}

	if ([Roles.Master, Roles.Admin, Roles.Backoffice, Roles.Sales].includes(userRole)) {
		if (process.env.REACT_APP_QUICKO_ENABLED === '1') {
			menuItems.push(availableItems['/quicko-vehicles']);
		}
		menuItems.push(availableItems['/my-companies']);
	}

	if (
		userRole === Roles.Admin ||
		userRole === Roles.Backoffice ||
		userRole === Roles.Sales ||
		userRole === Roles.Master
	) {
		menuItems.push(availableItems['/my-accounts']);
	}

	if (
		userRole === Roles.Admin ||
		userRole === Roles.Backoffice ||
		userRole === Roles.Master
	) {
		menuItems.push(
			availableItems['/offers'],
			availableItems['/vehicle-offer'],
			availableItems['/manage-dealers'],
			availableItems['/manage-users'],
		);
	}

	if (userRole === Roles.Admin || userRole === Roles.Master) {
		menuItems.push(availableItems['/import']);
	}

	if (userRole === Roles.Sales) {
		// if (process.env.REACT_APP_QUICKO_ENABLED === '1') {
		// 	menuItems.push(availableItems['/quicko-vehicles']);
		// }
		menuItems.push(
			availableItems['/vehicle-offer'],
			availableItems['/manage-dealers']
		);
	}

	if (
		userRole === Roles.Admin ||
		userRole === Roles.Backoffice ||
		userRole === Roles.Sales ||
		userRole === Roles.Master
	) {
		if (process.env.REACT_APP_WIZARD_ENABLED === '1') {
			menuItems.push(
				availableItems['/wizard']
			)
		}

		menuItems.push(
			availableItems['/autobiz'],
			availableItems[`/area-academy`],
			availableItems[`/area-marketing`],

		);
	}

	if (userRole === Roles.Master) {
		menuItems.push(availableItems['/search']);
	}

	const ReactRouterMenuItem = (props: any) => {
		const { to, ...menuItemProps } = props;

		return (
			<MenuItem active={pathIsVisible(to)} {...menuItemProps} component={<Link to={props.to}></Link>}>
				{props.children}
			</MenuItem>
		);
	};

	const ReactRouterSubMenuItem = (props: MenuItemType) => {
		const { icon, label, subMenu } = props;

		const defaultOpen =
			subMenu!.filter((el) => {
				return pathIsVisible(el.to!);
			}).length > 0;

		return (
			//@ts-ignore
			<SubMenu icon={icon} label={label} defaultOpen={defaultOpen}>
				{subMenu!.map((item, index) => (
					<ReactRouterMenuItem
						className={item.class}
						key={index}
						icon={item.icon}
						to={item.to}
					>
						{item.label}
					</ReactRouterMenuItem>
				))}
			</SubMenu>
		);
	};

	const handleSignOut = async (e) => {
		e.preventDefault()

		sessionStorage.removeItem('vehicleAvailable-filters');
		sessionStorage.removeItem('vehiclePromo-filters');
		sessionStorage.removeItem('vehicleWishlist-filters');
		sessionStorage.removeItem('userAziende-filters');
		sessionStorage.removeItem('userActions-filters');
		localStorage.removeItem('userRole');
		localStorage.removeItem('business_name');

		try {
			await signOut();
		} catch (error) {
			console.error('Error during sign-out', error);
		}
		navigate('/');
	};

	return (
		<Sidebar
			onBackdropClick={() => handleToggleSidebar(true)}
			breakPoint='lg'
			collapsedWidth='0'
			collapsed={toggled}
			toggled={!toggled}
			className='pro-sidebar-v1'
			onBreakPoint={(isBroken) => handleToggleSidebar(isBroken)}
		>
			<Menu className='pro-sidebar-header'>
				<MenuItem component={<div></div>} className='logo-item'>
					<Link to="/">
						<Icon className="logo" name="horizon-light" />
					</Link>
				</MenuItem>
			</Menu>
			<hr />
			<Menu>
				{menuItems.map((item: MenuItemType, index: number) => {
					return (
						item.subMenu !== undefined ? (
							<ReactRouterSubMenuItem
								key={index}
								icon={item.icon}
								label={item.label}
								subMenu={item.subMenu}
							/>
						) : (
							<ReactRouterMenuItem
								className={item.class}
								key={index}
								icon={item.icon}
								to={item.to}
							>
								{item.label}
							</ReactRouterMenuItem>
						)
					)
				}
				)}
			</Menu>
			<Menu className='username-menu'>
				<div className="d-flex flex-column align-items-center py-2">
					<div className="text-center">
						{user.signInUserSession.accessToken.payload.username}
					</div>
					<div>
						(
						{userRole === Roles.Azienda
							? user.attributes['custom:business_name']
							: userRole}
						)
					</div>
				</div>
				<ReactRouterMenuItem
					to={`/personal-area/${user.signInUserSession.accessToken.payload.username}`}
					icon={<Icon name="user" />}
				>
					{t('personal_area')}
				</ReactRouterMenuItem>
				<MenuItem icon={<FaSignOutAlt />} component={<div></div>}>
					<Link to="/" onClick={handleSignOut}>
						{t('logout')}
					</Link>
				</MenuItem>
			</Menu>
			<hr />
			<Menu>
				<MenuItem component={<div></div>}>
					<div className="privacy-links" >
						<a href={`/privacy-dealer/Informativa Dealer.docx`} target="_blank">
							{t('privacy_dealer')}
						</a>
						{/* <Link
						to="/privacy-dealer"

						state={{ flagArea: "TrattamentoDatiDealer", item: { title: "11 INFO CLIENTI DEALER piattaforma PREVENTIVATORE.docx", can_download: true } }}
						style={{ textDecoration: "none", color: "#adb5bd" }}
					>
						Privacy Dealer
					</Link> */}
						<a
							href={`/privacy-dipendenti/Informativa dipendenti Horizon.docx`}
							target="_blank"
						>
							{t('privacy_employees')}
						</a>
					</div>

					{/* <Link to="/privacy-dipendenti"

						state={{ flagArea: "TrattamentoDatiDipendenti", item: { title: "10 INFO DIPENDENTI  HORIZON Premio utilizzo piattaforma.docx", can_download: true } }}
						style={{ textDecoration: "none", color: "#adb5bd" }}
					>
						Privacy Dipendenti
					</Link> */}
				</MenuItem>
			</Menu>
		</Sidebar>
	);
};

export default SideMenu;
