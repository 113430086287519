import { createContext } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { Authenticator, View, Text, useAuthenticator } from '@aws-amplify/ui-react';
import { awsConfig } from '../config/aws-exports';
import { I18n } from 'aws-amplify';
import Icon from '../components/Icon/Icon';
import { useCookies } from 'react-cookie';
import { sendLoginToGTM, updateConsent } from '../utils/trackingGA4';
import './AuthContext.scss';

type ROLE = 'ADMIN' | 'BACKOFFICE' | 'SALES' | 'DEALER' | 'MASTER' | 'AZIENDA';

Amplify.configure(awsConfig);
const AuthContext = createContext(null);

const authScreenLabels = {
	it: {
		'Attempt limit exceeded, please try after some time.':
			"Limite di tentativi superato, riprova dopo un po' di tempo.",
		Sending: 'Caricamento',
		Code: 'Codice',
		'Code *': 'Codice *',
		'New Password': 'Nuova password',
		'Username cannot be empty': 'Il nome utente non può essere vuoto',
		'Confirm Password': 'Conferma password',
		'Reset Password': 'Ripristina la password',
		'Enter your username': "Inserisci l'email",
		'Enter your Username': 'Email',
		'Enter your Password': 'Password',
		'Resend Code': 'Rimanda il codice',
		Submit: 'Invia',
		'Send code': 'Invia',
		'Back to Sign In': 'Torna',
		Username: "Inserisci l'email",
		Password: 'Inserisci la password',
		'Forgot your password?': 'Hai dimenticato la password?',
		'Sign in': 'Accedi',
		'Account recovery requires verified contact information':
			"Il recupero dell'account richiede informazioni di contatto verificate",
		Verify: 'Verificare',
		Skip: 'Salta',
		'Signing in': 'Accedi',
		'Incorrect username or password.': 'Nome utente o password errati.',
	},
};

I18n.setLanguage('it');
I18n.putVocabularies(authScreenLabels);

const formFields = {
	signIn: {
		username: {
			label: '',
		},
		password: { label: '' },
	},
	resetPassword: {
		username: {
			label: '',
		},
	},
	confirmResetPassword: {
		confirmation_code: {
			label: '',
		},
		password: {
			label: '',
		},
		confirm_password: {
			label: '',
		},
	},
};

const components: any = {
	Header() {
		return (
			<View className="icon-view" textAlign="center">
				<Icon name="horizon-light" />
			</View>
		);
	},
	Footer() {
		return (
			<View className="footer small text-white" textAlign="center">
				<div>
					<strong>Horizon Automotive SpA</strong>
				</div>
				<div >PARTITA IVA: IT11133990967</div>
				<div className='mt-3'>
					<strong>Sede Centrale Milano:</strong> Via Copernico 4, 20090,
					Trezzano Sul Naviglio
				</div>
				<div>
					<strong>Sede Bergamo:</strong> Via per Grumello 43/A, 24127, Bergamo
				</div>
				<div>
					<strong>Sede Padova:</strong> Viale della Navigazione Interna 71,
					35129 Padova
				</div>
				<div>
					<strong>Sede Torino:</strong> Corso Susa 242, 10098, Rivoli
				</div>
				<div>
					<strong>Sede San Benedetto del Tronto:</strong> Via Pomezia 3, 63074,
					San Benedetto del Tronto
				</div>
			</View>
		);
	},
};

const AuthProvider = ({ children }) => {
	const [cookies, setCookie] = useCookies();

	const rowConsent = localStorage.getItem('tracking_consent')
	const consent = rowConsent && JSON.parse(rowConsent)

	const services = {
		async handleSignIn(formData) {
			let { username, password } = formData;

			const signIn = await Auth.signIn({
				username,
				password,
			});

			const expirationDate = new Date();
			expirationDate.setMonth(expirationDate.getMonth() + 26);
			setCookie('userID', signIn?.attributes?.sub, { expires: expirationDate })
			localStorage.setItem('userID', signIn?.attributes?.sub)

			const rawIsFirstLogin = localStorage.getItem('isFirstLogin')
			let isFirstLogin;
			if (rawIsFirstLogin !== null) {
				isFirstLogin = JSON.parse(rawIsFirstLogin)
			} else {
				// first login
				localStorage.setItem('isFirstLogin', JSON.stringify(true))
			}

			!isFirstLogin && setTimeout(() => {
				sendLoginToGTM(consent, signIn?.attributes?.['custom:role']);
			}, 300);

			// challangeName can be NEW_PASSWORD_REQUIRED upon first login after account creation
			if (!signIn.challengeName) {
				window.location.reload();
			}

			return signIn
		},
	};

	return (
		<Authenticator
			hideSignUp={true}
			formFields={formFields}
			components={components}
			services={services}
		>
			{({ user, signOut }) => {
				const value: any = {
					user,
					signOut,
					userRole: user?.attributes?.['custom:role'] as ROLE,
				};
				// set consent from previous user choice
				localStorage.setItem('userRole', JSON.stringify(value.userRole))
				consent && updateConsent()
				return (
					<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
				);
			}}
		</Authenticator>
	);
};

export { AuthContext, AuthProvider };
