import { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaMinusCircle } from 'react-icons/fa';
import { Column } from 'react-table';
import { GTMsendEvent } from '../../utils/trackingGA4';
import { DealerDTO, UserDTO } from '../../api';
import { useAuth } from '../../hooks/useAuth';
import { ReactSelectOption, Roles, SelectOptions, } from '../../types';
import { arrayToOptions, stringToOption } from '../../utils';
import toaster from '../../utils/toaster';
import { useApiClient } from '../../hooks/useApiClient';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { userActionsColumnsData } from '../../components/UserActions/userActionsColumnsData';
import UserArea from '../../components/User/UserArea';
import UserActions from '../../components/UserActions/UserActions';
import { OpportunityField } from '../../api/models/AccountEntity';
import i18next from 'i18next';

import './UserFormPage.scss';

const UserFormPage = () => {
	// the username  must be an email address
	const { username } = useParams<{ username: string }>();
	const navigate = useNavigate();
	const { apiClient, handleApi } = useApiClient();
	const [dealerOptions, setDealerOptions] = useState([] as any[]);
	const [response, setResponse]: [any, any] = useState(null);
	const [responseStoricoAzioni, setResponseStoricoAzioni]: [any, any] = useState([])
	const [loading, setLoading] = useState(true);
	const [loadingLogs, setLoadingLogs] = useState(true);
	const [referralPartners, setReferralPartners] = useState<ReactSelectOption[]>([]);
	const [backofficeUsers, setBackofficeUsers] = useState<(ReactSelectOption & { subtitle: string })[]>([])
	const [userSales, setUserSales] = useState([] as any[]);
	const [errorLogs, setErrorLogs] = useState(null);
	const [errorUser, setErrorUser] = useState(null);
	const { userRole, user } = useAuth();
	const [initialValues, setInitialValues]: [any, Function] = useState(null);
	const [roles, setRole] = useState<SelectOptions[]>();
	const [companyDetail, setCompanyDetail] = useState<any[]>();
	const [itsMe, setItsMe] = useState(false)
	const newUser = username !== 'new' ? false : true
	const { t } = useTranslation()

	useEffect(() => {
		if (userRole === Roles.Admin) {
			setRole(arrayToOptions(['ADMIN', 'MASTER', 'BACKOFFICE', 'DEALER', 'SALES', 'AZIENDA']));
		}
		if (userRole === Roles.Master) {
			setRole(arrayToOptions(['MASTER', 'BACKOFFICE', 'DEALER', 'SALES', 'AZIENDA']));
		}
		if (userRole === Roles.Backoffice) {
			setRole(arrayToOptions(['BACKOFFICE', 'DEALER', 'SALES', 'AZIENDA']));
		}
		// for now Sales cannot manage users
		if (userRole === Roles.Sales) {
			setRole(arrayToOptions(['']));
		}
		// for now Dealer cannot manage users
		if (userRole === Roles.Dealer) {
			setRole(arrayToOptions(['']));
		}
		// for now Azienda cannot manage users
		if (userRole === Roles.Azienda) {
			setRole(arrayToOptions([]));
		}

		setCompanyDetail(arrayToOptions(['AZIENDA', 'AGENTE']));

		if ([Roles.Admin, Roles.Master, Roles.Backoffice, Roles.Sales].includes(userRole)) {

			// GET DEALERS
			handleApi(apiClient.dealerController.getRegisterDealers())
				.then((res: DealerDTO[]) => {
					setDealerOptions(res.map((r) => stringToOption(r.ragione_sociale)))
				})
				.catch((err) => { });

			// GET REFERENCE PARTNERS
			handleApi(apiClient.accountController.getAccountFields())
				.then((res: OpportunityField) => {
					// setDealerOptions(res.map((r) => stringToOption(r.ragione_sociale)))

					// // @ts-ignore
					const options: ReactSelectOption[] = res[0]?.picklistValues?.map(el => {
						return { label: el.label, value: el.value }
					})

					setReferralPartners(options)
				})
				.catch((err) => {
					console.error(err)
					toaster.error(
						err.message || i18next.t('error_loading_account_data')
					);
				});

			// GET SALES
			handleApi(apiClient.userController.getRegisterSales())
				.then((res) => {
					const userSales = res.map((user) => stringToOption(user.given_name + ' ' + user.family_name, user.username))
					setUserSales(userSales)
				})
				.catch((err) => {
					console.error(err)
					toaster.error(
						err.message || i18next.t('error_loading_sales_data')
					);
				});
		}

		// GET SINGLE USER
		// edit mode
		if (!newUser) {
			handleApi(apiClient.userController.getRegisterUsers1(username!))
				.then((res) => {
					user.username === res.username && setItsMe(true)
					if (!res.sales || !Array.isArray(res.sales)) {
						res.sales = [];
					}
					setResponse(res);
					setLoading(false);
				})
				.catch((err) => {
					setErrorUser(err);
					setLoading(false);
					toaster.error(
						err.message || i18next.t('error_loading_user_data')
					);
				});
		}
		// if new
		else {
			setInitialValues({
				username: '',
				role: '',
				business_name: '',
				given_name: '',
				family_name: '',
				phone: '',
				is_active: '',
				is_active_email: true,
				company_detail: '',
				group: '',
				dealer: '',
				sales: [],
				phone_backup: '',
				email_backup: '',
				role_ext: '',
				reference_partner: [],
				reference_backoffice: null,
				is_salesforce_sales: false
			});

			setLoading(false);
		}

		// GET STORICO AZIONI
		handleApi(apiClient.vehicleController.getVehiclesFilter())
			.then((res) => {
				const storico_azioni = res.flatMap(vehicle =>
					vehicle.storico_azioni.filter(azione => (itsMe ? user.username : username) === azione.utente)
						.map(azione => {
							return {
								...azione,
								type: azione.type.replace("_", " "),
								id: vehicle.id,
								marca: vehicle.marca,
								modello: vehicle.modello,
								nr_ordine: vehicle.nr_ordine,
								allestimento: vehicle.allestimento,
								codice_costruttore: vehicle.codice_costruttore,
								telaio: vehicle.telaio,
								targa: vehicle.targa,
								dealer: vehicle.dealer
							}
						})
				);

				setLoadingLogs(false)
				setResponseStoricoAzioni(storico_azioni)

			})
			.catch((err) => {
				setErrorLogs(err);
				setLoading(false);
				toaster.error(
					err.message || i18next.t('error_loading_data')
				);
			});

		if (userRole === Roles.Master || userRole === Roles.Admin || userRole === Roles.Backoffice) {
			if (userRole === Roles.Backoffice) {
				const userAttrs = user.attributes
				setBackofficeUsers([
					{ label: `${userAttrs.given_name} ${userAttrs.family_name}`, value: userAttrs.email, subtitle: userAttrs.email }
				])
			} else {
				handleApi(apiClient.userController.getRegisterUsers())
					.then((res: UserDTO[]) => {
						res = res.filter(el => el.role === 'BACKOFFICE')

						const options: any = res.map(el => {
							return { label: `${el.given_name} ${el.family_name}`, value: el.username, subtitle: el.username }
						})

						setBackofficeUsers(options)
					})
					.catch((err) => {
						toaster.error(err.message || i18next.t('cannot_load_partners'))
					});
			}

		}
	}, []);

	useEffect(() => {
		if (response && userSales.length > 0 && referralPartners.length > 0) {
			const sales = response.sales?.map(user => {
				return userSales.find((sale) => {
					return user === sale.originalValue
				})
			})

			const referencePartnerIds = response.reference_partner || [];
			const partnerValues: ReactSelectOption[] = referencePartnerIds.map((el) => {
				const foundPartner = referralPartners.find(r => r.value === el);
				return {
					label: foundPartner ? foundPartner.label : '',
					value: el
				};
			});


			let referenceBackoffice: ReactSelectOption | null = null;
			if (response.reference_backoffice) {
				if (backofficeUsers.length) {
					referenceBackoffice = backofficeUsers.find(el => el.value === response.reference_backoffice) || null;
				} else {
					referenceBackoffice = { value: response.reference_backoffice, label: response.reference_backoffice }
				}
			}

			setInitialValues({
				username: response.username,
				role: response.role,
				business_name: response.business_name,
				given_name: response.given_name,
				family_name: response.family_name,
				phone: response.phone,
				is_active: response.is_active,
				is_active_email: (response.is_active_email === "false" ? false : true),
				company_detail: response.company_detail,
				group: response.group,
				dealer: response.dealer,
				sales: sales,
				phone_backup: response.phone_backup,
				email_backup: response.email_backup,
				role_ext: response.role_ext,
				reference_partner: partnerValues,
				reference_backoffice: referenceBackoffice,
				is_salesforce_sales: response.is_salesforce_sales
			});
		}

	}, [response, userSales, referralPartners, backofficeUsers])

	const validateFn = (values) => {

		const errors: any = {};
		const REQUIRED_MESSAGE = i18next.t('mandatory');
		const UNSUPPORTED = i18next.t('not_supported');

		if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)) {
			errors.username = i18next.t('username_must_be_a_valid_email_address');
		}
		if (!values.username) {
			errors.username = REQUIRED_MESSAGE;
		}

		if (!values.role) {
			errors.role = REQUIRED_MESSAGE;
		}

		if (!values.given_name) {
			errors.given_name = REQUIRED_MESSAGE;
		}

		if (!values.family_name) {
			errors.family_name = REQUIRED_MESSAGE;
		}

		if (values.role === 'AZIENDA' && !values.business_name) {
			errors.business_name = REQUIRED_MESSAGE;
		}

		if (!values.reference_partner.length && ['MASTER', 'BACKOFFICE', 'SALES'].includes(values.role)) {
			errors.reference_partner = REQUIRED_MESSAGE;
		}

		if (!values.reference_partner.length && ['SALES'].includes(values.role)) {
			errors.reference_partner = REQUIRED_MESSAGE;
		}

		if (values.reference_partner.length && !['MASTER', 'BACKOFFICE', 'SALES'].includes(values.role)) {
			errors.reference_partner = UNSUPPORTED;
		}

		return errors;
	};

	const submitFnFactory = (username) => {
		// if edit
		return (values, { setSubmitting, isSubmitting }) => {
			let body: UserDTO;
			body = { ...values }

			if (['MASTER', 'BACKOFFICE', 'SALES'].includes(values.role)) {
				body.reference_partner = values.reference_partner.map(el => el.value);
			} else {
				delete body.reference_partner;
			}

			if (values.role === 'SALES') {
				if (values.reference_backoffice) {
					body.reference_backoffice = values.reference_backoffice.value;
				}
			} else {
				delete body.reference_backoffice;
			}

			if (!newUser) {
				if (values.role === 'AZIENDA' && values.sales) {
					const sales = values.sales.map(sale => sale.originalValue)
					body = { ...values, sales };
				}

				handleApi(apiClient.userController.putRegisterUsers(username, body))
					.then((res) => {
						setSubmitting(false);
						GTMsendEvent({
							event: 'update_user',
							category: 'user',
							action: 'update',
							label: 'update_user',
						});

						toaster.success(i18next.t('user_updated_success', { username }));
					})
					.catch((err) => {
						console.error('err', err);
						setSubmitting(false);
						toaster.error(
							err.message || i18next.t('error_updating_user')
						);
					});
			}

			// if new user
			else {
				if (values.role === 'AZIENDA' && values.sales.length > 0) {
					// the SALES select field must show given_name + family_name but it sends the username
					// if sales select not multi then this
					// const salesUsername = userSales.find(userSale => userSale.value === values.sales)
					const sales = values.sales.map(sale => sale.originalValue)
					body = { ...values, sales };
				}


				handleApi(apiClient.userController.postRegisterUsers(body))
					.then((res) => {
						setSubmitting(false);
						GTMsendEvent({
							event: 'complete_new_user',
							category: 'user',
							action: 'complete',
							label: 'new_user'
						})
						toaster.success(i18next.t('user_created_success', { username: res.username }));
						navigate(`/manage-users`);
					})
					.catch((err) => {
						console.error('err', err);
						setSubmitting(false);
						toaster.error(
							err.message || i18next.t('error_creating_user')
						);
					});
			}
		}
	};

	const deleteUser = () => {
		handleApi(apiClient.userController.deleteRegisterUsers(username!))
			.then((res) => {
				GTMsendEvent({
					event: 'delete_user',
					category: 'user',
					action: 'delete',
					label: `delete_user`
				})
				toaster.success(i18next.t('user_deleted_success', { username }));
				navigate(`/manage-users`);
			})
			.catch((err) => {
				console.error('err', err);
				toaster.error(
					err.message || i18next.t('error_deleting_user')
				);
			});
	};

	const actionsColumns = useMemo(() => userActionsColumnsData as Column<Object>[], []);
	const actionsData = useMemo(() => responseStoricoAzioni ?? [], [responseStoricoAzioni]);

	return (
		<Container className="user-form-page-container" fluid>
			{['SALES', 'DEALER', 'AZIENDA'].includes(userRole) ?
				<Breadcrumb
					item1={{ name: i18next.t('back'), link: '/' }}
					activeItem={{ name: '' }}
				></Breadcrumb>
				:
				<Breadcrumb
					item1={{ name: i18next.t('manage_users'), link: '/manage-users' }}
					activeItem={{ name: i18next.t('user') }}
				></Breadcrumb>
			}

			{errorUser && <Navigate to="/not-authorized" replace />}

			{!loading && initialValues && (
				<div className="mb-3">
					<span className="display-6">
						{response ? (itsMe ? i18next.t("my_area") : response?.username) : i18next.t('create_new_user')}
					</span>

					{(username !== 'new' && !itsMe) && (
						<span className="float-end mt-2">
							<Button onClick={deleteUser}>
								<FaMinusCircle style={{ marginRight: '8px' }} />
								{t('delete_user')}
							</Button>
						</span>
					)}
				</div>
			)}

			{/* MY AREA SECTION */}
			<UserArea
				initialValues={initialValues}
				validateFn={validateFn}
				username={username}
				submitFnFactory={submitFnFactory}
				loading={loading}
				stringToOption={stringToOption}
				dealerOptions={dealerOptions}
				itsMe={itsMe}
				roles={roles}
				backofficeUsers={backofficeUsers}
				userSales={userSales}
				companyDetail={companyDetail}
				referralPartners={referralPartners}

			/>

			{/* USER LOGS SECTION */}
			{!newUser && (
				<UserActions
					// responseStoricoAzioni={responseStoricoAzioni}
					itsMe={itsMe}
					columns={actionsColumns}
					data={actionsData}
					loading={loadingLogs}
					error={errorLogs}
				/>
			)}
		</Container>
	);
};

export default UserFormPage;