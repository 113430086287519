/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfirmSellVehicleRequestDTO } from '../models/ConfirmSellVehicleRequestDTO';
import type { HideVehicleRequestDTO } from '../models/HideVehicleRequestDTO';
import type { OptionDTO } from '../models/OptionDTO';
import type { ReleaseVehicleOptionsRequestDTO } from '../models/ReleaseVehicleOptionsRequestDTO';
import type { RenewVehicleOptionsRequestDTO } from '../models/RenewVehicleOptionsRequestDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { MakeAvailableVehicleRequestDTO } from '../models/MakeAvailableVehicleRequestDTO';
import { VehicleInEvidenzaToggleDTO } from '../models/VehicleInEvidenzaToggleDTO';

export class VehicleActionsControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) { }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public postVehiclesActions(
        requestBody?: OptionDTO,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/vehicles/actions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public postVehiclesActionsConfirmSell(
        requestBody?: ConfirmSellVehicleRequestDTO,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/vehicles/actions/confirm-sell',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public postVehiclesActionsHide(
        requestBody?: HideVehicleRequestDTO,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/vehicles/actions/hide',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public postVehiclesActionsHideRenew(
        requestBody?: HideVehicleRequestDTO,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/vehicles/actions/hide-renew',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public postVehiclesActionsRelease(
        requestBody?: ReleaseVehicleOptionsRequestDTO,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/vehicles/actions/release',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public postVehiclesActionsRenewal(
        requestBody?: RenewVehicleOptionsRequestDTO,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/vehicles/actions/renewal',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param vehicleId
     * @returns any OK
     * @throws ApiError
     */
    public deleteVehiclesActions(
        vehicleId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/vehicles/actions/{vehicleId}',
            path: {
                'vehicleId': vehicleId,
            },
        });
    }

        /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
        public postVehiclesActionsMakeAvailable(
            requestBody?: MakeAvailableVehicleRequestDTO,
            ): CancelablePromise<any> {
                    return this.httpRequest.request({
                        method: 'POST',
                        url: '/vehicles/actions/make-available',
                        body: requestBody,
                        mediaType: 'application/json',
                        errors: {
                            401: `Not Authorized`,
                            403: `Not Allowed`,
                        },
                    });
                }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public toggleInEvidenza(
        requestBody?: VehicleInEvidenzaToggleDTO
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/vehicles/actions/toggle-in-evidenza',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
