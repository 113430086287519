import { useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash'

const ColumnFilterSelect = ({
	props,
	column: { filterValue, setFilter, preFilteredRows, id },
}) => {
	const { filterState, setFilterState } = props;

	const options = useMemo(() => {
		let options: any[] = [];

		preFilteredRows.forEach((row) => {
			if (row.values[id] && !options.filter((opt) => opt.value === row.values[id]).length) {
				options.push({
					id: id,
					value: row.values[id], // if string toLowerCase?
					label: row.values[id],
				});
			}
		});

		// list here all the options which should have sorted items in the select
		if (['city'].indexOf(id) > -1) {
			options = _.orderBy(options, ['label'], ['asc'])
		}

		options.unshift({ id, value: '', label: 'All' });

		return [...options];
	}, [id, preFilteredRows]);

	const selectedOption = {
		id,
		value: filterState[id],
		label: filterState[id] || 'All',
	};

	const handleChange = (opt) => {

		setFilterState((prevState) => {
			return {
				...prevState,
				[id]: opt.value,
			};
		});
	};

	return (
		<Select
			className="multiselect" // needs its own css class. Ora ce l'abbiamo: hor-singleselect. CHECK
			name={id}
			// defaultValue={options[0]}
			value={selectedOption}
			options={options}
			onChange={handleChange}
		/>
	);
};

export default ColumnFilterSelect;
