import i18next from 'i18next';
import { VehicleStateEnum, HistoryActionTypeEnum, RoleEnum, VehcileAvailabilityEnum } from '../api/models/Enums';

const translateAndUppercase = (key: string): string => {
    return i18next.t(key).toUpperCase();
};

export const translateVehicleStates = (state: VehicleStateEnum): string => {
    switch (state) {
        case VehicleStateEnum.DISPONIBILE:
            return translateAndUppercase("available");
        case VehicleStateEnum.OPZIONATO:
            return translateAndUppercase("optioned"); 
        case VehicleStateEnum.OSCURATO:
            return translateAndUppercase("obscured");
        case VehicleStateEnum.VENDUTO:
            return translateAndUppercase("sold");
        default:
            return "";
    }
}

export const translateVehicleAvailabilityState = (state: VehcileAvailabilityEnum): string => {
    switch (state) {
        case VehcileAvailabilityEnum.TARGATO:
            return translateAndUppercase("registered");
        case VehcileAvailabilityEnum.TELAIATO:
            return translateAndUppercase("framed");
        case VehcileAvailabilityEnum.IN_ARRIVO:
            return translateAndUppercase("incoming");
        case VehcileAvailabilityEnum.ORDINATO:
            return translateAndUppercase("ordered");
        default:
            return "";
    }
}

export const translateRoles = (state: RoleEnum): string => {
    switch (state) {
        case RoleEnum.AZIENDA:
            return translateAndUppercase("company");
        default:
            return state;
    }
}

export const translateStoricoAzioneStates = (state: HistoryActionTypeEnum): string => {
    switch (state) {
        case HistoryActionTypeEnum.DISPONIBILE:
            return translateAndUppercase("available");
        case HistoryActionTypeEnum.OPZIONAMENTO:
            return translateAndUppercase("optioning");
        case HistoryActionTypeEnum.OPZIONAMENTO_CANCELLATO:
            return translateAndUppercase("optioningCancelled");
        case HistoryActionTypeEnum.OPZIONAMENTO_RINNOVATO:
            return translateAndUppercase("optioningRenewed");
        case HistoryActionTypeEnum.OPZIONAMENTO_SCADUTO:
            return translateAndUppercase("optioningExpired");
        case HistoryActionTypeEnum.OSCURAMENTO:
            return translateAndUppercase("obscuring");
        case HistoryActionTypeEnum.OSCURAMENTO_RINNOVATO:
            return translateAndUppercase("obscuringRenewed");
        case HistoryActionTypeEnum.OSCURAMENTO_CANCELLATO:
            return translateAndUppercase("obscuringCancelled");
        case HistoryActionTypeEnum.VENDITA:
            return translateAndUppercase("sale");
        default:
            return "";
    }
}
