import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import { Storage } from 'aws-amplify';
import { FileUploader } from 'react-drag-drop-files';
import pusherJs from 'pusher-js';
import Channel from 'pusher-js/types/src/core/channels/channel';
import { Alert } from 'react-bootstrap';
import LoadingBar from 'react-top-loading-bar';
import { useState, useRef } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import toaster from '../../utils/toaster';
import { useAuth } from '../../hooks/useAuth';
import { GTMsendEvent } from '../../utils/trackingGA4';
import './ImportPage.scss';

const fileTypes = ['xlsx'];

const labelMap: any = {
	codice_colore_interno: i18next.t('internal_color_code'),
	codice_colore_esterno: i18next.t('external_color_code'),
	codice_costruttore: i18next.t('builder_code'),
	dup_key: i18next.t('duplications'),
	optionals: i18next.t('optionals'),
	image_list: i18next.t('images'),
};

const alertTypeMap: any = {
	success: i18next.t('success'),
	warning: i18next.t('warnings'),
	danger: i18next.t('errors'),
};

const ImportPage = () => {
	const [file, setFile] = useState<File | null>(null);
	const [errors, setErrors] = useState<any>(null);
	const [success, setSuccess] = useState<string[] | null>(null);
	const [warnings, setWarnings] = useState<any>(null);
	const { user } = useAuth();
	const loadingBarRef: any = useRef(null);
	const { t } = useTranslation()

	function getAlert(data: any, type: 'success' | 'warning' | 'danger') {
		if (!data) {
			return;
		}

		return (
			<Alert variant={type}>
				<Alert.Heading>{alertTypeMap[type]}</Alert.Heading>

				{Array.isArray(data) ? (
					<ul>
						{data.map((item, index) => (
							<li key={index}>
								<strong>{item}</strong>
							</li>
						))}
					</ul>
				) : (
					Object.keys(data).map((key: string) => (
						<div key={key}>
							<div>
								<strong>{key}</strong>
							</div>

							<ul>
								{Object.keys(data[key])
									.filter((k: string) => !!data[key][k])
									.sort((a: string, b: string) => {
										const searchKey: string = 'dup_key';

										if (a !== searchKey && b === searchKey) {
											return 1;
										}
										if (a === searchKey && b !== searchKey) {
											return -1;
										}
										return 0;
									})
									.map((k: string) => (
										<li key={k}>
											{labelMap[k]}: {data[key][k]}
										</li>
									))}
							</ul>
						</div>
					))
				)}
			</Alert>
		);
	}

	const handleChange = async (file: File) => {
		try {
			loadingBarRef?.current?.continuousStart();
			setSuccess(null);
			setErrors(null);
			setWarnings(null);
			setFile(file);

			const filePath: string = `${user.attributes.email}/${Date.now()}_${file.name
				}`;

			const pusher: pusherJs = new pusherJs(
				process.env.REACT_APP_PUSHER as string,
				{
					cluster: 'eu',
				}
			);

			const channelName: string = 'import';
			const channel: Channel = pusher.subscribe(channelName);

			channel.bind(`public/${filePath}`, (data: any) => {

				const resultsPath = data.message?.path;

				fetch(resultsPath)
					.then((response) => response.json())
					.then((result) => {

						if (result?.status === 'success') {
							result.success_vehicles?.length &&
								setSuccess(result.success_vehicles);
							result.errors && setErrors(result.errors);
							result.warnings && setWarnings(result.warnings);
							GTMsendEvent({
								event: 'complete_upload',
								category: 'offerta',
								action: 'upload',
								label: file.name
							});
						} else {
							onUploadError(result?.status_msg);
						}

						channel.unbind(`public/${filePath}`);
						pusher.unsubscribe(channelName);
						loadingBarRef?.current?.complete();
						setFile(null);
					});
			});

			await Storage.put(filePath, file, {
				contentType: file.type,
			});
		} catch (error) {
			onUploadError();
		}
	};

	const onUploadError = (error?: string) => {
		setFile(null);
		toaster.error(error || i18next.t('error_loading_file'));
	};

	const onTypeError = () => {
		setFile(null);
		toaster.error(i18next.t('unsupported_file'));
	};

	return (
		<>
			<LoadingBar color="#1fb0e5" height={4} ref={loadingBarRef} />
			<Container fluid>
				<div className="display-6">Import</div>
				<Card className="mt-2">
					<Card.Body>
						{getAlert(success, 'success')}
						{getAlert(warnings, 'warning')}
						{getAlert(errors, 'danger')}

						<FileUploader
							classes="dropzone text-center"
							fileOrFiles={file}
							types={fileTypes}
							handleChange={handleChange}
							onTypeError={onTypeError}
							name="file"
						>
							<div className="fs-4">
								<div>{t('drag_file_here_or_click_to_upload')}</div>
								<div className="fs-6 text-muted">
									{t('file_type')}: {fileTypes.join(', ')}
								</div>
							</div>

							{file ? <p className="mt-4">{(file as any).name}</p> : ''}
						</FileUploader>
					</Card.Body>
				</Card>
			</Container>
		</>
	);
};

export default ImportPage;
