import { Button, Card, Col, Row } from "react-bootstrap";
import { AccountEntitySearch } from "../../api/models/AccountEntity";
import dayjs from "dayjs";
import { FORMAT_DATE_TIME } from "../../utils/constants";
import { useTranslation } from 'react-i18next';

const AccountSmallPreview = ({ account, showAccountBtn = true }: { account: AccountEntitySearch, showAccountBtn?: boolean }) => {
    const { t } = useTranslation()

    return (
        <Card >
            <Card.Body>
                <Card.Subtitle className="mb-2 text-muted">{t('account_details')}</Card.Subtitle>
                <Card.Title>{account.Name}</Card.Title>
                <Row className="mt-4">
                    <Col>
                        <b>{t('type')}</b><br />
                        {account.RecordType?.Name || t('not_present')}
                    </Col>
                    <Col>
                        <b>{t('creation_date')}</b><br />
                        {dayjs(account.CreatedDate).format(FORMAT_DATE_TIME)}
                    </Col>
                    <Col>
                        <b>{t('phone')}</b><br />
                        {account.Phone || <span className='text-muted'>{t('not_present')}</span>}
                    </Col>
                    <Col>
                        <b>{t('email')}</b><br />
                        {account.Email__c || <span className='text-muted'>{t('not_present')}</span>}
                    </Col>
                </Row>
                {showAccountBtn && (
                    <Row className='mt-4'>
                        <Col>
                            <Button href={'/my-accounts/' + account.Id}>
                            {t('account_visualization')}
                            </Button>
                        </Col>
                    </Row>
                )}
            </Card.Body>
        </Card>
    )
};

export default AccountSmallPreview;