import i18next from 'i18next';  // Import the i18next instance


export const columnsData = [
  {
    accessor: 'id',
    id: 'id',
    Header: '',
    disableSortBy: true,
  },

  {
    id: 'checkbox',
    Header: i18next.t('select'),
    accessor: 'isChecked',
    width: "50px",
  },
  {
    Header: i18next.t('builder_code'),
    accessor: 'codice_costruttore',
  },
  {
    accessor: 'descrizione',
    id: i18next.t('description'),
    Header: 'Optional',
    width: 400,

  },
  {
    accessor: 'valore',
    id: 'valore',
    Header: i18next.t('cost'),
    width: 110,
    Cell: (data) => (
      <span>
        {data?.value?.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}
      </span>
    ),
  }
];
