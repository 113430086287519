import { NavLink } from 'react-router-dom';
import i18next from 'i18next';
import dayjs from 'dayjs';

import ColumnFilterMultiSelect from './components/filters/ColumnFilterMultiSelect';
import ColumnFilterInput from './components/filters/ColumnFilterInput';
import ColumnFilterDoubleInput from './components/filters/ColumnFilterDoubleInput';
import filterFn from './customFilterFn';
import Icon from '../Icon/Icon';
import { FORMAT_DATE } from '../../utils/constants';

export const ColumnsDataQuotationList = [
	{
		// this column is hidden (check VehicleTableContainer initialState)
		accessor: 'id',
		id: 'id',
		Header: '',
		disableSortBy: true,
		// enableSorting:false non funziona. Forse era solo per i global filters?
		// enableColumnFilter:false OPPURE disableFilterBy se esiste. Check
	},
	{
		accessor: 'marca',
		id: 'marca',
		Header: i18next.t('brand'),
		PrimaryFilter: ColumnFilterMultiSelect,
		filter: filterFn,
		width: 130,
	},
	{
		accessor: 'modello',
		id: 'modello',
		Header: i18next.t('model'),
		PrimaryFilter: ColumnFilterMultiSelect,
		width: 200,
		filter: filterFn,
	},
	{
		accessor: 'durata',
		id: 'durata',
		Header: i18next.t('duration'),
		inputType: 'number',
		PrimaryFilter: ColumnFilterInput,
		filter: filterFn,
		width: 180,
	},
	{
		accessor: 'anticipo',
		id: 'anticipo',
		Header: i18next.t('downpayment'),
		inputType: 'number',
		// PrimaryFilter: ColumnFilterInput,
		filter: filterFn,
		width: 180,
	},
	{
		accessor: 'km',
		id: 'km',
		Header: i18next.t('km_total'),
		inputType: 'number',
		PrimaryFilter: ColumnFilterInput,
		filter: filterFn,
		width: 190,
	},
	{
		accessor: 'data_inserimento',
		id: 'data_inserimento',
		Header: i18next.t('date_sent_request'),
		inputType: 'date',
		PrimaryFilter: ColumnFilterDoubleInput,
		filter: filterFn,
		width: 180,
		Cell: (data: any) => {
			const formattedDate = dayjs(data.value).format(FORMAT_DATE);
			return (
				<div>{formattedDate}</div>
			)
		},
	},
	{
		accessor: 'elenco_servizi',
		id: 'elenco_servizi',
		Header: i18next.t('services'),
		inputType: 'text',
		Cell: (data) => {
			return data.value.join(', ')
		},
		width: 500,
	},
	{
		accessor: 'note_generiche',
		id: 'note_generiche',
		Header: i18next.t('generic_notes'),
		inputType: 'text',
		// PrimaryFilter: ColumnFilterInput,
		filter: filterFn,
		width: 250,
	},
	{
		width: 60,
		accessor: 'actions',
		id: 'actions',
		Header: '',
		disableSortBy: true,
		headerClassName: 'column-sticky',
		className: 'column-sticky text-end',
		Cell: (data: any) => (
			<NavLink to={`/details/${data.row.original.id}`} className="btn">
				<Icon
					style={{ width: '1.2rem', height: '1.2rem' }}
					name="arrow-right"
				/>
			</NavLink>
		),
	},
]





