import { useState, useEffect, useMemo } from 'react';
import { FaFileCsv } from 'react-icons/fa';
import { ExportToCsv } from 'export-to-csv';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Column } from 'react-table';
import { Button, Col, Container, Row } from 'react-bootstrap';

import { useApiClient } from '../../hooks/useApiClient';
import VehicleTableContainer from '../../components/VehicleTable/VehicleTableContainer';
import { columnsData } from '../../components/VehicleTable/columnsData';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import toaster from '../../utils/toaster';
import { GTMsendEvent } from '../../utils/trackingGA4'
import { normalizeVehicleList } from '../../utils/vehicleList';

const QuickoVehiclesPage = () => {
	const { apiClient, handleApi } = useApiClient();
	const { t } = useTranslation()

	const [response, setResponse]: [any, any] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	let downloadData: any;

	const setDownloadData = (data: any) => {
		downloadData = data;
	};

	const hanldeExportButton = async () => {
		const options = {
			filename: 'quicko_vehicles_list',
		};

		const csvExporter = new ExportToCsv(options);
		csvExporter.generateCsv(downloadData);

		// send event to GA4
		GTMsendEvent({
			event: "CTA_download_file",
			evtCat: 'lista_veicoli_promo',
			evtAct: "download",
			evtLab: `${options.filename}.csv`
		})
	};

	useEffect(() => {
		handleApi(apiClient.vehicleController.getVehiclesFilter())
			.then((res: any[]) => {
				// temp vehicles ids that have low margin error in quicko inference
				const quickoVehiclesIds = [
					"66aa5330639e0c4cb80a1aea",
					"66aa5266639e0c4cb80a1ae8",
					"66aa44bca4a37513cf1c2af8",
					"66aa44f4a4a37513cf1c2afa",
					"66aa4517a4a37513cf1c2afc",
					"66aa4530a4a37513cf1c2afe",
					"66aa45f9a4a37513cf1c2b02",
					"66aa4aa2ea296c2c9a62a5be",
					"66aa4896def75f1a0a18cbcd",
					"66aa453fa4a37513cf1c2b00",
					"66aa486bdef75f1a0a18cbcb",
					"66aa481111a7891bf37b0786",
					"66aa465ea4a37513cf1c2b0c",
					"66aa4652a4a37513cf1c2b0a",
					"66aa462da4a37513cf1c2b06",
					"66aa461ea4a37513cf1c2b04",
					"66aa4f66ea296c2c9a62a5c0",
					"66aa4ed0a6f76d726145e0b9",
					"66aa4ec3a6f76d726145e0b7",
					"66aa4eaca6f76d726145e0b3",
					"66aa4e95a6f76d726145e0b1",
					"66aa4b47afe3ea4f3721d759",
					"66aa4b2fafe3ea4f3721d757",
					"66aa4b21afe3ea4f3721d755",
					"66aa4b08afe3ea4f3721d753",
					"66aa50ce888a057ff8852843",
					"66aa5040888a057ff8852841",
					"66aa50ce888a057ff8852843",
					"66aa5040888a057ff8852841",
					"66aa502f888a057ff885283f",
					"66aa5022888a057ff885283d",
					"66aa5017888a057ff885283b",
					"66aa5008888a057ff8852839",
					"66aa4f84888a057ff8852833",
					"66aa4f72ea296c2c9a62a5c2",
					"66aa5330639e0c4cb80a1aea",
					"66aa5266639e0c4cb80a1ae8",
					"66aa522c61ea1d0eb2f9cde6",
					"66aa50f9888a057ff8852849",
					"66aa50df888a057ff8852845",
					"66aa525761ea1d0eb2f9cdea",
					"66aa4f94888a057ff8852835",
					"66ab5b551b048542e8f8f2ef",
					"66ab5b3d1b048542e8f8f2ed",
					"66ab5b2a1b048542e8f8f2eb",
					"66ab5b161b048542e8f8f2e9",
					"66ab5acc2f265e739db3c462",
					"66ab5ab42f265e739db3c460",
					"66ab5aa32f265e739db3c45e",
					"66ab5a8c2f265e739db3c45c",
					"66ab5a132f265e739db3c45a",
					"66ab5a062f265e739db3c458",
					"66ab59f42f265e739db3c456",
					"66ab59a71b048542e8f8f2e7",
					"66ab599a1b048542e8f8f2e5",
					"66ab598a1b048542e8f8f2e3",
					"66ab597b1b048542e8f8f2e1",
					"66ab58e61b048542e8f8f2df",
					"66ab58d91b048542e8f8f2dd",
					"66ab58ba1b048542e8f8f2d9",
					"66ab58584fd7ac65fc34b4ba",
					"66ab58454fd7ac65fc34b4b8",
					"66ab58254fd7ac65fc34b4b6",
					"66ab57f1ff706302bebe07e0",
					"66ab4114c78e6548636d810d",
					"66ab40f8c78e6548636d810b",
					"66ab40ecc78e6548636d8109",
					"66ab40d3c78e6548636d8107",
					"66ab403a0015b633eb962f4d",
					"66ab3ed70015b633eb962f4b",
					"66ab3ecd0015b633eb962f49",
					"66ab3ec10015b633eb962f47",
					"66ab3eb20015b633eb962f45",
					"66ab3ea50015b633eb962f43",
					"66ab3da30015b633eb962f40",
					"66ab3d940015b633eb962f3e",
					"66ab3d870015b633eb962f3c",
					"66ab3d607d92360f9f23edd8",
					"66ab39e00015b633eb962f38",
					"66ab39c20015b633eb962f36",
					"66aa537f639e0c4cb80a1aec",
					"66aa5393639e0c4cb80a1aee",
					"66aa53b561ea1d0eb2f9cdec",
					"66aa55ffba2573745218fa8a",
					"66aa566047317376a3252104",
					"66aa566d47317376a3252106",
					"66aa568247317376a3252108",
					"66aa569747317376a325210a",
					"66ab3975c5d68f36f1e258e8",
					"66900d917f6c8b426910e8c6",
					"66900d917f6c8b426910e8c5",
					"6669ac98d9b9fb0acbb4de83",
					"6669ac98d9b9fb0acbb4de82",
					"6669ac98d9b9fb0acbb4de81",
					"65c1ea00f5447342d9eafd77",
					"65c1ea00f5447342d9eafd76",
					"65c1ea00f5447342d9eafd75",
					"65c1ea00f5447342d9eafd74",
					"65c1ea00f5447342d9eafd73"
				];


				res = res.filter(vehicle => {
					if (quickoVehiclesIds.includes(vehicle.id)) {
						return vehicle
					}
				})

				res = normalizeVehicleList(res);

				res.sort((a, b) => {
					if (a.stato_corrente.type === 'DISPONIBILE') return -1;
					if (b.stato_corrente.type === 'DISPONIBILE') return 1;
					return 0;
				})

				setResponse(res);
				setLoading(false);
			})
			.catch((err) => {
				setError(err);
				setLoading(false);
				toaster.error(
					err.message || i18next.t('error_loading_data')
				);
			});

	}, []);

	const columns = useMemo(() => columnsData as Column<Object>[], []);
	const data = useMemo(() => response ?? [], [response]);

	return (
		<Container fluid>
			<Row>
				<Col md={6} className="display-6">
					{t('quicko-vehicles-list')}
				</Col>

				<Col md={6} className="mt-4 mt-md-0 text-md-end">
					<Button className="btn me-3" onClick={hanldeExportButton}>
						<FaFileCsv className="fs-5" /> {t('download')}
					</Button>
				</Col>
			</Row>

			<ErrorBoundary>
				<VehicleTableContainer
					columns={columns}
					data={data}
					setDownloadData={setDownloadData}
					loading={loading}
					error={error}
				></VehicleTableContainer>
			</ErrorBoundary>
		</Container>
	);
};

export default QuickoVehiclesPage;
