import { useState, useEffect, useMemo } from 'react';
import { useApiClient } from '../../hooks/useApiClient';
import { useAuth } from '../../hooks/useAuth';
import VehicleTableContainer from '../../components/VehicleTable/VehicleTableContainer';
import QuotationListTableContainer from '../../components/QuotationList/QuotationListTableContainer';

import { Column } from 'react-table';
import { columnsDataWishlist } from '../../components/VehicleTable/columnsDataWishlist';
import { ColumnsDataQuotationList } from '../../components/QuotationList/ColumnsDataQuotationList';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import { VehicleStateEnum } from '../../api';
import { Roles } from '../../types';
import { useSearchParams, useParams } from 'react-router-dom';

import { Button, Col, Container, Row } from 'react-bootstrap';
import Restricted from '../../components/Restricted/Restricted';
import toaster from '../../utils/toaster';
import { useRefresh } from '../../hooks/useRefresh';

const WishlistPage = () => {
	const { apiClient, handleApi } = useApiClient();
	const { user, userRole } = useAuth();
	const [searchParams] = useSearchParams();
	const [itsMe, setItsMe] = useState(false)

	const [response, setResponse]: [any, any] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [quotationsResponse, setQuotationsResponse]: [any, any] = useState(null);
	const [quotationsLoading, setQuotationsLoading] = useState(false);
	const [quotationsError, setQuotationsError] = useState(null);



	const { username } = useParams<{ username: string }>();
	const refresh = useRefresh();
	const businessName = localStorage.getItem('business_name');

	const deleteWishlistFunc = (vehicleId) => {
		handleApi(apiClient.vehicleController.deleteWishlist(vehicleId))
			.then((res) => {
				toaster.success('Il veicolo è stato rimosso con successo dai preferiti');
				setLoading(false);
				refresh();
			})
			.catch((err) => {
				setError(err);
				setLoading(false);
				toaster.error(
					err.message || "Errore non specificato durante l'eliminazione dei dati"
				);
			});
	};

	useEffect(() => {
		user.username === username && setItsMe(true)

		handleApi(apiClient.vehicleController.getWishlist(username ? username : user.username))
			.then((res) => {
				res.forEach((elem) => {
					elem.deleteWishlist = deleteWishlistFunc;
					elem.in_wishlist = false;
				});
				setResponse(res);
				setLoading(false);
			})
			.catch((err) => {
				setError(err);
				setLoading(false);
			});

		if (username) {
			handleApi(apiClient.vehicleController.getQuotationRequests(username))
				.then((res) => {
					setQuotationsResponse(res);
					setQuotationsLoading(false);
				})
				.catch((err) => {
					setQuotationsError(err);
					setQuotationsLoading(false);
					toaster.error(
						err.message || 'Errore non specificato durante il caricamento delle richieste di preventivo'
					);
				});
		}
	}, []);

	const columns = useMemo(() => columnsDataWishlist as Column<Object>[], []);
	const data = useMemo(() => response ?? [], [response]);

	const quotationsColumns = useMemo(() => ColumnsDataQuotationList as Column<Object>[], []);
	const quotationsData = useMemo(() => quotationsResponse ?? [], [quotationsResponse]);

	return (
		<Container fluid>
			<Row>
				<Col md={12} className="display-6">
					{username ? `I Preferiti di ${businessName}` : 'I Miei Preferiti'}
				</Col>
			</Row>

			<ErrorBoundary>
				<VehicleTableContainer
					columns={columns}
					data={data}
					loading={loading}
					error={error}
				></VehicleTableContainer>
			</ErrorBoundary>



			{userRole !== 'AZIENDA' ?
				<div>
					<Row>
						<Col md={12} className="display-6">
							Lista Richieste Preventivi
						</Col>
					</Row>
					<ErrorBoundary>
						<QuotationListTableContainer
							columns={quotationsColumns}
							data={quotationsData}
							loading={quotationsLoading}
							error={quotationsError}
						></QuotationListTableContainer>
					</ErrorBoundary>
				</div>
				: ""
			}
		</Container>
	);
};

export default WishlistPage;
