import { Form, InputGroup } from 'react-bootstrap';

const ColumnFilterDoubleInput = ({
	props,
	column: { filterValue, setFilter, preFilteredRows, id },
}) => {
	const { filterState, setFilterState, type } = props;

	const handleChange = (e) => {

		setFilterState((prevState) => {
			const newState = {
				...prevState,
				[id]: {
					...prevState[id],
					[e.target.name]: e.target.value,
				},
			};

			return newState;
		});
	};

	return (
		// https://stackoverflow.com/questions/20321202/not-showing-placeholder-for-input-type-date-field
		<InputGroup>
			<Form.Control
				name="from"
				size="lg"
				type={type}
				placeholder="da..."
				value={filterState[id].from}
				onChange={handleChange}
			/>
			<Form.Control
				name="to"
				size="lg"
				type={type}
				placeholder="a..."
				value={filterState[id].to}
				onChange={handleChange}
			/>
		</InputGroup>
	);
};

export default ColumnFilterDoubleInput;
