import { useAuth } from '../../hooks/useAuth';
import { Roles } from '../../types';

const Restricted = ({ to, children }) => {
	const { userRole } = useAuth();

	if (userRole === Roles.Admin) {
		return <>{children}</>;
	}

	if (to.includes(userRole)) {
		return <>{children}</>;
	}

	return null;
};

export default Restricted;
