import { Card, Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../hooks/useAuth';
import Btn from '../Btn/Btn';
import RequiredFormLabel from '../RequiredFormLabel/RequiredFormLabel';
import SelectOptWithSubtitle from '../SelectOptWithSubtitle/SelectOptWithSubtitle';
import CardPlaceholder from '../CardPlaceholder/CardPlaceholder';
import { Roles } from '../../types';
import i18next from 'i18next';
function UserArea({
	initialValues,
	validateFn,
	username,
	submitFnFactory,
	loading,
	stringToOption,
	dealerOptions,
	itsMe,
	roles,
	userSales,
	companyDetail,
	referralPartners,
	backofficeUsers,
}) {
	let { userRole } = useAuth();
  const { t } = useTranslation()

	return (
		<>
			{loading ? (
				<CardPlaceholder />
			) : (
				<>
					{initialValues && (
						<Card className="mt-2">
							<Card.Body>
								<Formik
									initialValues={initialValues}
									validate={validateFn}
									onSubmit={submitFnFactory(username)}
								>
									{({
										values,
										errors,
										touched,
										handleChange,
										handleBlur,
										handleSubmit,
										isSubmitting,
										setFieldValue,
									}) => {
										let additionalFields;
										if (!values.role || values.role === 'BACKOFFICE') {
											additionalFields = null;
										}
										if (values.role === 'DEALER') {
											additionalFields = (
												<>
													<Row>
														<Col md={6}>
															<Form.Group className="mb-3">
																<Form.Label>{t('group')}</Form.Label>
																<Form.Control
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.group}
																	name="group"
																	type="text"
																/>
															</Form.Group>
														</Col>
														<Col md={6}>
															<Form.Group className="mb-3">
																<Form.Label>{t('dealer')}</Form.Label>
																<Select
																	value={stringToOption(values.dealer)}
																	onBlur={handleBlur}
																	onChange={(opt) => {
																		setFieldValue('dealer', opt?.value || '');
																	}}
																	name="dealer"
																	options={dealerOptions}
																/>
															</Form.Group>
														</Col>
													</Row>
													<Row>
														<Col md={6}>
															<Form.Group className="mb-3">
																<Form.Label>{t('functional_role')}</Form.Label>
																<Form.Control
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.role_ext}
																	name="role_ext"
																	type="text"
																/>
															</Form.Group>
														</Col>
													</Row>
												</>
											);
										}
										if (values.role === 'SALES') {
											additionalFields = (
												<Row>
													<Col md={6}>
														<Form.Group className="mb-3">
															<Form.Label>{t('phone_backup')}</Form.Label>
															<Form.Control
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.phone_backup}
																name="phone_backup"
																type="text"
															/>
														</Form.Group>
													</Col>
													<Col md={6}>
														<Form.Group className="mb-3">
															<Form.Label>{t('email_backup')}</Form.Label>
															<Form.Control
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.email_backup}
																name="email_backup"
																type="text"
															/>
														</Form.Group>
													</Col>
													<Col md={6}>
															<Form.Group className="mb-3">
																<Form.Label>
																{t('salesforce_seller')}: {values.is_salesforce_sales ? 'Si' : 'No'}
																</Form.Label>
																<Form.Check
																	name="is_salesforce_sales"
																	type="switch"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.is_salesforce_sales}
																	checked={values.is_salesforce_sales}
																	disabled={itsMe ? true : false}
																	className="hor-form-check-switch"
																/>
															</Form.Group>
														</Col>
												</Row>
											);
										}

										return (
											<Form onSubmit={handleSubmit}>
												<Row>
													<Col md={6}>
														<Form.Group className="mb-3">
															<Form.Label>
															{t('username')} ({t('email')})
																<span className="red-ast">*</span>
															</Form.Label>
															<Form.Control
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.username}
																name="username"
																type="text"
																disabled={itsMe ? true : false}
																className={`${errors.username && touched.username
																	? 'error-border'
																	: ''
																	}  ${itsMe ? 'grey-out' : ''}`}
															/>
															{errors.username && touched.username && (
																<small className="text-danger">
																	{errors.username as string}
																</small>
															)}
														</Form.Group>
													</Col>
													{values.role === 'AZIENDA' && (
														<Col md={6}>
															<Form.Group className="mb-3">
																<Form.Label>{t('business_name')}</Form.Label>
																<span className="red-ast">*</span>
																<Form.Control
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.business_name}
																	name="business_name"
																	type="text"
																	disabled={itsMe ? true : false}
																	className={`${errors.business_name && touched.business_name
																		? 'error-border'
																		: ''
																		}  ${itsMe ? 'grey-out' : ''}`}
																/>
																{errors.business_name && touched.business_name && (
																	<small className="text-danger">
																		{errors.business_name as string}
																	</small>
																)}
															</Form.Group>
														</Col>
													)}
													<Col md={6}>
														<Form.Group className="mb-3">
															<Form.Label>
															{t('role')}
																<span className="red-ast">*</span>
															</Form.Label>

															<Select
																value={stringToOption(values.role)}
																onBlur={handleBlur}
																onChange={(opt) => {
																	setFieldValue('role', opt?.value || '');

																	if (
																		!['MASTER', 'BACKOFFICE', 'SALES'].includes(
																			opt?.value
																		)
																	) {
																		setFieldValue('reference_partner', []);
																	} else if (
																		values.reference_partner.length > 1 &&
																		!['MASTER', 'BACKOFFICE'].includes(opt?.value)
																	) {
																		// If we change the role to a value lower than BACKOFFICE,
																		// we ensure that the user has only one parter id associeted to it
																		setFieldValue('reference_partner', [
																			values.reference_partner.at(-1),
																		]);
																	}
																}}
																name="role"
																options={roles}
																isDisabled={['SALES', 'DEALER', 'AZIENDA'].includes(
																	userRole
																)}
															/>

															{errors.role && touched.role && (
																<small className="text-danger">
																	{errors.role as string}
																</small>
															)}
														</Form.Group>
													</Col>
													<Col md={6}>
														<Form.Group className="mb-3">
															<Form.Label>
															{t('name')}
																<span className="red-ast">*</span>
															</Form.Label>
															<Form.Control
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.given_name}
																name="given_name"
																type="text"
																className={`${errors.given_name && touched.given_name
																	? 'error-border'
																	: ''
																	}`}
															/>
															{errors.given_name && touched.given_name && (
																<small className="text-danger">
																	{errors.given_name as string}
																</small>
															)}
														</Form.Group>
													</Col>
													<Col md={6}>
														<Form.Group className="mb-3">
															<Form.Label>
															{t('surname')}
																<span className="red-ast">*</span>
															</Form.Label>
															<Form.Control
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.family_name}
																name="family_name"
																type="text"
																className={`${errors.family_name && touched.family_name
																	? 'error-border'
																	: ''
																	}`}
															/>
															{errors.family_name && touched.family_name && (
																<small className="text-danger">
																	{errors.family_name as string}
																</small>
															)}
														</Form.Group>
													</Col>
													{values.role === 'AZIENDA' && (
														<Col md={6}>
															<Form.Group className="mb-3">
																<Form.Label>{t('sales')}</Form.Label>
																<Select
																	value={values.sales}
																	onBlur={handleBlur}
																	onChange={(opt) => {
																		setFieldValue('sales', opt || []);
																	}}
																	isMulti={true}
																	name="sales"
																	options={userSales}
																/>
															</Form.Group>
														</Col>
													)}
													<Col md={6}>
														<Form.Group className="mb-3">
															<Form.Label>{t('phone')}</Form.Label>
															<Form.Control
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.phone}
																name="phone"
																type="text"
															/>
														</Form.Group>
													</Col>
													{['MASTER', 'BACKOFFICE', 'SALES'].includes(
														values.role
													) && (
															<Col md={6}>
																<Form.Group className="mb-3">
																	<RequiredFormLabel label={i18next.t('reference_branch')} />
																	<Select
																		isMulti
																		value={values.reference_partner}
																		onBlur={handleBlur}
																		onChange={(opt) => {
																			if (
																				[Roles.Master, Roles.Backoffice].includes(userRole)
																			) {
																				if (values.role === 'SALES') {
																					setFieldValue('reference_partner', [
																						opt.at(-1),
																					]);
																				} else {
																					setFieldValue('reference_partner', opt);
																				}
																			}
																		}}
																		name="reference_partner"
																		noOptionsMessage={() => `${i18next.t('loading')}...`}
																		options={referralPartners}
																		placeholder={t('select_branch')}
																		isDisabled={
																			![Roles.Master, Roles.Backoffice].includes(userRole)
																		}
																	// La modifica del partner di riferimento deve essere sempre
																	// possibile per tutti gli account che possono creare un master
																	/>
																	{errors.reference_partner &&
																		touched.reference_partner && (
																			<small className="text-danger">
																				{errors.reference_partner as string}
																			</small>
																		)}
																</Form.Group>
															</Col>
														)}
													{['SALES'].includes(values.role) && (
														<Col md={6}>
															<Form.Group className="mb-3">
																<RequiredFormLabel label={i18next.t('reference_backoffice')} />
																<Select
																	isDisabled={![Roles.Master, Roles.Backoffice].includes(userRole)}
																	value={values.reference_backoffice}
																	onBlur={handleBlur}
																	onChange={(opt) => {
																		setFieldValue('reference_backoffice', opt);
																	}}
																	name="reference_backoffice"
																	noOptionsMessage={() => `${i18next.t('loading')}`}
																	options={backofficeUsers}
																	components={{
																		Option: (props: any) => {
																			return (
																				<SelectOptWithSubtitle
																					{...props}
																				></SelectOptWithSubtitle>
																			);
																		},
																	}}
																/>
																{errors.backoffice && touched.backoffice && (
																	<small className="text-danger">
																		{errors.backoffice as string}
																	</small>
																)}
															</Form.Group>
														</Col>
													)}
													{values.role === 'AZIENDA' && (
														<Col md={6}>
															<Form.Group className="mb-3">
																<Form.Label>{t('typology')}</Form.Label>

																<Select
																	value={stringToOption(values.company_detail)}
																	onBlur={handleBlur}
																	onChange={(opt) => {
																		setFieldValue(
																			'company_detail',
																			opt?.value || ''
																		);
																	}}
																	name="company_detail"
																	options={companyDetail}
																/>
																{errors.role && touched.role && (
																	<small className="text-danger">
																		{errors.role as string}
																	</small>
																)}
															</Form.Group>
														</Col>
													)}
													{values.role === 'AZIENDA' && (
														<Col md={6}>
															<Form.Group className="mb-3">
																<Form.Label>
																	{values.is_active_email
																		? i18next.t('email_notifications')
																		: i18next.t('no_email_notfications')}
																</Form.Label>
																<Form.Check
																	name="is_active_email"
																	type="switch"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.is_active_email}
																	checked={values.is_active_email}
																	disabled={itsMe ? true : false}
																	className="hor-form-check-switch"
																/>
															</Form.Group>
														</Col>
													)}
												</Row>

												{additionalFields}
												<hr />

												<Row>
													{username !== 'new' && (
														<Col md={6}>
															<Form.Group className="mb-3">
																<Form.Label>
																	{values.is_active ? i18next.t('active') : i18next.t('inactive')}
																</Form.Label>
																<Form.Check
																	name="is_active"
																	type="switch"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.is_active}
																	checked={values.is_active}
																	disabled={itsMe ? true : false}
																	className="hor-form-check-switch"
																/>
															</Form.Group>
														</Col>
													)}
												</Row>

												<Btn
													type="submit"
													icon="arrow-right"
													className="float-end"
													disabled={isSubmitting}
												>
													{username !== 'new' ? i18next.t('update') : i18next.t('create')}
												</Btn>
											</Form>
										);
									}}
								</Formik>
							</Card.Body>
						</Card>
					)}
				</>
			)}
		</>
	);
}

export default UserArea;
