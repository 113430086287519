import { getCookieValue } from '../utils/index'

interface EcommerceEventProps {
  event: string,

  // login
  userId?: string,
  userType?: string, 

  // select_item, complete_action, view_item
  item_id?: string, //ordine commerciale
  item_name?: string, // marca+modello+allestimento
  affiliation?: string //nome partner dealer
  item_category?: string, // marca
  item_category2?: string, //modello+allestimento
  item_category3?: string, //codiceCostruttore
  item_category4?: string, //disponibilità
  item_category5?: string, //disponibilità
  item_category6?: string, //disponibilità
  item_category7?: string, //disponibilità
  note_gerarchiche?: string
  advance?: string, //anticipo
  price?: number, //canone mensile

  // CTA event
  category?: string,
  action?: string,
  label?: string,
  actionType?: string,
  evtCat?: string, // categoria
  evtAct?: string, // azione
  evtLab?: string, // label

  // wishlist
  wishlist_action?: string,
  item_variant?: string,
  colour?: string

  // dealer
  groupName?: string
}

export const GTMsendEvent = (props: EcommerceEventProps) => {
  if (dataLayerExists()) {

    const filteredItems: EcommerceEventProps = {
      event: props.event
    };

    const userIdFromCookies = getCookieValue('userID');
    const userIdfromLocalStorage = localStorage.getItem('userID')
    for (const [key, value] of Object.entries(props)) {
      if (key !== 'event' && props[key] !== undefined && props[key] !== null && props[key] !== '') {
        filteredItems[key] = value
      }
    }

    (window as any).dataLayer.push({
      ...filteredItems,
      userID: userIdFromCookies ? userIdFromCookies : userIdfromLocalStorage
    });
  }
};

export const updateConsent = () => {
  if (typeof (window as any).gtag === 'function') {
     (window as any).gtag('consent', 'update', {
      'ad_storage': 'granted',
      'analytics_storage': 'granted',
      'functionality_storage': 'granted',
      'personalization_storage': 'granted'
    });
  } else {
    console.error('gtag is not defined');
  }
}

export const sendLoginToGTM = (consent, userRole) => {
  consent && GTMsendEvent({
    event: 'login',
    userType: userRole
  })
}

const dataLayerExists = () => {
  return (window as any).dataLayer && Array.isArray((window as any).dataLayer)
}