import { Spinner } from "react-bootstrap";

const SpinnerButton = ({ label, busy }: { label: string; busy: boolean }) => {
    return (
        <span>
            {busy ? <Spinner animation='border' size="sm" /> : label}
        </span>
    )
}

export default SpinnerButton;