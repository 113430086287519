import {
	useTable,
	useGlobalFilter,
	useFilters,
	useSortBy,
	usePagination,
	TableOptions,
	useBlockLayout,
} from 'react-table';

import UserAziendeTable from './UserAziendeTable'
import UserAziendePagination from './UserAziendePagination';
import './UserAziendeTable.scss';

interface TableContainerProps {
	columns: any;
	data: any;
	loading?: any;
	error?: any;
	presetFilter?: any;
}

const UserAziendeTableContainer: React.FC<TableContainerProps> = ({
	columns,
	data,
	loading,
	error,
	presetFilter = null,
}) => {

	const tableOptions: TableOptions<Object> = {
		columns,
		data,
		initialState: { pageSize: 5, hiddenColumns: ['id'] },
	};

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		rows,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
	} = useTable(
		tableOptions,
		useGlobalFilter,
		useFilters,
		useSortBy,
		usePagination,
		useBlockLayout
	);

	return (
		<>
			{/* <UserAziendeFilters
				headerGroups={headerGroups}
				setAllFilters={setAllFilters}
				loading={loading}
				presetFilter={presetFilter}
			></UserAziendeFilters> */}

			{!loading && !error && (
				<>
					<UserAziendeTable
						getTableProps={getTableProps}
						headerGroups={headerGroups}
						getTableBodyProps={getTableBodyProps}
						page={page}
						prepareRow={prepareRow}
						resultCount={rows.length}
					></UserAziendeTable>

					<UserAziendePagination
						gotoPage={gotoPage}
						canPreviousPage={canPreviousPage}
						previousPage={previousPage}
						nextPage={nextPage}
						canNextPage={canNextPage}
						pageCount={pageCount}
						state={state}
						pageOptions={pageOptions}
						setPageSize={setPageSize}
					></UserAziendePagination>
				</>
			)}

			{error && (
				<>
					<div className="table-error-message">{error.message}</div>
				</>
			)}
		</>
	);
};

export default UserAziendeTableContainer;
