/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContattoEntity } from '../models/ContattoEntity';
import type { DealerDTO } from '../models/DealerDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DealerControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) { }

    /**
     * @returns DealerDTO OK
     * @throws ApiError
     */
    public getRegisterDealers(): CancelablePromise<Array<DealerDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/register/dealers',
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public postRegisterDealers(
        requestBody?: DealerDTO,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/register/dealers',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
     * @param id
     * @returns DealerDTO OK
     * @throws ApiError
     */
    public getRegisterDealers1(
        id: string,
    ): CancelablePromise<DealerDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/register/dealers/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
    * @param id
    * @returns DealerDTO OK
    * @throws ApiError
    */
    public getRegisterDealers2(
        ragione_sociale: string,
    ): CancelablePromise<DealerDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/register/dealers/{ragione_sociale}/detail',
            path: {
                'ragione_sociale': ragione_sociale,
            },
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putRegisterDealers(
        id: string,
        requestBody?: DealerDTO,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/register/dealers/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
     * @param id
     * @returns void
     * @throws ApiError
     */
    public deleteRegisterDealers(
        id: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/register/dealers/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

    /**
     * @param ragioneSociale
     * @param ruolo
     * @returns ContattoEntity OK
     * @throws ApiError
     */
    public getRegisterDealersContacts(
        ragioneSociale: string,
        ruolo: Array<string>,
    ): CancelablePromise<Array<ContattoEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/register/dealers/{ragione_sociale}/contacts',
            path: {
                'ragione_sociale': ragioneSociale,
            },
            query: {
                'ruolo': ruolo,
            },
            errors: {
                401: `Not Authorized`,
                403: `Not Allowed`,
            },
        });
    }

}
