import i18next from 'i18next';
import { useState, useEffect, useMemo } from 'react';
import { Column } from 'react-table';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import toaster from '../../utils/toaster';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import { useApiClient } from '../../hooks/useApiClient';
import { ColumnsDataQuotationList } from '../../components/QuotationList/ColumnsDataQuotationList';
import QuotationListTableContainer from '../../components/QuotationList/QuotationListTableContainer';

function QuotationList() {
  const { apiClient, handleApi } = useApiClient();
  const [quotationsResponse, setQuotationsResponse]: [any, any] = useState(null);
  const [quotationsLoading, setQuotationsLoading] = useState(false);
  const [quotationsError, setQuotationsError] = useState(null);

  useEffect(() => {
		handleApi(apiClient.vehicleController.getQuotationRequestsForAzienda())
				.then((res) => {
					setQuotationsResponse(res);
					setQuotationsLoading(false);
				})
				.catch((err) => {
					setQuotationsError(err);
					setQuotationsLoading(false);
					toaster.error(
						err.message || i18next.t('unspecified_error_loading_quotation_requests')
					);
				});
  }, []);

  const quotationsColumns = useMemo(() => ColumnsDataQuotationList as Column<Object>[], []);
  const quotationsData = useMemo(() => quotationsResponse ?? [], [quotationsResponse]);

  return (
    <Container fluid>
      <Row>
        <Col md={6} className="display-6">
          Richieste Preventivi
        </Col>
      </Row>
      <ErrorBoundary>

        <QuotationListTableContainer
          columns={quotationsColumns}
          data={quotationsData}
          loading={quotationsLoading}
          error={quotationsError}
        ></QuotationListTableContainer>
      </ErrorBoundary>


    </Container>
  );
}

export default QuotationList


